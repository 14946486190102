<template>
  <b-overlay
    :show="loading"
    spinner-variant="secondary"
    bg-color="#fff"
    opacity="1"
    rounded="sm"
  >
    <b-card class="card-stats">
      <h2 class="stat-title">{{ title }}</h2>
      <div class="stat-value">
        <template v-if="currency">&euro;</template>
        {{ $options.filters.formatNumber(value, currency ? 2 : 0, true) }}
        <span class="stat-value-prev">
          <template v-if="currency">&euro;</template>
          {{ $options.filters.formatNumber(oldValue, currency ? 2 : 0, true) }}
        </span>
      </div>

      <span v-if="iconVisible" class="stat-icon">
        <span :class="{ 'd-none': trendDown }">
          <i class="far fa-arrow-trend-up text-success" />
        </span>
        <span :class="{ 'd-none': trendUp }">
          <i class="far fa-arrow-trend-down text-danger" />
        </span>
      </span>
    </b-card>
  </b-overlay>
</template>

<script>
  export default {
    props: {
      loading: {
        type: Boolean,
        default: () => {
          return false;
        },
      },

      value: {
        type: Number,
        default: () => {
          return 0;
        },
      },

      oldValue: {
        type: Number,
        default: () => {
          return 0;
        },
      },

      title: {
        type: String,
        default: () => {
          return "{Titel}";
        },
      },

      currency: {
        type: Boolean,
        default: () => {
          return true;
        },
      },
    },

    computed: {
      iconVisible() {
        return this.value !== this.oldValue;
      },

      trendUp() {
        return this.value > this.oldValue;
      },

      trendDown() {
        return this.value < this.oldValue;
      },
    },
  };
</script>