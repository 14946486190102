<template>
  <div>
    <b-card header="Leveranciers">
      <b-row>
        <b-col md="12">
          <b-form-group label="Zoeken" description="Zoek op naam">
            <b-input
              type="search"
              v-model="filters.terms"
              @change="filter"
              placeholder="Zoek een leverancier"
            />
          </b-form-group>
        </b-col>

        <b-col class="text-right">
          <b-button :to="{ name: 'supplier.create' }">
            <i class="far fa-plus"></i> Nieuwe leverancier
          </b-button>
        </b-col>
      </b-row>

      <b-table
        striped
        bordered
        hover
        show-empty
        @row-contextmenu="rowContextMenuHandler"
        @row-clicked="$root.rowClickRedirect($event, 'supplier.show')"
        :items="suppliers"
        :fields="fields"
        :busy="loading.suppliers"
      >
        <template #table-busy>
          <div class="text-center">
            <b-spinner
              variant="secondary"
              class="align-middle mr-3"
            ></b-spinner>
            <strong class="align-middle">Leveranciers worden geladen</strong>
          </div>
        </template>

        <template #cell(purchase_value_30)="row">
          <div class="stat-wrapper">
            <div :class="{ 'text-strong': row.item.purchase_value_30 > 0 }">
              &euro;
              {{ row.item.purchase_value_30 | formatNumber(2, true, true) }}
            </div>
          </div>
        </template>

        <template #cell(purchase_value_60)="row">
          <div class="stat-wrapper">
            <div :class="{ 'text-strong': row.item.purchase_value_60 > 0 }">
              &euro;
              {{ row.item.purchase_value_60 | formatNumber(2, true, true) }}
            </div>
          </div>
        </template>

        <template #cell(purchase_value_90)="row">
          <div class="stat-wrapper">
            <div :class="{ 'text-strong': row.item.purchase_value_90 > 0 }">
              &euro;
              {{ row.item.purchase_value_90 | formatNumber(2, true, true) }}
            </div>
          </div>
        </template>

        <template #cell(purchase_value)="row">
          <div class="stat-wrapper">
            <div :class="{ 'text-strong': row.item.purchase_value > 0 }">
              &euro;
              {{ row.item.purchase_value | formatNumber(2, true, true) }}
            </div>
          </div>
        </template>

        <template #empty="">
          <span class="text-center d-block">
            Er zijn geen leveranciers gevonden
          </span>
        </template>
      </b-table>
    </b-card>

    <context-menu
      :id="$route.name"
      :key="$route.fullPath"
      :display="showContextMenu"
      component="supplier"
      ref="menu"
    />
  </div>
</template>

<script>
  import ContextMenu from "@/components/ContextMenu";

  export default {
    components: {
      ContextMenu,
    },

    data() {
      return {
        showContextMenu: false,

        fields: [
          { key: "name", label: "Naam" },
          {
            key: "purchase_value_30",
            label: "Inkoopwaarde (30 dagen)",
            class: "column-stat",
            sortable: true,
          },
          {
            key: "purchase_value_60",
            label: "Inkoopwaarde (60 dagen)",
            class: "column-stat",
            sortable: true,
          },
          {
            key: "purchase_value_90",
            label: "Inkoopwaarde (90 dagen)",
            class: "column-stat",
            sortable: true,
          },
          {
            key: "purchase_value",
            label: "Inkoopwaarde",
            class: "column-stat",
            sortable: true,
          },
        ],

        suppliers: [],

        loading: {
          suppliers: false,
        },

        filters: {
          terms: null,
        },
      };
    },

    mounted() {
      this.filters = this.$root.setFilters(this.filters);
      this.index();
    },

    methods: {
      filter() {
        this.$root.applyFilters(this.filters);
        this.index();
      },

      index() {
        this.loading.suppliers = true;

        let params = {
          ...this.filters,
        };

        this.$http
          .get("suppliers", {
            params: params,
          })
          .then((response) => {
            this.suppliers = response.data.data;
          })
          .then(() => {
            this.loading.suppliers = false;
          });
      },

      rowContextMenuHandler(item, _, event) {
        this.$refs.menu.open(event, item);
        event.preventDefault();
      },
    },
  };
</script>