<template>
  <div class="login">
    <div>
      <img
        class="login__logo"
        src="@/assets/images/logo.svg"
        alt="Floer B.V."
      />
    </div>

    <b-card class="login__wrapper">
      <Windmill />

      <p class="text-danger mb-3" v-if="!!errors.default">
        {{ errors.default }}
      </p>

      <p class="text-danger mb-3" v-if="!!errors.code">
        De ingevoerde twee staps verificatie code was ongeldig.
      </p>

      <b-form v-if="showTwoFactor" @submit.prevent="attemptChallenge">
        <Input
          type="text"
          placeholder="Authenticatie code"
          v-model="twoFactorCode.code"
        />

        <b-checkbox
          v-model="twoFactorCode.isRecovery"
          switch
          size="lg"
          class="mb-3"
        >
          Gebruik herstelcode
        </b-checkbox>

        <b-button type="submit" variant="primary" block>Verifiëren</b-button>
      </b-form>

      <b-form v-else @submit.prevent="signIn">
        <Input
          type="email"
          placeholder="E-mailadres"
          v-model="credentials['email']"
          :id="`user-email`"
          :error="errors['email']"
        />

        <div class="password-preview">
          <Input
            :type="!showPassword ? 'password' : 'text'"
            placeholder="Wachtwoord"
            v-model="credentials['password']"
            :id="`user-password`"
            :error="errors['password']"
          />
          <div
            :class="{ active: showPassword }"
            @click="showPassword = !showPassword"
          >
            <i class="far fa-eye" />
          </div>
        </div>

        <b-form-checkbox
          v-model="credentials.remember"
          name="check-button"
          class="mb-3"
          switch
          size="lg"
        >
          Onthoud mij
        </b-form-checkbox>

        <b-button type="submit" variant="primary" block>Inloggen</b-button>
      </b-form>
    </b-card>
  </div>
</template>

<script>
  import Windmill from "@/components/Windmill";

  import { mapActions, mapGetters } from "vuex";

  export default {
    components: {
      Windmill,
    },

    data() {
      return {
        showPassword: false,
        showTwoFactor: false,

        twoFactorCode: {
          code: null,
          isRecovery: false,
        },

        credentials: {
          email: null,
          password: null,
          remember: false,
        },

        errors: {},
      };
    },

    computed: {
      ...mapGetters({
        auth: "auth/user",
      }),
    },

    methods: {
      ...mapActions({
        signInAction: "auth/signIn",
        attemptChallengeAction: "auth/attemptChallenge",
        attemptAction: "auth/attempt",
      }),

      attemptChallenge() {
        this.attemptChallengeAction(this.twoFactorCode)
          .then(() => {
            this.handleRedirects(true);
          })
          .catch(({ data }) => {
            this.showTwoFactor = false;
            this.credentials = {
              email: null,
              password: null,
              remember: false,
            };
            this.twoFactorCode = {
              code: null,
              isRecovery: false,
            };

            if (data.errors) {
              this.errors = this.$options.filters.setErrors(data.errors);
            }
          });
      },

      signIn() {
        this.errors = this.$options.filters.clearErrors(this.errors);

        this.signInAction(this.credentials)
          .then((data) => {
            if (data.two_factor) {
              this.showTwoFactor = data.two_factor;
            } else {
              this.attemptAction().then(() => {
                this.handleRedirects(data.two_factor);
              });
            }
          })
          .catch((errors) => {
            if (errors.data.errors) {
              this.errors = this.$options.filters.setErrors(errors.data.errors);
            }
          });
      },

      handleRedirects(twoFactorEnabled = false) {
        if (!twoFactorEnabled) {
          this.$router.push({
            name: "two-factor",
          });
        } else {
          if (this.$route.query.referer) {
            this.$router.push({
              path: this.$route.query.referer,
            });
          } else {
            this.$router.push({
              name: "dashboard",
            });
          }
        }
      },
    },
  };
</script>