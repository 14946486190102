<template>
  <div>
    <b-card class="mt-4">
      <b-row>
        <b-col md="12">
          <b-form-group class="mb-0">
            <b-select v-model="filters.period">
              <b-select-option value="coming_week">
                Komende 7 dagen
              </b-select-option>
              <b-select-option value="coming_thirty_days">
                Komende 30 dagen
              </b-select-option>
              <b-select-option value="coming_ninety_days">
                Komende 90 dagen
              </b-select-option>
              <b-select-option value="coming_year">
                Komend jaar
              </b-select-option>
            </b-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <b-row
      v-if="filters.period || (filters.period === 'other' && filters.year)"
    >
      <b-col>
        <StockChart
          class="mt-4"
          :filters="filters"
          :product="$route.params.id"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import StockChart from "./_StockChart";

  export default {
    components: {
      StockChart,
    },

    data() {
      return {
        options: {
          years: [],
          months: [],
        },

        filters: {
          period: "coming_thirty_days",
          year: null,
          month: null,
        },

        loading: {
          stats: false,
        },
      };
    },

    watch: {
      "filters.year"() {
        this.getMonths();
      },
    },

    methods: {
      getMonths() {
        let params = {
          year: this.filters.year,
          customer: this.$route.params.id,
        };

        this.$http
          .get("stats/get-months", { params: params })
          .then((response) => {
            this.options.months = response.data;
          });
      },

      getYears() {
        let params = {
          customer: this.$route.params.id,
        };

        this.$http.get("stats/get-years", { params: params }).then((response) => {
          this.options.years = response.data;
        });
      },
    },

    mounted() {
      this.getYears();
      this.getMonths();
    },
  };
</script>
