<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <back-button />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="36">
        <b-card header="Nieuwe inkooporder">
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Verwachte leverdatum"
                description="Laat dit veld leeg om een inkooporder zonder leverdatum aan te maken"
              >
                <b-input type="date" v-model="purchaseOrder.delivery_date" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <Input
                label="Factuurnummer"
                v-model="purchaseOrder.invoice_number"
              />
            </b-col>
            <b-col md="12">
              <b-form-group label="Factuurdatum">
                <vc-date-picker
                  is-expanded
                  title-position="left"
                  show-iso-weeknumbers
                  :attributes="calAttrs"
                  :model-config="modelConfig"
                  mode="date"
                  locale="nl"
                  trim-weeks
                  v-model="purchaseOrder.invoice_date"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text>
                          <i class="fad fa-calendar" />
                        </b-input-group-text>
                      </template>
                      <b-input :value="inputValue" v-on="inputEvents" />
                    </b-input-group>
                  </template>
                </vc-date-picker>
              </b-form-group>
            </b-col>
          </b-row>

          <b-table
            striped
            hover
            show-empty
            small
            no-local-sorting
            :items="purchaseOrder.orderlines"
            :fields="fields.purchaseOrder"
            @sort-changed="sort"
          >
            <template #table-busy>
              <div class="text-center">
                <b-spinner variant="secondary" class="align-middle mr-3" />
                <strong class="align-middle">Producten worden geladen</strong>
              </div>
            </template>

            <template #empty="">
              <span class="text-center d-block">
                Er zijn nog geen producten toegevoegd aan de inkooporder
              </span>
            </template>
          </b-table>

          <hr />

          <b-row>
            <b-col md="12">
              <b-form-group
                label="Zoeken"
                description="Zoek een product op SKU"
              >
                <b-input
                  type="search"
                  v-model="filters.terms"
                  @change="filter"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-table
                striped
                bordered
                hover
                show-empty
                no-local-sorting
                :items="products"
                :fields="fields.products"
                :busy="loading.products"
                @sort-changed="sort"
                @row-contextmenu="rowContextMenuHandler"
              >
                <template #table-busy>
                  <div class="text-center">
                    <b-spinner
                      variant="secondary"
                      class="align-middle mr-3"
                    ></b-spinner>
                    <strong class="align-middle">
                      Producten worden geladen
                    </strong>
                  </div>
                </template>

                <template #empty="">
                  <span class="text-center d-block">
                    Er zijn geen producten gevonden
                  </span>
                </template>

                <template #cell(sku)="row">
                  <span class="column-sku">{{ row.item.sku }}</span>
                  {{ row.item.name }}
                </template>

                <template #cell(physical_stock)="row">
                  <Input type="number" v-model="row.item.physical_stock" />
                </template>

                <template #cell(actions)="row">
                  <b-button
                    variant="primary"
                    v-b-tooltip.hover.left="
                      `Dit product toevoegen aan de inkooporder?`
                    "
                    @click="addToPurchaseOrder(row.item)"
                  >
                    <i class="far fa-plus mr-0"></i>
                  </b-button>
                </template>
              </b-table>

              <b-pagination-nav
                use-router
                first-number
                last-number
                v-model="productsMeta.current_page"
                :link-gen="linkGen"
                :number-of-pages="productsMeta.last_page"
                @change="getProducts"
              >
                <template #prev-text>
                  <i class="far fa-angle-left"></i>
                </template>
                <template #next-text>
                  <i class="far fa-angle-right"></i>
                </template>
                <template #ellipsis-text>
                  <i class="fal fa-ellipsis-h"></i>
                </template>
              </b-pagination-nav>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col>
        <b-card header="Acties">
          <b-button @click="createPurchaseOrder" block>Order aanmaken</b-button>
        </b-card>
      </b-col>
    </b-row>

    <context-menu
      :id="$route.name"
      :key="$route.fullPath"
      :display="showContextMenu"
      component="product"
      ref="menu"
    />
  </div>
</template>

<script>
  import ContextMenu from "@/components/ContextMenu";
  import BackButton from "@/components/BackButton";
  import _ from "lodash";

  export default {
    components: {
      ContextMenu,
      BackButton,
    },

    data() {
      return {
        filters: {
          terms: null,
          orderBy: "sku",
          orderDirection: "ASC",
        },

        purchaseOrder: {
          orderlines: [],
        },

        showContextMenu: false,

        fields: {
          products: [
            {
              key: "sku",
              label: "Product",
              sortable: true,
            },
            {
              key: "supplier_article_code",
              label: "Art. code",
              sortable: true,
              formatter: (value) => {
                return value || "-";
              },
            },
            {
              key: "supplier_name",
              label: "Naam leverancier",
              sortable: true,
              formatter: (value) => {
                return value || "-";
              },
            },
            {
              key: "physical_stock",
              label: "Aantal",
              sortable: true,
            },
            {
              key: "actions",
              label: "",
              tdClass: "column-fit-content",
            },
          ],

          purchaseOrder: [
            {
              key: "sku",
              label: "SKU",
              sortable: true,
            },
            {
              key: "name",
              label: "Naam",
              sortable: true,
            },
            {
              key: "quantity",
              label: "Aantal",
              sortable: true,
            },
          ],
        },

        products: [],
        productsMeta: {},

        loading: {
          products: false,
        },
      };
    },

    mounted() {
      this.filters = this.$root.setFilters(this.filters);
      this.getProducts();
    },

    methods: {
      sort(ctx) {
        this.filters.orderBy = ctx.sortBy;
        this.filters.orderDirection = ctx.sortDesc ? "desc" : "asc";

        this.filter();
      },

      linkGen(pageNum) {
        return pageNum === 1 ? "?" : `?page=${pageNum}`;
      },

      filter() {
        this.$root.applyFilters(this.filters);
        this.getProducts();
      },

      getProducts(page = null) {
        this.loading.products = true;

        let params = {
          ...this.filters,
          supplier: this.$route.params.id,
          page: page
            ? this.$route.query.page
              ? this.$route.query.page
              : null
            : null,
        };

        this.$http
          .get("products", {
            params: params,
          })
          .then((response) => {
            this.products = response.data.data;
            this.productsMeta = response.data.meta;
          })
          .then(() => {
            this.loading.products = false;
          });
      },

      createPurchaseOrder() {
        let data = {
          ...{ supplier_id: this.$route.params.id },
          ...this.purchaseOrder,
        };

        this.$http.post(`purchase-orders`, data).then((response) => {
          this.$router.push({
            name: "purchase-order.show",
            params: {
              id: this.$route.params.id,
              orderId: response.data.data.id,
            },
          });
        });
      },

      addToPurchaseOrder(product) {
        let index = _.findIndex(this.purchaseOrder.orderlines, {
          id: product.id,
        });

        if (index >= 0) {
          this.purchaseOrder.orderlines[index].quantity =
            parseInt(this.purchaseOrder.orderlines[index].quantity) +
            parseInt(product.physical_stock);
        } else {
          this.purchaseOrder.orderlines.push({
            id: product.id,
            sku: product.sku,
            name: product.name,
            quantity: product.physical_stock,
          });
        }
      },

      rowContextMenuHandler(item, _, event) {
        this.$refs.menu.open(event, item);
        event.preventDefault();
      },
    },
  };
</script>