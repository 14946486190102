export const OrderStatus = {
  PROCESSING: 1,
  SENT: 2,
  CANCELLED: 3,
  SAMPLE_PROCESSING: 4,
  SAMPLE_COMPLETED: 5,
  ON_HOLD: 6,
};

export const OrderStatusString = {
  1: 'In behandeling',
  2: 'Verzonden',
  3: 'Geannuleerd',
  4: 'Proefmonster open',
  5: 'Proefmonster verzonden',
  6: 'In de wacht'
};