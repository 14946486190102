<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <back-button />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="36">
        <b-card header="Nieuwe creditfactuur">
          <b-row>
            <b-col md="24">
              <search-select
                searchable
                :value="creditNote.customer"
                @input="selectCustomer"
                searchType="customers"
                labelTitle="Zoek een klant"
                labelSearchPlaceholder="Zoek een klant"
              />
            </b-col>
          </b-row>

          <hr />

          <b-button
            variant="primary"
            class="float-right mb-3"
            v-b-modal.add-orderline
          >
            <i class="far fa-stream"></i>Orderregel toevoegen
          </b-button>
          <b-table
            striped
            foot-clone
            hover
            show-empty
            responsive
            class="table-nowrap"
            :items="creditNote.lines"
            :fields="fields"
          >
            <template #empty> Er zijn nog geen regels toegevoegd </template>

            <template #cell(name)="row">
              <template v-if="row.item.product">
                <span class="column-sku">{{ row.item.product.sku }}</span>
                {{ row.item.product.name }}
              </template>
              <template v-else>{{ row.item.name }}</template>
            </template>

            <template #cell(quantity)="row">
              <Input type="number" v-model.number="row.item.quantity" />
            </template>

            <template #head(update_stock)="">
              <i
                class="far fa-info-circle"
                v-b-tooltip.right.hover
                title="Geef per product aan of de voorraad opgeboekt moet worden"
              />
            </template>

            <template #cell(update_stock)="row">
              <template v-if="row.item.product">
                <b-form-checkbox v-model="row.item.update_stock" switch />
              </template>
            </template>

            <template #cell(price)="row">
              <Input
                :class="{ 'is-invalid': row.item.price > 0 }"
                v-b-tooltip.hover.left="
                  row.item.price > 0
                    ? `Weet je zeker dat deze orderregel een positieve prijs moet hebben?`
                    : ''
                "
                prepend="€"
                type="number"
                step="0.01"
                v-model.number="row.item.price"
              />
            </template>

            <template #cell(total)="row">
              &euro;
              {{ (row.item.quantity * row.item.price) | formatNumber(2) }}
            </template>

            <template #cell(actions)="row">
              <b-button
                variant="outline-primary"
                v-b-tooltip.hover.left="`Deze orderregel verwijderen?`"
                @click="deleteCreditLine(row)"
              >
                <i class="far fa-trash-alt mr-0"></i>
              </b-button>
            </template>
          </b-table>

          <b-row class="d-flex justify-content-between">
            <b-col md="20">
              <strong>Opmerking:</strong>
              <b-form-textarea
                id="textarea"
                class="mt-2"
                placeholder="Eventuele opmerkingen.."
                rows="3"
                max-rows="6"
                v-model="creditNote.comments"
              />
            </b-col>
            <b-col md="16">
              <b-list-group class="lines">
                <b-list-group-item class="d-flex justify-content-between">
                  <b>Subtotaal ex:</b>
                  <span v-if="price.subtotal">
                    &euro;
                    {{ price.subtotal | formatNumber(2) }}
                  </span>
                  <span v-else>
                    &euro;
                    {{ price.subtotal | formatNumber(2) }}
                  </span>
                </b-list-group-item>

                <b-list-group-item
                  class="d-flex justify-content-between"
                  v-if="price.shippingCosts"
                >
                  <b>Verzendkosten:</b>
                  <span>
                    &euro;
                    {{ price.shippingCosts | formatNumber(2) }}
                  </span>
                </b-list-group-item>

                <b-list-group-item
                  class="d-flex justify-content-between"
                  v-if="price.extraCosts"
                >
                  <b>Extra kosten:</b>
                  <span>
                    &euro;
                    {{ price.extraCosts | formatNumber(2) }}
                  </span>
                </b-list-group-item>

                <b-list-group-item
                  class="d-flex justify-content-between"
                  v-if="price.discount"
                >
                  <b>Korting:</b>
                  <span>
                    &euro;
                    {{ price.discount | formatNumber(2) }}
                  </span>
                </b-list-group-item>

                <b-list-group-item class="d-flex justify-content-between">
                  <b>BTW:</b>
                  <span>
                    &euro;
                    {{ price.tax | formatNumber(2) }}
                  </span>
                </b-list-group-item>

                <b-list-group-item class="d-flex justify-content-between">
                  <b>Totaal incl:</b>
                  <span>
                    &euro;
                    {{ price.total | formatNumber(2) }}
                  </span>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col>
        <b-card header="Acties">
          <span id="disabled-wrapper" class="d-block" tabindex="0">
            <b-button
              block
              variant="primary"
              @click="validateOrderlines"
              :disabled="saveDisabled"
            >
              Aanmaken
            </b-button>
          </span>
          <b-tooltip target="disabled-wrapper">
            <template
              v-if="!creditNote.customer_id && creditNote.lines.length === 0"
            >
              Er is geen data ingevuld voor deze credit
            </template>
            <template v-else>
              <template v-if="!creditNote.customer_id">
                Voeg een klant toe
              </template>
              <template v-if="creditNote.lines.length === 0">
                Voeg minimaal één credit regel toe
              </template>
            </template>
          </b-tooltip>
        </b-card>

        <b-card class="mt-4" header="Kosten">
          <template v-if="1">
            <Input
              prepend="€"
              v-model.number="creditNote.shipping_costs"
              label="Verzendkosten"
              step="0.01"
              min="0"
              type="number"
            />
          </template>
          <template v-else>
            <p>
              <b>Verzendkosten</b>
            </p>
            <p>
              &euro;
              {{
                creditNote.shipping_costs
                  ? creditNote.shipping_costs
                  : 0 | formatNumber(2)
              }}
            </p>
          </template>

          <template v-if="1">
            <Input
              prepend="€"
              v-model.number="creditNote.extra_costs"
              label="Extra kosten"
              step="0.01"
              min="0"
              type="number"
            />
          </template>
          <template v-else>
            <p class="mt-3">
              <b>Extra kosten</b>
            </p>
            <p>
              &euro;
              {{
                creditNote.extra_costs
                  ? creditNote.extra_costs
                  : 0 | formatNumber(2)
              }}
            </p>
          </template>

          <template v-if="1">
            <b-form-group label="Korting">
              <b-select v-model="creditNote.discount_type">
                <b-select-option disabled :value="null">
                  Kies een optie
                </b-select-option>
                <b-select-option value="percentage">Percentage</b-select-option>
                <b-select-option value="price">Bedrag</b-select-option>
              </b-select>
            </b-form-group>
            <Input
              v-model.number="creditNote.discount"
              step="0.01"
              min="0"
              type="number"
            />
          </template>
          <template v-else>
            <p class="mt-3">
              <b>Korting</b>
            </p>
            <p>
              <template
                v-if="
                  creditNote.discount_type === 'price' ||
                  !creditNote.discount_type
                "
              >
                &euro;
                {{
                  creditNote.discount
                    ? creditNote.discount
                    : 0 | formatNumber(2)
                }}
              </template>
              <template v-else>
                {{
                  creditNote.discount
                    ? creditNote.discount
                    : 0 | formatNumber(2)
                }}
                %</template
              >
            </p>
          </template>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      id="add-orderline"
      centered
      hide-footer
      size="lg"
      title="Regel toevoegen"
    >
      <b-row>
        <b-col>
          <search-select
            searchable
            @input="selectProduct"
            searchType="products"
          />
        </b-col>
      </b-row>

      <template v-if="selectedProduct">
        <b-jumbotron class="mt-3 jumbotron--small">
          <h5>
            <b>{{ selectedProduct.sku }}</b> toevoegen aan de creditfactuur
          </h5>

          <p>
            <i>{{ selectedProduct.name }}</i>
          </p>

          <Input
            class="mt-2"
            type="number"
            min="0"
            placeholder="Aantal"
            label="Aantal"
            v-model="newCreditLine.quantity"
          />
          <b-button variant="primary" @click="addCreditLine">
            Voeg regel toe
          </b-button>
        </b-jumbotron>
      </template>

      <span class="or-seperator">Of, voer een vrije regel in</span>

      <b-jumbotron class="mt-3 jumbotron--small">
        <Input placeholder="Naam" label="Naam" v-model="freeCreditLine.name" />
        <b-row>
          <b-col md="24">
            <Input
              placeholder="Aantal"
              label="Aantal"
              type="number"
              min="0"
              v-model="freeCreditLine.quantity"
            />
          </b-col>
          <b-col>
            <Input
              placeholder="Prijs"
              label="Prijs"
              step="0.01"
              prepend="€"
              type="number"
              v-model="freeCreditLine.price"
            />
          </b-col>
        </b-row>
        <b-button variant="primary" @click="addFreeCreditLine">
          Voeg vrije regel toe
        </b-button>
      </b-jumbotron>
    </b-modal>
  </div>
</template>
<script>
  import BackButton from "@/components/BackButton";

  import SearchSelect from "@/components/SearchSelect";

  export default {
    components: {
      BackButton,
      SearchSelect,
    },

    data() {
      return {
        creditNote: {
          customer_id: null,
          customer: {},
          lines: [],
          shipping_costs: null,
          extra_costs: null,
          discount: null,
          discount_type: "percentage",
        },

        products: [],

        selectedProduct: null,

        freeCreditLine: {},
        newCreditLine: {
          quantity: 1,
        },

        fields: [
          { key: "name", label: "Naam", tdClass: "column-wrap-content" },
          {
            key: "quantity",
            label: "Aantal",
            tdClass: "column-form-control-max-width",
          },
          { key: "update_stock", label: "Voorraad bijwerken" },
          { key: "price", label: "Prijs", tdClass: "column-form-control" },
          { key: "total", label: "Totaal" },
          { key: "actions", label: "" },
        ],
      };
    },

    computed: {
      price() {
        let total = 0;
        let subtotal = 0;
        let subtotal$ = 0;
        let shippingCosts = this.creditNote.shipping_costs ?? 0;
        let extraCosts = this.creditNote.extra_costs ?? 0;
        let discount = this.creditNote.discount ?? 0;
        let discount_type = this.creditNote.discount_type ?? 0;
        let tax = 0;

        this.creditNote.lines.forEach((line) => {
          subtotal += line.quantity * line.price;
        });

        subtotal$ = subtotal + shippingCosts + extraCosts;

        if (discount) {
          discount =
            discount_type === "percentage"
              ? subtotal$ * -(discount / 100)
              : discount;

          subtotal$ = subtotal$ - discount;
        }

        tax = this.creditNote.customer.country === "be" ? 0 : subtotal$ * 0.21;
        total = subtotal$ + tax;

        return {
          total: total,
          subtotal: subtotal,
          shippingCosts: shippingCosts,
          extraCosts: extraCosts,
          discount: discount,
          tax: tax,
        };
      },

      saveDisabled() {
        return !this.creditNote.customer_id || this.creditNote.lines.length === 0;
      },
    },

    methods: {
      selectProduct(e) {
        this.selectedProduct = e;
      },

      selectCustomer(e) {
        this.creditNote.customer_id = e.id;
      },

      getCustomer(id) {
        this.$http.get(`customers/${id}`).then((response) => {
          this.creditNote.customer = response.data.data;
          this.creditNote.customer_id = response.data.data.id;
        });
      },

      addCreditLine() {
        let data = {
          ...this.newCreditLine,
          ...{
            sku: this.selectedProduct.sku,
            product: {
              sku: this.selectedProduct.sku,
              name: this.selectedProduct.name,
            },
            product_id: this.selectedProduct.id,
            name: this.selectedProduct.name,
            price: this.selectedProduct.price * -1,
          },
        };

        this.creditNote.lines.push(data);

        this.$root.notify("De orderregel is succesvol aangemaakt.");

        this.$bvModal.hide("add-orderline");
        this.selectedProduct = null;
        this.newCreditLine = {
          quantity: 1,
        };
      },

      addFreeCreditLine() {
        let data = {
          sku: "-",
          name: this.freeCreditLine.name,
          price: this.freeCreditLine.price,
          quantity: this.freeCreditLine.quantity,
        };

        this.creditNote.lines.push(data);

        this.$root.notify("De orderregel is succesvol aangemaakt.");

        this.$bvModal.hide("add-orderline");
        this.freeCreditLine = {};
      },

      deleteCreditLine(row) {
        this.creditNote.lines.splice(row.index, 1);
      },

      storeCreditNote() {
        this.$http
          .post(`credit-notes`, this.creditNote)
          .then(() => {
            this.$root.notify("De nieuwe credit is succesvol aangemaakt.");

            this.$router.push({
              name: "invoice.index",
              query: {
                tab: 1,
              },
            });
          })
          .catch((errors) => {
            this.errors = this.$options.filters.setErrors(errors.data.errors);
            this.$root.notify(errors.data.errors, "danger");
          });
      },

      validateOrderlines() {
        let hasPositivePrice = false;
        for (let line of this.creditNote.lines) {
          if (line.price > 0) {
            hasPositivePrice = true;
            break;
          }
        }

        if (hasPositivePrice) {
          this.$root.showConfirm(
            "Weet je zeker dat je credit orderlines moet bevatten met een positieve prijs?",
            () => {
              this.storeCreditNote();
            },
            () => {},
            "Nee",
            "Ja"
          );
        } else {
          this.storeCreditNote();
        }
      },
    },

    mounted() {
      if (this.$route.query.customer) {
        this.getCustomer(this.$route.query.customer);
      }
    },
  };
</script>