<template>
  <div>
    <b-card no-body class="card-staff">
      <b-card-body>
        <div class="d-flex align-items-center">
          <b-avatar
            variant="primary"
            :src="avatarUrl"
            class="mr-2 staff-avatar"
            size="lg"
            :text="initials"
          />
          <div class="flex-grow-1">
            <span class="staff-name">{{ displayData.name }}</span>
            <span class="staff-function">{{ displayData.function }}</span>
          </div>
          <b-button
            variant="transparant"
            class="edit-staff align-self-start mr-1"
            size="sm"
            @click="$bvModal.show(`staff-modal-${displayData.id}`)"
          >
            <i class="far fa-pencil mr-0" />
          </b-button>
          <b-button
            variant="transparant"
            class="edit-staff align-self-start"
            size="sm"
            @click="deleteStaffmember"
          >
            <i class="far fa-trash-alt mr-0" />
          </b-button>
        </div>
      </b-card-body>
      <b-list-group flush>
        <b-list-group-item v-if="displayData.birthday">
          <span>🎂</span>
          {{ new Date(displayData.birthday) | date("dd-MM-Y") }}
        </b-list-group-item>
        <b-list-group-item v-if="displayData.phone">
          <span>☎️</span>
          <a :href="`tel:${displayData.phone}`">{{ displayData.phone }}</a>
        </b-list-group-item>
        <b-list-group-item v-if="displayData.email">
          <span>📩</span>
          <a :href="`mailto:${displayData.email}`">{{ displayData.email }}</a>
        </b-list-group-item>
        <b-list-group-item v-if="displayData.interests">
          <span>#️⃣</span>
          <span class="wrap-content" v-html="displayData.interests"></span>
        </b-list-group-item>
      </b-list-group>
    </b-card>

    <b-modal
      :id="`staff-modal-${displayData.id}`"
      hide-header
      hide-footer
      centered
    >
      <b-row>
        <b-col>
          <h2>Personeel bewerken</h2>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="48">
          <Input v-model="formData.name" :error="errors['name']" label="Naam" />
        </b-col>

        <b-col md="48">
          <b-form-group
            label="Afbeelding"
            description="JPEG, PNG en GIF toegestaan"
          >
            <b-form-file
              v-model="formData.newAvatar"
              accept="image/jpeg, image/png, image/gif"
              browse-text="Bladeren"
              placeholder="Kies een afbeelding"
              drop-placeholder="Kies een afbeelding"
            />
          </b-form-group>
        </b-col>

        <b-col md="48">
          <Input
            v-model="formData.function"
            :error="errors['function']"
            label="Functie"
          />
        </b-col>

        <b-col md="48">
          <Input
            v-model="formData.phone"
            :error="errors['phone']"
            type="tel"
            label="Telefoonnummer"
          />
        </b-col>

        <b-col md="48">
          <Input
            v-model="formData.email"
            :error="errors['email']"
            type="email"
            label="Email"
          />
        </b-col>

        <b-col md="48">
          <b-form-group label="Verjaardag">
            <vc-date-picker
              is-expanded
              title-position="left"
              show-iso-weeknumbers
              :attributes="calAttrs"
              :modelConfig="modelConfig"
              mode="date"
              locale="nl"
              trim-weeks
              v-model="formData.birthday"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text>
                      <i class="fad fa-calendar" />
                    </b-input-group-text>
                  </template>
                  <b-input :value="inputValue" v-on="inputEvents" />
                </b-input-group>
              </template>
            </vc-date-picker>
          </b-form-group>
        </b-col>

        <b-col md="48">
          <b-form-group label="Interresses">
            <b-textarea v-model="formData.interests" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="text-right">
          <b-button
            @click="$bvModal.hide(`staff-modal-${displayData.id}`)"
            class="mr-2"
          >
            Annuleren
          </b-button>
          <b-button variant="primary" @click="updateStaffmember()">
            Opslaan
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>

  import _ from "lodash";

  export default {
    props: {
      staffmember: {
        type: Object,
        default: () => {
          return {
            name: "",
            birthday: new Date(),
            phone: null,
            email: null,
            interests: null,
          };
        },
      },
    },

    data() {
      return {
        updatedData: {},

        calAttrs: [
          {
            key: "today",
            highlight: {
              color: "blue",
              fillMode: "light",
            },
            dates: new Date(),
          },
        ],

        errors: [],

        modelConfig: {
          type: "string",
          mask: "YYYY-MM-DD", // Uses 'iso' if missing
        },
      };
    },

    methods: {
      updateStaffmember() {
        this.errors = this.$options.filters.clearErrors(this.errors);

        const config = {
          headers: { "content-type": "multipart/form-data" },
        };

        let data = new FormData();

        /**
         * We need method spoofing, because FormData only works on POST requests, this is a known PHP bug
         */
        data.append("_method", "PUT");

        _.each(this.formData, (value, key) => {
          if (value !== null) {
            data.append(key, value);
          }
        });

        this.$http
          .post(`customer-staff/${this.staffmember.id}`, data, config)
          .then(({ data }) => {
            this.updatedData = data.data;
            this.$bvModal.hide(`staff-modal-${this.staffmember.id}`);
            this.$root.notify("Personeel succesvol aangepast");
          })
          .catch(({ data }) => {
            this.errors = this.$options.filters.setErrors(data.errors);
          });
      },

      deleteStaffmember() {
        this.$http.delete(`customer-staff/${this.staffmember.id}`).then(() => {
          this.$root.notify("Personeel succesvol verwijderd");
          this.$parent.$emit("staffmember-deleted", this.staffmember.id);
        });
      },
    },

    computed: {
      initials() {
        let name = this.displayData.name.split(" ");

        if (name.length === 1) {
          name = name.at(0).charAt(0);
        } else {
          name = name.at(0).charAt(0) + name.at(-1).charAt(0);
        }

        return name;
      },

      avatarUrl() {
        let url = null;

        if (this.displayData.avatar) {
          let filename = this.displayData.avatar.split(".");

          url = `/media/avatars/${filename.at(0)}/${filename.at(-1)}`;
        }

        return url;
      },

      displayData() {
        return { ...this.staffmember, ...this.updatedData };
      },

      formData() {
        return { ...this.staffmember };
      },
    },
  };
</script>