<template>
  <b-overlay
    :show="loading"
    spinner-variant="secondary"
    bg-color="#fff"
    opacity="1"
    rounded="sm"
  >
    <b-card no-body class="card-stats">
      <b-card-body>
        <h4 class="card-title">{{ count }} best verkochte product(en)</h4>
      </b-card-body>
      <b-card-body class="pt-0 card-scroll">
        <template v-if="products.length">
          <p
            class="my-1 stat-product"
            v-for="(product, index) of products"
            :key="index"
          >
            <b-badge variant="primary" class="mx-2">
              {{ product.amount_sold }}
            </b-badge>
            <router-link
              :to="{
                name: 'product.show',
                params: { id: product.id },
              }"
            >
              {{ product.name }}
            </router-link>
          </p>
        </template>
        <template v-else>
          Er zijn geen producten gevonden voor deze periode
        </template>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
  export default {
    props: {
      loading: {
        type: Boolean,
        default: () => {
          return false;
        },
      },

      products: {
        type: Array,
        default: () => {
          return [];
        },
      },
    },

    computed: {
      count() {
        return this.products.length;
      },
    },
  };
</script>