import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from "@/views/Auth/Login";
import TwoFactorSetup from "@/views/Auth/TwoFactorSetup";

import Dashboard from "@/views/Dashboard";

import LocationIndex from "@/views/Locations/Index";
import LocationCreate from "@/views/Locations/Create";
import LocationShow from "@/views/Locations/Show";

import OrderIndex from "@/views/Orders/Index";
import OrderShow from "@/views/Orders/Show";

import SampleOrderIndex from "@/views/SampleOrders/Index";

import QueuedOrderIndex from "@/views/QueuedOrders/Index";

import ProductIndex from "@/views/Products/Index";
import ProductShow from "@/views/Products/Show";

import InvoiceIndex from "@/views/Invoices/Index";
import InvoiceShow from "@/views/Invoices/Show";

import CreditNoteCreate from "@/views/CreditNotes/Create";
import CreditNoteShow from "@/views/CreditNotes/Show";

import SupplierIndex from "@/views/Suppliers/Index";
import SupplierCreate from "@/views/Suppliers/Create";
import SupplierShow from "@/views/Suppliers/Show";

import PurchaseOrderCreate from "@/views/Suppliers/PurchaseOrder/Create";
import PurchaseOrderShow from "@/views/Suppliers/PurchaseOrder/Show";

import UserIndex from "@/views/Users/Index";
import UserCreate from "@/views/Users/Create";
import UserShow from "@/views/Users/Show";

import CustomerIndex from "@/views/Customers/Index";
import CustomerShow from "@/views/Customers/Show";

import ManagementIndex from "@/views/Management/Index"

import AgendaIndex from "@/views/Agenda/Index"

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter)

const routes = [
    /**
     * Auth routes
     */
    {
        path: '/',
        name: 'login',
        component: Login
    },
    {
        path: '/twee-staps-verificatie',
        name: 'two-factor',
        component: TwoFactorSetup
    },

    /**
     * Standalone routes
     */
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            title: 'Dashboard'
        }
    },
    {
        path: '/management/:tab?',
        name: 'management.index',
        component: ManagementIndex,
        meta: {
            admin: true,
            title: 'Management'
        }
    },

    /**
     * Order routes
     */
    {
        path: '/orders',
        name: 'order.index',
        component: OrderIndex,
        meta: {
            title: 'Orders'
        }
    },
    {
        path: '/orders/:id',
        name: 'order.show',
        component: OrderShow,
        meta: {
            title: 'Order bewerken'
        }
    },

    {
        path: '/proefmonster-orders',
        name: 'sample-order.index',
        component: SampleOrderIndex,
        meta: {
            title: 'Proefmonster orders'
        }
    },
    {
        path: '/proefmonster-orders/:id',
        name: 'sample-order.show',
        component: OrderShow,
        meta: {
            title: 'Proefmonster order bewerken'
        }
    },

    {
        path: '/order-wachtrij',
        name: 'queued-order.index',
        component: QueuedOrderIndex,
        meta: {
            title: 'Order wachtrij'
        }
    },
    {
        path: '/order-wachtrij/:id',
        name: 'queued-order.show',
        component: OrderShow,
        meta: {
            title: 'Order bewerken'
        }
    },

    /**
    * Product routes
    */
    {
        path: '/producten',
        name: 'product.index',
        component: ProductIndex,
        meta: {
            title: 'Producten'
        }
    },
    {
        path: '/producten/:id',
        name: 'product.show',
        component: ProductShow,
        meta: {
            title: 'Product bewerken'
        }
    },

    /**
     * Invoice routes
     */
    {
        path: '/facturatie',
        name: 'invoice.index',
        component: InvoiceIndex,
        meta: {
            admin: true,
            title: 'Facturatie'
        }
    },
    {
        path: '/facturatie/facturen/:id',
        name: 'invoice.show',
        component: InvoiceShow,
        meta: {
            admin: true,
            title: 'Factuur bewerken'
        }
    },
    {
        path: '/facturatie/creditfacturen/aanmaken',
        name: 'credit.create',
        component: CreditNoteCreate,
        meta: {
            title: 'Creditfactuur aanmaken'
        }
    },
    {
        path: '/facturatie/creditfacturen/:id',
        name: 'credit.show',
        component: CreditNoteShow,
        meta: {
            title: 'Creditfactuur bewerken'
        }
    },

    /**
     * Location routes
     */
    {
        path: '/locaties',
        name: 'location.index',
        component: LocationIndex,
        meta: {
            title: 'Locaties'
        }
    },
    {
        path: '/locaties/aanmaken',
        name: 'location.create',
        component: LocationCreate,
        meta: {
            title: 'Locatie aanmaken'
        }
    },
    {
        path: '/locaties/:id',
        name: 'location.show',
        component: LocationShow,
        meta: {
            title: 'Locatie bewerken'
        }
    },

    /**
     * Supplier routes
     */
    {
        path: '/leveranciers',
        name: 'supplier.index',
        component: SupplierIndex,
        meta: {
            title: 'Leveranciers'
        }
    },
    {
        path: '/leveranciers/aanmaken',
        name: 'supplier.create',
        component: SupplierCreate,
        meta: {
            title: 'Leverancier aanmaken'
        }
    },
    {
        path: '/leveranciers/:id',
        name: 'supplier.show',
        component: SupplierShow,
        meta: {
            title: 'Leverancier bewerken'
        }
    },
    {
        path: '/leveranciers/:id/inkooporder/aanmaken',
        name: 'purchase-order.create',
        component: PurchaseOrderCreate,
        meta: {
            title: 'Inkooporder aanmaken'
        }
    },
    {
        path: '/leveranciers/:id/inkooporder/:orderId',
        name: 'purchase-order.show',
        component: PurchaseOrderShow,
        meta: {
            title: 'Inkooporder bewerken'
        }
    },

    /**
     * User routes
     */
    {
        path: '/gebruikers',
        name: 'user.index',
        component: UserIndex,
        meta: {
            admin: true,
            title: 'Gebruikers'
        }
    },
    {
        path: '/gebruikers/aanmaken',
        name: 'user.create',
        component: UserCreate,
        meta: {
            admin: true,
            title: 'Gebruiker aanmaken'
        }
    },
    {
        path: '/gebruikers/:id',
        name: 'user.show',
        component: UserShow,
        meta: {
            admin: true,
            title: 'Gebruiker bewerken'
        }
    },

    /**
     * Customer routes
     */
    {
        path: '/klanten',
        name: 'customer.index',
        component: CustomerIndex,
        meta: {
            title: 'Klanten'
        }
    },
    {
        path: '/klanten/:id/:tab?',
        name: 'customer.show',
        component: CustomerShow,
        meta: {
            title: 'Klant bewerken'
        }
    },

    /**
     * Agenda routes
     */
    {
        path: '/agenda',
        name: 'agenda',
        component: AgendaIndex,
        meta: {
            title: 'Agenda'
        }
    },

]

const router = new VueRouter({
    base: '/',
    mode: 'history',
    routes
})

export default router