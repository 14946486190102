<template>
  <div>
    <b-card header="Locaties">
      <b-row>
        <b-col md="12">
          <b-form-group label="Zoeken" description="Zoek op naam">
            <b-input
              type="search"
              v-model="filters.terms"
              @change="filter"
              placeholder="Zoek een locatie"
            />
          </b-form-group>
        </b-col>

        <b-col class="text-right">
          <b-button :to="{ name: 'location.create' }">
            <i class="far fa-plus"></i> Nieuwe locatie
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-table
            striped
            bordered
            hover
            show-empty
            :items="locations"
            :fields="fields"
            :busy="loading.locations"
            @row-contextmenu="rowContextMenuHandler"
            @row-clicked="$root.rowClickRedirect($event, 'location.show')"
          >
            <template #table-busy>
              <div class="text-center">
                <b-spinner
                  variant="secondary"
                  class="align-middle mr-3"
                ></b-spinner>
                <strong class="align-middle">Locaties worden geladen</strong>
              </div>
            </template>

            <template #empty="">
              <span class="text-center d-block">
                Er zijn geen locaties gevonden
              </span>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>

    <context-menu
      :id="$route.name"
      :key="$route.fullPath"
      :display="showContextMenu"
      component="location"
      ref="menu"
    />
  </div>
</template>

<script>
  import ContextMenu from "@/components/ContextMenu";

  export default {
    components: {
      ContextMenu,
    },

    data() {
      return {
        showContextMenu: false,

        fields: [{ key: "name", label: "Naam" }],

        locations: [],

        loading: {
          locations: false,
        },

        filters: {
          terms: null,
        },
      };
    },

    mounted() {
      this.filters = this.$root.setFilters(this.filters);
      this.index();
    },

    methods: {
      filter() {
        this.$root.applyFilters(this.filters);
        this.index();
      },

      index() {
        this.loading.locations = true;

        let params = {
          ...this.filters,
        };

        this.$http
          .get("locations", {
            params: params,
          })
          .then((response) => {
            this.locations = response.data.data;
          })
          .then(() => {
            this.loading.locations = false;
          });
      },

      rowContextMenuHandler(item, _, event) {
        this.$refs.menu.open(event, item);
        event.preventDefault();
      },
    },
  };
</script>