<template>
  <b-row>
    <b-col class="p-3">
      <p class="font-weight-bold font-italic">Verschillende categorieën:</p>
      <p
        v-for="(category, index) in categories"
        :key="index"
        :value="category.id"
      >
        <span
          class="category-color category-color--legend"
          :style="`background-color: ${category.color}`"
        />
        {{ category.name }}
      </p>
    </b-col>
    <b-col class="p-3">
      <p class="font-weight-bold font-italic">
        Orders en zendingen kunnen verschillende kleuren hebben:
      </p>
      <p>
        <span
          class="category-color category-color--legend category-color--date"
        />
        Reguliere zendingen
      </p>
      <p>
        <span
          class="category-color category-color--legend category-color--van"
        />
        Bus zendingen
      </p>
      <p>
        <span
          class="category-color category-color--legend category-color--pick-up"
        />
        Pick up orders
      </p>
    </b-col>
  </b-row>
</template>

<script>
  export default {
    props: {
      categories: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
  };
</script>

<style>
</style>