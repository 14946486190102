<template>
  <b-card>
    <h2>Exact</h2>
    <b-row>
      <b-col md="16">
        <b-row>
          <b-col>
            <strong>Status</strong>
            <span
              class="status ml-2"
              :class="{ 'status--active': exact.active }"
            />
          </b-col>
          <template v-if="exact.active">
            <b-col md="48">
              <strong>Gekoppeld account:</strong>
              {{ exact.authenticated.FullName }}
            </b-col>
            <b-col md="48">
              <strong>Gekoppeld tot:</strong>
              {{ new Date(exact.expires) | date }}
            </b-col>
          </template>
          <template v-else>
            <b-col class="mt-2" md="48">
              <p class="font-italic mb-2">
                Gebruik onderstaande knop om een venster te openen waarin er
                ingelogd kan worden in Exact.
              </p>
              <p class="font-italic mb-2">
                Het venster sluit automatisch na succesvol inloggen. Herlaadt de
                pagina om wijzigingen te zien.
              </p>
              <b-button class="mt-3" @click="authorizeExact()">
                Exact koppelen
              </b-button>
            </b-col>
          </template>
        </b-row>
      </b-col>
      <b-col>
        <Input
          v-for="setting in settings"
          :key="setting.key"
          v-model="setting.value"
          :id="setting.key"
          :label="setting.label"
          :description="setting.description"
          :disabled="setting.read_only"
        />
        <b-button class="mt-3" @click="updateSettings('exact')">
          Exact informatie opslaan
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  data() {
    return {
      active: false,
      authenticated: {},
      expires: null,

      exact: {},

      settings: [],
    };
  },

  methods: {
    authorizeExact() {
      window.open(
        `${process.env.VUE_APP_API_BASE_URL}/exact/authorize`,
        'Exact Online',
        'popup',
        'width=600,height=600'
      );
    },

    getExactStatus() {
      this.$http.get(`exact/status`).then((response) => {
        this.exact = response.data;
      });
    },

    getSettings() {
      let params = {
        type: 'exact',
        read_only: false,
      };

      this.$http.get(`settings`, { params: params }).then((response) => {
        this.settings = response.data.data;
      });
    },

    updateSettings() {
      let data = {
        data: { ...this.settings },
      };

      this.$http.put(`settings`, data).then(() => {
        this.$root.notify('De instellingen zijn succesvol opgeslagen.');
      });
    },
  },

  mounted() {
    this.getExactStatus();
    this.getSettings();
  },
};
</script>
