<template>
  <div>
    <b-table
      striped
      bordered
      hover
      responsive
      show-empty
      no-local-sorting
      :items="orders"
      :fields="fields"
      :busy="loading"
      @sort-changed="sort"
      @row-contextmenu="rowContextMenuHandler"
      @row-clicked="$root.rowClickRedirect($event, 'order.show')"
    >
      <template #table-busy>
        <div class="text-center">
          <b-spinner variant="secondary" class="align-middle mr-3" />
          <strong class="align-middle">Orders worden geladen</strong>
        </div>
      </template>

      <template #empty="">
        <span class="text-center d-block"> Er zijn geen orders gevonden </span>
      </template>
    </b-table>

    <b-pagination-nav
      use-router
      first-number
      last-number
      v-model="meta.current_page"
      :link-gen="linkGen"
      :number-of-pages="meta.last_page"
      @change="updateOrders()"
    >
      <template #prev-text>
        <i class="far fa-angle-left"></i>
      </template>
      <template #next-text>
        <i class="far fa-angle-right"></i>
      </template>
      <template #ellipsis-text>
        <i class="fal fa-ellipsis-h"></i>
      </template>
    </b-pagination-nav>

    <context-menu
      :id="$route.name"
      :key="$route.fullPath"
      :display="showContextMenu"
      component="order"
      ref="menu"
    />
  </div>
</template>

<script>
  import ContextMenu from "@/components/ContextMenu";

  export default {
    components: {
      ContextMenu,
    },
    props: {
      status: {
        type: Number,
        default: null,
      },

      meta: {
        type: Object,
        default: () => {
          return {};
        },
      },

      orders: {
        type: Array,
        default: () => {
          return [];
        },
      },

      loading: {
        type: Boolean,
        default: false,
      },
    },

    methods: {
      updateOrders() {
        this.$emit("updateOrderTable");
      },

      rowContextMenuHandler(item, _, event) {
        let offsetLeft = 0;
        let offsetTop = 0;
        let container = event.target.closest(".table-responsive");

        // Calculate the right position of the context menu
        if (container) {
          let bodyBounds = document.body.getBoundingClientRect();
          let elBounds = container.getBoundingClientRect();
          offsetLeft = bodyBounds.left - elBounds.left;
          offsetTop = bodyBounds.top - elBounds.top;
        }

        this.$refs.menu.open(event, item, offsetLeft, offsetTop);
        event.preventDefault();
      },

      sort(ctx) {
        this.$parent.filters.orderBy = ctx.sortBy;
        this.$parent.filters.orderDirection = ctx.sortDesc ? "desc" : "asc";

        this.$parent.filter();
      },

      linkGen(pageNum) {
        return pageNum === 1 ? "?" : `?page=${pageNum}`;
      },
    },

    data() {
      return {
        showContextMenu: false,

        fields: [
          { key: "order_number", label: "Ordernummer", sortable: true },
          {
            key: "delivery_date",
            label: "Leverdatum",
            sortable: true,
            formatter: (value) => {
              if (value) {
                value = this.$options.filters.date(new Date(value), "dd/MM/yyyy");
              } else {
                value = "n.b.";
              }
              return value;
            },
          },
          {
            key: "created_at",
            label: "Aangemaakt op",
            formatter: (value) => {
              return this.$options.filters.date(
                new Date(value),
                "dd/MM/yyyy HH:mm:ss"
              );
            },
          },
        ],
      };
    },
  };
</script>
