<template>
  <div>
    <b-row>
      <b-col md="36">
        <h2>Service meldingen</h2>
      </b-col>
      <b-col class="text-right">
        <b-button
          variant="primary"
          @click="$bvModal.show('service-request-modal-create')"
        >
          <i class="far fa-plus mr-0" />
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-table
          striped
          bordered
          hover
          responsive
          show-empty
          no-local-sorting
          :items="serviceRequests"
          :fields="fields"
          :busy="loading"
          @row-contextmenu="rowContextMenuHandler"
          @row-clicked="showEditServiceRequestModal($event)"
        >
          <template #table-busy>
            <div class="text-center">
              <b-spinner variant="secondary" class="align-middle mr-3" />
              <strong class="align-middle">
                Service meldingen worden geladen
              </strong>
            </div>
          </template>

          <template #empty="">
            <span class="text-center d-block">
              Er zijn geen meldingen gevonden
            </span>
          </template>

          <template #cell(date)="row">
            {{ new Date(row.item.date) | date("dd/MM/yyyy") }}
          </template>

          <template #cell(id)="row">{{ row.item.display_number }}</template>

          <template #cell(is_solved)="row">
            <b-badge
              class="d-block"
              :variant="row.item.is_solved ? 'success' : 'danger'"
            >
              {{ row.item.is_solved ? "Opgelost" : "Open" }}
            </b-badge>
          </template>
        </b-table>

        <b-pagination-nav
          use-router
          first-number
          last-number
          v-model="serviceRequestsMeta.current_page"
          :link-gen="linkGen"
          :number-of-pages="serviceRequestsMeta.last_page"
          @change="getServiceRequests()"
        >
          <template #prev-text>
            <i class="far fa-angle-left"></i>
          </template>
          <template #next-text>
            <i class="far fa-angle-right"></i>
          </template>
          <template #ellipsis-text>
            <i class="fal fa-ellipsis-h"></i>
          </template>
        </b-pagination-nav>
      </b-col>
    </b-row>

    <b-modal id="service-request-modal-create" hide-header hide-footer centered>
      <b-row>
        <b-col>
          <h2>Melding toevoegen</h2>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="48">
          <b-form-group label="Datum melding">
            <vc-date-picker
              is-expanded
              title-position="left"
              show-iso-weeknumbers
              :attributes="calAttrs"
              :model-config="modelConfig"
              mode="date"
              locale="nl"
              trim-weeks
              v-model="newServiceRequest.date"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text>
                      <i class="fad fa-calendar" />
                    </b-input-group-text>
                  </template>
                  <b-input :value="inputValue" v-on="inputEvents" />
                </b-input-group>
              </template>
            </vc-date-picker>
          </b-form-group>
        </b-col>

        <b-col md="48">
          <Input
            v-model="newServiceRequest.order_number"
            :error="errors['order_number']"
            label="Ordernummer"
          />
        </b-col>

        <b-col md="48">
          <Input
            v-model="newServiceRequest.sku"
            :error="errors['sku']"
            label="Product"
            description="Gebruik de SKU van het betreffende product"
          />
        </b-col>

        <b-col md="48">
          <Input
            v-model="newServiceRequest.subject"
            :error="errors['subject']"
            label="Onderwerp"
            description="Omschrijf kort de melding"
          />
        </b-col>

        <b-col md="48">
          <b-form-group label="Aangeboden oplossing">
            <b-textarea v-model="newServiceRequest.suggested_solution" />
          </b-form-group>
        </b-col>

        <b-col md="48">
          <b-form-checkbox
            v-model="newServiceRequest.is_solved"
            variant="success"
            switch
          >
            Probleem is opgelost?
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="mt-3 text-right">
          <b-button
            @click="$bvModal.hide('service-request-modal-create')"
            class="mr-2"
          >
            Annuleren
          </b-button>
          <b-button variant="primary" @click="createServiceRequest()">
            Opslaan
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="service-request-modal-edit" hide-footer hide-header centered>
      <b-row>
        <b-col>
          <h2>#{{ selectedServiceRequest.display_number }} bewerken</h2>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="48">
          <b-form-group label="Datum melding">
            <vc-date-picker
              is-expanded
              title-position="left"
              show-iso-weeknumbers
              :attributes="calAttrs"
              :model-config="modelConfig"
              mode="date"
              locale="nl"
              trim-weeks
              v-model="selectedServiceRequest.date"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text>
                      <i class="fad fa-calendar" />
                    </b-input-group-text>
                  </template>
                  <b-input :value="inputValue" v-on="inputEvents" />
                </b-input-group>
              </template>
            </vc-date-picker>
          </b-form-group>
        </b-col>

        <b-col md="48">
          <Input
            v-model="selectedServiceRequest.order_number"
            :error="errors['order_number']"
            label="Ordernummer"
          />
        </b-col>

        <b-col md="48">
          <Input
            v-model="selectedServiceRequest.sku"
            :error="errors['sku']"
            label="Product"
            description="Gebruik de SKU van het betreffende product"
          />
        </b-col>

        <b-col md="48">
          <Input
            v-model="selectedServiceRequest.subject"
            :error="errors['subject']"
            label="Onderwerp"
            description="Omschrijf kort de melding"
          />
        </b-col>

        <b-col md="48">
          <b-form-group label="Aangeboden oplossing">
            <b-textarea v-model="selectedServiceRequest.suggested_solution" />
          </b-form-group>
        </b-col>

        <b-col md="48">
          <b-form-checkbox
            v-model="selectedServiceRequest.is_solved"
            variant="success"
            switch
          >
            Probleem is opgelost?
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="mt-3 text-right">
          <b-button
            @click="$bvModal.hide('service-request-modal-edit')"
            class="mr-2"
          >
            Annuleren
          </b-button>
          <b-button variant="primary" @click="updateServiceRequest()">
            Opslaan
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <context-menu
      :id="$route.name"
      :key="$route.fullPath"
      :display="showContextMenu"
      component="service-request"
      ref="menu"
    />
  </div>
</template>

<script>
  import ContextMenu from "@/components/ContextMenu";

  export default {
    components: {
      ContextMenu,
    },

    data() {
      return {
        showContextMenu: false,

        fields: [
          {
            key: "id",
            label: "#",
            class: "text-left column-fit-content",
            sortable: true,
          },
          { key: "subject", label: "Onderwerp" },
          {
            key: "order_number",
            label: "Order",
            sortable: true,
          },
          { key: "sku", label: "Product", sortable: true },
          {
            key: "date",
            label: "Gemeld op",
            class: "text-left column-fit-content",
            sortable: true,
          },
          {
            key: "is_solved",
            label: "Status",
            class: "column-fit-content",
            sortable: true,
          },
        ],

        filters: {
          orderBy: "id",
          orderDirection: "desc",
        },

        loading: false,

        serviceRequests: [],
        serviceRequestsMeta: {},
        newServiceRequest: {},
        selectedServiceRequest: {
          created_by: {},
          created_at: null,
          updated_at: null,
        },

        calAttrs: [
          {
            key: "today",
            highlight: {
              color: "blue",
              fillMode: "light",
            },
            dates: new Date(),
          },
        ],

        errors: [],

        modelConfig: {
          type: "string",
          mask: "YYYY-MM-DD", // Uses 'iso' if missing
        },
      };
    },

    props: {
      customer: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },

    methods: {
      rowContextMenuHandler(item, _, event) {
        let offsetLeft = 0;
        let offsetTop = 0;
        let container = event.target.closest(".table-responsive");

        // Calculate the right position of the context menu
        if (container) {
          let bodyBounds = document.body.getBoundingClientRect();
          let elBounds = container.getBoundingClientRect();
          offsetLeft = bodyBounds.left - elBounds.left + 10;
          offsetTop = bodyBounds.top - elBounds.top + 60;
        }

        this.$refs.menu.open(event, item, offsetLeft, offsetTop);
        event.preventDefault();
      },

      showEditServiceRequestModal(item) {
        this.$http.get(`service-requests/${item.id}`).then(({ data }) => {
          this.selectedServiceRequest = data.data;
        });

        this.$bvModal.show("service-request-modal-edit");
      },

      hideEditServiceRequestModal() {
        this.$bvModal.hide("service-request-modal-edit");
      },

      getServiceRequests() {
        this.loading = true;

        let params = {
          ...this.filters,
          customer: this.$route.params.id,
          page: this.$route.query.page ? this.$route.query.page : null,
        };

        this.$http
          .get("service-requests", { params: params })
          .then(({ data }) => {
            this.serviceRequests = data.data;
            this.serviceRequestsMeta = data.meta;

            this.loading = false;
          });
      },

      createServiceRequest() {
        this.errors = this.$options.filters.clearErrors(this.errors);

        let data = {
          ...this.newServiceRequest,
          customer_id: this.customer.id,
        };

        this.$http
          .post(`service-requests`, data)
          .then(() => {
            this.newServiceRequest = {};
            this.$root.notify("Service melding geplaatst");

            this.getServiceRequests();
          })
          .catch((errors) => {
            this.errors = this.$options.filters.setErrors(errors);
          });
      },

      updateServiceRequest() {
        let data = {
          ...this.selectedServiceRequest,
        };

        this.$http
          .put(`service-requests/${this.selectedServiceRequest.id}`, data)
          .then(({ data }) => {
            this.$root.notify("De wijzigingen zijn opgeslagen!");

            let updatedResource = data.data;

            var foundIndex = this.serviceRequests.findIndex(
              (x) => x.id == updatedResource.id
            );

            this.serviceRequests[foundIndex] = updatedResource;

            this.serviceRequests = this.serviceRequests.slice(0);

            // this.hideEditServiceRequestModal();
          });
      },

      sort(ctx) {
        this.$parent.filters.orderBy = ctx.sortBy;
        this.$parent.filters.orderDirection = ctx.sortDesc ? "desc" : "asc";

        this.$parent.filter();
      },

      linkGen(pageNum) {
        return pageNum === 1 ? "?" : `?page=${pageNum}`;
      },
    },

    mounted() {
      this.getServiceRequests();
    },
  };
</script>
