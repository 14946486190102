<template>
  <div
    v-on-clickaway="hideDropdown"
    @keyup.esc="onEscape"
    @keydown.up.prevent="typeAheadUp"
    @keydown.down.prevent="typeAheadDown"
    class="v-select search-select"
    :class="{ disabled: disabled }"
  >
    <button @click="toggle" type="button" class="v-select-toggle">
      <div>{{ title }}</div>
      <i class="fas fa-sort"></i>
    </button>
    <div v-show="show" class="v-dropdown-container">
      <div v-show="searchable" class="v-bs-searchbox">
        <b-input-group>
          <b-input-group-prepend>
            <i class="far fa-search"></i>
          </b-input-group-prepend>
          <b-form-input
            ref="searchbox"
            :placeholder="labelSearchPlaceholder"
            type="text"
            @change="search"
            autofocus
          ></b-form-input>
        </b-input-group>
      </div>
      <ul>
        <li v-show="searchable && results.length === 0" class="v-dropdown-item">
          {{ labelNotFound }} "{{ searchValue }}"
        </li>
        <li
          v-if="showDefaultOption"
          class="v-dropdown-item disabled default-option"
        >
          {{ labelTitle }}
        </li>
        <li
          v-for="(option, index) in results"
          :key="index"
          class="v-dropdown-item"
          :class="{
            selected: isSelectedOption(option, index),
            disabled: option[disabledProp],
          }"
          @click="onSelect(option, index)"
        >
          <span v-if="searchType === 'products'">
            {{ option.sku }}<i class="ml-3">{{ option.name }}</i>
          </span>
          <span v-if="searchType === 'customers'">
            {{ option.billing_formatted_name }}
            <i class="ml-3" v-if="option.billing_company">
              {{ option.billing_company }}
            </i>
          </span>
          <span v-if="searchType === 'orders'">
            {{ option.order_number }}
            <i class="ml-3" v-if="option.customer.billing_company">{{
              option.customer.billing_company
            }}</i>
            <i class="ml-3" v-else>{{
              option.customer.billing_formatted_name
            }}</i>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { mixin as clickaway } from "vue-clickaway";
  export default {
    name: "VSelect",
    mixins: [clickaway],
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      disabledProp: {
        type: String,
        default: "disabled",
      },
      labelTitle: {
        type: String,
        default: "Zoek een product",
      },
      labelNotFound: {
        type: String,
        default: "Geen resultaten gevonden voor: ",
      },
      labelSearchPlaceholder: {
        type: String,
        default: "Zoek een product",
      },
      options: {
        type: Array,
        default: () => [],
      },
      searchable: {
        type: Boolean,
        default: false,
      },
      showDefaultOption: {
        type: Boolean,
        default: false,
      },
      textProp: {
        type: String,
        default: "text",
      },
      value: {
        type: [Object, String, Number],
        default: null,
      },
      valueProp: {
        type: String,
        default: "value",
      },
      searchType: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        show: false,
        selectedValue: null,
        searchValue: "",
        typeAheadPointer: -1,
        results: [],
      };
    },
    computed: {
      title() {
        return this.selectedValue ? this.selectedValue : this.labelTitle;
      },
      reversedOptions() {
        return [...this.results].reverse();
      },
      lastOptionIndex() {
        return this.results.length - 1;
      },
    },
    watch: {
      value: {
        immediate: true,
        handler(newVal) {
          const index = this.options.findIndex((op) =>
            this.isEqualOption(op, newVal)
          );
          this.onSelect(newVal, index);
        },
      },
    },
    methods: {
      search(terms) {
        let params = {
          terms: terms,
        };

        this.searchValue = terms;

        this.$http.get(this.searchType, { params: params }).then((response) => {
          this.results = response.data.data;
        });
      },

      onSelect(option, index) {
        if (option && !option[this.disabledProp]) {
          if (this.searchType === "products") this.selectedValue = option.sku;
          if (this.searchType === "customers")
            this.selectedValue = option.billing_company
              ? option.billing_company
              : option.billing_formatted_name;
          if (this.searchType === "orders")
            this.selectedValue = option.order_number;

          this.typeAheadPointer = index;
          this.hideDropdown();
          this.$emit("input", option);
        } else if (option === null) {
          this.selectedValue = null;
        }
      },
      onEscape() {
        this.hideDropdown();
      },

      typeAheadUp() {
        if (!this.show) {
          this.show = true;
        }
        if (this.typeAheadPointer > 0) {
          const nextPointer = this.typeAheadPointer - 1;
          const option = this.results[nextPointer];
          const isDisabled = option ? option[this.disabledProp] || false : false;
          if (!isDisabled) {
            this.typeAheadPointer--;
          } else {
            this.typeAheadPointer--;
            this.typeAheadUp();
          }
        } else {
          const nextEnabledOption = this.reversedOptions.findIndex(
            (o) => o[this.disabledProp] !== true
          );
          this.typeAheadPointer = this.lastOptionIndex - nextEnabledOption;
        }
      },

      typeAheadDown() {
        if (!this.show) {
          this.show = true;
        }
        if (this.typeAheadPointer < this.lastOptionIndex) {
          const nextPointer = this.typeAheadPointer + 1;
          const option = this.results[nextPointer];
          const isDisabled = option ? option[this.disabledProp] || false : false;
          if (!isDisabled) {
            this.typeAheadPointer++;
          } else {
            this.typeAheadPointer++;
            this.typeAheadDown();
          }
        } else {
          const nextEnabledOption = this.results.findIndex(
            (o) => o[this.disabledProp] !== true
          );
          this.typeAheadPointer = nextEnabledOption;
        }
      },

      typeAheadSelect() {
        if (this.results[this.typeAheadPointer]) {
          this.onSelect(
            this.results[this.typeAheadPointer],
            this.typeAheadPointer
          );
        }
      },

      hideDropdown() {
        this.show = false;
      },

      isSelectedOption(option, index) {
        if (this.typeAheadPointer === -1 && this.selectedValue) {
          return this.isEqualOption(option, this.selectedValue);
        }
        return this.typeAheadPointer === index;
      },

      isEqualOption(a, b) {
        if (a && b && typeof a === "object" && typeof b === "object") {
          return (
            a[this.textProp] === b[this.textProp] &&
            a[this.valueProp] === b[this.valueProp]
          );
        }
        return a === b;
      },

      toggle() {
        if (!this.disabled) {
          this.show = !this.show;
          this.$nextTick(() => this.$refs.searchbox.focus());
        }
      },
    },
  };
</script>