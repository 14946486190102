<template>
  <div>
    <b-card>
      <h4 class="card-title">Beschikbare voorraad</h4>
      <canvas style="max-height: 400px" id="stock-chart"></canvas>
    </b-card>
  </div>
</template>

<script>
  import Chart from "chart.js/auto";

  export default {
    name: "StockChart",

    data() {
      return {
        prognosis: {
          periods: [],
          data: [],
        },

        chart: null,
      };
    },

    props: {
      product: null,

      filters: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },

    watch: {
      "filters.year"() {
        this.filter();
      },
      "filters.period"() {
        this.filter();
      },
      "filters.month"() {
        this.filter();
      },
    },

    methods: {
      filter() {
        this.chart.destroy();
        this.draw();
      },

      draw() {
        let params = {
          ...this.filters,
          ...{ product_id: this.product },
        };

        this.$http
          .get("stats/product-stock-prognosis", { params: params })
          .then(({ data }) => {
            this.prognosis = data;
          })
          .then(() => {
            const data = {
              labels: this.prognosis.periods,
              datasets: [
                {
                  label: "Beschikbare voorraad",
                  backgroundColor: "#494753",
                  borderColor: "#494753",
                  data: this.prognosis.data,
                  fill: {
                    above: "rgba(84, 141, 10, .4)",
                    below: "rgba(220, 53, 69, .4)",
                    target: { value: 0 },
                  },
                },
              ],
            };

            const ctx = document.getElementById("stock-chart").getContext("2d");

            const config = {
              type: "line",
              data: data,
              options: {
                plugins: {
                  legend: false,
                },
                scales: {
                  myScale: {
                    beginAtZero: true,
                    type: "linear",
                    position: "left",
                    ticks: {
                      precision: 0,
                    },
                  },
                },
              },
            };

            this.chart = new Chart(ctx, config);
          });
      },
    },

    mounted() {
      this.draw();
    },
  };
</script>