<template>
  <div>
    <b-card class="mt-4" bodyClass="overflow-visible">
      <b-row class="align-items-center justify-content-between">
        <b-col md="12">
          <b-form-group class="mb-0">
            <b-select v-model="filters.period" @change="filter()">
              <b-select-option :value="null"> Alles </b-select-option>
              <b-select-option value="today"> Vandaag </b-select-option>
              <b-select-option value="yesterday"> Gisteren </b-select-option>
              <b-select-option value="last_week">
                Afgelopen 7 dagen
              </b-select-option>
              <b-select-option value="last_thirty_days">
                Afgelopen 30 dagen
              </b-select-option>
              <b-select-option value="last_year">
                Afgelopen jaar
              </b-select-option>
              <b-select-option value="other">
                Anders, namelijk..
              </b-select-option>
            </b-select>
          </b-form-group>
        </b-col>

        <b-col md="12" v-if="filters.period === 'other'">
          <b-form-group class="mb-0">
            <b-select v-model="filters.year" @change="filter()">
              <b-select-option :value="null" selected>
                Alle jaren
              </b-select-option>
              <b-select-option
                v-for="(year, index) in options.years"
                :key="index"
                :value="year"
              >
                {{ year }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </b-col>

        <b-col md="12" v-if="filters.period === 'other'">
          <b-form-group class="mb-0">
            <b-button
              variant="input"
              block
              @click="selectExpanded = !selectExpanded"
            >
              {{ buttonText }}
              <i class="fas fa-sort" />
            </b-button>

            <template v-if="selectExpanded">
              <div class="v-select search-select">
                <div class="v-dropdown-container pt-0">
                  <b-form-group class="mb-0">
                    <b-form-checkbox-group
                      v-model="filters.month"
                      stacked
                      @change="filter()"
                    >
                      <b-form-checkbox
                        v-for="(month, index) in options.months"
                        :key="index"
                        :value="month"
                        class="m-2"
                      >
                        {{ new Date(2000, --month, 1) | date("LLLL") }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </div>
            </template>
          </b-form-group>
        </b-col>
        <b-col md="12" class="text-right">
          <b-checkbox v-model="customerView" switch>Klantweergave</b-checkbox>
        </b-col>
      </b-row>
    </b-card>

    <b-row class="mt-4 stats-customer">
      <b-col>
        <BestSoldProducts :loading="loading.products" :products="products" />
      </b-col>
      <b-col>
        <b-overlay
          :show="loading.stats"
          spinner-variant="secondary"
          bg-color="#fff"
          opacity="1"
          rounded="sm"
        >
          <b-row>
            <b-col md="24">
              <StatBlock
                title="Producten"
                :value="stats.current_period.items_sold"
                :oldValue="stats.previous_period.items_sold"
              />
            </b-col>
            <b-col md="24">
              <StatBlock
                title="Orders"
                :value="stats.current_period.total_orders"
                :oldValue="stats.previous_period.total_orders"
              />
            </b-col>
            <b-col md="24" class="mt-4">
              <StatBlock
                title="Omzet"
                :value="stats.current_period.revenue"
                :oldValue="stats.previous_period.revenue"
                currency
              />
            </b-col>
            <b-col md="24" class="mt-4">
              <StatBlock
                title="Gefactureerd"
                :value="stats.current_period.invoiced_revenue"
                :oldValue="stats.previous_period.invoiced_revenue"
                currency
              />
            </b-col>
          </b-row>
          <b-row v-if="!customerView">
            <b-col md="24" class="mt-4">
              <StatBlock
                title="Inkoop"
                :value="stats.current_period.purchase_total"
                :oldValue="stats.previous_period.purchase_total"
                currency
              />
            </b-col>
            <b-col md="24" class="mt-4">
              <MarginBlock
                title="Marge"
                :currentPeriod="stats.current_period"
                :previousPeriod="stats.previous_period"
                currency
              />
            </b-col>
          </b-row>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row
      v-if="filters.period || (filters.period === 'other' && filters.year)"
    >
      <b-col>
        <RevenueChart
          class="mt-4"
          :filters="filters"
          :customer="$route.params.id"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import StatBlock from "./_StatBlock";
  import MarginBlock from "./_MarginBlock";
  import RevenueChart from "./_RevenueChart";
  import BestSoldProducts from "./_BestSoldProducts";

  export default {
    components: {
      StatBlock,
      MarginBlock,
      RevenueChart,
      BestSoldProducts,
    },

    data() {
      return {
        stats: {
          current_period: {
            purchase_total: 0,
            items_sold: 0,
            subtotal: 0,
            tax: 0,
            total: 0,
            purchase_costs: 0,
            shipping_costs: 0,
            extra_costs: 0,
            discount: 0,
            total_orders: 0,
            aov: 0,
          },
          previous_period: {
            purchase_total: 0,
            items_sold: 0,
            subtotal: 0,
            tax: 0,
            total: 0,
            purchase_costs: 0,
            shipping_costs: 0,
            extra_costs: 0,
            discount: 0,
            total_orders: 0,
            aov: 0,
          },
        },

        customerView: false,

        products: [],

        options: {
          years: [],
          months: [],
        },

        filters: {
          period: "last_thirty_days",
          year: null,
          month: [],
        },

        selectExpanded: false,

        loading: {
          stats: false,
          products: false,
        },
      };
    },

    computed: {
      buttonText() {
        let text = "Kies een maand";

        if (this.filters.month.length) {
          let months = [...this.filters.month];

          months.sort((a, b) => {
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
          });

          months = months.map((x) =>
            this.$options.filters.date(new Date(2000, --x, 1), "LLLL")
          );
          text = months.join(", ");
        }

        return text;
      },
    },

    watch: {
      "filters.year"() {
        this.getMonths();
      },
    },

    methods: {
      filter() {
        this.getStats();
        this.getTopSoldProducts();
      },

      getMonths() {
        let params = {
          year: this.filters.year,
          customer: this.$route.params.id,
        };

        this.$http
          .get("stats/get-months", { params: params })
          .then((response) => {
            this.options.months = response.data;
          });
      },

      getYears() {
        let params = {
          customer: this.$route.params.id,
        };

        this.$http.get("stats/get-years", { params: params }).then((response) => {
          this.options.years = response.data;
        });
      },

      getStats() {
        this.loading.stats = true;

        let params = {
          year: this.filters.year,
          month: this.filters.month,
          period: this.filters.period,
        };

        this.$http
          .get(`stats/order-stats-by-customer/${this.$route.params.id}`, {
            params: params,
          })
          .then((response) => {
            this.stats = response.data.data;
          })
          .then(() => {
            this.loading.stats = false;
          });
      },

      getTopSoldProducts() {
        this.loading.products = true;

        let params = {
          year: this.filters.year,
          month: this.filters.month,
          period: this.filters.period,
          customer: this.$route.params.id,
          limit: 30,
        };

        this.$http
          .get("stats/top-sold-products", { params: params })
          .then((response) => {
            this.products = response.data.data;
          })
          .then(() => {
            this.loading.products = false;
          });
      },
    },

    mounted() {
      this.getYears();
      this.getMonths();

      this.getStats();
      this.getTopSoldProducts();
    },
  };
</script>
