<template>
  <b-card>
    <b-row>
      <b-col>
        <h2>API Tokens</h2>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <p class="mb-2">
          <strong>Let op!</strong> Deze tokens zijn account gebonden, je zult
          dus geen tokens aangemaakt door andere gebruikers zien.
        </p>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-row class="mt-2">
          <b-col>
            <Input
              placeholder="Beschrijving"
              label="Nieuwe API Token toevoegen"
              v-model="newToken.token_name"
            />
          </b-col>
          <b-col>
            <b-form-group label="Capabilities">
              <b-form-select v-model="newToken.capabilities">
                <b-form-select-option value="webhooks">
                  Webhooks
                </b-form-select-option>
                <b-form-select-option value="gadero">
                  Gadero API Routes
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-button variant="primary" @click="storeToken">Opslaan</b-button>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col>
        <b-table striped hover small bordered :fields="fields" :items="tokens">
          <template #cell(actions)="row">
            <b-button
              variant="primary"
              v-b-tooltip.hover.left="`Deze API Token verwijderen?`"
              @click="deleteToken(row.item)"
            >
              <i class="far fa-trash-alt mr-0"></i>
            </b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-modal id="tokenModal" hide-footer centered hide-header>
      <code>
        <div class="two-factor-recovery-codes text-center">
          <span>
            {{ plainTextToken }}
          </span>
        </div>
      </code>
    </b-modal>
  </b-card>
</template>

<script>
  export default {
    data() {
      return {
        fields: [
          {
            key: "name",
            label: "Beschrijving",
            sortable: true,
          },
          {
            key: "last_used_at",
            label: "Aangeroepen op",
            formatter: (value) => {
              return value ? this.$options.filters.date(new Date(value)) : "-";
            },
          },
          {
            key: "created_at",
            label: "Aangemaakt op",
            formatter: (value) => {
              return this.$options.filters.date(new Date(value));
            },
          },
          {
            key: "actions",
            label: "",
            tdClass: "column-fit-content",
          },
        ],

        tokens: [],
        newToken: {
          capabilities: "webhooks",
        },
        plainTextToken: null,
      };
    },

    methods: {
      getTokens() {
        this.$http.get(`tokens`).then(({ data }) => {
          this.tokens = data.tokens;
        });
      },

      storeToken() {
        let data = { ...this.newToken };

        this.$http.post(`tokens`, data).then(({ data }) => {
          this.newToken = {
            capabilities: "webhooks",
          };
          this.plainTextToken = data.token.plainTextToken;

          this.$bvModal.show("tokenModal");

          this.getTokens();

          this.$root.notify("De API token is succesvol aangemaakt.");
        });
      },

      deleteToken(item) {
        this.$root.showConfirm(
          "Weet je zeker dat je deze API Token wil verwijderen?",
          () => {
            let params = {
              tokenId: item.id,
            };

            this.$http.delete(`tokens`, { params: params }).then(() => {
              this.tokens = this.tokens.filter((existingItem) => {
                return existingItem.id !== item.id;
              });

              this.$root.notify("De API Token is succesvol verwijderd.");
            });
          }
        );
      },
    },

    mounted() {
      this.getTokens();
    },
  };
</script>