<template>
  <b-overlay
    :show="loading"
    spinner-variant="secondary"
    bg-color="#fff"
    opacity="1"
    rounded="sm"
  >
    <b-card class="card-stats" no-body>
      <h2 class="stat-title stat-title-scroll">
        {{ products.length }} Best verkochte producten
      </h2>
      <b-card-body>
        <template v-if="products.length">
          <p
            class="my-1 stat-product"
            v-for="(product, index) of products"
            :key="index"
          >
            <b-badge variant="primary" class="mx-2 text-monospace">
              {{ product.amount_sold }}
            </b-badge>
            <router-link
              :id="`product-stat-${index}`"
              :to="{
                name: 'product.show',
                params: { id: product.id },
              }"
            >
              {{ product.name }}
            </router-link>

            <b-popover
              :target="`product-stat-${index}`"
              triggers="hover"
              placement="right"
              boundary="window"
              variant="stat"
            >
              <div class="d-flex justify-content-between align-items-center">
                <div class="stat-value">
                  &euro;
                  {{ $options.filters.formatNumber(product.revenue, 2, true) }}
                  <span class="stat-value-prev">
                    &euro;
                    {{
                      $options.filters.formatNumber(
                        product.revenue_previous,
                        2,
                        true
                      )
                    }}
                  </span>
                </div>
                <span class="stat-icon ml-4 d-inline-block">
                  <i
                    :class="{
                      'fa-arrow-trend-up text-success':
                        product.revenue > product.revenue_previous,
                      'fa-arrow-trend-down text-danger':
                        product.revenue < product.revenue_previous,
                    }"
                    class="far"
                  />
                </span>
              </div>
            </b-popover>
          </p>
          <b-button
            v-if="products_meta.current_page < products_meta.last_page"
            variant="primary"
            class="btn-stat-load-more"
            @click="$parent.$parent.$parent.getTopSoldProducts()"
          >
            <i class="far fa-plus mr-0" />
          </b-button>
        </template>
        <template v-else>
          Er zijn geen producten gevonden voor deze periode
        </template>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
  export default {
    props: {
      loading: {
        type: Boolean,
        default: () => {
          return false;
        },
      },

      products: {
        type: Array,
        default: () => {
          return [];
        },
      },

      products_meta: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
  };
</script>