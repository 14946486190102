<template>
  <b-card class="card-stats">
    <h4 class="card-title">{{ title }}</h4>
    <span
      v-if="iconVisible"
      class="stat-icon"
      :id="`stat-tooltip-${$options.filters.toSlug(title)}`"
    >
      <span :class="{ 'd-none': trendDown }">
        <i class="far fa-arrow-trend-up text-success" />
      </span>
      <span :class="{ 'd-none': trendUp }">
        <i class="far fa-arrow-trend-down text-danger" />
      </span>
    </span>

    <div class="stat-value" v-if="currency">
      &euro; {{ value | formatNumber(2, true) }}
      <span class="stat-value-prev">
        &euro; {{ oldValue | formatNumber(2, true) }}
      </span>
    </div>
    <div class="stat-value" v-else>
      {{ value | formatNumber(0, true) }}
      <span class="stat-value-prev">
        {{ oldValue | formatNumber(0, true) }}
      </span>
    </div>

    <b-tooltip
      v-if="iconVisible"
      triggers="hover"
      :target="`stat-tooltip-${$options.filters.toSlug(title)}`"
    >
      <template v-if="value > oldValue">Stijging van</template>
      <template v-else>Daling van</template>
      {{ $options.filters.percentageDiff(value, oldValue) }}%
    </b-tooltip>
  </b-card>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: () => {
          return "";
        },
      },
      value: {
        type: Number,
        default: () => {
          return 0;
        },
      },
      oldValue: {
        type: Number,
        default: () => {
          return 0;
        },
      },
      currency: {
        type: Boolean,
        default: () => {
          return false;
        },
      },
    },

    computed: {
      iconVisible() {
        return this.value !== this.oldValue;
      },

      trendUp() {
        return this.value > this.oldValue;
      },

      trendDown() {
        return this.value < this.oldValue;
      },
    },
  };
</script>
