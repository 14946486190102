<template>
  <OrderTable
    :id="`finished-orders`"
    :orders="finished_orders"
    :meta="finished_orders_meta"
    :loading="loading"
    @updateOrderTable="getFinishedOrders()"
  />
</template>

<script>
  import OrderTable from "../_OrderTable";

  export default {
    components: {
      OrderTable,
    },
    data() {
      return {
        finished_orders: [],
        finished_orders_meta: {
          total: 0,
        },

        filters: {
          orderBy: "created_at",
          orderDirection: "desc",
        },

        loading: false,
      };
    },
    methods: {
      filter() {
        this.$root.applyFilters(this.filters);
        this.getFinishedOrders();
      },

      getFinishedOrders() {
        this.loading = true;

        let statuses = `${this.$root.OrderStatus.SENT},${this.$root.OrderStatus.SAMPLE_COMPLETED},${this.$root.OrderStatus.CANCELLED}`;

        let params = {
          ...this.filters,
          customer: this.$route.params.id,
          status: statuses,
          page: this.$route.query.page ? this.$route.query.page : null,
        };

        this.$http
          .get(`orders`, {
            params: params,
          })
          .then((response) => {
            this.finished_orders = response.data.data;
            this.finished_orders_meta = response.data.meta;

            this.loading = false;
          });
      },
    },
    mounted() {
      this.filters = this.$root.setFilters(this.filters);
      this.getFinishedOrders();
    },
  };
</script>