<template>
  <b-card>
    <b-row>
      <template v-if="hasItems">
        <b-col
          md="48"
          v-for="(user, key, index) in appointments"
          :key="index"
          class="agenda-request"
        >
          <b-row>
            <b-col md="48">
              <div class="agenda-request-user">
                <p>
                  {{ key }}
                  <b-badge class="ml-1 badge-align" variant="primary" pill>
                    {{ user.length }}
                  </b-badge>
                </p>
                <b-button
                  size="sm"
                  variant="primary"
                  v-b-toggle="`account-${index}`"
                  class="float-right"
                >
                  <span class="when-open">Inklappen</span>
                  <span class="when-closed">Uitklappen</span>
                </b-button>
              </div>
            </b-col>
            <b-col>
              <b-collapse :id="`account-${index}`">
                <div class="agenda-request-table">
                  <b-table
                    striped
                    hover
                    responsive
                    :fields="fields"
                    :items="user"
                  >
                    <template
                      #cell(actions)="row"
                      v-if="auth.role.id === $root.Role.ADMIN"
                    >
                      <b-button
                        variant="primary"
                        class="mr-2"
                        v-b-tooltip.hover.left="`Deze aanvraag accepteren?`"
                        @click="acceptRequest(row.item.id)"
                      >
                        <i class="far fa-check-square mr-0" />
                      </b-button>
                      <b-button
                        variant="outline-primary"
                        v-b-tooltip.hover.left="`Deze aanvraag afwijzen?`"
                        @click="declineRequest(row.item.id)"
                      >
                        <i class="far fa-trash-alt mr-0" />
                      </b-button>
                    </template>
                  </b-table>
                </div>
              </b-collapse>
            </b-col>
          </b-row>
        </b-col>
      </template>
      <template v-else>
        <b-col>
          <p class="font-italic">Er zijn geen aanvragen gevonden.</p>
        </b-col>
      </template>
    </b-row>
  </b-card>
</template>

<script>
  import { AppointmentType } from "@/enums/Appointment/Type";
  import { mapGetters } from "vuex";

  export default {
    data() {
      return {
        AppointmentType: AppointmentType,
        fields: [
          { key: "note", label: "Opmerking", class: "column-max-width" },
          { key: "date", label: "Datum" },
          {
            key: "end_date",
            label: "Einddatum",
            formatter: (value) => {
              return value || "-";
            },
          },
          { key: "actions", label: "", class: "column-fit-content" },
        ],
        appointments: [],
      };
    },

    computed: {
      ...mapGetters({
        auth: "auth/user",
      }),

      hasItems() {
        return Object.keys(this.appointments).length ? true : false;
      },
    },

    methods: {
      getAppointments() {
        let params = {
          type: AppointmentType.APPROVAL_REQUIRED,
        };

        this.$http
          .get("appointments/by-user", { params: params })
          .then((response) => {
            this.appointments = response.data;
          });
      },

      acceptRequest(id) {
        if (this.auth.role.id !== this.$root.Role.ADMIN) {
          return;
        }

        let data = {
          type: AppointmentType.STAFF,
        };

        this.$http
          .put(`appointments/${id}`, data)
          .then(() => {
            this.$root.notify("Aanvraag is succesvol goedgekeurd");
          })
          .catch(() => {
            this.$root.notify(
              "Er is iets mis gegaan, probber het later opnieuw",
              "danger"
            );
          })
          .then(() => {
            this.getAppointments();
          });
      },

      declineRequest(id) {
        if (this.auth.role.id !== this.$root.Role.ADMIN) {
          return;
        }

        this.$http
          .delete(`appointments/${id}`)
          .then(() => {
            this.$root.notify("Aanvraag is succesvol afgekeurd");
          })
          .catch(() => {
            this.$root.notify(
              "Er is iets mis gegaan, probber het later opnieuw",
              "danger"
            );
          })
          .then(() => {
            this.getAppointments();
          });
      },
    },

    created() {
      this.getAppointments();
    },
  };
</script>