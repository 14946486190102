var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-button',{staticClass:"agenda__item agenda__item--order",class:{
    'agenda__item--order-faded': _vm.order.packing_slip_path,
    'agenda__item--order-pickup':
      _vm.order.delivery_type === _vm.OrderDeliveryType.PICK_UP,
    'agenda__item--order-van':
      _vm.order.delivery_type === _vm.OrderDeliveryType.VAN_DELIVERY,
  },attrs:{"id":`popover-order-${_vm.order.id}`}},[(_vm.order.customer)?[_vm._v(" #"+_vm._s(_vm.order.order_number)+" "+_vm._s(_vm.order.customer.billing_company ? _vm.order.customer.billing_company : _vm.order.customer.billing_formatted_name)+" ")]:[_vm._v("#"+_vm._s(_vm.order.order_number))],_c('i',{staticClass:"fas fa-check-square mr-0"}),_c('b-popover',{attrs:{"target":`popover-order-${_vm.order.id}`,"custom-class":"popover-menu","placement":"bottom","triggers":"blur click","boundary":"window"}},[_c('b-button-group',{attrs:{"vertical":""}},[_c('b-button',{attrs:{"variant":"primary","to":{
          name: 'order.show',
          params: { id: _vm.order.id },
        },"target":"_blank"}},[_c('i',{staticClass:"far fa-external-link"}),_vm._v(" Order bekijken ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.downloadPackingSlip(_vm.order)}}},[_c('i',{staticClass:"far fa-file-download"}),_vm._v(" Pakbon downloaden ")])],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }