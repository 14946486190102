<template>
  <div
    class="context-menu"
    v-show="show"
    :style="style"
    tabindex="0"
    v-on-clickaway="close"
  >
    <b-button-group vertical size="sm" ref="buttons">
      <b-button
        v-for="(item, index) of items"
        :key="index"
        variant="context-menu"
      >
        <i class="far fa-external-link"></i>{{ item.text }}
      </b-button>

      <template v-if="component === 'service-request'">
        <b-button
          v-if="item.order"
          :to="{
            name: `order.show`,
            params: {
              id: item.order.id,
            },
          }"
          target="_blank"
          variant="context-menu"
        >
          <i class="far fa-external-link"></i>
          Open <b>{{ item.order_number }}</b> in een nieuw tabblad
        </b-button>
        <b-button
          v-if="item.product"
          :to="{
            name: `product.show`,
            params: {
              id: item.product.id,
            },
          }"
          target="_blank"
          variant="context-menu"
        >
          <i class="far fa-external-link"></i>
          Open <b>{{ item.product.sku }}</b> in een nieuw tabblad
        </b-button>
      </template>
      <template v-else>
        <b-button :to="linkObject" target="_blank" variant="context-menu">
          <i class="far fa-external-link"></i>

          Open
          <b v-if="item.order_number">{{ item.order_number }}</b>
          <b v-else-if="item.formatted_name">{{ item.formatted_name }}</b>
          <b v-else-if="item.sku">{{ item.sku }}</b>
          <b v-else-if="item.product">{{ item.product.sku }}</b>
          <b v-else-if="item.name">{{ item.name }}</b>
          in een nieuw tabblad
        </b-button>
        <b-button @click="copyLink" variant="context-menu">
          <i class="far fa-link"></i>

          Link kopiëren
        </b-button>
      </template>
    </b-button-group>
  </div>
</template>
<script>
  import Vue from "vue";
  import { mixin as clickaway } from "vue-clickaway";
  /**
   * @description Place this component in the root of the component you want to use it in, otherwise the positioning will not work correctly!
   *
   * @param {Boolean} display
   * @param {String} component
   *
   * @author Michael Lanting <michael@nordique.nl>
   * @version 1.1.0
   */
  export default {
    mixins: [clickaway],
    props: {
      display: Boolean,

      component: {
        type: String,
        default: () => {
          return null;
        },
      },

      items: {
        type: Array,
        default: () => {
          return [];
        },
      },
    },

    data() {
      return {
        left: 0,
        top: 0,
        show: false,
        item: {},
      };
    },

    computed: {
      style() {
        return {
          top: this.top + "px",
          left: this.left + "px",
        };
      },

      linkObject() {
        let params = {
          id: this.item.id,
        };

        if (this.component === "purchase-order") {
          params = { id: this.item.supplier_id, orderId: this.item.id };
        }

        return {
          name: `${this.component}.show`,
          params: params,
        };
      },
    },

    methods: {
      close() {
        this.show = false;
        this.left = 0;
        this.top = 0;
      },

      open(evt, item, leftOffset = 0, topOffset = 0) {
        this.left = evt.pageX + leftOffset;
        this.top = evt.pageY - 5 + topOffset;

        Vue.nextTick(() => this.$el.focus());

        this.show = true;
        this.item = item;
      },

      copyLink() {
        let text = this.$router.resolve(this.linkObject);

        var dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = `${process.env.VUE_APP_BASE_URL}${text.href}`;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);

        this.$root.notify("De link is succesvol gekopieerd");
      },
    },
  };
</script>