<template>
  <b-card class="card-stats">
    <h4 class="card-title">{{ title }}</h4>
    <span
      v-if="iconVisible"
      class="stat-icon"
      :id="`stat-tooltip-${$options.filters.toSlug(title)}`"
    >
      <span :class="{ 'd-none': trendDown }">
        <i class="far fa-arrow-trend-up text-success" />
      </span>
      <span :class="{ 'd-none': trendUp }">
        <i class="far fa-arrow-trend-down text-danger" />
      </span>
    </span>

    <div class="stat-value" v-if="currency">
      &euro; {{ currentMargin | formatNumber(2, true) }}
      <span
        class="stat-value-extra"
        :id="`stat-extra-tooltip-${$options.filters.toSlug(title)}`"
      >
        {{ percentageOfRevenue | formatNumber(0) }}%
      </span>
      <span class="stat-value-prev">
        &euro; {{ previousMargin | formatNumber(2, true) }}
      </span>
    </div>
    <div class="stat-value" v-else>
      {{ currentMargin | formatNumber(0, true) }}
      <span class="stat-value-prev">
        {{ previousMargin | formatNumber(0, true) }}
      </span>
    </div>

    <b-tooltip
      v-if="iconVisible"
      triggers="hover"
      boundary="window"
      placement="right"
      :target="`stat-tooltip-${$options.filters.toSlug(title)}`"
    >
      <template v-if="currentMargin > previousMargin">Stijging van</template>
      <template v-else>Daling van</template>
      {{ $options.filters.percentageDiff(currentMargin, previousMargin) }}%
    </b-tooltip>

    <b-tooltip
      triggers="hover"
      boundary="window"
      placement="right"
      :target="`stat-extra-tooltip-${$options.filters.toSlug(title)}`"
    >
      Percentage van omzet
    </b-tooltip>
  </b-card>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: () => {
          return "";
        },
      },
      currentPeriod: {
        type: Object,
        default: () => {
          return {
            subtotal: 0,
            purchase_total: 0,
          };
        },
      },
      previousPeriod: {
        type: Object,
        default: () => {
          return {
            subtotal: 0,
            purchase_total: 0,
          };
        },
      },
      currency: {
        type: Boolean,
        default: () => {
          return false;
        },
      },
    },

    computed: {
      currentMargin() {
        return this.currentPeriod.subtotal - this.currentPeriod.purchase_total;
      },

      previousMargin() {
        return this.previousPeriod.subtotal - this.previousPeriod.purchase_total;
      },

      percentageOfRevenue() {
        return (this.currentMargin / this.currentPeriod.subtotal) * 100;
      },

      iconVisible() {
        return this.currentMargin !== this.previousMargin;
      },

      trendUp() {
        return this.currentMargin > this.previousMargin;
      },

      trendDown() {
        return this.currentMargin < this.previousMargin;
      },
    },
  };
</script>
