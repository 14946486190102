var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-overlay',{attrs:{"show":_vm.loading,"spinner-variant":"secondary","bg-color":"#fff","opacity":"1","rounded":"sm"}},[_c('b-card',{staticClass:"card-stats",attrs:{"no-body":""}},[_c('h2',{staticClass:"stat-title stat-title-scroll"},[_vm._v(" "+_vm._s(_vm.customers.length)+" Klanten met hoogste omzet ")]),_c('b-card-body',[(_vm.customers.length)?[_vm._l((_vm.customers),function(customer,index){return _c('p',{key:index,staticClass:"my-1 stat-product"},[_c('b-badge',{staticClass:"mx-2 text-monospace",attrs:{"variant":"primary"}},[_vm._v(" € "+_vm._s(_vm._f("formatNumber")(customer.revenue,2, true))+" ")]),_c('router-link',{attrs:{"id":`customer-stat-${index}`,"to":{
              name: 'customer.show',
              params: { id: customer.id },
            }}},[_vm._v(" "+_vm._s(customer.name)+" ")]),_c('b-popover',{attrs:{"target":`customer-stat-${index}`,"triggers":"hover","placement":"right","boundary":"window","variant":"stat"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"stat-value"},[_vm._v(" € "+_vm._s(_vm.$options.filters.formatNumber(customer.revenue, 2, true))+" "),_c('span',{staticClass:"stat-value-prev"},[_vm._v(" € "+_vm._s(_vm.$options.filters.formatNumber( customer.revenue_previous, 2, true ))+" ")])]),_c('span',{staticClass:"stat-icon ml-4 d-inline-block"},[_c('i',{staticClass:"far",class:{
                    'fa-arrow-trend-up text-success':
                      customer.revenue > customer.revenue_previous,
                    'fa-arrow-trend-down text-danger':
                      customer.revenue < customer.revenue_previous,
                  }})])])])],1)}),(_vm.meta.current_page < _vm.meta.last_page)?_c('b-button',{staticClass:"btn-stat-load-more",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$parent.$parent.$parent.getTopRevenueCustomers()}}},[_c('i',{staticClass:"far fa-plus mr-0"})]):_vm._e()]:[_vm._v(" Er zijn geen klanten gevonden voor deze periode ")]],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }