<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <back-button />
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col>
        <b-card no-body>
          <template #header>
            Factuur - Order #{{ invoice.order.order_number }}
          </template>

          <b-card-body>
            <Invoice :invoice="invoice" :company-info="companyInfo"></Invoice>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card header="Acties">
          <b-button @click="sendInvoiceMail" variant="secondary" block>
            Factuur versturen
          </b-button>
          <b-button
            @click="downloadInvoice"
            v-if="invoice.path"
            variant="primary"
            block
          >
            Factuur downloaden
          </b-button>

          <hr />

          <b-button @click="deleteInvoice" variant="danger" block>
            Factuur verwijderen
          </b-button>
        </b-card>

        <b-card class="mt-4" header="Informatie">
          <b-row>
            <b-col>
              <p><b>Factuurnummer</b></p>
              <template v-if="invoice.number">
                {{ invoice.number }}
              </template>
              <template v-else>
                <b-badge>Beschikbaar na versturen</b-badge>
              </template>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col>
              <p><b>Factuurdatum</b></p>
              <template v-if="invoice.date">
                {{ new Date(invoice.date) | date }}
              </template>
              <template v-else>
                <b-badge>Beschikbaar na versturen</b-badge>
              </template>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col>
              <p><b>Aangemaakt door</b></p>
              {{ invoice.created_by.formatted_name }}
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col>
              <p><b>Aangemaakt op</b></p>
              {{ new Date(invoice.created_at) | date }}
            </b-col>
          </b-row>

          <hr />

          <b-row class="mt-3">
            <b-col>
              <p>
                <b>Exact</b>
                <i
                  class="fas ml-2"
                  :class="{
                    'fa-exclamation-triangle text-warning': !invoice.exact_id,
                    'fa-check-circle text-success': invoice.exact_id,
                  }"
                />
              </p>
              <p v-if="!invoice.exact_id">
                Nog niet ingeboekt, dit gebeurt automatisch na het versturen van
                de factuur.
              </p>
              <p v-else>De factuur is netjes ingeboekt in Exact.</p>
              <!-- <template v-else>
                <b-badge v-if="invoice.is_paid" variant="success">
                  Betaald
                </b-badge>
                <b-badge v-else variant="warning">Wachtend op betaling</b-badge>
              </template> -->

              <b-button
                v-if="!invoice.exact_id && invoice.status === 2"
                @click="createExactSalesEntry"
                variant="primary"
                class="mt-2"
                block
              >
                Inboeken in Exact
              </b-button>
            </b-col>
          </b-row>

          <hr />

          <b-button
            :to="{
              name: 'order.show',
              params: { id: invoice.order.id },
            }"
            target="_blank"
            variant="primary"
            class="btn-block mt-3"
          >
            Order bekijken
          </b-button>
        </b-card>

        <b-card class="mt-4" header="Kosten">
          <template v-if="invoice.status === 1">
            <Input
              v-model="invoice.order.shipping_costs"
              label="Verzendkosten"
              step="0.01"
              min="0"
              type="number"
            />
          </template>
          <template v-else>
            <p>
              <b>Verzendkosten</b>
            </p>
            <p>
              &euro;
              {{
                invoice.order.shipping_costs
                  ? invoice.order.shipping_costs
                  : 0 | formatNumber(2)
              }}
            </p>
          </template>

          <template v-if="invoice.status === 1">
            <Input
              v-model="invoice.order.extra_costs"
              label="Extra kosten"
              step="0.01"
              min="0"
              type="number"
            />
          </template>
          <template v-else>
            <p class="mt-3">
              <b>Extra kosten</b>
            </p>
            <p>
              &euro;
              {{
                invoice.order.extra_costs
                  ? invoice.order.extra_costs
                  : 0 | formatNumber(2)
              }}
            </p>
          </template>

          <template v-if="invoice.status === 1">
            <b-form-group label="Korting">
              <b-select v-model="invoice.order.discount_type">
                <b-select-option disabled :value="null">
                  Kies een optie
                </b-select-option>
                <b-select-option value="percentage">Percentage</b-select-option>
                <b-select-option value="price">Bedrag</b-select-option>
              </b-select>
            </b-form-group>
            <Input
              v-model="invoice.discount"
              step="0.01"
              min="0"
              type="number"
            />
          </template>
          <template v-else>
            <p class="mt-3">
              <b>Korting</b>
            </p>
            <p>
              <template
                v-if="
                  invoice.order.discount_type === 'price' ||
                  !invoice.order.discount_type
                "
              >
                &euro;
                {{
                  invoice.order.discount
                    ? invoice.order.discount
                    : 0 | formatNumber(2)
                }}
              </template>
              <template v-else>
                {{
                  invoice.order.discount
                    ? invoice.order.discount
                    : 0 | formatNumber(2)
                }}
                %</template
              >
            </p>
          </template>

          <b-button
            v-if="invoice.status === 1"
            @click="updateOrder"
            variant="primary"
            class="btn-block mt-3"
          >
            Opslaan
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import Invoice from "./Invoice";

  import BackButton from "@/components/BackButton";

  export default {
    components: {
      Invoice,
      BackButton,
    },

    data: function () {
      return {
        fields: [
          {
            key: "name",
            label: "Product",
          },
          {
            key: "quantity",
            label: "Hoeveelheid",
          },
          {
            key: "m2_per_product",
            label: "m2",
          },
          {
            key: "price_total",
            label: "Prijs",
          },
        ],

        companyInfo: {},

        order: {
          date_create: null,
          billing_first_name: null,
        },

        invoice: {
          status: null,
          order: {},
          created_by: {
            name: null,
          },
        },
      };
    },

    computed: {
      dateCreated() {
        return new Date(this.order.date_created * 1000);
      },
    },

    methods: {
      sendInvoiceMail() {
        this.$root.showConfirm(
          "Weet je zeker dat je deze factuur wil versturen?",
          () => {
            this.$http.get(`create-invoice/${this.$route.params.id}`).then(() => {
              this.$root.notify("De factuur is succesvol verstuurd.");

              this.getInvoice();
            });
          }
        );
      },

      getInvoice() {
        this.$http.get(`invoices/${this.$route.params.id}`).then((response) => {
          this.invoice = response.data.data;
        });
      },

      updateOrder() {
        let data = {
          discount: this.invoice.discount,
          discount_type: this.invoice.discount_type,
          shipping_costs: this.invoice.shipping_costs,
          extra_costs: this.invoice.extra_costs,
        };

        this.$http
          .put(`invoices/${this.$route.params.id}`, data)
          .then((response) => {
            this.$root.notify("De kosten zijn succesvol aangepast.");
            this.invoice = response.data.data;
          });
      },

      deleteInvoice() {
        this.$root.showConfirm(
          "Weet je zeker dat je deze factuur wil verwijderen?",
          () => {
            this.$http.delete(`invoices/${this.$route.params.id}`).then(() => {
              this.$root.notify("De factuur is succesvol verwijderd.");
              this.$router.push({ name: "invoice.index" });
            });
          }
        );
      },

      downloadInvoice() {
        this.$http
          .get(`invoices/${this.invoice.id}/download`, {
            responseType: "arraybuffer",
          })
          .then((response) => {
            let blob = new Blob([response.data], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `Floer ${this.invoice.number} - Floer BV`;
            link.click();
          });
      },

      createExactSalesEntry() {
        let params = {
          id: this.invoice.id,
          type: "invoice",
        };

        this.$http
          .get(`exact/create-sales-entry`, { params: params })
          .then(() => {
            this.$root.notify("De factuur is succesvol ingeboekt.");
          })
          .catch((errors) => {
            this.$root.notify(errors.data.message, "danger");
          });
      },

      getCompanyInfo() {
        let params = {
          type: "company",
        };

        this.$http.get(`settings`, { params: params }).then((response) => {
          this.companyInfo = response.data.data;
        });
      },
    },

    mounted() {
      this.getInvoice();
      this.getCompanyInfo();
    },
  };
</script>