<template>
  <div>
    <b-card>
      <h4 class="card-title">Omzet</h4>
      <canvas style="max-height: 400px" id="stock-chart"></canvas>
    </b-card>
  </div>
</template>

<script>
  import Chart from "chart.js/auto";

  export default {
    name: "StockChart",

    data() {
      return {
        prognosis: {
          periods: [],
          data: [],
        },

        chart: null,
      };
    },

    props: {
      customer: null,

      filters: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },

    watch: {
      "filters.year"() {
        this.filter();
      },
      "filters.period"() {
        this.filter();
      },
      "filters.month"() {
        this.filter();
      },
    },

    methods: {
      filter() {
        this.chart.destroy();
        this.draw();
      },

      draw() {
        let params = {
          ...this.filters,
          ...{ customer: this.customer },
        };

        this.$http
          .get("stats/customer-revenue", { params: params })
          .then(({ data }) => {
            this.prognosis = data;
          })
          .then(() => {
            const data = {
              labels: this.prognosis.periods,
              datasets: [
                {
                  label: "Omzet",
                  backgroundColor: "#7978FF",
                  borderColor: "#7978FF",
                  data: this.prognosis.data,
                },
                {
                  label: "Total omzet",
                  backgroundColor: "#329E35",
                  borderColor: "#329E35",
                  data: this.prognosis.extra,
                  hidden: true,
                },
              ],
            };

            const ctx = document.getElementById("stock-chart").getContext("2d");

            const config = {
              type: "line",
              data: data,
              options: {
                plugins: {
                  legend: true,
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        let label = context.dataset.label || "";

                        if (label) {
                          label += ": ";
                        }
                        if (context.parsed.y !== null) {
                          label += new Intl.NumberFormat("nl-NL", {
                            style: "currency",
                            currency: "EUR",
                          }).format(context.parsed.y);
                        }
                        return label;
                      },
                    },
                  },
                },
                scales: {
                  revenuePerPeriod: {
                    beginAtZero: true,
                    type: "linear",
                    position: "left",
                    ticks: {
                      precision: 0,
                      callback: function (value) {
                        return new Intl.NumberFormat("nl-NL", {
                          style: "currency",
                          currency: "EUR",
                        }).format(value);
                      },
                    },
                  },
                },
              },
            };

            this.chart = new Chart(ctx, config);
          });
      },
    },

    mounted() {
      this.draw();
    },
  };
</script>