<template>
  <div>
    <b-card header="Gebruikers">
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Zoeken"
            description="Zoek op voornaam, achternaam of email"
          >
            <b-input
              type="search"
              v-model="filters.terms"
              @change="filter"
              placeholder="Zoek een gebruiker"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Rol">
            <b-form-select v-model="filters.role" @change="filter">
              <b-select-option value="null" selected> Alles </b-select-option>
              <b-select-option :value="$root.Role.WORKER">
                Medewerkers
              </b-select-option>
              <b-select-option :value="$root.Role.AGENT">
                Vertegenwoordigers
              </b-select-option>
              <b-select-option :value="$root.Role.ADMIN">
                Beheerders
              </b-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col class="text-right">
          <b-button :to="{ name: 'user.create' }">
            <i class="far fa-plus" /> Nieuwe gebruiker
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-table
            striped
            bordered
            hover
            show-empty
            no-local-sorting
            id="user-table"
            :items="users"
            :fields="fields"
            :busy="loading.users"
            @sort-changed="sort"
            @row-contextmenu="rowContextMenuHandler"
            @row-clicked="$root.rowClickRedirect($event, 'user.show')"
          >
            <template #table-busy>
              <div class="text-center">
                <b-spinner variant="secondary" class="align-middle mr-3" />
                <strong class="align-middle">Gebruikers worden geladen</strong>
                <strong class="align-middle">
                  Gebruikers worden geladen
                </strong>
              </div>
            </template>
            <template #empty="">
              <span class="text-center d-block">
                Er zijn geen gebruikers gevonden
              </span>
            </template>

            <template #cell(has2FA)="row">
              <b-badge v-if="row.item.has2FA" variant="success" class="ml-1">
                AAN
              </b-badge>
              <b-badge v-else variant="danger" class="ml-1">UIT</b-badge>
            </template>
          </b-table>

          <b-pagination-nav
            use-router
            first-number
            last-number
            v-model="usersMeta.current_page"
            :link-gen="linkGen"
            :number-of-pages="usersMeta.last_page"
            @change="getUsers"
          >
            <template #prev-text>
              <i class="far fa-angle-left" />
            </template>
            <template #next-text>
              <i class="far fa-angle-right" />
            </template>
            <template #ellipsis-text>
              <i class="fal fa-ellipsis-h" />
            </template>
          </b-pagination-nav>
        </b-col>
      </b-row>
    </b-card>

    <context-menu
      :id="$route.name"
      :key="$route.fullPath"
      :display="showContextMenu"
      component="user"
      ref="menu"
    />
  </div>
</template>

<script>
  import ContextMenu from "@/components/ContextMenu";

  export default {
    components: {
      ContextMenu,
    },

    data() {
      return {
        filters: {
          terms: null,
          orderBy: null,
          orderDirection: null,
          role: null,
        },
        showContextMenu: false,
        fields: [
          { key: "formatted_name", label: "Naam", sortable: true },
          { key: "email", label: "E-mailadres" },
          { key: "role.name", label: "Rol" },
          { key: "has2FA", label: "2FA", class: "column-fit-content" },
        ],
        users: [],
        usersMeta: {},
        loading: {
          users: false,
        },
      };
    },

    mounted() {
      this.filters = this.$root.setFilters(this.filters);
      this.getUsers();
    },

    methods: {
      sort(ctx) {
        this.filters.orderBy =
          ctx.sortBy === "formatted_name" ? "first_name" : ctx.sortBy;
        this.filters.orderDirection = ctx.sortDesc ? "desc" : "asc";

        this.filter();
      },

      linkGen(pageNum) {
        return pageNum === 1 ? "?" : `?page=${pageNum}`;
      },

      filter() {
        this.$root.applyFilters(this.filters);
        this.getUsers();
      },

      getUsers(page = null) {
        this.loading.users = true;

        let params = {
          ...this.filters,
          page: page
            ? this.$route.query.page
              ? this.$route.query.page
              : null
            : null,
        };

        this.$http
          .get("users", { params: params })
          .then((response) => {
            this.users = response.data.data;
            this.usersMeta = response.data.meta;
          })
          .then(() => {
            this.loading.users = false;
          });
      },

      rowContextMenuHandler(item, _, event) {
        this.$refs.menu.open(event, item);
        event.preventDefault();
      },
    },
  };
</script>