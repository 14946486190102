<template>
  <b-overlay
    :show="loading"
    spinner-variant="secondary"
    bg-color="#fff"
    opacity="1"
    rounded="sm"
  >
    <b-card class="card-stats" no-body>
      <h2 class="stat-title stat-title-scroll">Omzet per vertegenwoordiger</h2>
      <b-card-body>
        <template v-if="data.length">
          <p
            class="my-1 stat-product"
            v-for="(item, index) of data"
            :key="index"
          >
            <b-badge variant="primary" class="mx-2 text-monospace">
              &euro; {{ item.revenue | formatNumber(2, true) }}
            </b-badge>
            <router-link
              :id="`agent-stat-${index}`"
              :to="{
                name: 'user.show',
                params: { id: item.agent_id },
              }"
            >
              {{ item.name }}
            </router-link>

            <b-popover
              :target="`agent-stat-${index}`"
              triggers="hover"
              placement="right"
              boundary="window"
              variant="stat"
            >
              <div class="d-flex justify-content-between align-items-center">
                <div class="stat-value">
                  &euro;
                  {{ $options.filters.formatNumber(item.revenue, 2, true) }}
                  <span class="stat-value-prev">
                    &euro;
                    {{
                      $options.filters.formatNumber(
                        item.revenue_previous,
                        2,
                        true
                      )
                    }}
                  </span>
                </div>
                <span class="stat-icon ml-4 d-inline-block">
                  <i
                    :class="{
                      'fa-arrow-trend-up text-success':
                        item.revenue > item.revenue_previous,
                      'fa-arrow-trend-down text-danger':
                        item.revenue < item.revenue_previous,
                    }"
                    class="far"
                  />
                </span>
              </div>
            </b-popover>
          </p>
          <b-button
            v-if="meta.current_page < meta.last_page"
            variant="primary"
            class="btn-stat-load-more"
            @click="$parent.$parent.$parent.getTopRevenueAgents()"
          >
            <i class="fas fa-plus mr-0" />
          </b-button>
        </template>
        <template v-else>
          Er zijn geen klanten gevonden voor deze periode
        </template>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
  export default {
    props: {
      loading: {
        type: Boolean,
        default: () => {
          return false;
        },
      },

      data: {
        type: Array,
        default: () => {
          return [];
        },
      },

      meta: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
  };
</script>