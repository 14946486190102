<template>
  <div>
    <b-row>
      <b-col>
        <b-tabs pills end content-class="mb-3">
          <b-tab lazy active title="Agenda">
            <b-card class="card--agenda" no-body>
              <template #header>
                <b-col class="pl-0 d-flex align-items-center">
                  <b-button variant="primary" @click="loadWeek('prev')">
                    <i class="mr-0 far fa-caret-left" />
                  </b-button>
                  <b-button class="mx-2" variant="primary" @click="loadWeek()">
                    Huidige week
                  </b-button>
                  <b-button variant="primary" @click="loadWeek('next')">
                    <i class="mr-0 far fa-caret-right" />
                  </b-button>
                  <span class="ml-3 font-italic text-muted">
                    Week: {{ weekNumber }}
                  </span>
                </b-col>
                <b-col class="d-flex justify-content-end align-items-start">
                  <b-button variant="primary" @click="openCreateModal()">
                    Nieuwe afspraak
                  </b-button>
                </b-col>
                <b-col md="12" class="pr-0">
                  <b-form-group class="mb-0">
                    <b-button
                      variant="input"
                      block
                      @click="selectExpanded = !selectExpanded"
                    >
                      Toon / verberg categorieën
                      <i class="fas fa-sort" />
                    </b-button>

                    <template v-if="selectExpanded">
                      <div class="v-select search-select">
                        <div class="v-dropdown-container pt-0">
                          <b-form-group class="mb-0">
                            <b-form-checkbox-group
                              v-model="selectedCategories"
                              stacked
                            >
                              <b-form-checkbox
                                v-for="(category, index) in categories"
                                :key="index"
                                :value="category.id"
                                class="m-2"
                              >
                                {{ category.name }}
                                <span
                                  class="category-color"
                                  :style="`background-color: ${category.color}`"
                                />
                              </b-form-checkbox>
                            </b-form-checkbox-group>
                          </b-form-group>
                        </div>
                      </div>
                    </template>
                  </b-form-group>
                </b-col>
              </template>

              <b-card-body class="pt-0">
                <b-row class="agenda-wrapper">
                  <b-col
                    class="agenda__day"
                    :class="{
                      'agenda__day--today':
                        $options.filters.date(new Date(), 'd MM Y') ===
                        $options.filters.date(new Date(day), 'd MM Y'),
                    }"
                    v-for="(day, index) of currentWeekList"
                    :key="index"
                  >
                    <div class="agenda__day-title">
                      {{ new Date(day) | date("iiii d MMMM") }}
                    </div>

                    <template
                      v-if="
                        loading.orders ||
                        loading.purchaseOrders ||
                        loading.appointments
                      "
                    >
                      <b-skeleton animation="wave" width="60%" />
                      <b-skeleton type="button" width="100%" />
                      <b-skeleton type="button" width="100%" class="mt-1" />
                      <b-skeleton type="button" width="100%" class="mt-1" />
                      <b-skeleton type="button" width="100%" class="mt-1" />
                      <b-skeleton type="button" width="100%" class="mt-1" />
                      <b-skeleton type="button" width="100%" class="mt-1" />
                    </template>
                    <template v-else>
                      <agenda-category-order
                        :day="day"
                        :orders="orders"
                        :order-stats="orderStats"
                        :category-id="categories.orders.id"
                        :selected-categories="selectedCategories"
                      />

                      <agenda-category-appointment
                        :day="day"
                        :appointments="appointments[AppointmentType.REMINDER]"
                        :appointment-type="AppointmentType.REMINDER"
                        :category-id="categories.reminders.id"
                        :selected-categories="selectedCategories"
                      />

                      <agenda-category-purchase-order
                        :day="day"
                        :purchase-orders="purchaseOrders"
                        :category-id="categories.purchaseOrders.id"
                        :selected-categories="selectedCategories"
                      />

                      <agenda-category-appointment
                        :day="day"
                        :appointments="appointments[AppointmentType.REGULAR]"
                        :appointment-type="AppointmentType.REGULAR"
                        :category-id="categories.regular.id"
                        :selected-categories="selectedCategories"
                      />

                      <agenda-category-appointment
                        :day="day"
                        :appointments="appointments[AppointmentType.STAFF]"
                        :appointment-type="AppointmentType.STAFF"
                        :category-id="categories.staff.id"
                        :selected-categories="selectedCategories"
                      />

                      <agenda-category-appointment
                        :day="day"
                        :appointments="
                          appointments[AppointmentType.APPROVAL_REQUIRED]
                        "
                        :appointment-type="AppointmentType.APPROVAL_REQUIRED"
                        :category-id="categories.approval_required.id"
                        :selected-categories="selectedCategories"
                      />

                      <agenda-category-appointment
                        :day="day"
                        :appointments="appointments[AppointmentType.SHOWROOM]"
                        :appointment-type="AppointmentType.SHOWROOM"
                        :category-id="categories.showroom.id"
                        :selected-categories="selectedCategories"
                      />

                      <agenda-birthday
                        :day="day"
                        :staff="staff"
                        :category-id="categories.birthdays.id"
                        :selected-categories="selectedCategories"
                      />

                      <div
                        class="agenda__stats mt-3"
                        v-if="
                          selectedCategories.includes(categories.orders.id) &&
                          orderStats[day]
                        "
                      >
                        <p>
                          <span>Aantal orders:</span>
                          <span>{{ orderStats[day].orders }}</span>
                        </p>
                        <p>
                          <span>Aantal producten:</span>
                          <span>{{ orderStats[day].items_sold }}</span>
                        </p>
                        <p>
                          <span>Totale waarde:</span>
                          <span>
                            &euro;
                            {{
                              orderStats[day].items_value
                                | formatNumber(2, true)
                            }}
                          </span>
                        </p>
                      </div>
                    </template>
                  </b-col>
                </b-row>
                <legenda :categories="categories" />
              </b-card-body>
            </b-card>
          </b-tab>
          <b-tab lazy>
            <template #title>
              Aanvragen
              <b-badge
                class="ml-1"
                variant="primary"
                v-if="ApprovalRequiredCount"
                pill
              >
                {{ ApprovalRequiredCount }}
              </b-badge>
            </template>
            <requests />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>

    <b-modal
      id="createAppointmentModal"
      size="lg"
      title="Nieuwe afspraak toevoegen"
    >
      <b-row>
        <b-col v-if="appointment.type === 5">
          <b-form-group label="Toelichting">
            <b-input v-model="appointment.note" :maxlength="255" />
          </b-form-group>
        </b-col>
        <b-col v-else>
          <b-form-group label="Titel">
            <b-input
              placeholder="Papier container"
              v-model="appointment.title"
            />
          </b-form-group>
        </b-col>

        <b-col md="16">
          <b-form-group label="Type afspraak">
            <b-select v-model="appointment.type">
              <b-select-option :value="null" selected disabled>
                Kies een optie
              </b-select-option>
              <b-select-option :value="1">Algemene zaken</b-select-option>
              <b-select-option
                :value="2"
                v-if="auth.role.id === $root.Role.ADMIN"
              >
                Personeel
              </b-select-option>
              <b-select-option :value="5" v-else> Vrij vragen </b-select-option>
              <b-select-option :value="3">Showroom</b-select-option>
            </b-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Datum">
            <b-input type="date" v-model="appointment.date" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-1 mb-3">
        <b-col>
          <b-form-checkbox switch v-model="appointment.repeat">
            Afspraak herhalen
          </b-form-checkbox>
        </b-col>
      </b-row>

      <template v-if="appointment.repeat">
        <b-row>
          <b-col>
            <b-form-group label="Eind datum">
              <b-input type="date" v-model="appointment.end_date" />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Herhaald">
              <b-select v-model="appointment.interval">
                <b-select-option :value="null" selected disabled>
                  Kies een optie
                </b-select-option>
                <b-select-option :value="1">Dagelijks</b-select-option>
                <b-select-option :value="2">Wekelijks</b-select-option>
                <b-select-option :value="3">Twee wekelijks</b-select-option>
                <b-select-option :value="4">Maandelijks</b-select-option>
                <b-select-option :value="5">Elk kwartaal</b-select-option>
                <b-select-option :value="6">Jaarlijks</b-select-option>
              </b-select>
            </b-form-group>
          </b-col>
        </b-row>
      </template>

      <b-row v-if="appointment.type === 2">
        <b-col>
          <b-form-group label="Toelichting">
            <b-input v-model="appointment.note" :maxlength="255" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col> </b-col>
      </b-row>
      <template #modal-footer>
        <b-button variant="primary" @click="storeAppointment">
          Afspraak toevoegen
        </b-button>
      </template>
    </b-modal>

    <b-modal id="updateAppointmentModal" size="lg" title="Afspraak bewerken">
      <b-row>
        <b-col v-if="appointment.type === 5">
          <b-form-group label="Toelichting">
            <b-input v-model="appointment.note" :maxlength="255" />
          </b-form-group>
        </b-col>
        <b-col v-else>
          <b-form-group label="Titel">
            <b-input
              placeholder="Papier container"
              v-model="appointment.title"
            />
          </b-form-group>
        </b-col>

        <template v-if="appointment.type != 5">
          <b-col md="16">
            <b-form-group label="Type afspraak">
              <b-select v-model="appointment.type">
                <b-select-option :value="null" selected disabled>
                  Kies een optie
                </b-select-option>
                <b-select-option :value="1">Algemene zaken</b-select-option>
                <b-select-option :value="2">Personeel</b-select-option>
                <b-select-option :value="3">Showroom</b-select-option>
              </b-select>
            </b-form-group>
          </b-col>
        </template>
        <template v-else>
          <b-col md="16">
            <b-form-group label="Type afspraak">
              <b-input disabled value="Vrij vragen" />
            </b-form-group>
          </b-col>
        </template>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Datum">
            <b-input type="date" v-model="appointment.date" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="appointment.type === 2">
        <b-col>
          <b-form-group label="Toelichting">
            <b-input v-model="appointment.note" :maxlength="255" />
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button variant="primary" @click="updateAppointment">
          <template v-if="appointment.type != 5"> Afspraak bijwerken </template>
          <template v-else>
            <template v-if="auth.role.id === $root.Role.ADMIN">
              Afspraak goedkeuren
            </template>
            <template v-else> Afspraak bijwerken </template>
          </template>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import {
    addWeeks,
    eachDayOfInterval,
    startOfWeek,
    isMonday,
    isFriday,
    nextFriday,
    getISOWeek,
  } from "date-fns";

  import { AppointmentType } from "@/enums/Appointment/Type";

  import { mapGetters } from "vuex";

  import Requests from "./Partials/_Requests";
  import Legenda from "./Partials/_Legenda";
  import AgendaCategoryOrder from "./Partials/_AgendaCategories/_Order";
  import AgendaCategoryAppointment from "./Partials/_AgendaCategories/_Appointment";
  import AgendaCategoryPurchaseOrder from "./Partials/_AgendaCategories/_PurchaseOrder";
  import AgendaBirthday from "./Partials/_AgendaCategories/_Birthday";

  export default {
    components: {
      Legenda,
      Requests,
      AgendaCategoryOrder,
      AgendaCategoryAppointment,
      AgendaCategoryPurchaseOrder,
      AgendaBirthday,
    },

    data() {
      return {
        AppointmentType: AppointmentType,
        selectExpanded: false,
        selectedCategories: [1, 2, 3, 4, 5, 6, 7, 8],
        categories: {
          orders: {
            id: 1,
            name: "Orders / Zendingen",
            color: "#8e24aa",
          },
          reminders: {
            id: 6,
            name: "Reminders",
            color: "#ff9d0b",
          },
          purchaseOrders: {
            id: 2,
            name: "Inkooporders",
            color: "#f5be26",
          },
          regular: {
            id: 3,
            name: "Algemene zaken",
            color: "#616161",
          },
          staff: {
            id: 4,
            name: "Personeel",
            color: "#d50000",
          },
          approval_required: {
            id: 7,
            name: "Goedkeuring nodig",
            color: "#a30000",
          },
          showroom: {
            id: 5,
            name: "Showroom",
            color: "#54cc5d",
          },
          birthdays: {
            id: 8,
            name: "Verjaardagen",
            color: "#0fbee5",
          },
        },

        loading: {
          orders: false,
          purchaseOrders: false,
          appointments: false,
          staff: false,
        },

        weekNumber: null,
        selectedWeek: null,

        currentWeekList: [],

        appointment: {
          type: null,
          interval: null,
          date: this.$options.filters.date(new Date(), "yyyy-MM-dd"),
        },

        orders: [],
        orderStats: [],
        purchaseOrders: [],
        appointments: [],
        staff: [],

        ApprovalRequiredCount: null,
      };
    },

    watch: {
      selectedCategories: {
        handler() {
          localStorage.setItem("selectedCategories", this.selectedCategories);
        },
      },
    },

    computed: {
      ...mapGetters({
        auth: "auth/user",
      }),
    },

    methods: {
      getOrders() {
        this.loading.orders = true;

        let params = {
          start: this.currentWeekList[0],
          end: this.currentWeekList[4],
        };

        this.$http.get("agenda/orders", { params: params }).then(({ data }) => {
          this.orders = data.orders;
          this.orderStats = data.stats;
          this.loading.orders = false;
        });
      },

      getPurchaseOrders() {
        this.loading.purchaseOrders = true;

        let params = {
          start: this.currentWeekList[0],
          end: this.currentWeekList[4],
        };

        this.$http
          .get("agenda/purchase-orders", { params: params })
          .then(({ data }) => {
            this.purchaseOrders = data;
            this.loading.purchaseOrders = false;
          });
      },

      getAppointments() {
        this.loading.appointments = true;

        let params = {
          start: this.currentWeekList[0],
          end: this.currentWeekList[4],
        };

        this.$http
          .get("agenda/appointments", { params: params })
          .then(({ data }) => {
            this.appointments = data;
            this.loading.appointments = false;
          });
      },

      getBirthdays() {
        this.loading.staff = true;

        let params = {
          start: this.currentWeekList[0],
          end: this.currentWeekList[4],
        };

        this.$http
          .get("agenda/customer-birthdays", { params: params })
          .then(({ data }) => {
            this.staff = data;
            this.loading.staff = false;
          });
      },

      openCreateModal() {
        this.appointment = {
          type: null,
          interval: null,
          date: this.$options.filters.date(new Date(), "yyyy-MM-dd"),
        };

        this.$bvModal.show("createAppointmentModal");
      },

      openUpdateModal(appointment) {
        this.appointment = appointment;
        this.$bvModal.show("updateAppointmentModal");
      },

      appointmentCount() {
        let params = {
          type: AppointmentType.APPROVAL_REQUIRED,
        };

        this.$http
          .get(`appointments/count`, { params: params })
          .then(({ data }) => {
            this.ApprovalRequiredCount = data;
          });
      },

      updateAppointment() {
        if (this.appointment.type === 5) {
          if (this.auth.role.id === this.$root.Role.ADMIN) {
            this.appointment.type = 2;
          }
        }

        let data = {
          ...this.appointment,
        };

        this.$http
          .put(`appointments/${this.appointment.id}`, data)
          .then(() => {
            this.$root.notify("Afspraak succesvol opgeslagen");
          })
          .catch(() => {
            this.$root.notify(
              "Er is iets mis gegaan, probber het later opnieuw",
              "danger"
            );
          })
          .then(() => {
            this.getAppointments();
          });
      },

      storeAppointment() {
        if (this.appointment.type === 5) {
          this.appointment.title = `${this.auth.first_name}`;
        }

        this.$http
          .post("appointments", this.appointment)
          .then(() => {
            this.appointment = {
              type: null,
              interval: null,
              date: this.$options.filters.date(new Date(), "yyyy-MM-dd"),
            };

            this.$root.notify("Afspraak succesvol opgeslagen");
          })
          .catch(() => {
            this.$root.notify(
              "Er is iets mis gegaan, probber het later opnieuw",
              "danger"
            );
          })
          .then(() => {
            this.getAppointments();
          });
      },

      deleteAppointment(appointment, siblings = false) {
        let plural = "afspraak";

        let params = {
          siblings: siblings,
        };

        if (siblings) {
          plural = "afspraken";
        }

        this.$root.showConfirm(
          `Weet je zeker dat je deze ${plural} wil verwijderen?`,
          () => {
            this.$http
              .delete(`appointments/${appointment.id}`, { params: params })
              .then(() => {
                this.$root.notify(`Geselecteerde ${plural} succesvol verwijderd`);
              })
              .catch(() => {
                this.$root.notify(
                  "Er is iets mis gegaan, probber het later opnieuw",
                  "danger"
                );
              })
              .then(() => {
                this.getAppointments();
              });
          }
        );
      },

      loadWeek(type = "current") {
        switch (type) {
          case "next":
            this.selectedWeek = addWeeks(this.selectedWeek, 1);

            break;
          case "prev":
            this.selectedWeek = addWeeks(this.selectedWeek, -1);

            break;
          default:
            this.selectedWeek = new Date();
            break;
        }
        this.weekNumber = getISOWeek(this.selectedWeek);

        this.currentWeekList = eachDayOfInterval({
          start: isMonday(this.selectedWeek)
            ? this.selectedWeek
            : startOfWeek(this.selectedWeek, { weekStartsOn: 1 }),
          end: isFriday(this.selectedWeek)
            ? this.selectedWeek
            : nextFriday(this.selectedWeek),
        });

        for (let i = 0; i < this.currentWeekList.length; i++) {
          this.currentWeekList[i] = this.$options.filters.date(
            this.currentWeekList[i],
            "yyyy-MM-dd"
          );
        }

        this.getOrders();
        this.getPurchaseOrders();
        this.getAppointments();
        this.getBirthdays();
      },
    },

    mounted() {
      this.loadWeek();
      this.appointmentCount();
    },

    created() {
      if (localStorage.getItem("selectedCategories").length !== 0) {
        this.selectedCategories = localStorage
          .getItem("selectedCategories")
          .split(",")
          .map(Number);
      }
    },
  };
</script>