<template>
  <div>
    <b-row>
      <b-col md="24">
        <Input
          label="Relatiecode"
          type="text"
          :error="errors.exact_customer_code"
          v-model="customer.exact_customer_code"
        />
      </b-col>
      <b-col md="24">
        <Input
          label="Afkorting"
          type="text"
          description="Dit is de beschrijving die op de factuur in Exact komt te staan"
          :error="errors.exact_invoice_description"
          v-model="customer.exact_invoice_description"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    props: {
      customer: {
        type: Object,
        default: () => {
          return {};
        },
      },
      errors: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
    computed: {
      ...mapGetters({
        auth: "auth/user",
      }),
    },
  };
</script>