<template>
  <div>
    <b-row>
      <b-col>
        <b-tabs
          @activate-tab="onTabSwitch"
          v-model="filters.tab"
          pills
          content-class="mt-3"
        >
          <b-tab>
            <template #title>
              Facturen
              <b-badge v-if="invoiceCount" class="ml-1" variant="primary" pill>
                {{ invoiceCount }}
              </b-badge>
            </template>
            <b-card>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Zoeken"
                    description="Zoek op factuurnummer, ordernummer of klant"
                  >
                    <b-input
                      type="search"
                      v-model="filters.terms"
                      @change="filter"
                      placeholder="Zoek een factuur"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label="Filteren op status"
                    label-for="statusFilter"
                  >
                    <b-form-select
                      v-model="filters.status"
                      :options="options.status"
                      value-field="value"
                      text-field="name"
                      id="statusFilter"
                      @change="filter"
                    >
                      <template v-slot:first>
                        <b-form-select-option value="all" selected>
                          Alles
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mt-3">
                <b-col>
                  <b-table
                    hover
                    striped
                    responsive
                    show-empty
                    bordered
                    :items="invoices"
                    :fields="fields.invoices"
                    :busy="loading"
                    class="table-dashboard"
                    @row-clicked="
                      $root.rowClickRedirect($event, 'invoice.show')
                    "
                  >
                    <template #table-busy>
                      <div class="text-center">
                        <b-spinner
                          variant="secondary"
                          class="align-middle mr-3"
                        ></b-spinner>
                        <strong class="align-middle">
                          Facturen worden geladen
                        </strong>
                      </div>
                    </template>
                    <template #empty>
                      Er zijn geen facturen beschikbaar
                    </template>

                    <template #cell(number)="row">
                      <template v-if="row.item.number">
                        {{ row.item.number }}
                      </template>
                      <template v-else>
                        <b-badge>Beschikbaar na versturen</b-badge>
                      </template>
                    </template>

                    <template #cell(status)="row">
                      <template v-if="row.item.status === 1">
                        <b-badge variant="info">Open</b-badge>
                      </template>
                      <template v-else-if="row.item.status === 2">
                        <b-badge variant="success">Verstuurd</b-badge>
                      </template>
                      <template v-else>-</template>
                    </template>

                    <template #cell(is_paid)="row">
                      <b-badge v-if="row.item.is_paid" variant="success"
                        >Betaald</b-badge
                      >
                      <b-badge
                        v-else-if="row.item.exact_id && !row.item.is_paid"
                        variant="warning"
                        >Wachtend op betaling</b-badge
                      >
                      <template v-else> - </template>
                    </template>

                    <template #cell(accepted_by)="row">
                      <template v-if="row.item.accepted_by">
                        {{ row.item.accepted_by.formatted_name }}
                      </template>
                      <template v-else>
                        <b-badge>Beschikbaar na versturen</b-badge>
                      </template>
                    </template>

                    <template #cell(actions)="row">
                      <b-button
                        :to="{
                          name: 'order.show',
                          params: {
                            id: row.item.order.id,
                          },
                        }"
                        size="sm"
                      >
                        Order bekijken
                      </b-button>
                    </template>
                  </b-table>

                  <b-pagination-nav
                    use-router
                    first-number
                    last-number
                    v-model="invoicesMeta.current_page"
                    :link-gen="linkGen"
                    :number-of-pages="invoicesMeta.last_page"
                    @change="getInvoices"
                  >
                    <template #prev-text>
                      <i class="far fa-angle-left"></i>
                    </template>
                    <template #next-text>
                      <i class="far fa-angle-right"></i>
                    </template>
                    <template #ellipsis-text>
                      <i class="fal fa-ellipsis-h"></i>
                    </template>
                  </b-pagination-nav>
                </b-col>
              </b-row>
            </b-card>
          </b-tab>
          <b-tab>
            <template #title>
              Creditfacturen
              <b-badge
                v-if="creditNoteCount"
                class="ml-1"
                variant="primary"
                pill
              >
                {{ creditNoteCount }}
              </b-badge>
            </template>

            <b-card>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Zoeken"
                    description="Zoek op factuurnummer, ordernummer of klant"
                  >
                    <b-input
                      type="search"
                      v-model="filters.terms"
                      @change="filter('credit')"
                      placeholder="Zoek een factuur"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label="Filteren op status"
                    label-for="statusFilter"
                  >
                    <b-form-select
                      v-model="filters.status"
                      :options="options.status"
                      value-field="value"
                      text-field="name"
                      id="statusFilter"
                      @change="filter('credit')"
                    >
                      <template v-slot:first>
                        <b-form-select-option value="all" selected>
                          Alles
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col class="text-right">
                  <b-button :to="{ name: 'credit.create' }">
                    <i class="far fa-plus"></i> Nieuwe credit
                  </b-button>
                </b-col>
              </b-row>

              <b-row class="mt-3">
                <b-col>
                  <b-table
                    hover
                    striped
                    responsive
                    show-empty
                    bordered
                    :items="creditNotes"
                    :fields="fields.creditNotes"
                    :busy="loading"
                    class="table-dashboard"
                    @row-clicked="$root.rowClickRedirect($event, 'credit.show')"
                  >
                    <template #table-busy>
                      <div class="text-center">
                        <b-spinner
                          variant="secondary"
                          class="align-middle mr-3"
                        />
                        <strong class="align-middle">
                          Creditfacturen worden geladen
                        </strong>
                      </div>
                    </template>
                    <template #empty>
                      Er zijn geen creditfacturen beschikbaar
                    </template>

                    <template #cell(number)="row">
                      <template v-if="row.item.number">
                        {{ row.item.number }}
                      </template>
                      <template v-else>
                        <b-badge>Beschikbaar na versturen</b-badge>
                      </template>
                    </template>

                    <template #cell(status)="row">
                      <template v-if="row.item.status === 1">
                        <b-badge variant="info">Open</b-badge>
                      </template>
                      <template v-else-if="row.item.status === 2">
                        <b-badge variant="success">Verstuurd</b-badge>
                      </template>
                      <template v-else>-</template>
                    </template>

                    <template #cell(accepted_by)="row">
                      <template v-if="row.item.accepted_by">
                        {{ row.item.accepted_by.formatted_name }}
                      </template>
                      <template v-else>
                        <b-badge>Beschikbaar na versturen</b-badge>
                      </template>
                    </template>

                    <template #cell(actions)="row">
                      <b-button
                        :to="{
                          name: 'customer.show',
                          params: {
                            id: row.item.customer.id,
                          },
                        }"
                        size="sm"
                      >
                        Klant bekijken
                      </b-button>
                    </template>
                  </b-table>

                  <b-pagination-nav
                    use-router
                    first-number
                    last-number
                    v-model="creditNotesMeta.current_page"
                    :link-gen="linkGen"
                    :number-of-pages="creditNotesMeta.last_page"
                    @change="getCreditNotes"
                  >
                    <template #prev-text>
                      <i class="far fa-angle-left"></i>
                    </template>
                    <template #next-text>
                      <i class="far fa-angle-right"></i>
                    </template>
                    <template #ellipsis-text>
                      <i class="fal fa-ellipsis-h"></i>
                    </template>
                  </b-pagination-nav>
                </b-col>
              </b-row>
            </b-card>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        fields: {
          invoices: [
            {
              key: "number",
              label: "Factuur nummer",
              sortable: true,
            },
            {
              key: "accepted_by",
              label: "Verstuurd door",
              sortable: true,
            },
            { key: "status", sortable: true },
            {
              key: "order.order_number",
              label: "Ordernummer",
              sortable: true,
            },
            {
              key: "order.customer.billing_formatted_name",
              label: "Klant",
              sortable: true,
            },
            { key: "actions", label: "", class: "column-fit-content" },
          ],

          creditNotes: [
            {
              key: "number",
              label: "Credit nummer",
              sortable: true,
            },
            {
              key: "accepted_by",
              label: "Verstuurd door",
              sortable: true,
            },
            { key: "status", sortable: true },
            {
              key: "customer.billing_formatted_name",
              label: "Klant",
              sortable: true,
            },
            { key: "actions", label: "", class: "column-fit-content" },
          ],
        },

        filters: {
          status: 1,
          terms: null,
          tab: 0,
        },

        options: {
          status: [
            {
              value: 1,
              name: "Open",
            },
            {
              value: 2,
              name: "Verstuurd",
            },
          ],
        },

        invoices: [],
        invoicesMeta: {},
        invoiceCount: null,

        creditNotes: [],
        creditNotesMeta: {},
        creditNoteCount: null,

        loading: false,
      };
    },

    methods: {
      getOpenInvoicesCount() {
        let params = {
          status: 1,
        };

        this.$http
          .get("invoices/count", {
            params: params,
          })
          .then((response) => {
            this.invoiceCount = response.data;
          });
      },

      getOpenCreditNotesCount() {
        let params = {
          status: 1,
        };

        this.$http
          .get("credit-notes/count", {
            params: params,
          })
          .then((response) => {
            this.creditNoteCount = response.data;
          });
      },

      getInvoices(page = null) {
        this.loading = true;

        let params = {
          ...this.filters,
          page: page
            ? this.$route.query.page
              ? this.$route.query.page
              : null
            : null,
        };

        this.$http
          .get("invoices", { params: params })
          .then((response) => {
            this.invoices = response.data.data;
            this.invoicesMeta = response.data.meta;
          })
          .then(() => {
            this.loading = false;
          });
      },

      getCreditNotes(page = null) {
        this.loading = true;

        let params = {
          ...this.filters,
          page: page
            ? this.$route.query.page
              ? this.$route.query.page
              : null
            : null,
          orderDirection: "desc",
          orderBy: "id",
        };

        this.$http
          .get("credit-notes", { params: params })
          .then((response) => {
            this.creditNotes = response.data.data;
            this.creditNotesMeta = response.data.meta;
          })
          .then(() => {
            this.loading = false;
          });
      },

      linkGen(pageNum) {
        return pageNum === 1 ? "?" : `?page=${pageNum}`;
      },

      filter(type = "invoice") {
        this.$root.applyFilters(this.filters);

        switch (type) {
          case "credit":
            this.getCreditNotes();
            break;

          default:
            this.getInvoices();
            break;
        }
      },

      resetFilters() {
        this.filters.status = 1;
        this.filters.terms = null;

        this.$root.applyFilters(this.filters);
      },

      onTabSwitch(newTab) {
        this.filters.tab = newTab;

        this.resetFilters();

        switch (newTab) {
          case 1:
            this.getCreditNotes();
            break;

          default:
            this.getInvoices();
            break;
        }
      },
    },

    created() {
      this.filters = this.$root.setFilters(this.filters);
    },

    mounted() {
      this.getOpenInvoicesCount();
      this.getOpenCreditNotesCount();

      if (this.filters.tab === 1) {
        this.getCreditNotes();
      } else {
        this.getInvoices();
      }
    },
  };
</script>
                  