<template>
  <div class="login">
    <div>
      <img
        class="login__logo"
        src="@/assets/images/logo.svg"
        alt="Floer B.V."
      />
    </div>

    <b-card class="login__wrapper login__wrapper--two-factor">
      <b-row>
        <b-col md="48">
          <h2>Scannen</h2>
          <p>
            Scan onderstaande QR code met een Authenticator app en bewaar de
            herstel codes op een veilige plek.
          </p>
          <p class="mt-2">
            Na het verlaten van deze pagina zal de code niet meer zichtbaar
            zijn. Om de code opnieuw te scannen moet 2FA opnieuw ingesteld
            worden.
          </p>
        </b-col>
        <b-col md="48">
          <hr />
        </b-col>
        <b-col md="48" class="text-center">
          <div class="two-factor-qr-code mb-4" v-if="qrCode" v-html="qrCode" />
        </b-col>
        <b-col md="48" class="text-center">
          <b>Herstelcodes</b>
          <div v-if="recoveryCodes.length" class="two-factor-recovery-codes">
            <span v-for="(code, index) of recoveryCodes" :key="index">{{
              code
            }}</span>
          </div>
        </b-col>
        <b-col md="48" class="mt-3 text-center">
          <b>Geheime sleutel</b>
          <div class="two-factor-recovery-codes">
            <span>{{ secretKey }}</span>
          </div>
        </b-col>

        <b-col md="48" class="mt-3">
          <b-button block variant="primary" :to="{ name: 'dashboard' }">
            Doorgaan naar dashboard
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    data() {
      return {
        qrCode: "",
        recoveryCodes: [],
        secretKey: null,
      };
    },

    computed: {
      ...mapGetters({
        auth: "auth/user",
      }),
    },

    methods: {
      enableTwoFactorAuth() {
        this.$http.post("/user/two-factor-authentication").then(() => {
          this.$http.get("/user/two-factor-qr-code").then((response) => {
            this.qrCode = response.data.svg;
          });

          this.$http.get("/user/two-factor-recovery-codes").then((response) => {
            this.recoveryCodes = response.data;
          });

          this.$http.get("/user/two-factor-secret-key").then((response) => {
            this.secretKey = response.data.secretKey;
          });
        });
      },
    },

    mounted() {
      this.enableTwoFactorAuth();
    },
  };
</script>