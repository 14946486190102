<template>
  <div class="pdf-content invoice">
    <b-row class="mb-3 pdf-item">
      <b-col xl="20">
        <b-img src="@/assets/images/logo.svg" fluid class="mb-5" />
        <h1 class="mt-5">Factuur</h1>
        <p>
          {{ invoice.order.customer.billing_formatted_name }}
        </p>
        <p v-if="invoice.order.customer.billing_company">
          {{ invoice.order.customer.billing_company }}
        </p>
        <p>
          {{ invoice.order.customer.billing_address }}
        </p>
        <p v-if="invoice.order.customer.vat_number" class="mt-2">
          BTW NR {{ invoice.order.customer.vat_number }}
        </p>
      </b-col>

      <b-col offset-xl="8" class="invoice__information">
        <div>
          <p class="font-weight-bold">
            {{ companyInfo.name.value }}
          </p>
          <p>{{ companyInfo.address.value }}</p>
          <p>
            {{ companyInfo.zipcode.value }},
            {{ companyInfo.city.value }}
          </p>
          <p>
            {{ companyInfo.email.value }}
          </p>
          <p>
            {{ companyInfo.phone.value }}
          </p>
        </div>

        <div class="mt-3">
          <p>
            KvK NR
            {{ companyInfo.companynumber.value }}
          </p>
          <p>BTW NR {{ companyInfo.vatnumber.value }}</p>
          <p>IBAN {{ companyInfo.iban.value }}</p>
        </div>

        <div class="mt-5">
          <b-list-group>
            <b-list-group-item>
              <span>Factuurnummer:</span>
              <span v-if="invoice.number">
                {{ invoice.number }}
              </span>
              <span v-else>
                <b-badge>Beschikbaar na versturen</b-badge>
              </span>
            </b-list-group-item>

            <b-list-group-item>
              <span>Factuurdatum:</span>
              <span v-if="invoice.date">
                {{ new Date(invoice.date) | date("d MMMM yyyy") }}
              </span>
              <span v-else>
                <b-badge>Beschikbaar na versturen</b-badge>
              </span>
            </b-list-group-item>

            <b-list-group-item>
              <span>Ordernummer:</span>
              <span>
                {{ invoice.order.order_number }}
              </span>
            </b-list-group-item>

            <b-list-group-item>
              <span>Orderdatum:</span>
              <span>
                {{ new Date(invoice.order.created_at) | date("d MMMM yyyy") }}
              </span>
            </b-list-group-item>

            <b-list-group-item>
              <span>Betaalmethode:</span>
              <span v-if="invoice.order.is_mollie"
                >Reeds betaald in webshop</span
              >
              <span v-else>Op Factuur</span>
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-col>
    </b-row>

    <!-- Price table -->
    <b-row class="mb-2 pdf-item">
      <b-col>
        <b-table :items="invoice.order.orderlines" :fields="fields">
          <template v-slot:cell(name)="row">
            {{ row.item.product.name }}
            <span class="invoice__table-sku">
              SKU: {{ row.item.product.sku }}
            </span>
          </template>
          <template v-slot:cell(price_total)="row">
            &euro;
            {{ (row.item.price * row.item.quantity) | formatNumber(2) }}
          </template>
          <template v-slot:cell(m2_per_product)="row">
            {{
              (row.item.product.m2_per_product * row.item.quantity)
                | formatNumber(3)
            }}
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- Comments and invoice total -->
    <b-row class="pdf-item mb-5">
      <b-col xl="20">
        <span class="invoice__comments-header"> Order opmerking: </span>
        <b-form-textarea
          id="textarea"
          class="mt-2"
          placeholder="Eventuele opmerkingen.."
          rows="3"
          max-rows="6"
          v-model="invoice.comments"
          @blur="updateInvoice"
        ></b-form-textarea>
        <template v-if="invoice.order.customer.country === 'be'">
          <p>
            Betreft een intracommunautaire levering Belgie: intracommunautaire
            levering: artikel 138, lid 1, Richtlijn 2006/112.
          </p>
        </template>
      </b-col>
      <b-col>
        <b-list-group class="lines">
          <b-list-group-item>
            <span>Subtotaal</span>
            <span>
              &euro;
              {{ invoice.order.price.subtotal | formatNumber(2) }}
            </span>
          </b-list-group-item>

          <b-list-group-item v-if="invoice.order.price.shippingCosts">
            <span>Verzendkosten</span>
            <span>
              &euro;
              {{ invoice.order.price.shippingCosts | formatNumber(2) }}
            </span>
          </b-list-group-item>

          <b-list-group-item v-if="invoice.order.price.extraCosts">
            <span>Extra kosten</span>
            <span>
              &euro;
              {{ invoice.order.price.extraCosts | formatNumber(2) }}
            </span>
          </b-list-group-item>

          <b-list-group-item
            v-for="(fee_line, index) in invoice.order.fee_lines"
            :key="index"
          >
            <span>{{ fee_line.description }}</span>
            <span>
              &euro;
              {{ fee_line.value | formatNumber(2) }}
            </span>
          </b-list-group-item>

          <b-list-group-item v-if="invoice.order.price.discount">
            <span>Korting</span>
            <span>
              &euro;
              {{ invoice.order.price.discount | formatNumber(2) }}
            </span>
          </b-list-group-item>

          <b-list-group-item v-if="invoice.price.tax">
            <span>BTW</span>
            <span>
              &euro;
              {{ invoice.order.price.tax | formatNumber(2) }}
            </span>
          </b-list-group-item>

          <b-list-group-item>
            <span>Totaal</span>
            <span>
              &euro;
              {{ invoice.order.price.total | formatNumber(2) }}
            </span>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>

    <hr />

    <!-- Footer -->
    <b-row class="pdf-item mt-5">
      <b-col class="text-center">
        <p>
          <span class="font-weight-bold">Betalingstermijn:</span>
          14 dagen, bij betaling factuurnummer vermelden.
        </p>
        <p>
          <span class="font-weight-bold">IBAN </span>
          {{ companyInfo.iban.value }}
        </p>
        <p>
          <span class="font-weight-bold">BTW NR </span>
          {{ companyInfo.vatnumber.value }}
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  export default {
    props: ["invoice", "companyInfo"],

    data: function () {
      return {
        price: {},

        fields: [
          {
            key: "name",
            label: "Product",
          },
          {
            key: "quantity",
            label: "Hoeveelheid",
          },
          {
            key: "m2_per_product",
            label: "m2",
          },
          {
            key: "price_total",
            label: "Prijs",
          },
        ],
      };
    },

    methods: {
      updateInvoice() {
        this.$http
          .put(`invoices/${this.$route.params.id}`, this.invoice)
          .then(() => {
            this.$root.notify("Factuur succesvol aangepast");
          });
      },
    },
  };
</script>
