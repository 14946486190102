<template>
  <b-button
    class="agenda__item agenda__item--purchase-order"
    :class="{
      'agenda__item--purchase-order-faded': purchaseOrder.status === 3,
    }"
    :id="`popover-purchase-order-${purchaseOrder.id}`"
  >
    {{ purchaseOrder.supplier.name }}
    <i class="fas fa-check-square mr-0"></i>

    <b-popover
      :target="`popover-purchase-order-${purchaseOrder.id}`"
      custom-class="popover-menu"
      placement="bottom"
      triggers="blur click"
      boundary="window"
    >
      <b-button-group vertical>
        <b-button
          variant="primary"
          :to="{
            name: 'purchase-order.show',
            params: {
              id: purchaseOrder.supplier.id,
              orderId: purchaseOrder.id,
            },
          }"
          target="_blank"
        >
          <i class="far fa-external-link" />
          Inkooporder bekijken
        </b-button>
      </b-button-group>
    </b-popover>
  </b-button>
</template>

<script>
  export default {
    props: {
      purchaseOrder: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
  };
</script>