<template>
  <b-button small variant="light" @click="$router.go(-1)">
    <i class="fas fa-chevron-left"></i>
    Terug naar de vorige pagina
  </b-button>
</template>

<script>
  export default {};
</script>

<style>
</style>