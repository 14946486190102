var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-button',{staticClass:"agenda__item agenda__item--birthday",attrs:{"id":`popover-staffmember-${_vm.staffmember.id}`}},[_vm._v(" 🎂 "+_vm._s(_vm.staffmember.name)+" "),_c('b-popover',{attrs:{"target":`popover-staffmember-${_vm.staffmember.id}`,"custom-class":"popover-menu","placement":"bottom","triggers":"blur click","boundary":"window"}},[_c('b-button-group',{attrs:{"vertical":""}},[_c('b-button',{attrs:{"variant":"primary","to":{
          name: 'customer.show',
          params: {
            id: _vm.staffmember.customer.id,
            tab: 'personeel',
          },
        },"target":"_blank"}},[_c('i',{staticClass:"far fa-external-link"}),_vm._v(" Personeel "+_vm._s(_vm.staffmember.customer.billing_formatted_name)+" bekijken ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }