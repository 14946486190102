<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <back-button />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card header="Nieuwe locatie">
          <b-form @submit.prevent="store">
            <Input
              :label="input.label"
              :type="input.type"
              v-model="location[key]"
              :id="`location-${index}`"
              v-for="(input, key, index) in inputs"
              :key="key"
            />
            <b-button type="submit">Aanmaken</b-button>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  import BackButton from "@/components/BackButton";

  export default {
    components: { BackButton },

    data() {
      return {
        location: {},
        inputs: {
          name: {
            label: "Naam",
            type: "text",
          },
        },
      };
    },

    methods: {
      store() {
        this.$http
          .post(`locations`, this.location)
          .then(() => {
            this.$root.notify("De nieuwe locatie is succesvol aangemaakt.");

            this.$router.push({
              name: "location.index",
            });
          })
          .catch((errors) => {
            this.errors = this.$options.filters.setErrors(errors.data.errors);
            this.$root.notify(errors.data.errors, "danger");
          });
      },
    },
  };
</script>