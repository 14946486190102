<template>
  <b-card no-body class="mt-4">
    <template #header>
      <span>
        Verwachte leveringen
        <b-badge class="ml-2">
          {{ purchase_orders_meta.total }}
        </b-badge>
      </span>
    </template>
    <b-card-body>
      <b-table
        striped
        bordered
        hover
        show-empty
        :items="purchase_orders"
        :fields="fields"
        :busy="loading"
        @row-contextmenu="rowContextMenuHandler"
        @row-clicked="clickHandler"
      >
        <template #table-busy>
          <div class="text-center">
            <b-spinner
              variant="secondary"
              class="align-middle mr-3"
            ></b-spinner>
            <strong class="align-middle"
              >Verwachte leveringen worden geladen</strong
            >
          </div>
        </template>
        <template #empty="">
          <span class="text-center d-block">
            Er zijn geen verwachte leveringen gevonden
          </span>
        </template>
      </b-table>

      <b-pagination-nav
        use-router
        first-number
        last-number
        v-model="purchase_orders_meta.current_page"
        :link-gen="linkGen"
        :number-of-pages="purchase_orders_meta.last_page"
        @change="getPurchaseOrders(status)"
      >
        <template #prev-text>
          <i class="far fa-angle-left"></i>
        </template>
        <template #next-text>
          <i class="far fa-angle-right"></i>
        </template>
        <template #ellipsis-text>
          <i class="fal fa-ellipsis-h"></i>
        </template>
      </b-pagination-nav>
    </b-card-body>
  </b-card>
</template>

<script>
  import { OrderStatus } from "@/enums/PurchaseOrder/Status";

  export default {
    props: {
      /**
       * The unique ID used for the collapse
       */
      id: {
        type: String,
        default: null,
      },

      loading: {
        type: Boolean,
        default: false,
      },
    },

    watch: {
      filters: {
        deep: true,
        handler() {
          this.getPurchaseOrders();
        },
      },
    },

    data() {
      return {
        filters: {
          orderBy: "created_at",
          orderDirection: "DESC",
          status: `${OrderStatus.PENDING}, ${OrderStatus.INCOMING}`,
        },

        purchase_orders: [],
        purchase_orders_meta: {},

        fields: [
          {
            key: "orderlines.0.quantity",
            label: "Aantal",
          },
          {
            key: "expected_delivery_date",
            label: "Verwachte datum",
            sortable: true,
            formatter: (value) => {
              if (value) {
                value = this.$options.filters.date(new Date(value), "dd/MM/yyyy");
              } else {
                value = "Nog niet bekend";
              }

              return value;
            },
          },
        ],
      };
    },

    mounted() {
      this.getPurchaseOrders();
    },

    methods: {
      linkGen(pageNum) {
        return pageNum === 1 ? "?" : `?purchase_orders_page=${pageNum}`;
      },

      rowContextMenuHandler(item, _, event) {
        this.$parent.rowContextMenuHandler(item, _, event, "purchase-order");
      },

      clickHandler(record) {
        this.$router.push({
          name: "purchase-order.show",
          params: {
            id: record.supplier_id,
            orderId: record.id,
          },
        });
      },

      getPurchaseOrders() {
        let page = null;

        if (this.$route.query.purchase_orders_page) {
          page = this.$route.query.purchase_orders_page;
        }

        let params = {
          ...this.filters,
          product: this.$route.params.id,
          page: page,
        };

        this.$http
          .get(`purchase-orders`, {
            params: params,
          })
          .then((response) => {
            this.purchase_orders = response.data.data;
            this.purchase_orders_meta = response.data.meta;
          });
      },
    },
  };
</script>