<template>
  <div>
    <b-row class="mb-3">
      <b-col class="d-flex justify-content-end">
        <b-button
          small
          variant="light"
          @click="syncWoocommerce"
          v-b-tooltip.hover
          title="De synchronisatie duurt gemiddeld 15-25 seconden"
        >
          <i class="fas fa-sync"></i>
          Klanten uit WooCommerce halen
        </b-button>
      </b-col>
    </b-row>

    <b-card>
      <template #header>
        Klanten

        <div class="d-flex align-content-center justify-content-end">
          <b-form-group class="mb-0 d-inline-block mr-2">
            <b-button
              variant="transparant"
              size="sm"
              @click="selectFields = !selectFields"
            >
              <i class="far fa-eye-slash" />Toon / verberg kolommen
            </b-button>

            <template v-if="selectFields">
              <div class="v-select search-select">
                <div class="v-dropdown-container pt-0">
                  <b-form-group class="mb-0">
                    <template v-for="field in fields">
                      <b-checkbox
                        v-if="field.label"
                        :key="field.key"
                        v-model="field.visible"
                        class="m-2"
                      >
                        {{ field.label }}
                      </b-checkbox>
                    </template>
                  </b-form-group>
                </div>
              </div>
            </template>
          </b-form-group>

          <b-form-checkbox
            v-if="
              auth.role.id === $root.Role.AGENT ||
              auth.role.id === $root.Role.ADMIN
            "
            class="d-flex align-items-center"
            v-model="viewMyCustomers"
            name="filter-show-my-customers"
            @change="filter"
            switch
            size="sm"
          >
            Toon mijn klanten
          </b-form-checkbox>
        </div>
      </template>

      <b-row>
        <b-col>
          <b-form-group
            label="Zoeken"
            description="Zoek op klantnaam, bedrijfsnaam of email"
          >
            <b-input
              type="search"
              v-model="filters.terms"
              @change="filter"
              placeholder="Zoek een klant"
            />
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group label="Land" description="Kies een land">
            <b-form-select v-model="filters.country" @change="filter">
              <b-form-select-option :value="null" selected>
                Alles
              </b-form-select-option>
              <b-form-select-option value="nl">
                🇳🇱 Nederland
              </b-form-select-option>
              <b-form-select-option value="be">
                🇧🇪 België
              </b-form-select-option>
              <b-form-select-option value="fr">🇫🇷 Frans</b-form-select-option>
              <b-form-select-option value="dk">🇩🇰 Deens</b-form-select-option>
              <b-form-select-option value="gb">
                🇬🇧 Engels
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            label="Soort klant"
            description="Kies het type klant(en)"
          >
            <b-button variant="input" block @click="toggleSelectCustomerTypes">
              Kies type
              <b-badge v-if="selectedCustomerTypes.length" variant="dark">
                {{ selectedCustomerTypes.length }}
              </b-badge>

              <i class="fas fa-sort"></i>
            </b-button>

            <template v-if="selectCustomerTypes">
              <div class="v-select search-select">
                <div
                  v-show="selectCustomerTypes"
                  class="v-dropdown-container pt-0"
                >
                  <b-form-group class="m-0">
                    <b-checkbox
                      v-model="selectedCustomerTypes"
                      v-for="option in customerTypes"
                      :key="option.value"
                      :value="option.value"
                      :unchecked-value="null"
                      :class="'m-2'"
                      @change="filter"
                    >
                      {{ $root.CustomerTypeString[option.value] }}
                    </b-checkbox>
                  </b-form-group>
                </div>
              </div>
            </template>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            label="Presentatie middel"
            description="Kies presentatie middel(en)"
          >
            <b-button variant="input" block @click="toggleSelectDisplays">
              Kies presentatie
              <b-badge v-if="selectedPresentations.length" variant="dark">
                {{ selectedPresentations.length }}
              </b-badge>
              <i class="fas fa-sort"></i>
            </b-button>

            <template v-if="selectDisplays">
              <div class="v-select search-select">
                <div v-show="selectDisplays" class="v-dropdown-container pt-0">
                  <b-form-group class="m-0">
                    <b-checkbox
                      v-model="selectedPresentations"
                      v-for="(option, index) in presentations"
                      :key="index"
                      :value="option.product_id"
                      :class="'m-2'"
                      @change="filter"
                    >
                      {{ option.product_id }} - {{ option.name }}
                    </b-checkbox>
                  </b-form-group>
                </div>
              </div>
            </template>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            label="Vertegenwoordiger"
            description="Kies een vertegenwoordiger"
          >
            <b-form-select v-model="filters.agent" @change="filter">
              <b-form-select-option :value="null" selected>
                Alles
              </b-form-select-option>
              <b-form-select-option
                v-for="(agent, index) of agents"
                :key="index"
                :value="agent.id"
              >
                {{ agent.formatted_name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            label="Per pagina"
            description="Kies het aantal resultaten per pagina"
          >
            <b-form-select v-model="filters.limit" @change="filter">
              <b-form-select-option :value="15" selected>
                15
              </b-form-select-option>
              <b-form-select-option :value="30"> 30 </b-form-select-option>
              <b-form-select-option :value="45"> 45 </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <b-table
            striped
            bordered
            hover
            show-empty
            no-local-sorting
            id="user-table"
            :items="customers"
            :fields="visibleFields"
            :busy="loading.customers"
            @sort-changed="sort"
            @row-contextmenu="rowContextMenuHandler"
            @row-clicked="$root.rowClickRedirect($event, 'customer.show')"
          >
            <template #table-busy>
              <div class="text-center">
                <b-spinner
                  variant="secondary"
                  class="align-middle mr-3"
                ></b-spinner>
                <strong class="align-middle">Klanten worden geladen</strong>
              </div>
            </template>

            <template #empty="">
              <span class="text-center d-block">
                Er zijn geen klanten gevonden
              </span>
            </template>

            <template #cell(type)="row">
              <template v-if="row.item.type === 'dealer'">
                {{
                  $root.CustomerTypeString[
                    row.item.dealer_type || row.item.type
                  ]
                }}
              </template>
              <template v-else>
                {{ $root.CustomerTypeString[row.item.type] }}
              </template>
            </template>

            <template #cell(revenue)="row">
              <div class="stat-wrapper">
                <div>
                  <b>
                    &euro; {{ row.item.revenue | formatNumber(2, true, true) }}
                  </b>
                  <br />
                  &euro;
                  {{ row.item.revenue_previous | formatNumber(2, true, true) }}
                </div>

                <div>
                  <span :id="`stat-tooltip-${row.field.key}-${row.index}`">
                    <i
                      class="far"
                      :class="{
                        'fa-arrow-trend-up text-success':
                          row.item.revenue > row.item.revenue_previous,
                        'fa-arrow-trend-down text-danger':
                          row.item.revenue < row.item.revenue_previous,
                      }"
                    />
                  </span>
                </div>
              </div>

              <b-tooltip
                triggers="hover"
                :target="`stat-tooltip-${row.field.key}-${row.index}`"
              >
                <template v-if="row.item.revenue > row.item.revenue_previous">
                  Stijging van
                </template>
                <template v-else>Daling van</template>
                {{
                  $options.filters.percentageDiff(
                    row.item.revenue,
                    row.item.revenue_previous
                  )
                }}%
              </b-tooltip>
            </template>

            <template #cell(revenue_year)="row">
              <div class="stat-wrapper">
                <div>
                  <b>
                    &euro;
                    {{ row.item.revenue_year | formatNumber(2, true, true) }}
                  </b>
                  <br />
                  &euro;
                  {{
                    row.item.revenue_previous_year | formatNumber(2, true, true)
                  }}
                </div>

                <div>
                  <span :id="`stat-tooltip-${row.field.key}-${row.index}`">
                    <i
                      class="far"
                      :class="{
                        'fa-arrow-trend-up text-success':
                          row.item.revenue_year >
                          row.item.revenue_previous_year,
                        'fa-arrow-trend-down text-danger':
                          row.item.revenue_year <
                          row.item.revenue_previous_year,
                      }"
                    />
                  </span>

                  <b-tooltip
                    triggers="hover"
                    :target="`stat-tooltip-${row.field.key}-${row.index}`"
                  >
                    <template
                      v-if="
                        row.item.revenue_year > row.item.revenue_previous_year
                      "
                    >
                      Stijging van
                    </template>
                    <template v-else>Daling van</template>
                    {{
                      $options.filters.percentageDiff(
                        row.item.revenue_year,
                        row.item.revenue_previous_year
                      )
                    }}%
                  </b-tooltip>
                </div>
              </div>
            </template>

            <template #cell(revenue_last_calendar_year)="row">
              <div class="stat-wrapper">
                <div>
                  <b>
                    &euro;
                    {{
                      row.item.revenue_last_calendar_year
                        | formatNumber(2, true, true)
                    }}
                  </b>
                  <br />
                  &euro;
                  {{
                    row.item.revenue_second_to_last_calendar_year
                      | formatNumber(2, true, true)
                  }}
                </div>

                <div>
                  <span :id="`stat-tooltip-${row.field.key}-${row.index}`">
                    <i
                      class="far"
                      :class="{
                        'fa-arrow-trend-up text-success':
                          row.item.revenue_last_calendar_year >
                          row.item.revenue_second_to_last_calendar_year,
                        'fa-arrow-trend-down text-danger':
                          row.item.revenue_last_calendar_year <
                          row.item.revenue_second_to_last_calendar_year,
                      }"
                    />
                  </span>

                  <b-tooltip
                    triggers="hover"
                    :target="`stat-tooltip-${row.field.key}-${row.index}`"
                  >
                    <template
                      v-if="
                        row.item.revenue_last_calendar_year >
                        row.item.revenue_second_to_last_calendar_year
                      "
                    >
                      Stijging van
                    </template>
                    <template v-else>Daling van</template>
                    {{
                      $options.filters.percentageDiff(
                        row.item.revenue_last_calendar_year,
                        row.item.revenue_second_to_last_calendar_year
                      )
                    }}%
                  </b-tooltip>
                </div>
              </div>
            </template>

            <template #cell(revenue_current_year_until_now)="row">
              <div class="stat-wrapper">
                <div>
                  <b>
                    &euro;
                    {{
                      row.item.revenue_current_year_until_now
                        | formatNumber(2, true, true)
                    }}
                  </b>
                  <br />
                  &euro;
                  {{
                    row.item.revenue_previous_year_until_now_then
                      | formatNumber(2, true, true)
                  }}
                </div>

                <div>
                  <span :id="`stat-tooltip-${row.field.key}-${row.index}`">
                    <i
                      class="far"
                      :class="{
                        'fa-arrow-trend-up text-success':
                          row.item.revenue_current_year_until_now >
                          row.item.revenue_previous_year_until_now_then,
                        'fa-arrow-trend-down text-danger':
                          row.item.revenue_current_year_until_now <
                          row.item.revenue_previous_year_until_now_then,
                      }"
                    />
                  </span>

                  <b-tooltip
                    triggers="hover"
                    :target="`stat-tooltip-${row.field.key}-${row.index}`"
                  >
                    <template
                      v-if="
                        row.item.revenue_current_year_until_now >
                        row.item.revenue_previous_year_until_now_then
                      "
                    >
                      Stijging van
                    </template>
                    <template v-else>Daling van</template>
                    {{
                      $options.filters.percentageDiff(
                        row.item.revenue_current_year_until_now,
                        row.item.revenue_previous_year_until_now_then
                      )
                    }}%
                  </b-tooltip>
                </div>
              </div>
            </template>

            <template
              #head(revenue_current_year_until_last_full_month)="{ label }"
            >
              {{
                label.replace(
                  "[month]",
                  $options.filters.date(
                    new Date().setMonth(new Date().getMonth() - 1),
                    "MMM"
                  )
                )
              }}
            </template>

            <template #cell(revenue_current_year_until_last_full_month)="row">
              <div class="stat-wrapper">
                <div>
                  <b>
                    &euro;
                    {{
                      row.item.revenue_current_year_until_last_full_month
                        | formatNumber(2, true, true)
                    }}
                  </b>
                  <br />
                  &euro;
                  {{
                    row.item.revenue_previous_year_until_last_full_month
                      | formatNumber(2, true, true)
                  }}
                </div>

                <div>
                  <span :id="`stat-tooltip-${row.field.key}-${row.index}`">
                    <i
                      class="far"
                      :class="{
                        'fa-arrow-trend-up text-success':
                          row.item.revenue_current_year_until_last_full_month >
                          row.item.revenue_previous_year_until_last_full_month,
                        'fa-arrow-trend-down text-danger':
                          row.item.revenue_current_year_until_last_full_month <
                          row.item.revenue_previous_year_until_last_full_month,
                      }"
                    />
                  </span>

                  <b-tooltip
                    triggers="hover"
                    :target="`stat-tooltip-${row.field.key}-${row.index}`"
                  >
                    <template
                      v-if="
                        row.item.revenue_current_year_until_last_full_month >
                        row.item.revenue_previous_year_until_last_full_month
                      "
                    >
                      Stijging van
                    </template>
                    <template v-else>Daling van</template>
                    {{
                      $options.filters.percentageDiff(
                        row.item.revenue_current_year_until_last_full_month,
                        row.item.revenue_previous_year_until_last_full_month
                      )
                    }}%
                  </b-tooltip>
                </div>
              </div>
            </template>

            <template #cell(country)="row">
              <template v-if="row.item.country === 'nl'">🇳🇱</template>
              <template v-else-if="row.item.country === 'be'">🇧🇪</template>
              <template v-else-if="row.item.country === 'fr'">🇫🇷</template>
              <template v-else-if="row.item.country === 'dk'">🇩🇰</template>
              <template v-else-if="row.item.country === 'gb'">🇬🇧</template>
              <template v-else>-</template>
            </template>
          </b-table>

          <b-pagination-nav
            use-router
            first-number
            last-number
            v-model="customersMeta.current_page"
            :link-gen="linkGen"
            :number-of-pages="customersMeta.last_page"
            @change="getCustomers"
          >
            <template #prev-text>
              <i class="far fa-angle-left"></i>
            </template>
            <template #next-text>
              <i class="far fa-angle-right"></i>
            </template>
            <template #ellipsis-text>
              <i class="fal fa-ellipsis-h"></i>
            </template>
          </b-pagination-nav>
        </b-col>
      </b-row>
    </b-card>

    <context-menu
      :id="$route.name"
      :key="$route.fullPath"
      :display="showContextMenu"
      component="customer"
      ref="menu"
    />
  </div>
</template>

<script>
  import ContextMenu from "@/components/ContextMenu";
  import { mapGetters } from "vuex";

  export default {
    components: {
      ContextMenu,
    },

    data() {
      return {
        filters: {
          country: null,
          terms: null,
          orderBy: null,
          orderDirection: null,
          agent: null,
          type: "dealer",
          limit: 15,
        },

        customerTypes: [
          {
            value: "dealer",
          },
          {
            value: "dealer_2",
          },
          {
            value: "dealer_1",
          },
          {
            value: "dealer_0",
          },
          {
            value: "dealer_star",
          },
          {
            value: "dealer_project",
          },
          {
            value: "dealer_inactive",
          },
          {
            value: "customer",
          },
          {
            value: "gadero",
          },
        ],

        viewMyCustomers: false,

        selectedPresentations: [],
        selectedCustomerTypes: ["dealer"],

        showContextMenu: false,

        fields: [
          {
            key: "country",
            label: "",
            class: "column-fit-content",
            visible: true,
          },
          {
            key: "billing_company",
            label: "Bedrijfsnaam",
            sortable: true,
            visible: true,
            formatter: (value) => {
              return value || "-";
            },
          },
          {
            key: "type",
            label: "Type",
            sortable: true,
            visible: true,
            class: "column-fit-content text-left",
          },
          {
            key: "billing_city",
            label: "Woonplaats",
            sortable: true,
            visible: true,
            formatter: (value) => {
              return value || "-";
            },
          },
          {
            key: "agent.first_name",
            label: "Vertegenwoordiger",
            sortable: true,
            visible: true,
            formatter: (value) => {
              return value || "-";
            },
          },
          {
            key: "revenue_last_calendar_year",
            label: "Omzet vorig jaar",
            class: "column-stat",
            sortable: true,
            visible: true,
          },
          {
            key: "revenue_current_year_until_last_full_month",
            label: "Omzet jan. - [month]",
            class: "column-stat",
            sortable: true,
            visible: true,
          },
          {
            key: "revenue_current_year_until_now",
            label: "Omzet jan. - nu",
            class: "column-stat",
            sortable: true,
            visible: true,
          },
          {
            key: "revenue",
            label: "Omzet (30 dagen)",
            class: "column-stat",
            sortable: true,
            visible: true,
          },
          {
            key: "revenue_year",
            label: "Omzet (jaar)",
            class: "column-stat",
            sortable: true,
            visible: true,
          },
        ],

        customers: [],
        customersMeta: {},
        presentations: [],
        agents: [],
        selectDisplays: false,
        selectFields: false,
        selectCustomerTypes: false,

        loading: {
          customers: false,
        },
      };
    },

    computed: {
      ...mapGetters({
        auth: "auth/user",
      }),

      visibleFields: {
        get: function () {
          return this.fields.filter((field) => field.visible);
        },
        set: function (value) {
          this.fields = value;
        },
      },
    },

    watch: {
      viewMyCustomers: {
        handler() {
          localStorage.setItem("viewMyCustomers", this.viewMyCustomers);
        },
      },

      visibleFields: {
        handler() {
          localStorage.setItem("customerFields", JSON.stringify(this.fields));
        },
      },
    },

    created() {
      if (
        localStorage.getItem("viewMyCustomers") &&
        localStorage.getItem("viewMyCustomers") === "true"
      ) {
        this.viewMyCustomers = true;
      } else {
        this.viewMyCustomers = false;
      }

      if (localStorage.getItem("customerFields").length !== 0) {
        this.visibleFields = JSON.parse(localStorage.getItem("customerFields"));
      }
    },

    mounted() {
      this.filters = this.$root.setFilters(this.filters);
      this.getDisplays();
      this.getAgents();
      this.getCustomers();
    },

    methods: {
      sort(ctx) {
        switch (ctx.sortBy) {
          case "formatted_name":
            this.filters.orderBy = "first_name";
            break;
          case "revenue":
            this.filters.orderBy = "order_stats_sum_subtotal";
            break;
          case "revenue_year":
            this.filters.orderBy = "order_stats_sum_subtotal_year";
            break;
          default:
            this.filters.orderBy = ctx.sortBy;
        }

        this.filters.orderDirection = ctx.sortDesc ? "desc" : "asc";
        this.filter();
      },

      linkGen(pageNum) {
        return pageNum === 1 ? "?" : `?page=${pageNum}`;
      },

      filter() {
        this.$root.applyFilters(this.filters);
        this.getCustomers();
      },

      getCustomers(page = null) {
        this.loading.customers = true;

        let params = {
          ...this.filters,
          presentations: this.selectedPresentations,
          types: this.selectedCustomerTypes,
          show_my_customers: this.viewMyCustomers,
          page: page
            ? this.$route.query.page
              ? this.$route.query.page
              : null
            : null,
        };

        this.$http
          .get("customers", { params: params })
          .then((response) => {
            this.customers = response.data.data;
            this.customersMeta = response.data.meta;
          })
          .then(() => {
            this.loading.customers = false;
          });
      },

      getDisplays() {
        this.$http.get("displays", { params: this.filters }).then((response) => {
          this.presentations = response.data.data;
        });
      },

      getAgents() {
        let params = {
          agents: true,
        };

        this.$http.get("users", { params: params }).then((response) => {
          this.agents = response.data.data;
        });
      },

      toggleSelectDisplays() {
        this.selectDisplays = !this.selectDisplays;
      },

      toggleSelectCustomerTypes() {
        this.selectCustomerTypes = !this.selectCustomerTypes;
      },

      syncWoocommerce() {
        this.$http.get("webhook/woocommerce/customers").then(() => {
          this.$root.notify("Nieuwe klanten zijn succesvol toegevoegd.");
        });
      },

      rowContextMenuHandler(item, _, event) {
        this.$refs.menu.open(event, item);
        event.preventDefault();
      },
    },
  };
</script>