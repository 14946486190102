<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <back-button />
      </b-col>
      <b-col md="16">
        <search-select
          searchable
          @input="searchCustomer"
          searchType="customers"
          labelTitle="Zoek een klant"
          labelSearchPlaceholder="Zoek een klant"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="36">
        <b-card no-body>
          <b-card-body>
            <h2>
              {{ customer.billing_formatted_name }}
              <span v-if="customer.billing_company">
                {{ customer.billing_company }}
              </span>
            </h2>
          </b-card-body>

          <b-tabs pills card vertical lazy>
            <template v-for="tab in tabs">
              <b-tab
                :key="tab.slug"
                :active="$route.params.tab === tab.slug"
                v-if="tabShouldBeVisible(tab)"
                @click="updateUrl(tab)"
              >
                <template #title>
                  {{ tab.title }}
                  <b-badge
                    class="ml-1"
                    variant="primary"
                    pill
                    v-if="tab.total !== null"
                  >
                    {{ tab.total }}
                  </b-badge>
                </template>
                <component
                  :is="tab.component"
                  v-bind="getProps(tab.slug)"
                  @staffmember-created="addStaffmember($event)"
                  @staffmember-deleted="removeStaffmember($event)"
                />
              </b-tab>
            </template>
          </b-tabs>
        </b-card>

        <Stats />
      </b-col>

      <b-col md="12">
        <b-card>
          <b-button variant="primary" block @click="checkUpdateCustomer">
            Klant opslaan
          </b-button>
        </b-card>

        <b-card no-body class="mt-4 card-scroll-collapse">
          <b-card-body>
            <h4 class="card-title">
              Presentaties
              <b-button
                size="sm"
                variant="primary"
                v-b-toggle="'displays'"
                class="float-right"
              >
                <span class="when-open"><i class="far fa-minus mr-0" /></span>
                <span class="when-closed"><i class="far fa-plus mr-0" /></span>
              </b-button>
            </h4>
          </b-card-body>
          <b-collapse :id="'displays'" :visible="false">
            <b-table
              hover
              show-empty
              :items="displays"
              :fields="fields.displays"
              :busy="loading.displays"
              class="mb-0 table-font-small table-semi-narrow"
              thead-class="d-none"
            >
              <template #table-busy>
                <div class="text-center">
                  <b-spinner variant="secondary" class="align-middle mr-3" />
                  <strong class="align-middle">
                    Presentatie middelen worden geladen
                  </strong>
                </div>
              </template>

              <template #empty="">
                <span class="text-center d-block"> Er ging iets fout! </span>
              </template>

              <template #cell(actions)="row">
                <b-form-checkbox
                  v-model="row.item.checked"
                  @change="updateDisplay(row.item)"
                />
              </template>
            </b-table>
          </b-collapse>
        </b-card>

        <b-card no-body class="mt-4">
          <b-card-body>
            <h4 class="card-title">
              Tijdlijn
              <b-button
                size="sm"
                variant="primary"
                v-b-toggle="'customerComments'"
                class="float-right"
              >
                <span class="when-open"><i class="far fa-minus mr-0" /></span>
                <span class="when-closed"><i class="far fa-plus mr-0" /></span>
              </b-button>
              <b-button
                size="sm"
                variant="transparant"
                class="float-right mr-2"
                v-b-modal.comment-modal
              >
                <i class="far fa-pencil mr-0" />
              </b-button>
              <b-button
                id="filter-button"
                size="sm"
                variant="transparant"
                class="float-right mr-2"
                :class="{
                  active:
                    filters.showUpdateComments ||
                    filters.showComments ||
                    filters.showAgentComments,
                }"
              >
                <i class="far fa-filter mr-0" />
              </b-button>

              <b-popover
                target="filter-button"
                triggers="click"
                placement="bottom"
              >
                <b-form-checkbox
                  class="no-wrap"
                  v-model="filters.showUpdateComments"
                  switch
                  :disabled="loading.customerComments"
                >
                  Updates
                </b-form-checkbox>
                <hr class="my-1" />
                <b-form-checkbox
                  class="no-wrap"
                  v-model="filters.showComments"
                  switch
                  :disabled="loading.customerComments"
                >
                  Opmerkingen
                </b-form-checkbox>
                <hr class="my-1" />
                <b-form-checkbox
                  class="no-wrap"
                  v-model="filters.showAgentComments"
                  switch
                  :disabled="loading.customerComments"
                >
                  Vertegenwoordiger
                </b-form-checkbox>
              </b-popover>
            </h4>
          </b-card-body>
          <b-collapse :id="'customerComments'" :visible="true">
            <b-card-body class="pt-0">
              <template v-if="customerComments.length">
                <div class="timeline timeline--has-pagination mt-3">
                  <div
                    :class="`timeline__item timeline__item--${line.type}`"
                    :style="`--item-background-color: ${line.author.color}`"
                    v-for="(line, key) in customerComments"
                    v-bind:key="key"
                  >
                    <div>
                      <div class="timeline__date-wrapper">
                        <span class="timeline__date">
                          <template
                            v-if="
                              line.type === 'agent-comment' ||
                              line.type === 'customer-comment'
                            "
                          >
                            <span class="timeline__color" />
                            <template v-if="line.type === 'agent-comment'"
                              >⭐️</template
                            ><b>{{ line.author.first_name }}</b>
                            {{ new Date(line.created_at) | date }}

                            <span
                              v-if="auth.role.id === $root.Role.ADMIN"
                              class="timeline__delete"
                              @click="deleteTimelineMessage(line)"
                            >
                              <i class="far fa-trash-alt" />
                            </span>
                          </template>
                          <template v-else-if="line.type === 'service-request'">
                            <b>Service melding</b>
                            {{ new Date(line.created_at) | date }}
                          </template>
                          <template v-else>
                            <b>Update</b>
                            {{ new Date(line.created_at) | date }}
                          </template>
                        </span>
                      </div>

                      <div class="timeline__content">
                        <div
                          class="timeline__message"
                          v-html="$options.filters.urlify(line.comment)"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-center mt-3"
                    :class="{
                      'justify-content-between':
                        customerCommentsMeta.current_page !==
                        customerCommentsMeta.last_page,
                      'justify-content-end':
                        customerCommentsMeta.current_page ===
                        customerCommentsMeta.last_page,
                    }"
                  >
                    <button-spinner
                      :loading="loading.customerComments"
                      variant="light"
                      @click="paginate"
                      v-if="
                        customerCommentsMeta.current_page !==
                        customerCommentsMeta.last_page
                      "
                    >
                      <i class="far fa-rotate mr-2" />Toon meer
                    </button-spinner>

                    <span class="timeline__meta">
                      toont: {{ customerComments.length }} /
                      {{ customerCommentsMeta.total }}
                    </span>
                  </div>
                </div>
              </template>
              <template v-else> Geen berichten gevonden </template>
            </b-card-body>
          </b-collapse>
        </b-card>
      </b-col>
    </b-row>

    <b-modal id="comment-modal" title="Nieuwe opmerking" hide-footer centered>
      <b-form-textarea
        placeholder="Opmerking.."
        rows="3"
        v-model="new_customer_comment"
      />

      <template
        v-if="
          auth.role.id === $root.Role.ADMIN || auth.role.id === $root.Role.AGENT
        "
      >
        <b-form-checkbox
          class="no-wrap mt-2 mb-4"
          v-model="is_agent_comment"
          switch
        >
          Interne opmerking (vertegenwoordiger)
        </b-form-checkbox>
      </template>

      <b-button
        variant="primary"
        class="mt-2"
        block
        @click="storeCustomerComment()"
      >
        Opmerking plaatsen
      </b-button>
    </b-modal>
  </div>
</template>

<script>
  import BackButton from "@/components/BackButton";
  import SearchSelect from "@/components/SearchSelect";

  import OrderTable from "./Partials/_OrderTable";
  import CreditNotesTable from "./Partials/_CreditNotesTable";
  import SentInvoicesTable from "./Partials/_SentInvoicesTable";

  import Information from "./Partials/Tabs/_Information";
  import Agent from "./Partials/Tabs/_Agent";
  import OpenOrders from "./Partials/Tabs/_OpenOrders";
  import FinishedOrders from "./Partials/Tabs/_FinishedOrders";
  import SentInvoices from "./Partials/Tabs/_SentInvoices";
  import Credits from "./Partials/Tabs/_Credits";
  import Exact from "./Partials/Tabs/_Exact";
  import Staff from "./Partials/Tabs/_Staff";
  import ServiceRequests from "./Partials/Tabs/_ServiceRequests";

  import Stats from "./Partials/Stats/_Stats";

  import { mapGetters } from "vuex";

  export default {
    components: {
      Agent,
      BackButton,
      CreditNotesTable,
      Credits,
      Exact,
      FinishedOrders,
      Information,
      OpenOrders,
      OrderTable,
      SearchSelect,
      SentInvoices,
      SentInvoicesTable,
      ServiceRequests,
      Stats,
    },
    data() {
      return {
        tabs: [
          {
            title: "Algemene informatie",
            slug: "algemene-informatie",
            component: Information,
            admin: false,
            agent: false,
            total: null,
          },
          {
            title: "Personeel / Socials",
            slug: "personeel",
            component: Staff,
            admin: false,
            agent: false,
            total: null,
          },
          {
            title: "Vertegenwoordiger",
            slug: "vertegenwoordiger",
            component: Agent,
            admin: true,
            agent: true,
            total: null,
          },
          {
            title: "Service meldingen",
            slug: "service-meldingen",
            component: ServiceRequests,
            admin: false,
            agent: false,
            total: 0,
          },
          {
            title: "Openstaande orders",
            slug: "openstaande-orders",
            component: OpenOrders,
            admin: false,
            agent: false,
            total: 0,
          },
          {
            title: "Afgeronde orders",
            slug: "afgeronde-orders",
            component: FinishedOrders,
            admin: false,
            agent: false,
            total: 0,
          },
          {
            title: "Verzonden facturen",
            slug: "verzonden-facturen",
            component: SentInvoices,
            admin: false,
            agent: false,
            total: 0,
          },
          {
            title: "Creditfacturen",
            slug: "creditfacturen",
            component: Credits,
            admin: false,
            agent: false,
            total: 0,
          },
          {
            title: "Exact",
            slug: "exact",
            component: Exact,
            admin: true,
            agent: true,
            total: null,
          },
        ],

        editor: null,
        customer: {},
        displays: [],

        customerComments: [],
        customerCommentsMeta: {},

        errors: {},

        fields: {
          displays: [
            {
              key: "name",
              label: "Naam",
            },
            { key: "actions", class: "column-fit-content" },
          ],
        },

        loading: {
          customer: false,
          displays: false,
          customerComments: false,
        },

        page: {
          customerComments: 1,
        },

        new_customer_comment: null,
        is_agent_comment: false,

        og_code: null,
        editor_content: {},

        filters: {
          showComments: true,
          showUpdateComments: false,
          showAgentComments: true,
        },
      };
    },

    watch: {
      "filters.showUpdateComments"() {
        this.getCustomerComments(true);
      },
      "filters.showComments"() {
        this.getCustomerComments(true);
      },
      "filters.showAgentComments"() {
        this.getCustomerComments(true);
      },
    },

    computed: {
      ...mapGetters({
        auth: "auth/user",
      }),
      agentProps() {
        return { customer: this.customer };
      },
      staffProps() {
        return {
          customer: this.customer,
          staff: this.customer.staff,
        };
      },
      serviceRequestProps() {
        return { customer: this.customer };
      },
      creditProps() {
        return { customer: this.customer };
      },
      exactProps() {
        return { customer: this.customer, errors: this.errors };
      },
      finishedOrderProps() {
        return { customer: this.customer };
      },
      informationOrderProps() {
        return { customer: this.customer, errors: this.errors };
      },
      openOrderProps() {
        return { customer: this.customer };
      },
      sentInvoicesProps() {
        return { customer: this.customer, errors: this.errors };
      },
    },

    methods: {
      getProps(tab) {
        switch (tab) {
          case "vertegenwoordiger":
            return this.agentProps;
          case "personeel":
            return this.staffProps;
          case "service-meldingen":
            return this.staffProps;
          case "creditfacturen":
            return this.creditProps;
          case "exact":
            return this.exactProps;
          case "afgeronde-orders":
            return this.finishedOrderProps;
          case "algemene-informatie":
            return this.informationOrderProps;
          case "openstaande-orders":
            return this.openOrderProps;
          case "verzonden-facturen":
            return this.sentInvoicesProps;
        }
      },

      updateTotal(slug, value) {
        this.tabs.filter((tab) => {
          if (tab.slug === slug && value) {
            tab.total = value;
          }
        });
      },

      addStaffmember(e) {
        this.customer.staff.push(e);
      },

      removeStaffmember(e) {
        this.customer.staff = this.customer.staff.filter((staffmember) => {
          return staffmember.id !== e;
        });
      },

      getCustomer() {
        this.loading.customer = true;

        this.$http
          .get(`customers/${this.$route.params.id}`)
          .then(({ data }) => {
            this.customer = data.data;

            this.og_code = this.customer.exact_customer_code;

            this.updateTotal("openstaande-orders", data.data.open_orders_count);
            this.updateTotal("afgeronde-orders", data.data.finished_orders_count);
            this.updateTotal("verzonden-facturen", data.data.sent_invoices_count);
            this.updateTotal("creditfacturen", data.data.credit_notes_count);
            this.updateTotal(
              "service-meldingen",
              data.data.service_requests_count
            );
          })
          .then(() => {
            this.loading.customer = false;
          });
      },

      checkUpdateCustomer() {
        if (this.customer.exact_customer_code !== this.og_code) {
          this.$root.showConfirm(
            `De Exact Code van deze klant is aangepast van ${this.og_code} naar ${this.customer.exact_customer_code}, weet je zeker dat je deze wilt aanpassen?`,
            () => {
              this.updateCustomer();
            }
          );
        } else {
          this.updateCustomer();
        }
      },

      updateCustomer() {
        this.errors = {};

        let data = {
          ...this.customer,
        };

        if (
          this.auth.role.id === this.$root.Role.WORKER ||
          (this.auth.id !== this.customer.agent_id &&
            this.auth.role.id !== this.$root.Role.ADMIN)
        ) {
          delete data.agent_comment;
        }

        this.$http
          .put(`customers/${this.$route.params.id}`, data)
          .then((response) => {
            this.$root.notify("De gebruiker is succesvol bewerkt.");

            this.og_code = response.data.data.exact_customer_code;
          })
          .catch((errors) => {
            this.errors = this.$options.filters.setErrors(errors.data.errors);
          });
      },

      getDisplays() {
        this.loading.displays = true;
        this.$http
          .get(`displays/${this.$route.params.id}`)
          .then((response) => {
            this.displays = response.data.data;
          })
          .then(() => {
            this.loading.displays = false;
          });
      },

      updateDisplay(item) {
        this.$root.showConfirm(
          item.checked === false
            ? "Weet je zeker dat dit niet bij de klant staat?"
            : "Weet je zeker dat dit bij de klant staat?",
          () => {
            let data = {
              customer_id: this.$route.params.id,
              item: item,
            };

            this.$http
              .post(`displays`, data)
              .then(() => {
                this.$root.notify("De gebruiker is succesvol bewerkt.");
              })
              .catch((errors) => {
                this.errors = this.$options.filters.setErrors(errors.data.errors);
              });
          },
          () => {
            item.checked = item.checked === false ? true : false;
          }
        );
      },

      storeCustomerComment() {
        let data = {
          comment: this.new_customer_comment,
          customer_id: this.$route.params.id,
          type: this.is_agent_comment ? "agent-comment" : "customer-comment",
        };

        this.$http
          .post("customer-comments", data)
          .then((response) => {
            this.$root.notify("Opmerking geplaatst");
            this.customerComments.unshift(response.data.data);
          })
          .then(() => {
            this.new_customer_comment = null;
          });
      },

      deleteTimelineMessage(message) {
        this.$http.delete(`customer-comments/${message.id}`).then(() => {
          this.$root.notify("Opmerking is verwijderd");
          this.getCustomerComments();
        });
      },

      getCustomerComments(reset = false) {
        this.loading.customerComments = true;

        let params = {
          customer: this.$route.params.id,
          showUpdates: this.filters.showUpdateComments,
          showComments: this.filters.showComments,
          showAgentComments: this.filters.showAgentComments,
        };

        this.$http
          .get("customer-comments", { params: params })
          .then(({ data }) => {
            if (reset) {
              this.customerComments = data.data;
            } else {
              this.customerComments = this.customerComments.concat(data.data);
            }

            this.customerCommentsMeta = data.meta;
          })
          .then(() => {
            this.loading.customerComments = false;
          });
      },

      searchCustomer(record) {
        this.$router.push({
          name: "customer.show",
          params: { id: record.id },
        });
      },

      updateUrl(tab) {
        this.$router.push({
          name: "customer.show",
          params: {
            id: this.customer.id,
            tab: tab.slug,
          },
        });
      },

      tabShouldBeVisible(tab) {
        if (tab.admin || tab.agent) {
          if (this.auth.role.id === this.$root.Role.ADMIN) {
            return true;
          }
        }

        if (tab.agent) {
          if (this.auth.role.id === this.$root.Role.AGENT) {
            return true;
          }
        }

        if (!tab.admin && !tab.agent) {
          return true;
        }

        return false;
      },

      paginate() {
        this.page.customerComments++;
        this.getCustomerComments();
      },

      initComponent() {
        this.getCustomer();
        this.getCustomerComments(true);
        this.getDisplays();
      },
    },

    beforeRouteUpdate(to, from, next) {
      next();

      if (from.params.id != to.params.id) {
        this.initComponent();
      }
    },

    mounted() {
      this.initComponent();
    },
  };
</script>