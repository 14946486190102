<template>
  <div>
    <b-button
      variant="primary"
      class="mb-3"
      :to="{
        name: 'credit.create',
        query: {
          customer: customer.id,
        },
      }"
    >
      <i class="fas fa-plus" /> Nieuwe credit voor
      {{ customer.billing_formatted_name }}
    </b-button>
    <b-table
      striped
      bordered
      hover
      responsive
      show-empty
      :items="data"
      :fields="fields"
      :busy="loading"
      @row-clicked="$root.rowClickRedirect($event, 'credit.show')"
    >
      <template #table-busy>
        <div class="text-center">
          <b-spinner variant="secondary" class="align-middle mr-3" />
          <strong class="align-middle">Creditfacturen worden geladen</strong>
        </div>
      </template>

      <template #empty="">
        <span class="text-center d-block">
          Er zijn geen creditfacturen gevonden
        </span>
      </template>

      <template #cell(number)="row">
        <template v-if="row.item.number">
          {{ row.item.number }}
        </template>
        <template v-else>
          <b-badge>Beschikbaar na versturen</b-badge>
        </template>
      </template>

      <template #cell(accepted_by)="row">
        <template v-if="row.item.accepted_by">
          {{ row.item.accepted_by.formatted_name }}
        </template>
        <template v-else>
          <b-badge>Beschikbaar na versturen</b-badge>
        </template>
      </template>

      <template #cell(date)="row">
        <template v-if="row.item.date">
          {{ new Date(row.item.date) | date }}
        </template>
        <template v-else> - </template>
      </template>

      <template #cell(status)="row">
        {{ $root.CreditStatusString[row.item.status] }}
      </template>

      <template #cell(actions)="row">
        <b-button
          v-if="row.item.status === $root.CreditStatus.SENT"
          @click="downloadCredit(row.item)"
          variant="primary"
          size="sm"
          v-b-tooltip.hover.left="`Creditfactuur ${row.item.number} downloaden`"
        >
          <i class="far fa-cloud-download mr-0" />
        </b-button>
      </template>
    </b-table>

    <b-pagination-nav
      use-router
      first-number
      last-number
      v-model="meta.current_page"
      :link-gen="linkGen"
      :number-of-pages="meta.last_page"
      @change="updateCreditNotes()"
    >
      <template #prev-text>
        <i class="far fa-angle-left"></i>
      </template>
      <template #next-text>
        <i class="far fa-angle-right"></i>
      </template>
      <template #ellipsis-text>
        <i class="fal fa-ellipsis-h"></i>
      </template>
    </b-pagination-nav>
  </div>
</template>

<script>
  export default {
    props: {
      meta: {
        type: Object,
        default: () => {
          return {};
        },
      },

      data: {
        type: Array,
        default: () => {
          return [];
        },
      },

      loading: {
        type: Boolean,
        default: false,
      },

      customer: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },

    methods: {
      updateCreditNotes() {
        this.$emit("updateCreditNotesTable");
      },

      linkGen(pageNum) {
        return pageNum === 1 ? "?" : `?credit_notes_page=${pageNum}`;
      },

      downloadCredit(item) {
        this.$http
          .get(`credit-notes/${item.id}/download`, {
            responseType: "arraybuffer",
          })
          .then((response) => {
            let blob = new Blob([response.data], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `Creditfactuur ${item.number} - Floer BV`;
            link.click();
          });
      },
    },

    data() {
      return {
        fields: [
          { key: "number", label: "Credit nummer", sortable: true },
          {
            key: "accepted_by",
            label: "Verstuurd door",
          },
          { key: "date", label: "Verstuurd op", sortable: true },
          { key: "status", label: "Status", sortable: true },
          { key: "actions", label: "", class: "column-fit-content" },
        ],
      };
    },
  };
</script>