<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <back-button />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <b-card header="Nieuwe locatie">
          <b-form @submit.prevent="update">
            <Input
              :label="input.label"
              :placeholder="input.placeholder"
              :type="input.type"
              v-model="location[key]"
              :id="`location-${index}`"
              v-for="(input, key, index) in inputs"
              :key="key"
            >
            </Input>
            <b-button type="submit">Opslaan</b-button>
            <b-button @click="destroy" variant="primary" class="float-right">
              Verwijderen
            </b-button>
          </b-form>
        </b-card>
      </b-col>

      <b-col>
        <b-card header="Producten">
          <b-table
            striped
            hover
            show-empty
            small
            :items="location.products"
            :fields="fields"
            @row-clicked="$root.rowClickRedirect($event, 'product.show')"
          >
            <template #empty="">
              <span class="text-center d-block">
                Er zijn geen producten gevonden
              </span>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  import BackButton from "@/components/BackButton";

  export default {
    components: { BackButton },

    data() {
      return {
        location: {},
        fields: [
          { key: "sku", label: "SKU" },
          { key: "name", label: "Naam" },
        ],
        products: [],
        inputs: {
          name: {
            label: "Naam",
            type: "text",
          },
        },
      };
    },

    mounted() {
      this.get();
    },

    methods: {
      get() {
        this.$http.get(`locations/${this.$route.params.id}`).then((response) => {
          this.location = response.data.data;
        });
      },

      update() {
        this.$http
          .put(`locations/${this.$route.params.id}`, this.location)
          .then((response) => {
            this.location = response.data.data;

            this.$root.notify("De locatie is succesvol bijgewerkt.");
          });
      },

      destroy() {
        this.$root.showConfirm(
          "Weet je zeker dat je deze locatie wil verwijderen?",
          () => {
            this.$http
              .delete(`locations/${this.$route.params.id}`)
              .then(() => {
                this.$router.push({ name: "location.index" });
                this.$root.notify("De locatie is succesvol verwijderd.");
              })
              .catch((errors) => {
                this.$root.notify(errors.data.errors, "danger");
              });
          }
        );
      },
    },
  };
</script>