<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <back-button />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card header="Nieuwe leverancier">
          <b-form @submit.prevent="store">
            <Input
              :label="inputs.label"
              :placeholder="input.placeholder"
              :type="input.type"
              v-model="supplier[key]"
              :id="`supplier-${index}`"
              v-for="(input, key, index) in inputs"
              :key="key"
            >
            </Input>
            <b-button type="submit">Aanmaken</b-button>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  import BackButton from "@/components/BackButton";

  export default {
    components: { BackButton },

    data() {
      return {
        supplier: {},
        inputs: {
          name: {
            label: "Naam",
            type: "text",
          },
        },
      };
    },

    methods: {
      store() {
        this.$http
          .post(`suppliers`, this.supplier)
          .then(() => {
            this.$root.notify("De nieuwe leverancier is succesvol aangemaakt.");

            this.$router.push({
              name: "supplier.index",
            });
          })
          .catch((errors) => {
            this.errors = this.$options.filters.setErrors(errors.data.errors);
            this.$root.notify(errors.data.errors, "danger");
          });
      },
    },
  };
</script>