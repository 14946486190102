<template>
  <div>
    <h2>Socials</h2>
    <b-row>
      <b-col md="24">
        <Input
          label="Facebook"
          type="text"
          :error="errors.facebook_url"
          v-model="customer.facebook_url"
          id="customer-facebook_url"
        />
      </b-col>
      <b-col md="24">
        <Input
          label="Twitter"
          type="text"
          :error="errors.twitter_url"
          v-model="customer.twitter_url"
          id="customer-twitter_url"
        />
      </b-col>
      <b-col md="24">
        <Input
          label="Instagram"
          type="text"
          :error="errors.instagram_url"
          v-model="customer.instagram_url"
          id="customer-instagram_url"
        />
      </b-col>
      <b-col md="24">
        <Input
          label="Pinterest"
          type="text"
          :error="errors.pinterest_url"
          v-model="customer.pinterest_url"
          id="customer-pinterest_url"
        />
      </b-col>
    </b-row>

    <hr class="my-3" />

    <b-row>
      <b-col>
        <h2>Personeel</h2>
      </b-col>
      <b-col class="text-right">
        <b-button
          variant="primary"
          @click="$bvModal.show('staff-modal-create')"
        >
          <i class="far fa-plus mr-0" />
        </b-button>
      </b-col>
    </b-row>
    <div class="staffmember-list">
      <b-row>
        <b-col
          v-for="(group, index) of $options.filters.chunk(
            staff,
            staff.length / 2
          )"
          :key="index"
          md="24"
        >
          <StaffCard
            :class="{ 'mt-4': index }"
            v-for="(staffmember, index) of group"
            :key="index"
            :staffmember="staffmember"
          />
        </b-col>
      </b-row>
    </div>

    <b-modal id="staff-modal-create" hide-header hide-footer centered>
      <b-row>
        <b-col>
          <h2>Personeel toevoegen</h2>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="48">
          <Input
            v-model="newStaffMember.name"
            :error="errors['name']"
            label="Naam"
          />
        </b-col>

        <b-col md="48">
          <b-form-group
            label="Afbeelding"
            description="JPEG, PNG en GIF toegestaan"
          >
            <b-form-file
              v-model="newStaffMember.avatar"
              accept="image/jpeg, image/png, image/gif"
              browse-text="Bladeren"
              placeholder="Kies een afbeelding"
              drop-placeholder="Kies een afbeelding"
            />
          </b-form-group>
        </b-col>

        <b-col md="48">
          <Input
            v-model="newStaffMember.function"
            :error="errors['function']"
            label="Functie"
          />
        </b-col>

        <b-col md="48">
          <Input
            v-model="newStaffMember.phone"
            :error="errors['phone']"
            type="tel"
            label="Telefoonnummer"
          />
        </b-col>

        <b-col md="48">
          <Input
            v-model="newStaffMember.email"
            :error="errors['email']"
            type="email"
            label="E-mail"
          />
        </b-col>

        <b-col md="48">
          <b-form-group label="Verjaardag">
            <vc-date-picker
              is-expanded
              title-position="left"
              show-iso-weeknumbers
              :attributes="calAttrs"
              :model-config="modelConfig"
              mode="date"
              locale="nl"
              trim-weeks
              v-model="newStaffMember.birthday"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text>
                      <i class="fad fa-calendar" />
                    </b-input-group-text>
                  </template>
                  <b-input :value="inputValue" v-on="inputEvents" />
                </b-input-group>
              </template>
            </vc-date-picker>
          </b-form-group>
        </b-col>

        <b-col md="48">
          <b-form-group label="Interresses">
            <b-textarea v-model="newStaffMember.interests" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="text-right">
          <b-button @click="$bvModal.hide('staff-modal-create')" class="mr-2">
            Annuleren
          </b-button>
          <b-button variant="primary" @click="createStaffMember()">
            Opslaan
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
  import StaffCard from "@/components/StaffCard";

  import _ from "lodash";

  export default {
    components: {
      StaffCard,
    },

    data() {
      return {
        newStaffMember: {},

        calAttrs: [
          {
            key: "today",
            highlight: {
              color: "blue",
              fillMode: "light",
            },
            dates: new Date(),
          },
        ],

        errors: [],

        modelConfig: {
          type: "string",
          mask: "YYYY-MM-DD", // Uses 'iso' if missing
        },
      };
    },

    props: {
      customer: {
        type: Object,
        default: () => {
          return {};
        },
      },

      staff: {
        type: Array,
        default: () => {
          return [];
        },
      },
    },

    methods: {
      createStaffMember() {
        this.errors = this.$options.filters.clearErrors(this.errors);

        const config = {
          headers: { "Content-Type": "multipart/form-data" },
        };

        let data = new FormData();

        data.append("_method", "POST");
        data.append("customer_id", this.customer.id);

        _.each(this.newStaffMember, (value, key) => {
          data.append(key, value);
        });

        this.$http
          .post(`customer-staff`, data, config)
          .then(({ data }) => {
            this.newStaffMember = {};
            this.$root.notify("Personeel succesvol toegevoegd");
            this.$emit("staffmember-created", data.data);
          })
          .catch((errors) => {
            this.errors = this.$options.filters.setErrors(errors);
          });
      },
    },
  };
</script>
