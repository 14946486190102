<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <back-button />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card class="card-overflow-visible" header="Inkooporder">
          <b-alert
            :show="purchaseOrder.status === OrderStatus.CANCELLED"
            variant="danger"
          >
            <i class="fas fa-exclamation-triangle text-danger mr-2"></i>
            Deze inkooporder is geannuleerd
          </b-alert>

          <b-alert
            :show="purchaseOrder.status === OrderStatus.RECEIVED"
            variant="success"
          >
            <i class="fas fa-check text-success mr-2"></i>
            Deze inkooporder is ontvangen
          </b-alert>

          <b-row>
            <b-col md="12">
              <b-form-group label="Verwachte leverdatum">
                <template
                  v-if="
                    purchaseOrder.status === OrderStatus.INCOMING ||
                    purchaseOrder.status === OrderStatus.PENDING
                  "
                  v-slot:description
                >
                  Laat dit veld leeg om een inkooporder zonder leverdatum aan te
                  maken
                </template>

                <b-form-input
                  type="date"
                  v-if="
                    purchaseOrder.status === OrderStatus.INCOMING ||
                    purchaseOrder.status === OrderStatus.PENDING
                  "
                  v-model="purchaseOrder.expected_delivery_date"
                />

                <span class="cant-touch-this" v-else>
                  {{
                    new Date(purchaseOrder.expected_delivery_date)
                      | date("dd/MM/yyyy")
                  }}
                </span>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <Input
                label="Factuurnummer"
                v-model="purchaseOrder.invoice_number"
              />
            </b-col>

            <b-col md="12">
              <b-form-group label="Factuurdatum">
                <vc-date-picker
                  is-expanded
                  title-position="left"
                  show-iso-weeknumbers
                  :attributes="calAttrs"
                  :model-config="modelConfig"
                  mode="date"
                  locale="nl"
                  trim-weeks
                  v-model="purchaseOrder.invoice_date"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text>
                          <i class="fad fa-calendar" />
                        </b-input-group-text>
                      </template>
                      <b-input :value="inputValue" v-on="inputEvents" />
                    </b-input-group>
                  </template>
                </vc-date-picker>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-table
                striped
                hover
                show-empty
                no-local-sorting
                :items="purchaseOrder.orderlines"
                :fields="fields.purchaseOrder"
                @sort-changed="sort"
                @row-contextmenu="rowContextMenuHandler"
              >
                <template #table-busy>
                  <div class="text-center">
                    <b-spinner variant="secondary" class="align-middle mr-3" />
                    <strong class="align-middle">
                      Producten worden geladen
                    </strong>
                  </div>
                </template>

                <template #empty="">
                  <span class="text-center d-block">
                    Er zijn nog geen producten toegevoegd aan de inkooporder
                  </span>
                </template>

                <template
                  v-if="purchaseOrder.status === OrderStatus.INCOMING"
                  #cell(quantity)="row"
                >
                  <Input type="number" v-model="row.item.quantity" />
                </template>

                <template #cell(purchase_price)="row">
                  <template
                    v-if="purchaseOrder.status === OrderStatus.INCOMING"
                  >
                    <Input type="number" v-model="row.item.purchase_price">
                      <template #description>
                        Pak: &euro;
                        {{ row.item.product.purchase_price | formatNumber(3) }}
                        <span class="d-inline-block mx-2 text-muted">|</span>
                        m<sup class="text-muted">2</sup>: &euro;
                        {{
                          (row.item.product.purchase_price /
                            row.item.product.m2_per_product)
                            | formatNumber(3)
                        }}
                      </template>
                    </Input>
                  </template>
                  <template v-else>
                    <template v-if="row.item.purchase_price">
                      &euro; {{ row.item.purchase_price | formatNumber(3) }}
                    </template>
                    <template v-else> - </template>
                  </template>
                </template>
              </b-table>
            </b-col>
          </b-row>

          <template v-if="purchaseOrder.status === OrderStatus.PENDING">
            <hr />

            <b-row>
              <b-col>
                <b-table
                  striped
                  bordered
                  hover
                  show-empty
                  no-local-sorting
                  :items="products"
                  :fields="fields.products"
                  :busy="loading.products"
                  @sort-changed="sort"
                  @row-contextmenu="rowContextMenuHandler"
                  @row-clicked="
                    $root.rowClickRedirect($event.product, 'product.show')
                  "
                >
                  <template #table-busy>
                    <div class="text-center">
                      <b-spinner
                        variant="secondary"
                        class="align-middle mr-3"
                      />

                      <strong class="align-middle">
                        Producten worden geladen
                      </strong>
                    </div>
                  </template>

                  <template #empty="">
                    <span class="text-center d-block">
                      Er zijn geen producten gevonden
                    </span>
                  </template>

                  <template #cell(physical_stock)="row">
                    <Input type="number" v-model="row.item.physical_stock" />
                  </template>

                  <template #cell(actions)="row">
                    <b-button
                      variant="primary"
                      v-b-tooltip.hover.left="
                        `Dit product toevoegen aan de inkooporder?`
                      "
                      @click="addToPurchaseOrder(row.item)"
                    >
                      <i class="far fa-plus mr-0"></i>
                    </b-button>
                  </template>
                </b-table>

                <b-pagination-nav
                  use-router
                  first-number
                  last-number
                  v-model="productsMeta.current_page"
                  :link-gen="linkGen"
                  :number-of-pages="productsMeta.last_page"
                  @change="getProducts"
                >
                  <template #prev-text>
                    <i class="far fa-angle-left"></i>
                  </template>
                  <template #next-text>
                    <i class="far fa-angle-right"></i>
                  </template>
                  <template #ellipsis-text>
                    <i class="fal fa-ellipsis-h"></i>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
          </template>
        </b-card>
      </b-col>

      <b-col md="12">
        <b-card header="Acties">
          <b-form-group>
            <b-button block @click="updatePurchaseOrder">
              Order bijwerken
            </b-button>
          </b-form-group>

          <b-form-group v-if="purchaseOrder.status === OrderStatus.INCOMING">
            <b-button
              block
              variant="primary"
              @click="updatePurchaseOrderStatus(OrderStatus.RECEIVED)"
            >
              Order ontvangen
            </b-button>
          </b-form-group>

          <b-form-group
            v-if="purchaseOrder.status === OrderStatus.PENDING"
            :description="
              purchaseOrder.orderlines.length === 0
                ? 'Om een inkooporder te kunnen bevestigen moeten er eerst producten toegevoegd worden.'
                : null
            "
          >
            <b-button
              :disabled="purchaseOrder.orderlines.length === 0"
              block
              variant="primary"
              @click="updatePurchaseOrderStatus(OrderStatus.INCOMING)"
            >
              Order bevestigen
            </b-button>
          </b-form-group>

          <template
            v-if="
              purchaseOrder.status === OrderStatus.INCOMING ||
              purchaseOrder.status === OrderStatus.PENDING
            "
          >
            <hr />
            <b-button
              block
              variant="danger"
              @click="updatePurchaseOrderStatus(OrderStatus.CANCELLED)"
            >
              Order annuleren
            </b-button>
          </template>
        </b-card>
      </b-col>
    </b-row>

    <context-menu
      :id="$route.name"
      :key="$route.fullPath"
      :display="showContextMenu"
      component="product"
      ref="menu"
    />
  </div>
</template>

<script>
  import ContextMenu from "@/components/ContextMenu";
  import BackButton from "@/components/BackButton";
  import _ from "lodash";
  import { OrderStatus } from "@/enums/PurchaseOrder/Status";

  export default {
    components: {
      ContextMenu,
      BackButton,
    },

    data() {
      return {
        OrderStatus: OrderStatus,

        filters: {},

        purchaseOrder: {
          expected_delivery_date: null,
          orderlines: [],
        },

        showContextMenu: false,

        fields: {
          products: [
            {
              key: "sku",
              label: "SKU",
              sortable: true,
            },
            {
              key: "description",
              label: "Korte beschrijving",
            },
            {
              key: "physical_stock",
              label: "Aantal",
            },
            {
              key: "actions",
              label: "",
            },
          ],

          purchaseOrder: [
            {
              key: "product.sku",
              label: "SKU",
            },
            {
              key: "product.name",
              label: "Naam",
            },
            {
              key: "quantity",
              label: "Aantal",
            },
            {
              key: "purchase_price",
              label: "Inkoop per pak",
              tdClass: "column-fixed-width-250",
            },
            {
              key: "actions",
              label: "",
            },
          ],
        },

        products: [],
        productsMeta: {},

        loading: {
          products: false,
        },
      };
    },

    mounted() {
      this.getPurchaseOrder();

      this.filters = this.$root.setFilters(this.filters);
      this.getProducts();
    },

    methods: {
      sort(ctx) {
        this.filters.orderBy = ctx.sortBy;
        this.filters.orderDirection = ctx.sortDesc ? "desc" : "asc";

        this.filter();
      },

      linkGen(pageNum) {
        return pageNum === 1 ? "?" : `?page=${pageNum}`;
      },

      filter() {
        this.$root.applyFilters(this.filters);
        this.getProducts();
      },

      getProducts(page = null) {
        this.loading.products = true;

        let params = {
          ...this.filters,
          supplier: this.$route.params.id,
          page: page
            ? this.$route.query.page
              ? this.$route.query.page
              : null
            : null,
        };

        this.$http
          .get("products", {
            params: params,
          })
          .then((response) => {
            this.products = response.data.data;
            this.productsMeta = response.data.meta;
          })
          .then(() => {
            this.loading.products = false;
          });
      },

      getPurchaseOrder() {
        this.loading.purchaseOrder = true;

        this.$http
          .get(`purchase-orders/${this.$route.params.orderId}`)
          .then((response) => {
            this.purchaseOrder = response.data.data;
          });

        this.loading.products = false;
      },

      updatePurchaseOrder() {
        let data = {
          ...this.purchaseOrder,
        };

        this.$http
          .put(`purchase-orders/${this.$route.params.orderId}`, data)
          .then(() => {
            this.$root.notify("Inkooporder succesvol aangepast");
          });
      },

      updatePurchaseOrderStatus(status) {
        let data = {
          ...this.purchaseOrder,
          ...{ status: status },
        };

        this.$http
          .put(`purchase-orders/${this.$route.params.orderId}`, data)
          .then(() => {
            this.purchaseOrder.status = status;
          });
      },

      addToPurchaseOrder(product) {
        let index = _.findIndex(this.purchaseOrder.orderlines, {
          id: product.id,
        });

        if (index >= 0) {
          this.purchaseOrder.orderlines[index].quantity =
            parseInt(this.purchaseOrder.orderlines[index].quantity) +
            parseInt(product.physical_stock);
        } else {
          this.purchaseOrder.orderlines.push({
            id: product.id,
            product: {
              sku: product.sku,
              name: product.name,
            },
            quantity: product.physical_stock,
          });
        }
      },

      rowContextMenuHandler(item, _, event) {
        this.$refs.menu.open(event, item.product);
        event.preventDefault();
      },
    },
  };
</script>