<template>
  <b-modal
    id="transmissionHistoryModal"
    hide-footer
    hide-header
    centered
    size="lg"
  >
    <b-row>
      <b-col>
        <h3>Historie</h3>
        <b-table
          class="mb-0"
          striped
          bordered
          show-empty
          :fields="fields"
          :items="updates"
        >
          <template #empty>
            <div class="text-center">Er is geen data gevonden.</div>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
  export default {
    props: {
      updates: {
        type: Array,
        default: () => {
          return [];
        },
      },
    },

    data() {
      return {
        fields: [
          { key: "status_date", label: "Datum" },
          //   { key: "depot_number", label: "Depot" },
          {
            key: "description",
            label: "Omschrijving",
          },
          //   { key: "type", label: "Type" },
        ],
      };
    },
  };
</script>
