<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <back-button />
      </b-col>
      <b-col>
        <search-select
          searchable
          @input="searchOrder"
          searchType="orders"
          labelTitle="Zoek een order"
          labelSearchPlaceholder="Zoek een order"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="36">
        <b-card>
          <template #header>
            <b-col class="p-0">
              <span class="align-middle"> Order details </span>
              <b-badge
                v-if="order.is_mollie"
                variant="success"
                class="align-middle ml-1 p-2 badge--pulse"
              >
                Betaald via Mollie
              </b-badge>
            </b-col>
            <b-col class="p-0">
              <b-button
                size="sm"
                variant="transparant"
                class="float-right"
                @click="$root.copyData(order)"
              >
                <i class="far fa-copy"></i>Verzendgegevens kopiëren
              </b-button>
            </b-col>
          </template>
          <b-form @submit.prevent="updateOrder()">
            <OrderInfoTable :order.sync="order" :user="auth" />

            <hr />

            <b-row class="mt-4">
              <b-col>
                <b-row>
                  <b-col>
                    <template
                      v-if="order.invoice && order.invoice.status !== 1"
                    >
                      <p>
                        <b>Verzendkosten</b>
                      </p>
                      <p>
                        &euro;
                        {{
                          order.shipping_costs
                            ? order.shipping_costs
                            : 0 | formatNumber(2)
                        }}
                      </p>
                    </template>
                    <template v-else>
                      <Input
                        v-model="order.shipping_costs"
                        label="Verzendkosten"
                        type="number"
                        step="0.01"
                      />
                    </template>
                  </b-col>
                  <b-col>
                    <template
                      v-if="order.invoice && order.invoice.status !== 1"
                    >
                      <p class="mt-3">
                        <b>Extra kosten</b>
                      </p>
                      <p>
                        &euro;
                        {{
                          order.extra_costs
                            ? order.extra_costs
                            : 0 | formatNumber(2)
                        }}
                      </p>
                    </template>
                    <template v-else>
                      <Input
                        v-model="order.extra_costs"
                        label="Extra kosten"
                        type="number"
                        step="0.01"
                      />
                    </template>
                  </b-col>
                </b-row>
                <b-row class="d-flex align-items-end">
                  <template v-if="order.invoice && order.invoice.status !== 1">
                    <b-col>
                      <p class="mt-3">
                        <b>Korting</b>
                      </p>
                      <p>
                        <template
                          v-if="
                            order.discount_type === 'price' ||
                            !order.discount_type
                          "
                        >
                          &euro;
                          {{
                            order.discount
                              ? order.discount
                              : 0 | formatNumber(2)
                          }}
                        </template>
                        <template v-else>
                          {{
                            order.discount
                              ? order.discount
                              : 0 | formatNumber(2)
                          }}
                          %
                        </template>
                      </p>
                    </b-col>
                  </template>
                  <template v-else>
                    <b-col>
                      <b-form-group label="Korting">
                        <b-select v-model="order.discount_type">
                          <b-select-option disabled :value="null">
                            Kies een optie
                          </b-select-option>
                          <b-select-option value="percentage"
                            >Percentage
                          </b-select-option>
                          <b-select-option value="price"
                            >Bedrag
                          </b-select-option>
                        </b-select>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <Input
                        v-model="order.discount"
                        type="number"
                        step="0.01"
                      />
                    </b-col>
                  </template>
                </b-row>
              </b-col>
              <b-col>
                <b-form-group label="Order opmerking">
                  <b-form-textarea
                    id="commentInput"
                    placeholder="Order opmerking toevoegen"
                    rows="3"
                    v-model="order.customer_remark"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="text-right">
                <b-button variant="primary" type="submit">
                  Order opslaan
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

        <b-card class="mt-4">
          <template #header>
            <span>Order regels</span>
            <b-button
              v-if="!order.invoice"
              size="sm"
              variant="transparant"
              class="float-right"
              v-b-modal.add-orderline
            >
              <i class="far fa-stream"></i>Orderregel toevoegen
            </b-button>
          </template>
          <b-table
            striped
            foot-clone
            hover
            responsive
            class="table-nowrap"
            :items="order.orderlines"
            :fields="fields"
            :tbody-tr-class="trClass"
            @row-contextmenu="rowContextMenuHandler"
          >
            <template #cell(notice)="row">
              <template
                v-if="
                  !row.item.product.ignore_stock_warning &&
                  row.item.quantity > row.item.product.physical_stock
                "
              >
                <span
                  v-b-tooltip.hover.right="
                    `Aantal in deze regel is hoger dan de fysieke voorraad`
                  "
                >
                  <i class="fas fa-exclamation-triangle text-danger" />
                </span>
              </template>
            </template>

            <template #cell(quantity)="row">
              <template v-if="order.invoice && order.invoice.status !== 1">
                {{ row.item.quantity }}
              </template>
              <Input v-else type="number" v-model="row.item.quantity" />
            </template>

            <template #cell(price)="row">
              <template v-if="order.invoice && order.invoice.status !== 1">
                &euro; {{ row.item.price | formatNumber(2) }}
              </template>
              <Input
                v-else
                prepend="€"
                type="number"
                v-model="row.item.price"
              />
            </template>

            <template #cell(total)="row">
              &euro;
              {{ (row.item.quantity * row.item.price) | formatNumber(2) }}
              <span
                class="float-left text-muted w-100"
                v-if="row.item.product.purchase_price"
              >
                &euro;
                {{ row.item.product.purchase_price | formatNumber(2) }}
              </span>
            </template>

            <template v-slot:row-details="row">
              <b-card>
                <template v-if="row.item.samples.length > 0">
                  <b-row>
                    <b-col sm="12" class="text-sm-right">
                      <b>Proefproducten:</b>
                    </b-col>
                    <b-col>
                      <ul class="mb-0 list-unstyled">
                        <li
                          class="mb-2"
                          v-for="sample in row.item.samples"
                          :key="sample.id"
                        >
                          <router-link
                            :to="{
                              name: 'product.show',
                              params: {
                                id: sample.id,
                              },
                            }"
                          >
                            {{ sample.name }}
                          </router-link>
                          <div class="block">
                            <b-badge variant="dark">
                              {{ sample.sku }}
                            </b-badge>
                            <b-badge
                              class="ml-1"
                              v-if="sample.supplier_name"
                              variant="dark"
                            >
                              {{ sample.supplier_name }}
                            </b-badge>
                          </div>
                        </li>
                      </ul>
                    </b-col>
                  </b-row>
                </template>

                <template v-else-if="order.channel === OrderChannel.GADERO">
                  <b-row>
                    <b-col>
                      <p class="mb-2">
                        <i>
                          Het bestelde oppervlakte wordt doorgestuurd vanuit
                          Gadero. De oppervlakte per stuk is eigen informatie.
                        </i>
                      </p>
                      <p>
                        <strong>Bestelde oppervlakte:</strong>
                        <template v-if="row.item.ordered_surface">
                          {{ row.item.ordered_surface | formatNumber(3) }}
                          m<sup>2 </sup>
                        </template>
                        <template v-else> Niet bekend</template>
                      </p>
                      <p>
                        <strong>Oppervlakte per stuk:</strong>
                        <template v-if="row.item.product.m2_per_product">
                          {{
                            row.item.product.m2_per_product | formatNumber(3)
                          }}
                          m<sup>2 </sup>
                        </template>
                        <template v-else> Niet bekend</template>
                      </p>
                      <p class="mt-3">
                        <strong>Aantal:</strong>
                        <template
                          v-if="
                            row.item.product.m2_per_product &&
                            row.item.ordered_surface
                          "
                        >
                          {{ row.item.ordered_surface | formatNumber(3) }} /
                          {{
                            row.item.product.m2_per_product | formatNumber(3)
                          }}
                          =
                          {{
                            (row.item.ordered_surface /
                              row.item.product.m2_per_product)
                              | formatNumber(3)
                          }}
                          pakken
                        </template>
                        <template v-else> Niet bekend</template>
                      </p>

                      <p class="mt-3">
                        <strong>Product prijs (Gadero):</strong>
                        &euro;
                        {{ row.item.price_gadero | formatNumber(3, true) }}
                      </p>
                    </b-col>
                  </b-row>
                </template>
              </b-card>
            </template>

            <template #cell(actions)="row">
              <b-button
                @click="row.toggleDetails"
                variant="primary"
                class="mr-2"
                v-if="
                  row.item.samples.length > 0 ||
                  order.channel === OrderChannel.GADERO
                "
                v-b-tooltip.hover.left="`Extra informatie`"
              >
                <span v-show="!row.detailsShowing">
                  <i class="mr-0 fas fa-chevron-up" />
                </span>

                <span v-show="row.detailsShowing">
                  <i class="mr-0 fas fa-chevron-down" />
                </span>
              </b-button>

              <span
                v-if="order.channel === OrderChannel.GADERO"
                :id="`extraMarginButton-${row.item.id}`"
                class="mr-2"
              >
                <b-button
                  variant="primary"
                  @click="addMarginToOrderline(row.item)"
                  :disabled="row.item.has_extra_margin"
                >
                  <i class="far fa-badge-percent mr-0" />
                </b-button>
              </span>

              <b-tooltip
                :target="`extraMarginButton-${row.item.id}`"
                boundary="window"
                placement="right"
              >
                <template v-if="row.item.has_extra_margin">
                  De marge is al verekend op deze orderregel
                </template>
                <template v-else>
                  10% marge verekenen over deze orderregel
                </template>
              </b-tooltip>

              <b-button
                v-if="!order.invoice"
                class="mr-2"
                variant="primary"
                v-b-tooltip.hover.left="`Deze orderregel bijwerken?`"
                @click="updateOrderline(row.item)"
              >
                <i class="far fa-save mr-0" />
              </b-button>

              <b-button
                v-if="!order.invoice"
                variant="outline-primary"
                v-b-tooltip.hover.left="`Deze orderregel verwijderen?`"
                @click="deleteOrderline(row.item)"
              >
                <i class="far fa-trash-alt mr-0" />
              </b-button>
            </template>
          </b-table>

          <b-row class="d-flex">
            <b-col md="16">
              <b-list-group class="lines">
                <b-list-group-item class="d-flex justify-content-between">
                  <b>Inkooptotaal:</b>
                  <span>
                    &euro;
                    {{ order.stats.purchase_total | formatNumber(2) }}
                  </span>
                </b-list-group-item>

                <b-list-group-item
                  class="d-flex justify-content-between gap-2"
                  v-if="!order.invoice"
                >
                  <b>Extra:</b>
                  <Input
                    size="sm"
                    class="mb-0"
                    prepend="€"
                    type="number"
                    :min="0"
                    v-model="order.purchase_costs"
                  />
                  <b-button
                    size="sm"
                    variant="primary"
                    @click="updateOrder('purchase_costs', true)"
                  >
                    <i class="far fa-save mr-0" />
                  </b-button>
                </b-list-group-item>
              </b-list-group>
            </b-col>

            <b-col offset-md="3">
              <b-list-group class="lines">
                <b-list-group-item class="d-flex justify-content-between">
                  <b>Subtotaal ex:</b>
                  <span>
                    &euro;
                    {{ order.price.subtotal | formatNumber(2) }}
                  </span>
                </b-list-group-item>

                <b-list-group-item
                  class="d-flex justify-content-between"
                  v-if="order.price.shippingCosts"
                >
                  <b>Verzendkosten:</b>
                  <span>
                    &euro;
                    {{ order.price.shippingCosts | formatNumber(2) }}
                  </span>
                </b-list-group-item>

                <b-list-group-item
                  class="d-flex justify-content-between"
                  v-if="order.price.extraCosts"
                >
                  <b>Extra kosten:</b>
                  <span>
                    &euro;
                    {{ order.price.extraCosts | formatNumber(2) }}
                  </span>
                </b-list-group-item>

                <b-list-group-item
                  class="d-flex justify-content-between"
                  v-for="(fee_line, index) in order.fee_lines"
                  :key="index"
                >
                  <b>{{ fee_line.description }}:</b>
                  <span>
                    &euro;
                    {{ fee_line.value | formatNumber(2) }}
                  </span>
                </b-list-group-item>

                <b-list-group-item
                  class="d-flex justify-content-between"
                  v-if="order.price.discount"
                >
                  <b>Korting:</b>
                  <span>
                    &euro;
                    {{ order.price.discount | formatNumber(2) }}
                  </span>
                </b-list-group-item>

                <b-list-group-item class="d-flex justify-content-between">
                  <b>BTW:</b>
                  <span>
                    &euro;
                    {{ order.price.tax | formatNumber(2) }}
                  </span>
                </b-list-group-item>

                <b-list-group-item class="d-flex justify-content-between">
                  <b>Totaal incl:</b>
                  <span>
                    &euro;
                    {{ order.price.total | formatNumber(2) }}
                  </span>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card class="mt-4">
          <template #header>
            <div>
              Agenda reminder
              <span
                class="agenda-status"
                :class="{ 'agenda-status--active': order.reminder.show }"
              />
            </div>
          </template>
          <b-row>
            <b-col>
              <vc-date-picker
                is-expanded
                title-position="left"
                show-iso-weeknumbers
                :min-date="new Date()"
                :attributes="calAttrs"
                mode="date"
                :disabled-dates="{ weekdays: [1, 7] }"
                locale="nl"
                trim-weeks
                v-model="order.reminder.date"
              />
            </b-col>
            <b-col>
              <b-input
                v-model="order.reminder.title"
                placeholder="Nieuwe reminder toevoegen"
              />
              <b-form-checkbox
                v-model="order.reminder.show"
                name="check-button"
                class="mt-3"
                :value="1"
                :unchecked-value="0"
                switch
              >
                Tonen in agenda
              </b-form-checkbox>

              <b-button
                variant="primary"
                class="mt-3"
                block
                v-if="order.reminder.id"
                @click="updateReminder()"
              >
                Reminder aanpassen
              </b-button>
              <b-button
                variant="primary"
                class="mt-3"
                block
                v-else
                @click="storeReminder()"
              >
                Reminder instellen
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col md="12">
        <b-card header="Klant">
          <p v-if="order.customer.billing_company">
            <b>{{ order.customer.billing_company }}</b>
          </p>
          <p>{{ order.customer.billing_formatted_name }}</p>
          <p>{{ order.customer.billing_address }}</p>

          <hr />

          <p>
            {{ order.customer.billing_phone }}
          </p>
          <p>
            {{ order.customer.email }}
          </p>

          <hr />

          <p>
            {{ order.customer.comments }}
          </p>

          <router-link
            :to="{ name: 'customer.show', params: { id: order.customer.id } }"
            block
            class="btn btn-primary btn-block mt-3"
          >
            Klant bekijken
          </router-link>
        </b-card>

        <b-card class="mt-4" header="Acties">
          <span id="process-order-wrapper" class="d-block mb-2" tabindex="0">
            <b-button
              v-if="order.status === OrderStatus.ON_HOLD"
              :disabled="processOrderButton"
              block
              @click="
                order.status = OrderStatus.PROCESSING;
                updateOrder();
              "
            >
              Order verwerken
            </b-button>
          </span>

          <b-tooltip
            v-if="processOrderButton"
            boundary="window"
            target="process-order-wrapper"
            placement="left"
          >
            Vul de leverdatum en/ of inkoopkosten aan
          </b-tooltip>

          <b-button
            v-if="
              order.status === OrderStatus.SENT ||
              order.status === OrderStatus.CANCELLED
            "
            block
            @click="
              oldStatus = order.status;
              order.status = OrderStatus.PROCESSING;
              updateOrder('status', true, oldStatus);
            "
          >
            In behandeling nemen
          </b-button>
          <b-button
            v-if="order.status === OrderStatus.PROCESSING"
            block
            @click="
              oldStatus = order.status;
              order.status = OrderStatus.SENT;
              updateOrder(
                'status',
                order.delivery_type !== 2 &&
                  order.delivery_type !== 4 &&
                  !order.transmission_transport_number,
                oldStatus
              );
            "
          >
            Markeer als verzonden
          </b-button>
          <b-button
            v-if="order.status === OrderStatus.SAMPLE_PROCESSING"
            block
            @click="
              order.status = OrderStatus.SAMPLE_COMPLETED;
              updateOrder('status');
            "
          >
            Proefmonster markeren als verzonden
          </b-button>
          <b-button
            v-if="order.status === OrderStatus.SAMPLE_COMPLETED"
            block
            @click="
              order.status = OrderStatus.SAMPLE_PROCESSING;
              updateOrder('status');
            "
          >
            Proefmonster markeren als open
          </b-button>
          <b-button
            v-if="
              order.status === OrderStatus.SAMPLE_PROCESSING ||
              order.status === OrderStatus.PROCESSING ||
              order.status === OrderStatus.ON_HOLD
            "
            block
            @click="
              order.status = OrderStatus.CANCELLED;
              updateOrder('status');
            "
          >
            Markeer als geannuleerd
          </b-button>

          <hr />

          <b-button block variant="primary" @click="sendOrderConfirmation">
            Orderbevestiging versturen
          </b-button>
          <b-button block variant="primary" @click="downloadPackingSlip">
            Pakbon downloaden
          </b-button>
        </b-card>

        <b-card class="mt-4" header="Transmission">
          <b-button
            block
            variant="primary"
            @click="sendToTransmission"
            v-if="!order.shipment.transport_number"
          >
            Verzenden naar Transmission
          </b-button>
          <template v-else>
            <div>
              <b>Transportnummer: </b>
              {{ order.shipment.transport_number }}
            </div>
            <div v-if="order.shipment.delivery_date">
              <b>Afgeleverd op: </b>
              {{ order.shipment.delivery_date }}
            </div>

            <div v-if="order.shipment.signed_by">
              <b>Getekend door: </b>
              {{ order.shipment.signed_by }}
            </div>

            <div v-if="order.shipment.signature">
              <b>Handtekening: </b>
              <b-img
                fluid
                :src="`data:image/jpeg;base64,${order.shipment.signature}`"
                alt="Handtekening"
              />
            </div>
            <hr />

            <b-button
              block
              variant="primary"
              @click="showTransmissionHistoryModal"
            >
              Historie
              <!-- <b-badge>{{ order.shipment.updates.length }}</b-badge> -->
            </b-button>

            <b-button block variant="primary" @click="getFromTransmission">
              Haal data uit Transmission
            </b-button>
          </template>
        </b-card>

        <b-card class="mt-4" header="Factuur">
          <template v-if="order.invoice">
            <b-row>
              <b-col>
                <b>Factuurnummer: </b><br />
                <template v-if="order.invoice.number">
                  {{ order.invoice.number }}
                </template>
                <template v-else>
                  <b-badge>Beschikbaar na versturen</b-badge>
                </template>
              </b-col>
            </b-row>

            <b-row class="mt-3">
              <b-col>
                <b>Factuurdatum: </b><br />
                <template v-if="order.invoice.date">
                  {{
                    new Date(order.invoice.date) | date("dd-MM-yyyy HH:mm:ss")
                  }}
                </template>
                <template v-else>
                  <b-badge>Beschikbaar na versturen</b-badge>
                </template>
              </b-col>
            </b-row>

            <b-row class="mt-3">
              <b-col>
                <b>Aangemaakt door: </b><br />
                {{ order.invoice.created_by.formatted_name }}
              </b-col>
            </b-row>

            <b-row class="mt-3">
              <b-col>
                <b>Aangemaakt op: </b><br />
                {{
                  new Date(order.invoice.created_at)
                    | date("dd-MM-yyyy HH:mm:ss")
                }}
              </b-col>
            </b-row>

            <hr
              v-if="auth.role.id === $root.Role.ADMIN || order.invoice.path"
            />

            <b-row
              v-if="auth.role.id === $root.Role.ADMIN || order.invoice.path"
            >
              <b-col>
                <b-button
                  variant="primary"
                  block
                  v-if="auth.role.id === $root.Role.ADMIN"
                  :to="{
                    name: 'invoice.show',
                    params: {
                      id: order.invoice.id,
                    },
                  }"
                >
                  Factuur bekijken
                </b-button>

                <b-button
                  variant="primary"
                  block
                  v-if="order.invoice.path"
                  @click="downloadInvoice"
                >
                  Factuur downloaden
                </b-button>
              </b-col>
            </b-row>
          </template>

          <!-- TODO: Re-enable this
            <b-form-group
            description="De factuur wordt automatisch klaargezet wanneer deze wordt verzonden, gebruik deze knop om het handmatig te doen."
            v-else-if="
              (order.status === OrderStatus.PROCESSING ||
                order.status === OrderStatus.SENT ||
                order.status === OrderStatus.SAMPLE_PROCESSING ||
                order.status === OrderStatus.SAMPLE_COMPLETED) &&
              order.channel === OrderChannel.WOOCOMMERCE
            ">
          -->

          <b-form-group
            description="De factuur wordt automatisch klaargezet wanneer deze wordt verzonden, gebruik deze knop om het handmatig te doen."
            v-else-if="
              (order.status === OrderStatus.PROCESSING ||
                order.status === OrderStatus.SENT) &&
              order.channel === OrderChannel.WOOCOMMERCE
            "
          >
            <b-button variant="primary" block @click="storeInvoice">
              Factuur klaarzetten
            </b-button>
          </b-form-group>

          <p
            v-else-if="
              order.status === OrderStatus.CANCELLED &&
              order.channel === OrderChannel.WOOCOMMERCE &&
              !order.invoice
            "
          >
            Voor geannuleerde orders kan geen factuur aangemaakt worden.
          </p>

          <p v-else>
            Voor Gadero- en proefmonster orders kan geen factuur aangemaakt
            worden.
          </p>
        </b-card>

        <b-card class="mt-4" header="Opmerkingen">
          <b-form-textarea
            id="commentInput"
            placeholder="Nieuwe opmerking toevoegen"
            rows="3"
            v-model="comment"
          />

          <b-button
            variant="primary"
            class="mt-2"
            block
            @click="storeOrderComment()"
          >
            Opmerking plaatsen
          </b-button>

          <div class="comments mt-3">
            <div v-for="(comment, index) in order.comments" :key="index">
              <hr />
              <div class="comment" :class="`comment-${comment.type}`">
                <p v-if="comment.author && comment.type === 'comment'">
                  <b>{{ comment.author.formatted_name }}</b> op
                  {{ new Date(comment.created_at) | date }}
                </p>
                <p v-else>
                  <b>Update</b> op
                  {{ new Date(comment.created_at) | date }}
                </p>
                <p v-html="$options.filters.urlify(comment.comment)" />
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <context-menu
      :id="$route.name"
      :key="$route.fullPath"
      :display="showContextMenu"
      component="product"
      ref="menu"
    />

    <b-modal id="add-orderline" centered size="lg" title="Orderregel toevoegen">
      <b-row>
        <b-col>
          <search-select
            searchable
            @input="selectProduct"
            searchType="products"
          />
        </b-col>
      </b-row>

      <template v-if="selectedProduct">
        <hr />
        <b-row>
          <b-col>
            <h5>
              <b>{{ selectedProduct.sku }}</b> toevoegen aan orderregel
            </h5>
            <p>
              <i>{{ selectedProduct.name }}</i>
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-3 d-flex align-items-end">
          <b-col>
            <b-form-group label="Aantal" class="mb-0">
              <b-form-input
                type="number"
                min="0"
                placeholder="Hoeveelheid"
                v-model="newOrderline.quantity"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-button variant="primary" @click="createOrderline">
              Voeg orderregel toe
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal id="add-orderline" centered size="lg" title="Orderregel toevoegen">
      <b-row>
        <b-col>
          <search-select
            searchable
            @input="selectProduct"
            searchType="products"
          />
        </b-col>
      </b-row>

      <template v-if="selectedProduct">
        <hr />
        <b-row>
          <b-col>
            <h5>
              <b>{{ selectedProduct.sku }}</b> toevoegen aan orderregel
            </h5>
            <p>
              <i>{{ selectedProduct.name }}</i>
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-3 d-flex align-items-end">
          <b-col>
            <b-form-group label="Aantal" class="mb-0">
              <b-form-input
                type="number"
                min="0"
                placeholder="Hoeveelheid"
                v-model="newOrderline.quantity"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-button variant="primary" @click="createOrderline">
              Voeg orderregel toe
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <TransmissionHistoryModal :updates="order.shipment.updates" />
  </div>
</template>

<script>
  import BackButton from "@/components/BackButton";
  import ContextMenu from "@/components/ContextMenu";
  import SearchSelect from "@/components/SearchSelect";

  import OrderInfoTable from "./Partials/_OrderInfoTable";
  import TransmissionHistoryModal from "./Partials/_TransmissionHistoryModal";

  import { OrderStatus } from "@/enums/Order/Status";
  import { OrderChannel } from "@/enums/Order/Channel";
  import { OrderDeliveryType } from "@/enums/Order/DeliveryType";
  import { AppointmentType } from "@/enums/Appointment/Type";

  import { mapGetters } from "vuex";

  export default {
    components: {
      BackButton,
      ContextMenu,
      OrderInfoTable,
      TransmissionHistoryModal,
      SearchSelect,
    },

    computed: {
      ...mapGetters({
        auth: "auth/user",
      }),

      processOrderButton() {
        let disabled = false;

        if (
          (!this.order.delivery_date &&
            this.order.delivery_type !== OrderDeliveryType.ON_CALL) ||
          !this.order.delivery_address ||
          this.missingPurchaseCosts
        ) {
          disabled = true;
        }

        return disabled;
      },

      missingPurchaseCosts() {
        let missing = false;

        if (!this.order.purchase_costs) {
          for (let orderline of this.order.orderlines) {
            if (orderline.product.id === 1405) {
              continue;
            }

            if (!orderline.product.purchase_price) {
              missing = true;
              break;
            }
          }
        }

        return missing;
      },
    },

    data() {
      return {
        order: {
          price: {
            purchasePrice: 0,
            discount: 0,
            extraCosts: 0,
            shippingCosts: 0,
            subtotal: 0,
            tax: 0,
            total: 0,
          },
          stats: {
            purchase_total: 0,
          },
          orderlines: [],
          purchase_costs: null,
          customer: {},
          created_at: new Date(),
          shipment: {
            updates: [],
          },
          reminder: {
            show: 1,
          },
        },

        calAttrs: [
          {
            key: "today",
            highlight: {
              color: "blue",
              fillMode: "light",
            },
            dates: new Date(),
          },
        ],

        products: [],

        filters: {
          terms: null,
        },

        selectedProduct: null,
        newOrderline: {
          quantity: 1,
        },

        OrderStatus: OrderStatus,
        OrderChannel: OrderChannel,
        OrderDeliveryType: OrderDeliveryType,
        AppointmentType: AppointmentType,

        comment: null,

        showContextMenu: false,

        fields: [
          {
            key: "notice",
            label: "",
            tdClass: "column-fit-content",
          },
          {
            key: "product.sku",
            label: "SKU",
            tdClass: "column-wrap-content",
            formatter: (value) => {
              return value || "FLPROEF";
            },
          },
          { key: "name", label: "Naam", tdClass: "column-wrap-content" },
          {
            key: "quantity",
            label: "Aantal",
            tdClass: "column-form-control-max-width",
          },
          { key: "price", label: "Prijs", tdClass: "column-form-control" },
          { key: "total", label: "Totaal" },
          { key: "actions", label: "" },
        ],

        sample: false,
      };
    },

    methods: {
      getOrder() {
        this.$http.get(`orders/${this.$route.params.id}`).then((response) => {
          this.order = response.data.data;

          this.order.orderlines.forEach((orderline) => {
            if (orderline.samples.length > 0) {
              this.$set(orderline, "_showDetails", true);
              this.sample = true;
            }
          });

          if (!this.order.stats) {
            this.order.stats = {
              purchase_total: 0,
            };
          }

          if (!this.order.shipment) {
            this.order.shipment = {
              updates: [],
            };
          }

          if (!this.order.reminder) {
            this.order.reminder = {
              show: 1,
            };
          }
        });
      },

      showTransmissionHistoryModal() {
        this.$bvModal.show("transmissionHistoryModal");
      },

      selectProduct(e) {
        this.selectedProduct = e;
      },

      trClass(item, type) {
        if (!item || type !== "row") {
          return;
        }

        if (
          !item.product.ignore_stock_warning &&
          item.quantity > item.product.physical_stock
        ) {
          return "table-danger";
        }
      },

      updateOrder(field = null, showConfirm = false, oldStatus = null) {
        let data = field
          ? {
              [field]: this.order[field],
            }
          : this.order;

        if (field && field !== "status" && showConfirm) {
          this.$root.showConfirm(
            "Weet je zeker dat je extra inkoopkosten wil aanpassen?",
            () => {
              this.$http
                .put(`orders/${this.order.id}`, data)
                .then((response) => {
                  this.$root.notify("De order is succesvol aangepast.");

                  this.order.price.purchasePrice =
                    response.data.data.price.purchasePrice;

                  this.order.invoice = response.data.data.invoice;
                })
                .catch((errors) => {
                  this.errors = this.$options.filters.setErrors(
                    errors.data.errors
                  );
                });
            }
          );
        } else if (this.order.status === OrderStatus.CANCELLED) {
          this.$root.showConfirm(
            "Wil de klant echt annuleren? 😞",
            () => {
              this.$http
                .put(`orders/${this.order.id}`, data)
                .then(() => {
                  this.$root.notify("De order is succesvol aangepast.");
                })
                .catch((errors) => {
                  this.errors = this.$options.filters.setErrors(
                    errors.data.errors
                  );
                });
            },
            () => {
              this.order.status = OrderStatus.PROCESSING;
            }
          );
        } else if (this.order.status === OrderStatus.PROCESSING && showConfirm) {
          this.$root.showConfirm(
            "Weet je zeker dat je de order weer in behandeling wil nemen?",
            () => {
              this.$http
                .put(`orders/${this.order.id}`, data)
                .then(() => {
                  this.$root.notify("De order is succesvol aangepast.");
                })
                .catch((errors) => {
                  this.errors = this.$options.filters.setErrors(
                    errors.data.errors
                  );
                });
            },
            () => {
              this.order.status = oldStatus;
            }
          );
        } else if (this.order.status === OrderStatus.SENT && showConfirm) {
          this.$http
            .put(`orders/${this.order.id}`, data)
            .then((response) => {
              this.$root.notify("De order is succesvol aangepast.");

              this.order.price.purchasePrice =
                response.data.data.price.purchasePrice;

              this.order.invoice = response.data.data.invoice;
            })
            .catch((errors) => {
              this.errors = this.$options.filters.setErrors(errors.data.errors);
            });
          this.$root.showConfirm(
            "Wil je de order ook direct naar Transmission sturen?",
            () => {
              this.$http
                .get(`transmission/create-shipment/${this.order.id}`)
                .then(() => {
                  this.$root.notify(
                    "Het transport is succesvol aangemaakt in Transmission."
                  );
                })
                .catch((errors) => {
                  this.$root.notify(errors.data.message, "danger");
                });
            },
            () => {},
            "Nee",
            "Ja"
          );
        } else {
          this.$http
            .put(`orders/${this.order.id}`, data)
            .then(({ data }) => {
              this.$root.notify("De order is succesvol aangepast.");

              this.order.price.purchasePrice = data.data.price.purchasePrice;

              this.order.distance_by_road = data.data.distance_by_road;
              this.order.distance_as_the_crow_flies =
                data.data.distance_as_the_crow_flies;
              this.order.invoice = data.data.invoice;
            })
            .catch((errors) => {
              this.errors = this.$options.filters.setErrors(errors.data.errors);
            });
        }
      },

      getNewOrderStats() {
        this.$http.get(`orders/${this.order.id}`).then(({ data }) => {
          this.order.price = data.data.price;
        });
      },

      addMarginToOrderline(orderline) {
        orderline.price = parseFloat(orderline.price * 0.9).toFixed(3);
        orderline.has_extra_margin = true;

        let data = {
          price: orderline.price,
          has_extra_margin: orderline.has_extra_margin,
        };

        this.$http
          .put(`orderlines/${orderline.id}`, data)
          .then(() => {
            this.$root.notify("De marge is succesvol toegevoegd.");
          })
          .then(() => {
            this.getNewOrderStats();
          });
      },

      updateOrderline(orderline) {
        let data = {
          ...orderline,
        };

        this.$http
          .put(`orderlines/${orderline.id}`, data)
          .then(() => {
            this.$root.notify("De orderline is succesvol aangepast.");
          })
          .catch((errors) => {
            this.errors = this.$options.filters.setErrors(errors.data.errors);
          });
      },

      deleteOrderline(orderline) {
        this.$root.showConfirm(
          "Weet je zeker dat je deze orderregel wil verwijderen?",
          () => {
            this.$http.delete(`orderlines/${orderline.id}`).then(() => {
              this.order.orderlines = this.order.orderlines.filter(
                (existingOrderline) => {
                  return existingOrderline.id !== orderline.id;
                }
              );

              this.$root.notify("De orderregel is succesvol verwijderd.");
            });
          }
        );
      },

      createOrderline() {
        let data = {
          ...this.newOrderline,
          ...{
            order_id: this.order.id,
            product_id: this.selectedProduct.id,
            name: this.selectedProduct.name,
          },
        };

        this.$http
          .post("orderlines", data)
          .then((response) => {
            this.order.orderlines.push(response.data.data);
          })
          .then(() => {
            this.$root.notify("De orderregel is succesvol aangemaakt.");

            this.$bvModal.hide("add-orderline");
            this.selectedProduct = null;
            this.newOrderline = {
              quantity: 1,
            };
          });
      },

      updateInvoice() {
        let data = {
          ...this.order.invoice,
        };

        this.$http
          .put(`invoices/${this.order.invoice.id}}`, data)
          .then((response) => {
            this.$root.notify("De kosten zijn succesvol aangepast.");

            this.invoice = response.data.data;
          });
      },

      storeReminder() {
        let data = {
          ...this.order.reminder,
          ...{
            order_id: this.order.id,
            type: AppointmentType.REMINDER,
          },
        };

        this.$http.post(`appointments`, data).then((response) => {
          this.$root.notify("De reminder is succesvol ingesteld.");

          this.order.reminder = response.data.data;
        });
      },

      updateReminder() {
        let data = {
          ...this.order.reminder,
        };

        this.$http
          .put(`appointments/${this.order.reminder.id}`, data)
          .then(() => {
            this.$root.notify("De reminder is succesvol aangepast.");
          });
      },

      downloadInvoice() {
        this.$http
          .get(`invoices/${this.order.invoice.id}/download`, {
            responseType: "arraybuffer",
          })
          .then((response) => {
            let blob = new Blob([response.data], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `Factuur ${this.order.invoice.number} - Floer BV`;
            link.click();
          });
      },

      sendOrderConfirmation() {
        this.$root.showConfirm(
          "Weet je zeker dat je de orderbevestiging wil versturen?",
          () => {
            this.$http
              .get(`order-confirmation/${this.$route.params.id}/send`)
              .then(() => {
                this.$root.notify("De orderbevestiging is succesvol verstuurd.");

                this.getOrder();
              });
          }
        );
      },

      sendToTransmission() {
        this.$root.showConfirm(
          "Weet je zeker dat je de order naar Transmission wil versturen?",
          () => {
            this.$http
              .get(`transmission/create-shipment/${this.$route.params.id}`)
              .then(() => {
                this.$root.notify(
                  "Het transport is succesvol aangemaakt in Transmission."
                );
              })
              .catch((data) => {
                this.$root.notify(data.default, "danger");
              });
          }
        );
      },

      getFromTransmission() {
        this.$http
          .get(`transmission/get-shipment-updates/${this.$route.params.id}`)
          .then(({ data }) => {
            this.$root.notify(
              "Extra data is succesvol opgehaald uit Transmission."
            );

            this.order.shipment = data.data;
          })
          .catch((data) => {
            this.$root.notify(data.default, "danger");
          });
      },

      downloadPackingSlip() {
        this.$http
          .get(`packing-slip/${this.$route.params.id}/download`, {
            responseType: "arraybuffer",
          })
          .then((response) => {
            let blob = new Blob([response.data], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `Pakbon ${this.order.order_number} - Floer BV`;
            link.click();
          });
      },

      storeOrderComment() {
        let data = {
          comment: this.comment,
          order_id: this.order.id,
          type: "comment",
        };

        this.$http.post("order-comment", data).then(() => {
          this.$root.notify("Opmerking geplaatst");
          this.comment = null;
          this.getOrder();
        });
      },

      storeInvoice() {
        this.$http
          .post("invoices", {
            order_id: this.order.id,
          })
          .then((response) => {
            this.order.invoice = response.data.data;
          });
      },

      rowContextMenuHandler(item, _, event) {
        this.$refs.menu.open(event, item.product);
        event.preventDefault();
      },

      searchOrder(record) {
        this.$router.push({
          name: this.sample === false ? "order.show" : "sample-order.show",
          params: { id: record.id },
        });
      },
    },

    beforeRouteUpdate(to, from, next) {
      next();
      this.getOrder();
    },

    mounted() {
      this.getOrder();
    },
  };
</script>