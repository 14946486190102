<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <back-button />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card header="Nieuwe gebruiker">
          <b-row>
            <b-col md="24" v-for="(input, key, index) in inputs" :key="key">
              <Input
                :label="input.label"
                :placeholder="input.placeholder"
                :type="input.type"
                :error="errors[key]"
                v-model="user[key]"
                :id="`user-${index}`"
              />
            </b-col>
          </b-row>

          <hr />

          <b-row>
            <b-col md="24">
              <b-form-group label="Rol">
                <b-select
                  :options="roles"
                  v-model="user.role_id"
                  value-field="id"
                  text-field="name"
                />
              </b-form-group>

              <b-form-checkbox
                v-model="user.show_as_agent"
                v-if="user.role_id === $root.Role.ADMIN"
                name="check-button"
                class="mt-2"
                switch
              >
                Deze gebruiker ook tonen als vertegenwoordiger
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          class="mt-4"
          header="Mail instellingen klanten"
          v-if="
            (user.role_id === $root.Role.ADMIN && user.show_as_agent) ||
            user.role_id === $root.Role.AGENT
          "
        >
          <p class="mb-3">
            Stel hier de klant mails in die je wil ontvangen als BCC.
          </p>

          <b-form-checkbox
            v-model="user.mail_invoice"
            name="check-button"
            class="mt-2"
            switch
          >
            Facturen
          </b-form-checkbox>

          <b-form-checkbox
            v-model="user.mail_order_sent"
            name="check-button"
            class="mt-2"
            switch
          >
            Order verzonden
          </b-form-checkbox>

          <b-form-checkbox
            v-model="user.mail_order_processing"
            name="check-button"
            class="mt-2"
            switch
          >
            Order in behandeling
          </b-form-checkbox>

          <b-form-checkbox
            v-model="user.mail_order_confirmation"
            name="check-button"
            class="mt-2"
            switch
          >
            Order bevestiging
          </b-form-checkbox>

          <b-form-checkbox
            v-model="user.mail_order_cancelled"
            name="check-button"
            class="mt-2"
            switch
          >
            Order geannuleerd
          </b-form-checkbox>
        </b-card>
      </b-col>

      <b-col md="12">
        <b-card header="Acties">
          <b-button @click="createUser" variant="primary" block type="submit">
            Aanmaken
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  import BackButton from "@/components/BackButton";

  export default {
    components: { BackButton },

    data() {
      return {
        user: {
          role_id: this.$root.Role.WORKER,
        },
        roles: [],
        errors: {},

        inputs: {
          first_name: {
            label: "Voornaam",
            placeholder: "Jeroen",
            type: "text",
          },
          last_name: {
            label: "Achternaam",
            placeholder: "Zoet",
            type: "text",
          },
          email: {
            label: "E-mailadres",
            placeholder: "jeroen@floer.nl",
            type: "email",
          },
          color: {
            label: "Kleur",
            type: "color",
          },
          password: {
            label: "Wachtwoord",
            placeholder: "•••••••••••",
            type: "password",
          },
          password_confirmation: {
            label: "Wachtwoord herhalen",
            placeholder: "•••••••••••",
            type: "password",
          },
        },
      };
    },

    methods: {
      createUser() {
        this.$http
          .post(`users`, this.user)
          .then(() => {
            this.$root.notify("De nieuwe gebruiker is succesvol aangemaakt.");

            this.$router.push({
              name: "user.index",
            });
          })
          .catch((errors) => {
            this.errors = this.$options.filters.setErrors(errors.data.errors);
            this.$root.notify(errors.data.errors, "danger");
          });
      },

      getRoles() {
        this.$http.get("roles").then((response) => {
          this.roles = response.data.data;
        });
      },
    },

    mounted() {
      this.getRoles();
    },
  };
</script>