<template>
  <b-button
    class="agenda__item agenda__item--reminder"
    :class="{
      'agenda__item--reminder-faded': appointment.finished,
    }"
    :id="`popover-reminder-${appointment.id}`"
  >
    #{{ appointment.order.order_number }}
    {{ appointment.title }}

    <i class="fas fa-check-square mr-0" v-if="appointment.finished" />

    <b-popover
      :target="`popover-reminder-${appointment.id}`"
      custom-class="popover-menu"
      placement="bottom"
      triggers="blur click"
      boundary="window"
    >
      <b-button-group vertical>
        <b-button
          variant="primary"
          :to="{
            name: 'order.show',
            params: { id: appointment.order_id },
          }"
          target="_blank"
        >
          <i class="far fa-external-link" />
          Order bekijken
        </b-button>
        <b-button
          v-if="!appointment.finished"
          variant="primary"
          @click="finishReminder(appointment)"
        >
          <i class="far fa-calendar-check" />
          Reminder afronden
        </b-button>
        <b-button
          v-else
          variant="primary"
          @click="finishReminder(appointment, 0)"
        >
          <i class="far fa-calendar-check" />
          Reminder heropenen
        </b-button>
        <b-button variant="primary" @click="moveDayAhead(appointment)">
          <i class="far fa-calendar-plus" />
          +1 dag
        </b-button>
      </b-button-group>
    </b-popover>
  </b-button>
</template>

<script>
  export default {
    props: {
      appointment: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },

    methods: {
      finishReminder(appointment, finished = 1) {
        let data = {
          finished: finished,
        };

        this.$http.put(`appointments/${appointment.id}`, data).then(() => {
          if (finished) {
            this.$root.notify("De reminder is succesvol afgerond.");
          } else {
            this.$root.notify("De reminder is succesvol heropend.");
          }

          appointment.finished = finished ? true : false;
        });
      },

      moveDayAhead(appointment) {
        this.$http
          .get(`agenda/day-ahead/appointment/${appointment.id}`)
          .then(() => {
            this.$root.notify(
              "De agenda afspraak is een dag naar voren geschoven"
            );
            this.$parent.getAppointments();
          });
      },
    },
  };
</script>