export const AppointmentType = {
    REGULAR: 1,
    STAFF: 2,
    SHOWROOM: 3,
    REMINDER: 4,
    APPROVAL_REQUIRED: 5,
};

export const AppointmentTypeString = {
    1: 'Algemene zaken',
    2: 'Personeel',
    3: 'Showroom',
    4: 'Reminders',
    5: 'Goedkeuring nodig',
};