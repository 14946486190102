<template>
  <b-overlay
    :show="loading"
    spinner-variant="secondary"
    bg-color="#fff"
    opacity="1"
    rounded="sm"
  >
    <b-card class="card-stats">
      <h2 class="stat-title">Besteld</h2>
      <h4 class="stat-title-small">Omzet</h4>
      <div class="stat-value">
        <template v-if="currency">&euro;</template>
        {{ value | formatNumber(2, true) }}

        <span class="stat-value-prev">
          <template v-if="currency">&euro;</template>
          {{ oldValue | formatNumber(2, true) }}
        </span>
      </div>

      <hr />

      <h4 class="stat-title-small">Marge</h4>
      <div class="stat-value">
        <template v-if="currency">&euro;</template>
        {{ margin | formatNumber(2, true) }}

        <span
          v-if="!isNaN(marginPercentage)"
          class="stat-value-extra"
          :id="`stat-extra-tooltip-${$options.filters.toSlug(title)}`"
        >
          {{ marginPercentage | formatNumber(0) }}%
        </span>

        <span class="stat-value-prev">
          <template v-if="currency">&euro;</template>
          {{ oldMargin | formatNumber(2, true) }}
        </span>
      </div>

      <hr />

      <h4 class="stat-title-small">Inkoopwaarde</h4>
      <div class="stat-value">
        <template v-if="currency">&euro;</template>
        {{ purchaseTotal | formatNumber(2, true) }}
        <span class="stat-value-prev">
          <template v-if="currency">&euro;</template>
          {{ oldPurchaseTotal | formatNumber(2, true) }}
        </span>
      </div>

      <span v-if="iconVisible" class="stat-icon">
        <span :class="{ 'd-none': trendDown }">
          <i class="far fa-arrow-trend-up text-success" />
        </span>
        <span :class="{ 'd-none': trendUp }">
          <i class="far fa-arrow-trend-down text-danger" />
        </span>
      </span>
    </b-card>
  </b-overlay>
</template>

<script>
  export default {
    props: {
      loading: {
        type: Boolean,
        default: () => {
          return false;
        },
      },

      purchaseTotal: {
        type: Number,
        default: () => {
          return 0;
        },
      },

      oldPurchaseTotal: {
        type: Number,
        default: () => {
          return 0;
        },
      },

      value: {
        type: Number,
        default: () => {
          return 0;
        },
      },

      oldValue: {
        type: Number,
        default: () => {
          return 0;
        },
      },

      currency: {
        type: Boolean,
        default: () => {
          return true;
        },
      },
    },

    computed: {
      iconVisible() {
        return this.value !== this.oldValue;
      },

      margin() {
        return this.value - this.purchaseTotal;
      },

      oldMargin() {
        return this.oldValue - this.oldPurchaseTotal;
      },

      marginPercentage() {
        return ((this.value - this.purchaseTotal) / this.value) * 100;
      },

      trendUp() {
        return this.value > this.oldValue;
      },

      trendDown() {
        return this.value < this.oldValue;
      },
    },
  };
</script>