<template>
  <b-button
    class="agenda__item agenda__item--order"
    :class="{
      'agenda__item--order-faded': order.packing_slip_path,
      'agenda__item--order-pickup':
        order.delivery_type === OrderDeliveryType.PICK_UP,
      'agenda__item--order-van':
        order.delivery_type === OrderDeliveryType.VAN_DELIVERY,
    }"
    :id="`popover-order-${order.id}`"
  >
    <template v-if="order.customer">
      #{{ order.order_number }}
      {{
        order.customer.billing_company
          ? order.customer.billing_company
          : order.customer.billing_formatted_name
      }}
    </template>
    <template v-else>#{{ order.order_number }}</template>
    <i class="fas fa-check-square mr-0"></i>

    <b-popover
      :target="`popover-order-${order.id}`"
      custom-class="popover-menu"
      placement="bottom"
      triggers="blur click"
      boundary="window"
    >
      <b-button-group vertical>
        <b-button
          variant="primary"
          :to="{
            name: 'order.show',
            params: { id: order.id },
          }"
          target="_blank"
        >
          <i class="far fa-external-link" />
          Order bekijken
        </b-button>
        <b-button variant="primary" @click="downloadPackingSlip(order)">
          <i class="far fa-file-download" />
          Pakbon downloaden
        </b-button>
      </b-button-group>
    </b-popover>
  </b-button>
</template>

<script>
  import { OrderDeliveryType } from "@/enums/Order/DeliveryType";

  export default {
    props: {
      order: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },

    data() {
      return {
        OrderDeliveryType: OrderDeliveryType,
      };
    },

    methods: {
      downloadPackingSlip(order) {
        this.$http
          .get(`packing-slip/${order.id}/download`, {
            responseType: "arraybuffer",
          })
          .then((response) => {
            let blob = new Blob([response.data], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `Pakbon ${order.order_number} - Floer BV`;
            link.click();

            this.$root.notify("Pakbon gedownload");
          })
          .then(() => {
            this.$parent.getOrders();
          });
      },
    },
  };
</script>