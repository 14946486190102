<template>
  <b-form-group
    :label-for="id"
    :invalid-feedback="error"
    :state="error ? false : null"
    :content-cols-md="horizontal ? 32 : null"
  >
    <b-form-file
      v-if="type === 'file'"
      v-model="file"
      :accept="acceptedFileTypes"
      :placeholder="placeholder"
      :drop-placeholder="dropPlaceholder"
      browse-text="Bladeren"
    />

    <template v-else>
      <template v-if="!disabled">
        <template v-if="prepend || append">
          <b-input-group :prepend="prepend" :append="append">
            <b-input
              :id="id"
              :placeholder="placeholder"
              :value="value"
              :type="type"
              :state="error ? false : null"
              :step="step"
              :min="min"
              :max="max"
              :disabled="disabled"
              :lazy="lazy"
              :number="type === 'number'"
              @input="emitInput"
              @update="emitUpdate"
              @blur="emitBlur"
              @change="emitChange"
            />
          </b-input-group>
        </template>
        <template v-else>
          <b-input
            :id="id"
            :placeholder="placeholder"
            :value="value"
            :type="type"
            :size="size"
            :state="error ? false : null"
            :step="step"
            :min="min"
            :max="max"
            :disabled="disabled"
            :lazy="lazy"
            :number="type === 'number'"
            @input="emitInput"
            @update="emitUpdate"
            @blur="emitBlur"
            @change="emitChange"
          />
        </template>
      </template>
      <template v-else>
        <span class="cant-touch-this">
          {{ value }}
        </span>
      </template>
    </template>

    <template v-slot:label v-if="label">
      {{ label }}
      <span v-b-tooltip.hover v-if="tooltip && !hideTooltip" :title="tooltip">
        <i class="info-form far fa-info-circle" />
      </span>
    </template>

    <slot></slot>

    <template v-if="descriptionSlotFilled">
      <small class="form-text text-muted">
        <slot name="description">
          {{ description }}
        </slot>
      </small>
    </template>
  </b-form-group>
</template>

<script>
  /**
   * This component uses two way data binding, this means you can
   * use the v-model attribute when using the component
   *
   * @example <Input v-model={model} label="{label}" etc..></Input>
   *
   * @author Michael Lanting <michael@nordique.nl>
   * @version 1.0.0
   */
  export default {
    props: {
      /**
       * The unique ID used for linking the labels to the input field
       */
      id: {
        type: String,
        default: null,
      },

      /**
       * The label to be shown
       */
      label: {
        type: String,
        default: null,
      },

      /**
       * The error to be shown when validation fails
       */
      error: {
        default: null,
      },

      /**
       * The value to track
       */
      value: {
        default: null,
      },

      /**
       * The file to track
       */
      file: {
        default: null,
      },

      /**
       * The placeholder to be shown
       */
      placeholder: {
        type: String,
        default: null,
      },

      /**
       * The description to be shown
       */
      description: {
        type: String,
        default: null,
      },

      /**
       * The steps on a number field
       */
      step: {
        default: null,
      },

      /**
       * The min for a number field
       */
      min: {
        default: null,
      },

      /**
       * The max for a number field
       */
      max: {
        default: null,
      },

      /**
       * The drop placeholder to be shown
       */
      dropPlaceholder: {
        type: String,
        default: null,
      },

      /**
       * The tooltip to be shown
       */
      tooltip: {
        type: String,
        default: null,
      },

      /**
       * The amount of cols for a label
       */
      horizontal: {
        type: Boolean,
        default: null,
      },

      /**
       * Sync data on change
       */
      lazy: {
        type: Boolean,
        default: false,
      },

      /**
       * String to prepend before the input
       */
      prepend: {
        type: String,
        default: null,
      },

      /**
       * String to append after the input
       */
      append: {
        type: String,
        default: null,
      },

      /**
       * Disabled state
       */
      disabled: {
        type: Boolean,
        default: false,
      },

      /**
       * Disabled state
       */
      hideTooltip: {
        type: Boolean,
        default: false,
      },

      /**
       * Disabled state
       */
      size: {
        type: String,
        default: null,
      },

      acceptedFileTypes: {
        type: String,
        default: () => {
          return "*";
        },
      },

      /**
       * The type of input
       * @values text, number, email, password, search, url, tel, date, time, range, color, file
       */
      type: {
        type: String,
        default: "text",
        validator: function (value) {
          return (
            [
              "text",
              "number",
              "email",
              "password",
              "search",
              "url",
              "tel",
              "date",
              "time",
              "range",
              "color",
              "file",
            ].indexOf(value) !== -1
          );
        },
      },
    },

    computed: {
      descriptionSlotFilled() {
        return !!this.$slots.description || this.description;
      },
    },

    methods: {
      emitInput(value) {
        if (this.type !== "search" || value.length >= 3 || value.length === 0) {
          this.$emit("input", value);
        }
      },

      emitBlur(value) {
        if (this.type !== "search" || value.length >= 3 || value.length === 0) {
          this.$emit("blur", value);
        }
      },

      emitChange(value) {
        if (this.type !== "search" || value.length >= 3 || value.length === 0) {
          this.$emit("change", value);
        }
      },

      emitUpdate(value) {
        if (this.type !== "search" || value.length >= 3 || value.length === 0) {
          this.$emit("update", value);
        }
      },
    },
  };
</script>
