<template>
  <b-card>
    <h2>Transmission</h2>
    <b-row>
      <b-col md="24" v-for="setting in settings" :key="setting.key">
        <Input
          v-model="setting.value"
          :id="setting.key"
          :label="setting.label"
          :description="setting.description"
          :disabled="setting.read_only"
        />
      </b-col>
    </b-row>
    <b-button class="mt-3" @click="updateSettings('transmission')">
      Transmission informatie opslaan
    </b-button>
  </b-card>
</template>

<script>
  export default {
    data() {
      return {
        settings: [],
      };
    },

    methods: {
      getSettings() {
        let params = {
          type: "transmission",
          read_only: false,
        };

        this.$http.get(`settings`, { params: params }).then((response) => {
          this.settings = response.data.data;
        });
      },

      updateSettings() {
        let data = {
          data: { ...this.settings },
        };

        this.$http.put(`settings`, data).then(() => {
          this.$root.notify("De instellingen zijn succesvol opgeslagen.");
        });
      },
    },

    mounted() {
      this.getSettings();
    },
  };
</script>