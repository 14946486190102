<template>
  <div
    id="app"
    :class="{
      main: auth && $route.name !== 'two-factor',
      'navigation-expanded': expanded,
    }"
  >
    <router-view />
    <vue-progress-bar></vue-progress-bar>

    <Navigation v-if="auth && $route.name !== 'two-factor'" />

    <div
      class="env-notice env"
      v-if="host === 'beheer.floer.nl' && ip === '213.126.202.110'"
    >
      <i class="fas fa-exclamation-triangle mr-2" />
      DIT IS DE LIVE OMGEVING
    </div>
  </div>
</template>

<style lang="sass">
@import "@/assets/scss/style"
</style>
<script>
  import { mapGetters } from "vuex";
  import Navigation from "./components/Navigation";

  export default {
    components: {
      Navigation,
    },

    data() {
      return {
        expanded: true,
        ip: null,
        host: window.location.host,
      };
    },

    computed: {
      ...mapGetters({
        auth: "auth/user",
      }),
    },

    watch: {
      expanded: {
        handler() {
          localStorage.setItem("navigationExpanded", this.expanded);
        },
      },
    },

    methods: {
      checkIP() {
        fetch("https://api.ipify.org?format=json", {
          method: "GET",
        })
          .then((response) => response.text())
          .then((result) => (this.ip = JSON.parse(result).ip));
      },

      setCookie() {
        this.$http.get("/sanctum/csrf-cookie");
      },
    },

    created() {
      this.$http.interceptors.request.use((config) => {
        this.$Progress.start();
        return config;
      });

      this.$http.interceptors.response.use(
        (response) => {
          this.$Progress.finish();
          return Promise.resolve(response);
        },
        (error) => {
          this.$Progress.finish();
          return Promise.reject(error.response.data.errors);
        }
      );

      if (
        localStorage.getItem("navigationExpanded") &&
        localStorage.getItem("navigationExpanded") === "true"
      ) {
        this.expanded = true;
      } else {
        this.expanded = false;
      }
    },

    mounted() {
      this.checkIP();
      this.setCookie();
    },
  };
</script>