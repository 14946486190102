<template>
  <div>
    <b-row>
      <b-col>
        <b-tabs pills content-class="mt-4" @activate-tab="updateUrl">
          <b-tab
            v-for="tab in tabs"
            :key="tab.slug"
            :title="tab.title"
            :active="$route.params.tab === tab.slug"
          >
            <component :is="tab.component" />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import ApiTokens from "./Partials/_ApiTokens";
  import Exact from "./Partials/_Exact";
  import Exports from "./Partials/_Exports";
  import Settings from "./Partials/_Settings";
  import Transmission from "./Partials/_Transmission";
  import Whitelist from "./Partials/_Whitelist";

  export default {
    data() {
      return {
        tabs: [
          {
            title: "Instellingen",
            slug: "instellingen",
            component: Settings,
          },
          {
            title: "Exports",
            slug: "exports",
            component: Exports,
          },
          {
            title: "Exact",
            slug: "exact",
            component: Exact,
          },
          {
            title: "Transmission",
            slug: "transmission",
            component: Transmission,
          },
          {
            title: "Whitelist",
            slug: "whitelist",
            component: Whitelist,
          },
          {
            title: "API Tokens",
            slug: "api-tokens",
            component: ApiTokens,
          },
        ],
      };
    },

    methods: {
      updateUrl(newTabIndex) {
        this.$router.push({
          name: "management.index",
          params: { tab: this.tabs[newTabIndex].slug },
        });
      },
    },
  };
</script>