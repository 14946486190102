<template>
  <b-overlay
    :show="loading"
    spinner-variant="secondary"
    bg-color="#fff"
    opacity="1"
    rounded="sm"
  >
    <b-card class="card-stats" no-body>
      <h2 class="stat-title stat-title-scroll">
        {{ products.length }} Best verkochte proefmonsters
      </h2>
      <b-card-body>
        <template v-if="products.length">
          <p
            class="my-1 stat-product"
            v-for="(product, index) of products"
            :key="index"
          >
            <b-badge variant="primary" class="mx-2 text-monospace">
              {{ product.amount_sold }}
            </b-badge>
            <router-link
              :to="{
                name: 'product.show',
                params: { id: product.id },
              }"
            >
              {{ product.name }}
            </router-link>
          </p>
          <b-button
            v-if="products_meta.current_page < products_meta.last_page"
            variant="primary"
            class="btn-stat-load-more"
            @click="$parent.$parent.$parent.getTopSoldSamples()"
          >
            <i class="far fa-plus mr-0" />
          </b-button>
        </template>
        <template v-else>
          Er zijn geen producten gevonden voor deze periode
        </template>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
  export default {
    props: {
      loading: {
        type: Boolean,
        default: () => {
          return false;
        },
      },

      products: {
        type: Array,
        default: () => {
          return [];
        },
      },

      products_meta: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
  };
</script>