<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <back-button />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="36">
        <b-tabs v-model="tabIndex" pills content-class="mt-4">
          <b-tab title="Algemene info">
            <b-row>
              <b-col>
                <b-card>
                  <h2>
                    {{ user.formatted_name }}
                  </h2>
                  <b-row>
                    <b-col
                      md="24"
                      v-for="(input, key, index) in inputs"
                      :key="key"
                    >
                      <Input
                        :label="input.label"
                        :placeholder="input.placeholder"
                        :type="input.type"
                        :error="errors[key]"
                        v-model="user[key]"
                        :id="`user-${index}`"
                      />
                    </b-col>
                  </b-row>

                  <template v-if="auth.role_id === $root.Role.ADMIN">
                    <hr />

                    <b-row>
                      <b-col md="24">
                        <b-form-group label="Rol">
                          <b-select
                            :options="roles"
                            v-model="user.role_id"
                            value-field="id"
                            text-field="name"
                          />
                        </b-form-group>

                        <b-form-checkbox
                          v-model="user.show_as_agent"
                          v-if="user.role_id === $root.Role.ADMIN"
                          name="check-button"
                          class="mt-2"
                          switch
                        >
                          Deze gebruiker ook tonen als vertegenwoordiger
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                  </template>
                </b-card>

                <b-tabs
                  class="mt-4"
                  pills
                  v-if="
                    (user.role_id === $root.Role.ADMIN && user.show_as_agent) ||
                    user.role_id === $root.Role.AGENT
                  "
                >
                  <b-tab title="Mailinstellingen">
                    <b-card
                      class="mt-4"
                      v-if="
                        (user.role_id === $root.Role.ADMIN &&
                          user.show_as_agent) ||
                        user.role_id === $root.Role.AGENT
                      "
                    >
                      <h4>Mail instellingen klanten</h4>
                      <p class="mb-3">
                        Stel hier de klant mails in die je wil ontvangen als
                        BCC.
                      </p>

                      <b-form-checkbox
                        v-model="user.mail_invoice"
                        name="check-button"
                        class="mt-2"
                        switch
                      >
                        Facturen
                      </b-form-checkbox>

                      <b-form-checkbox
                        v-model="user.mail_order_sent"
                        name="check-button"
                        class="mt-2"
                        switch
                      >
                        Order verzonden
                      </b-form-checkbox>

                      <b-form-checkbox
                        v-model="user.mail_order_processing"
                        name="check-button"
                        class="mt-2"
                        switch
                      >
                        Order in behandeling
                      </b-form-checkbox>

                      <b-form-checkbox
                        v-model="user.mail_order_confirmation"
                        name="check-button"
                        class="mt-2"
                        switch
                      >
                        Order bevestiging
                      </b-form-checkbox>

                      <b-form-checkbox
                        v-model="user.mail_order_cancelled"
                        name="check-button"
                        class="mt-2"
                        switch
                      >
                        Order geannuleerd
                      </b-form-checkbox>
                    </b-card>
                  </b-tab>
                  <b-tab class="mt-4" title="Commissie">
                    <b-card no-body>
                      <b-card-body>
                        <h4 class="mb-3">Commissie per klant</h4>

                        <template v-if="user.customers.length">
                          <template v-if="auth.role_id === $root.Role.ADMIN">
                            <b-row>
                              <b-col md="12">
                                <Input
                                  label="Bulk wijziging"
                                  type="number"
                                  v-model="bulk_commission_percentage"
                                  append="%"
                                  description="Vul hier het commissie percentage in voor alle
                    klanten bij deze vertegenwoordiger"
                                  :min="0"
                                />
                                <b-button
                                  variant="primary"
                                  @click="bulkUpdateCommission"
                                >
                                  Aanpassen
                                </b-button>
                              </b-col>
                            </b-row>
                          </template>
                        </template>
                        <template v-else>
                          <b-alert variant="warning" show>
                            <i class="far fa-warning mr-2" /> Geen klanten
                            gevonden
                          </b-alert>
                        </template>
                      </b-card-body>

                      <template v-if="user.customers.length">
                        <div class="scrollable" style="--max-height: 500px">
                          <b-row
                            v-for="(customer, index) of user.customers"
                            :key="index"
                          >
                            <b-col md="36">
                              <div class="d-flex align-items-center mb-3 gap-2">
                                <Input
                                  :disabled="auth.role_id !== $root.Role.ADMIN"
                                  v-model="customer.commission_percentage"
                                  :label="customer.billing_formatted_name"
                                  :min="0"
                                  horizontal
                                  type="number"
                                  class="mb-0"
                                  append="%"
                                />
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                      </template>
                    </b-card>
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab>
            <template #title>
              2FA
              <b-badge v-if="user.has2FA" variant="success" class="ml-1">
                AAN
              </b-badge>
              <b-badge v-else variant="danger" class="ml-1">UIT</b-badge>
            </template>

            <b-row>
              <b-col>
                <b-card>
                  <b-row>
                    <b-col>
                      <h2>Twee staps verificatie</h2>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-button
                        v-if="!user.has2FA"
                        @click="enableTwoFactorAuth"
                      >
                        Inschakelen
                      </b-button>
                      <template v-else-if="user.has2FA">
                        <p>
                          Twee staps verificatie staat ingeschakeld. Om 2FA te
                          deactiveren en een nieuwe code te scannen moet je
                          contact opnemen met een beheerder.
                        </p>
                        <template v-if="auth.role.id === $root.Role.ADMIN">
                          <b-button class="mt-2" @click="disableTwoFactorAuth">
                            Uitschakelen
                          </b-button>
                        </template>
                      </template>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-col>
      <b-col md="12">
        <b-card>
          <b-button
            @click="updateUser"
            variant="primary"
            type="submit"
            class="mr-2"
            block
          >
            Opslaan
          </b-button>
          <hr />
          <b-button @click="deleteUser" variant="danger" block>
            Verwijderen
          </b-button>
        </b-card>
      </b-col>
    </b-row>

    <b-modal id="twoFactorModal" hide-footer centered hide-header>
      <b-row>
        <b-col md="48">
          <h2>Scannen</h2>
          <p>
            Scan onderstaande QR code met een Authenticator app en bewaar de
            herstel codes op een veilige plek.
          </p>
          <p class="mt-2">
            Na het sluiten van dit venster zal de code niet meer zichtbaar zijn.
            Om de code opnieuw te scannen moet 2FA opnieuw ingesteld worden.
          </p>
        </b-col>
        <b-col md="48">
          <hr />
        </b-col>
        <b-col md="48" class="text-center">
          <div class="two-factor-qr-code mb-4" v-if="qrCode" v-html="qrCode" />
        </b-col>
        <b-col md="48" class="text-center">
          <b>Herstelcodes</b>
          <div v-if="recoveryCodes.length" class="two-factor-recovery-codes">
            <span v-for="(code, index) of recoveryCodes" :key="index">{{
              code
            }}</span>
          </div>
        </b-col>
        <b-col md="48" class="mt-3 text-center">
          <b>Geheime sleutel</b>
          <div class="two-factor-recovery-codes">
            <span>{{ secretKey }}</span>
          </div>
        </b-col>
      </b-row>
      <b-button
        @click="$bvModal.hide('twoFactorModal')"
        block
        variant="primary"
        class="mt-3"
        >Sluiten</b-button
      >
    </b-modal>
  </div>
</template>
<script>
  import BackButton from "@/components/BackButton";

  import { mapGetters } from "vuex";

  export default {
    components: { BackButton },
    data() {
      return {
        user: {
          has2FA: false,
          customers: [],
        },

        roles: [],
        errors: {},

        qrCode: "",
        recoveryCodes: [],
        secretKey: null,

        bulk_commission_percentage: null,

        inputs: {
          first_name: {
            label: "Voornaam",
            placeholder: "Jeroen",
            type: "text",
          },
          last_name: {
            label: "Achternaam",
            placeholder: "Zoet",
            type: "text",
          },
          email: {
            label: "E-mailadres",
            placeholder: "jeroen@floer.nl",
            type: "email",
          },
          color: {
            label: "Kleur",
            type: "color",
          },
          password: {
            label: "Wachtwoord",
            placeholder: "•••••••••••",
            type: "password",
          },
          password_confirmation: {
            label: "Wachtwoord herhalen",
            placeholder: "•••••••••••",
            type: "password",
          },
        },
      };
    },

    computed: {
      ...mapGetters({
        auth: "auth/user",
      }),
    },

    methods: {
      deleteUser() {
        this.$root.showConfirm(
          "Weet je zeker dat je deze gebruiker wilt verwijderen?",
          () => {
            this.$http
              .delete(`users/${this.user.id}`)
              .then(() => {
                this.$root.notify("De gebruiker is succesvol verwijderd.");
                this.$router.push({
                  name: "user.index",
                });
              })
              .catch((errors) => {
                this.$root.notify(errors.data.errors, "danger");
              });
          }
        );
      },

      getRoles() {
        this.$http.get("roles").then((response) => {
          this.roles = response.data.data;
        });
      },

      getUser() {
        this.$http.get(`users/${this.$route.params.id}`).then((response) => {
          this.user = response.data.data;
        });
      },

      enableTwoFactorAuth() {
        let data = {
          user: this.$route.params.id,
        };

        this.$http.post("/user/two-factor-authentication", data).then(() => {
          this.$http
            .get("/user/two-factor-qr-code", {
              params: data,
            })
            .then((response) => {
              this.qrCode = response.data.svg;
            });

          this.$http
            .get("/user/two-factor-recovery-codes", { params: data })
            .then((response) => {
              this.recoveryCodes = response.data;
            });

          this.$http
            .get("/user/two-factor-secret-key", { params: data })
            .then((response) => {
              this.secretKey = response.data.secretKey;
            });

          this.user.has2FA = true;

          this.$bvModal.show("twoFactorModal");
        });
      },

      disableTwoFactorAuth() {
        let data = {
          user: this.$route.params.id,
        };

        this.$http
          .delete("/user/two-factor-authentication", { params: data })
          .then(() => {
            this.user.has2FA = false;
          });
      },

      bulkUpdateCommission() {
        Object.keys(this.user.customers).forEach(
          (v) =>
            (this.user.customers[v].commission_percentage =
              this.bulk_commission_percentage)
        );

        this.bulk_commission_percentage = null;
      },

      updateUser() {
        let data = {
          ...this.user,
        };

        if (this.auth.role_id !== this.$root.Role.ADMIN) {
          delete data.customers;
        }

        this.$http
          .put(`users/${this.user.id}`, data)
          .then(() => {
            this.$root.notify("De gebruiker is succesvol bewerkt.");
          })
          .catch((errors) => {
            this.errors = this.$options.filters.setErrors(errors.data.errors);
          });
      },
    },

    mounted() {
      this.getUser();
      this.getRoles();
    },
  };
</script>