var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"},{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.close),expression:"close"}],staticClass:"context-menu",style:(_vm.style),attrs:{"tabindex":"0"}},[_c('b-button-group',{ref:"buttons",attrs:{"vertical":"","size":"sm"}},[_vm._l((_vm.items),function(item,index){return _c('b-button',{key:index,attrs:{"variant":"context-menu"}},[_c('i',{staticClass:"far fa-external-link"}),_vm._v(_vm._s(item.text)+" ")])}),(_vm.component === 'service-request')?[(_vm.item.order)?_c('b-button',{attrs:{"to":{
          name: `order.show`,
          params: {
            id: _vm.item.order.id,
          },
        },"target":"_blank","variant":"context-menu"}},[_c('i',{staticClass:"far fa-external-link"}),_vm._v(" Open "),_c('b',[_vm._v(_vm._s(_vm.item.order_number))]),_vm._v(" in een nieuw tabblad ")]):_vm._e(),(_vm.item.product)?_c('b-button',{attrs:{"to":{
          name: `product.show`,
          params: {
            id: _vm.item.product.id,
          },
        },"target":"_blank","variant":"context-menu"}},[_c('i',{staticClass:"far fa-external-link"}),_vm._v(" Open "),_c('b',[_vm._v(_vm._s(_vm.item.product.sku))]),_vm._v(" in een nieuw tabblad ")]):_vm._e()]:[_c('b-button',{attrs:{"to":_vm.linkObject,"target":"_blank","variant":"context-menu"}},[_c('i',{staticClass:"far fa-external-link"}),_vm._v(" Open "),(_vm.item.order_number)?_c('b',[_vm._v(_vm._s(_vm.item.order_number))]):(_vm.item.formatted_name)?_c('b',[_vm._v(_vm._s(_vm.item.formatted_name))]):(_vm.item.sku)?_c('b',[_vm._v(_vm._s(_vm.item.sku))]):(_vm.item.product)?_c('b',[_vm._v(_vm._s(_vm.item.product.sku))]):(_vm.item.name)?_c('b',[_vm._v(_vm._s(_vm.item.name))]):_vm._e(),_vm._v(" in een nieuw tabblad ")]),_c('b-button',{attrs:{"variant":"context-menu"},on:{"click":_vm.copyLink}},[_c('i',{staticClass:"far fa-link"}),_vm._v(" Link kopiëren ")])]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }