<template>
  <div>
    <b-row>
      <b-col>
        <b-table-simple responsive class="table-product-info">
          <b-thead>
            <b-tr>
              <b-th colspan="1"></b-th>
              <b-th colspan="1">Aantal</b-th>
              <b-th colspan="1">M<sup>2</sup></b-th>
              <b-th colspan="1">Productinformatie</b-th>
              <b-th colspan="1"></b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-th>Fysieke voorraad</b-th>
              <b-td>
                <span>
                  {{ product.physical_stock }}
                </span>
              </b-td>
              <b-td>
                <span>
                  {{
                    (product.physical_stock * product.m2_per_product)
                      | formatNumber(3)
                  }}
                </span>
              </b-td>
              <b-th>SKU</b-th>
              <b-td>
                <span>
                  {{ product.sku }}
                </span>
              </b-td>
            </b-tr>

            <b-tr>
              <b-th>Gereserveerde voorraad</b-th>
              <b-td>
                <span>
                  {{ product.reserved_stock }}
                </span>
              </b-td>
              <b-td>
                <span>
                  {{
                    (product.reserved_stock * product.m2_per_product)
                      | formatNumber(3)
                  }}
                </span>
              </b-td>
              <b-th>
                <template v-if="product.woocommerce_id">WooCommerce</template>
                <template v-else-if="product.gadero_id">Gadero</template>
                ID
              </b-th>
              <b-td>
                <span>
                  {{ product.woocommerce_id || product.gadero_id }}
                </span>
              </b-td>
            </b-tr>

            <b-tr>
              <b-th>Beschikbare voorraad</b-th>
              <b-td>
                <span>
                  {{ product.available_stock }}
                </span>
              </b-td>
              <b-td>
                <span>
                  {{
                    (product.available_stock * product.m2_per_product)
                      | formatNumber(3)
                  }}
                </span>
              </b-td>
              <b-th>Artikelnaam leverancier</b-th>
              <b-td colspan="2" class="column-input">
                <Input
                  v-model="product.supplier_name"
                  @input="updateValue($event)"
                />
              </b-td>
            </b-tr>

            <b-tr>
              <b-th>Verwachte leveringen</b-th>
              <b-td>
                <span>
                  {{ product.incoming_stock }}
                </span>
              </b-td>
              <b-td>
                <span>
                  {{
                    (product.incoming_stock * product.m2_per_product)
                      | formatNumber(3)
                  }}
                </span>
              </b-td>
              <b-th>Artikelcode leverancier</b-th>
              <b-td colspan="2" class="column-input">
                <Input
                  v-model="product.supplier_article_code"
                  @input="updateValue($event)"
                />
              </b-td>
            </b-tr>
            <b-tr>
              <b-th></b-th>
              <b-td></b-td>
              <b-td></b-td>
              <b-th>Kleurcode leverancier</b-th>
              <b-td colspan="2" class="column-input">
                <Input
                  v-model="product.supplier_color_code"
                  @input="updateValue($event)"
                />
              </b-td>
            </b-tr>

            <b-tr class="table-spacing-top">
              <b-th>M<sup>2</sup>/pak</b-th>
              <b-td colspan="2">
                <span v-if="product.m2_per_product">
                  {{ product.m2_per_product | formatNumber(3) }}
                </span>
                <span v-else> n.b. </span>
              </b-td>
            </b-tr>

            <b-tr>
              <b-th>Prijs/pak</b-th>
              <b-td
                colspan="2"
                :class="{ 'column-input': auth.role_id === $root.Role.ADMIN }"
              >
                <template v-if="auth.role_id !== $root.Role.ADMIN">
                  <span>
                    &euro;
                    {{ product.price | formatNumber(3) }}
                  </span>
                </template>

                <template v-else>
                  <Input
                    v-model="product.price"
                    @input="updateValue($event)"
                    type="number"
                    prepend="€"
                    step="0.001"
                    min="0"
                  />
                </template>
              </b-td>
              <b-th>Prijs/M<sup>2</sup></b-th>
              <b-td colspan="2">
                <span>
                  &euro;
                  {{
                    (product.price / product.m2_per_product) | formatNumber(3)
                  }}
                </span>
              </b-td>
            </b-tr>

            <b-tr>
              <b-th>Inkoopprijs/pak</b-th>
              <b-td
                colspan="2"
                :class="{ 'column-input': auth.role_id === $root.Role.ADMIN }"
              >
                <template v-if="auth.role_id !== $root.Role.ADMIN">
                  <span>
                    &euro;
                    {{ product.purchase_price | formatNumber(3) }}
                  </span>
                </template>

                <template v-else>
                  <Input
                    v-model="product.purchase_price"
                    @input="updateValue($event)"
                    type="number"
                    prepend="€"
                    step="0.001"
                    min="0"
                  />
                </template>
              </b-td>

              <b-th>Inkoopprijs/M<sup>2</sup></b-th>
              <b-td colspan="2">
                <span>
                  &euro;
                  {{
                    (product.purchase_price / product.m2_per_product)
                      | formatNumber(3)
                  }}
                </span>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group label="Leverancier">
          <b-select
            v-model="product.supplier_id"
            @input="updateValue($event)"
            :options="suppliers"
            value-field="id"
            text-field="name"
          >
            <template #first>
              <b-select-option selected disabled :value="null">
                Kies een leverancier
              </b-select-option>
            </template>
          </b-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="my-1">
      <b-col md="48" class="mb-1">
        <strong>Overige opties</strong>
      </b-col>
      <b-col>
        <b-form-checkbox
          switch
          v-model="product.is_presentation"
          :disabled="product.is_linked"
        >
          Presentatie middel
        </b-form-checkbox>
      </b-col>
      <b-col>
        <b-form-checkbox switch v-model="product.is_sold_per_piece">
          Verkocht per stuk
        </b-form-checkbox>
      </b-col>
      <b-col>
        <b-form-checkbox
          name="filter-show-archived"
          switch
          :checked="product.archived"
          @change="$parent.archiveProduct"
        >
          Product is gearchiveerd
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row class="mt-2" v-if="auth.role.id === $root.$root.Role.ADMIN">
      <!--
        This if check shoud be moved to the "hide_in_balance_sheet" checkbox when new fields are added. For now we hide the complete row.
      -->
      <b-col>
        <b-form-checkbox
          switch
          v-model="product.hide_in_balance_sheet"
          @change="$parent.hideInStockBalance"
        >
          Verberg in voorraad balans
        </b-form-checkbox>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    computed: {
      ...mapGetters({
        auth: "auth/user",
      }),
    },

    props: {
      product: {
        type: Object,
        default: () => {
          return {};
        },
      },

      suppliers: {
        type: Array,
        default: () => {
          return [];
        },
      },
    },

    methods: {
      updateValue(value) {
        if (value === "") {
          value = null;
        }

        this.$emit("input", value);
      },
    },
  };
</script>
