var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-overlay',{attrs:{"show":_vm.loading,"spinner-variant":"secondary","bg-color":"#fff","opacity":"1","rounded":"sm"}},[_c('b-card',{staticClass:"card-stats",attrs:{"no-body":""}},[_c('h2',{staticClass:"stat-title stat-title-scroll"},[_vm._v(" "+_vm._s(_vm.products.length)+" Best verkochte producten ")]),_c('b-card-body',[(_vm.products.length)?[_vm._l((_vm.products),function(product,index){return _c('p',{key:index,staticClass:"my-1 stat-product"},[_c('b-badge',{staticClass:"mx-2 text-monospace",attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(product.amount_sold)+" ")]),_c('router-link',{attrs:{"id":`product-stat-${index}`,"to":{
              name: 'product.show',
              params: { id: product.id },
            }}},[_vm._v(" "+_vm._s(product.name)+" ")]),_c('b-popover',{attrs:{"target":`product-stat-${index}`,"triggers":"hover","placement":"right","boundary":"window","variant":"stat"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"stat-value"},[_vm._v(" € "+_vm._s(_vm.$options.filters.formatNumber(product.revenue, 2, true))+" "),_c('span',{staticClass:"stat-value-prev"},[_vm._v(" € "+_vm._s(_vm.$options.filters.formatNumber( product.revenue_previous, 2, true ))+" ")])]),_c('span',{staticClass:"stat-icon ml-4 d-inline-block"},[_c('i',{staticClass:"far",class:{
                    'fa-arrow-trend-up text-success':
                      product.revenue > product.revenue_previous,
                    'fa-arrow-trend-down text-danger':
                      product.revenue < product.revenue_previous,
                  }})])])])],1)}),(_vm.products_meta.current_page < _vm.products_meta.last_page)?_c('b-button',{staticClass:"btn-stat-load-more",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$parent.$parent.$parent.getTopSoldProducts()}}},[_c('i',{staticClass:"far fa-plus mr-0"})]):_vm._e()]:[_vm._v(" Er zijn geen producten gevonden voor deze periode ")]],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }