<template>
  <div
    class="agenda__category"
    v-if="selectedCategories.includes(categoryId) && orders && orders[day]"
  >
    <span>Orders / Zendingen</span>
    <div>
      <b-button
        block
        variant="primary"
        size="sm"
        class="mb-3 btn-download"
        @click="downloadPackingSlips(new Date(day), $event)"
      >
        <i class="far fa-file-download" />
        Pakbonnen downloaden
        <b-spinner small class="ml-2"></b-spinner>
      </b-button>

      <agenda-item-order
        v-for="(order, index) of orders[day]"
        :key="index"
        :order="order"
      />
    </div>
  </div>
</template>

<script>
  import AgendaItemOrder from "../_AgendaItems/_Order";

  export default {
    components: {
      AgendaItemOrder,
    },

    props: {
      selectedCategories: {
        type: Array,
        default: () => {
          return [];
        },
      },

      orders: {
        type: [Object, Array],
        default: () => {
          return {};
        },
      },

      day: {
        type: String,
      },

      categoryId: {
        type: Number,
      },
    },

    methods: {
      downloadPackingSlips(day = new Date(), event) {
        event.target.disabled = true;

        let params = {
          day: day,
        };

        this.$http
          .get(`packing-slip/download-bulk`, {
            responseType: "arraybuffer",
            params: params,
          })
          .then((response) => {
            let blob = new Blob([response.data], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `Pakbonnen ${this.$options.filters.date(
              day
            )} - Floer BV`;
            link.click();

            this.$root.notify("Pakbonnen gedownload");
          })
          .then(() => {
            this.$parent.getOrders();
            event.target.disabled = false;
          });
      },

      getOrders() {
        this.$parent.getOrders();
      },
    },
  };
</script>
