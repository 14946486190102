<template>
  <div>
    <b-row class="mb-3">
      <b-col class="d-flex justify-content-end">
        <b-button
          small
          variant="light"
          @click="syncWoocommerce"
          v-b-tooltip.hover
          title="De synchronisatie duurt gemiddeld 15-25 seconden"
        >
          <i class="fas fa-sync"></i>
          Orders uit WooCommerce halen
        </b-button>
      </b-col>
    </b-row>
    <b-card header="Orders">
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Zoeken"
            description="Zoek op ordernummer, klant, product of factuurnummer"
          >
            <b-input
              type="search"
              v-model="filters.terms"
              @change="filter"
              placeholder="Zoek een order"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-table
            striped
            bordered
            hover
            show-empty
            no-local-sorting
            :items="orders"
            :fields="fields"
            :busy="loading.orders"
            @sort-changed="sort"
            @row-contextmenu="rowContextMenuHandler"
            @row-clicked="$root.rowClickRedirect($event, 'order.show')"
          >
            <template #table-busy>
              <div class="text-center">
                <b-spinner
                  variant="secondary"
                  class="align-middle mr-3"
                ></b-spinner>
                <strong class="align-middle">Orders worden geladen</strong>
              </div>
            </template>
            <template #empty="">
              <span class="text-center d-block">
                Er zijn geen orders gevonden
              </span>
            </template>
          </b-table>

          <b-pagination-nav
            use-router
            first-number
            last-number
            v-model="ordersMeta.current_page"
            :link-gen="linkGen"
            :number-of-pages="ordersMeta.last_page"
            @change="getOrders"
          >
            <template #prev-text>
              <i class="far fa-angle-left"></i>
            </template>
            <template #next-text>
              <i class="far fa-angle-right"></i>
            </template>
            <template #ellipsis-text>
              <i class="fal fa-ellipsis-h"></i>
            </template>
          </b-pagination-nav>
        </b-col>
      </b-row>
    </b-card>

    <context-menu
      :id="$route.name"
      :key="$route.fullPath"
      :display="showContextMenu"
      component="order"
      ref="menu"
    />
  </div>
</template>

<script>
  import ContextMenu from "@/components/ContextMenu";
  import { OrderStatus, OrderStatusString } from "@/enums/Order/Status";
  import { OrderChannel, OrderChannelString } from "@/enums/Order/Channel";

  export default {
    components: {
      ContextMenu,
    },

    data() {
      return {
        OrderStatus: OrderStatus,
        OrderStatusString: OrderStatusString,
        OrderChannel: OrderChannel,
        OrderChannelString: OrderChannelString,
        filters: {
          channel: null,
          status: 6,
          terms: null,
          orderBy: null,
          orderDirection: null,
        },
        showContextMenu: false,
        fields: [
          { key: "order_number", label: "Ordernummer", sortable: true },
          {
            key: "status",
            label: "Status",
            formatter: (value) => {
              return OrderStatusString[value];
            },
          },
          {
            key: "customer.billing_company",
            label: "Bedrijfsnaam",
            sortable: true,
            formatter: (value) => {
              return value || "-";
            },
          },
          {
            key: "customer.billing_formatted_name",
            label: "Klantnaam",
            sortable: true,
          },
          {
            key: "created_at",
            label: "Aangemaakt op",
            sortable: true,
            formatter: (value) => {
              return this.$options.filters.date(
                new Date(value),
                "dd/MM/yyyy HH:mm:ss"
              );
            },
          },
        ],
        orders: [],
        ordersMeta: {},
        loading: {
          orders: true,
        },
      };
    },

    mounted() {
      this.filters = this.$root.setFilters(this.filters);
      this.getOrders();
    },

    methods: {
      sort(ctx) {
        this.filters.orderBy = ctx.sortBy;
        this.filters.orderDirection = ctx.sortDesc ? "desc" : "asc";

        this.filter();
      },

      linkGen(pageNum) {
        return pageNum === 1 ? "?" : `?page=${pageNum}`;
      },

      filter() {
        this.$root.applyFilters(this.filters);
        this.getOrders();
      },

      getOrders(page = null) {
        this.loading.orders = true;

        let params = {
          ...this.filters,
          page: page
            ? this.$route.query.page
              ? this.$route.query.page
              : null
            : null,
        };

        this.$http
          .get("orders", { params: params })
          .then((response) => {
            this.orders = response.data.data;
            this.ordersMeta = response.data.meta;
          })
          .then(() => {
            this.loading.orders = false;
          });
      },

      rowContextMenuHandler(item, _, event) {
        this.$refs.menu.open(event, item);
        event.preventDefault();
      },

      syncWoocommerce() {
        this.$http.get("webhook/woocommerce/orders").then(() => {});
      },
    },
  };
</script>