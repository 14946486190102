import Axios from 'axios'

export default {
    namespaced: true,

    state: {
        user: null,
    },

    mutations: {
        SET_USER(state, data) {
            state.user = data;
        }
    },

    getters: {
        authenticated(state) {
            return state.user;
        },

        user(state) {
            return state.user;
        }
    },

    actions: {
        /**
         * Attempt authentication of client
         *
         * @param {Object} credentials
         * 
         * @author Michael Lanting <michael@nordique.nl>
         * @version 1.0.0
         */
        async signIn(context, credentials) {
            let { data } = await Axios
                .post(`login`, credentials);

            return data;
        },

        /**
         * Attempt the two factor challenge
         * 
         * @param {Object} twoFactorCode 
         * @returns 
         */
        async attemptChallenge({ dispatch }, twoFactorCode) {
            let data = {
                code: !twoFactorCode.isRecovery ? twoFactorCode.code : null,
                recovery_code: twoFactorCode.isRecovery ? twoFactorCode.code : null,
            }

            await Axios
                .post(`two-factor-challenge`, data);

            return dispatch('attempt');
        },

        /**
         * Attempt setting the token for future authentication
         *
         * @param {String} token
         * 
         * @author Michael Lanting <michael@nordique.nl>
         * @version 1.0.0
         */
        async attempt({ commit }) {
            try {
                let response = await Axios
                    .get(`auth/user`)

                commit('SET_USER', response.data.data);
            } catch (e) {
                commit('SET_USER', null);
            }
        },

        /**
         * Attempt to sign out the authenticated client
         * 
         * @author Michael Lanting <michael@nordique.nl>
         * @version 1.0.0
         */
        async signOut({ commit }) {
            return Axios.post('auth/logout').then(() => {
                commit('SET_USER', null);
            });
        }
    },
};