<template>
  <div
    class="agenda__category"
    v-if="selectedCategories.includes(categoryId) && staff && staff[monthDay]"
  >
    <span>Verjaardagen</span>
    <div>
      <AgendaBirthday
        v-for="(staffmember, index) of staff[monthDay]"
        :key="index"
        :staffmember="staffmember"
      />
    </div>
  </div>
</template>

<script>
  import AgendaBirthday from "../_AgendaItems/_AgendaBirthday";

  export default {
    components: {
      AgendaBirthday,
    },

    computed: {
      monthDay() {
        return this.day.slice(5);
      },
    },

    props: {
      selectedCategories: {
        type: Array,
        default: () => {
          return [];
        },
      },

      staff: {
        type: [Object, Array],
        default: () => {
          return {};
        },
      },

      day: {
        type: String,
      },

      categoryId: {
        type: Number,
      },
    },
  };
</script>