var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-button',{staticClass:"agenda__item agenda__item--purchase-order",class:{
    'agenda__item--purchase-order-faded': _vm.purchaseOrder.status === 3,
  },attrs:{"id":`popover-purchase-order-${_vm.purchaseOrder.id}`}},[_vm._v(" "+_vm._s(_vm.purchaseOrder.supplier.name)+" "),_c('i',{staticClass:"fas fa-check-square mr-0"}),_c('b-popover',{attrs:{"target":`popover-purchase-order-${_vm.purchaseOrder.id}`,"custom-class":"popover-menu","placement":"bottom","triggers":"blur click","boundary":"window"}},[_c('b-button-group',{attrs:{"vertical":""}},[_c('b-button',{attrs:{"variant":"primary","to":{
          name: 'purchase-order.show',
          params: {
            id: _vm.purchaseOrder.supplier.id,
            orderId: _vm.purchaseOrder.id,
          },
        },"target":"_blank"}},[_c('i',{staticClass:"far fa-external-link"}),_vm._v(" Inkooporder bekijken ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }