import { render, staticRenderFns } from "./_FinishedOrders.vue?vue&type=template&id=dc13f70c&"
import script from "./_FinishedOrders.vue?vue&type=script&lang=js&"
export * from "./_FinishedOrders.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports