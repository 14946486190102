export const AppointmentInterval = {
    DAILY: 1,
    WEEKLY: 2,
    EVERYOTHERWEEK: 3,
    MONTHLY: 4,
    QUARTERLY: 5,
    YEARLY: 6,
};

export const AppointmentIntervalString = {
    1: 'dagelijks',
    2: 'wekelijks',
    3: 'om de week',
    4: 'maandelijks',
    5: 'elke 3 maand',
    6: 'jaarlijks',
};