<template>
  <div>
    <b-table
      striped
      bordered
      hover
      responsive
      show-empty
      :items="data"
      :fields="fields"
      :busy="loading"
      @row-clicked="$root.rowClickRedirect($event, 'invoice.show')"
    >
      <template #table-busy>
        <div class="text-center">
          <b-spinner variant="secondary" class="align-middle mr-3" />
          <strong class="align-middle">Facturen worden geladen</strong>
        </div>
      </template>

      <template #empty="">
        <span class="text-center d-block">
          Er zijn geen facturen gevonden
        </span>
      </template>

      <template #cell(date)="row">
        {{ new Date(row.item.date) | date }}
      </template>
      <template #cell(actions)="row">
        <b-button
          @click="downloadInvoice(row.item)"
          variant="primary"
          size="sm"
          v-b-tooltip.hover.left="`Factuur ${row.item.number} downloaden`"
        >
          <i class="far fa-cloud-download mr-0" />
        </b-button>
      </template>
    </b-table>

    <b-pagination-nav
      use-router
      first-number
      last-number
      v-model="meta.current_page"
      :link-gen="linkGen"
      :number-of-pages="meta.last_page"
      @change="updateSentInvoices"
    >
      <template #prev-text>
        <i class="far fa-angle-left"></i>
      </template>
      <template #next-text>
        <i class="far fa-angle-right"></i>
      </template>
      <template #ellipsis-text>
        <i class="fal fa-ellipsis-h"></i>
      </template>
    </b-pagination-nav>
  </div>
</template>

<script>
  export default {
    props: {
      meta: {
        type: Object,
        default: () => {
          return {};
        },
      },

      data: {
        type: Array,
        default: () => {
          return [];
        },
      },

      loading: {
        type: Boolean,
        default: false,
      },

      customer: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },

    methods: {
      updateSentInvoices() {
        this.$emit('updateSentInvoicesTable');
      },

      linkGen(pageNum) {
        return pageNum === 1 ? "?" : `?invoices_page=${pageNum}`;
      },

      downloadInvoice(item) {
        this.$http
          .get(`invoices/${item.id}/download`, {
            responseType: "arraybuffer",
          })
          .then((response) => {
            let blob = new Blob([response.data], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `Floer ${item.number} - Floer BV`;
            link.click();
          });
      },
    },

    data() {
      return {
        fields: [
          { key: "number", label: "Factuur nummer", sortable: true },
          {
            key: "accepted_by.formatted_name",
            label: "Verstuurd door",
          },
          { key: "date", label: "Verstuurd op", sortable: true },
          { key: "actions", label: "", class: "column-fit-content" },
        ],
      };
    },
  };
</script>