<template>
  <b-overlay
    :show="loading"
    spinner-variant="secondary"
    bg-color="#fff"
    opacity="1"
    rounded="sm"
  >
    <b-card class="card-stats" no-body>
      <h2 class="stat-title stat-title-scroll">
        Takenlijst {{ checked }}/{{ items.length }}
      </h2>
      <b-card-body class="px-0 pb-0">
        <b-list-group flush>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <b-input
              class="transparant"
              v-model="task"
              placeholder="Nieuw item toevoegen"
            />
            <div class="pr-5">
              <b-button size="sm" variant="transparant" @click="storeItem">
                <i class="far fa-save mr-0" />
              </b-button>
            </div>
          </b-list-group-item>
          <b-list-group-item
            v-for="(item, index) in items"
            :key="index"
            class="d-flex justify-content-between align-items-center"
          >
            <span class="w-50">
              {{ item.title }}
            </span>

            <div class="pr-5">
              <b-badge
                v-if="item.due_date"
                class="text-monospace"
                :variant="
                  item.checked
                    ? 'success'
                    : new Date(item.due_date) < new Date()
                    ? 'danger'
                    : 'warning'
                "
              >
                {{ new Date(item.due_date) | date("dd MMMM") }}
              </b-badge>

              <b-button
                variant="transparant"
                size="sm"
                :id="`tasklist-item-${index}`"
                class="mx-2"
                tabindex="0"
                href="#"
              >
                <i class="far fa-calendar mr-0" />
              </b-button>

              <b-button
                variant="transparant"
                size="sm"
                :id="`tasklist-item-${index}`"
                class="mr-2"
                @click="deleteItem(item)"
              >
                <i class="far fa-trash-can mr-0" />
              </b-button>

              <b-checkbox
                v-model="item.checked"
                @input="updateItem(item)"
                switch
                class="d-inline-block ml-1"
              />
            </div>

            <b-popover
              :target="`tasklist-item-${index}`"
              boundary="window"
              placement="right"
              triggers="focus"
              custom-class="p-0"
              no-fade
            >
              <vc-date-picker
                title-position="left"
                show-iso-weeknumbers
                :attributes="calAttrs"
                mode="date"
                locale="nl"
                trim-weeks
                is24hr
                @input="updateItem(item)"
                v-model="item.due_date"
              />
            </b-popover>
          </b-list-group-item>
        </b-list-group>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    data() {
      return {
        loading: false,

        items: [],

        task: null,

        calAttrs: [
          {
            key: "today",
            highlight: {
              color: "blue",
              fillMode: "light",
            },
            dates: new Date(),
          },
        ],
      };
    },

    computed: {
      ...mapGetters({
        auth: "auth/user",
      }),

      checked() {
        return this.items.filter((obj) => obj.checked === true).length;
      },
    },

    methods: {
      getItems() {
        this.loading = true;

        let params = {
          user: this.auth.id,
          orderBy: "due_date",
          orderDirection: "asc",
        };

        this.$http.get("tasklist-items", { params: params }).then(({ data }) => {
          this.items = data.data;
        });

        this.loading = false;
      },

      storeItem() {
        let data = {
          title: this.task,
        };

        this.$http.post("tasklist-items", data).then(() => {
          this.task = null;
          this.getItems();
        });
      },

      updateItem(item) {
        this.$http.put(`tasklist-items/${item.id}`, item).then(() => {});
      },

      deleteItem(item) {
        this.$http.delete(`tasklist-items/${item.id}`).then(() => {
          this.items = this.items.filter((object) => {
            return object.id !== item.id;
          });
        });
      },
    },

    mounted() {
      this.getItems();
    },
  };
</script>