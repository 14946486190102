var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',{staticClass:"mb-3"},[_c('b-col',[_c('back-button')],1)],1),_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',[_c('b-card',{attrs:{"no-body":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Factuur - Order #"+_vm._s(_vm.invoice.order.order_number)+" ")]},proxy:true}])},[_c('b-card-body',[_c('Invoice',{attrs:{"invoice":_vm.invoice,"company-info":_vm.companyInfo}})],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-card',{attrs:{"header":"Acties"}},[_c('b-button',{attrs:{"variant":"secondary","block":""},on:{"click":_vm.sendInvoiceMail}},[_vm._v(" Factuur versturen ")]),(_vm.invoice.path)?_c('b-button',{attrs:{"variant":"primary","block":""},on:{"click":_vm.downloadInvoice}},[_vm._v(" Factuur downloaden ")]):_vm._e(),_c('hr'),_c('b-button',{attrs:{"variant":"danger","block":""},on:{"click":_vm.deleteInvoice}},[_vm._v(" Factuur verwijderen ")])],1),_c('b-card',{staticClass:"mt-4",attrs:{"header":"Informatie"}},[_c('b-row',[_c('b-col',[_c('p',[_c('b',[_vm._v("Factuurnummer")])]),(_vm.invoice.number)?[_vm._v(" "+_vm._s(_vm.invoice.number)+" ")]:[_c('b-badge',[_vm._v("Beschikbaar na versturen")])]],2)],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('p',[_c('b',[_vm._v("Factuurdatum")])]),(_vm.invoice.date)?[_vm._v(" "+_vm._s(_vm._f("date")(new Date(_vm.invoice.date)))+" ")]:[_c('b-badge',[_vm._v("Beschikbaar na versturen")])]],2)],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('p',[_c('b',[_vm._v("Aangemaakt door")])]),_vm._v(" "+_vm._s(_vm.invoice.created_by.formatted_name)+" ")])],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('p',[_c('b',[_vm._v("Aangemaakt op")])]),_vm._v(" "+_vm._s(_vm._f("date")(new Date(_vm.invoice.created_at)))+" ")])],1),_c('hr'),_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('p',[_c('b',[_vm._v("Exact")]),_c('i',{staticClass:"fas ml-2",class:{
                  'fa-exclamation-triangle text-warning': !_vm.invoice.exact_id,
                  'fa-check-circle text-success': _vm.invoice.exact_id,
                }})]),(!_vm.invoice.exact_id)?_c('p',[_vm._v(" Nog niet ingeboekt, dit gebeurt automatisch na het versturen van de factuur. ")]):_c('p',[_vm._v("De factuur is netjes ingeboekt in Exact.")]),(!_vm.invoice.exact_id && _vm.invoice.status === 2)?_c('b-button',{staticClass:"mt-2",attrs:{"variant":"primary","block":""},on:{"click":_vm.createExactSalesEntry}},[_vm._v(" Inboeken in Exact ")]):_vm._e()],1)],1),_c('hr'),_c('b-button',{staticClass:"btn-block mt-3",attrs:{"to":{
            name: 'order.show',
            params: { id: _vm.invoice.order.id },
          },"target":"_blank","variant":"primary"}},[_vm._v(" Order bekijken ")])],1),_c('b-card',{staticClass:"mt-4",attrs:{"header":"Kosten"}},[(_vm.invoice.status === 1)?[_c('Input',{attrs:{"label":"Verzendkosten","step":"0.01","min":"0","type":"number"},model:{value:(_vm.invoice.order.shipping_costs),callback:function ($$v) {_vm.$set(_vm.invoice.order, "shipping_costs", $$v)},expression:"invoice.order.shipping_costs"}})]:[_c('p',[_c('b',[_vm._v("Verzendkosten")])]),_c('p',[_vm._v(" € "+_vm._s(_vm._f("formatNumber")(_vm.invoice.order.shipping_costs ? _vm.invoice.order.shipping_costs : 0,2))+" ")])],(_vm.invoice.status === 1)?[_c('Input',{attrs:{"label":"Extra kosten","step":"0.01","min":"0","type":"number"},model:{value:(_vm.invoice.order.extra_costs),callback:function ($$v) {_vm.$set(_vm.invoice.order, "extra_costs", $$v)},expression:"invoice.order.extra_costs"}})]:[_c('p',{staticClass:"mt-3"},[_c('b',[_vm._v("Extra kosten")])]),_c('p',[_vm._v(" € "+_vm._s(_vm._f("formatNumber")(_vm.invoice.order.extra_costs ? _vm.invoice.order.extra_costs : 0,2))+" ")])],(_vm.invoice.status === 1)?[_c('b-form-group',{attrs:{"label":"Korting"}},[_c('b-select',{model:{value:(_vm.invoice.order.discount_type),callback:function ($$v) {_vm.$set(_vm.invoice.order, "discount_type", $$v)},expression:"invoice.order.discount_type"}},[_c('b-select-option',{attrs:{"disabled":"","value":null}},[_vm._v(" Kies een optie ")]),_c('b-select-option',{attrs:{"value":"percentage"}},[_vm._v("Percentage")]),_c('b-select-option',{attrs:{"value":"price"}},[_vm._v("Bedrag")])],1)],1),_c('Input',{attrs:{"step":"0.01","min":"0","type":"number"},model:{value:(_vm.invoice.discount),callback:function ($$v) {_vm.$set(_vm.invoice, "discount", $$v)},expression:"invoice.discount"}})]:[_c('p',{staticClass:"mt-3"},[_c('b',[_vm._v("Korting")])]),_c('p',[(
                _vm.invoice.order.discount_type === 'price' ||
                !_vm.invoice.order.discount_type
              )?[_vm._v(" € "+_vm._s(_vm._f("formatNumber")(_vm.invoice.order.discount ? _vm.invoice.order.discount : 0,2))+" ")]:[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.invoice.order.discount ? _vm.invoice.order.discount : 0,2))+" %")]],2)],(_vm.invoice.status === 1)?_c('b-button',{staticClass:"btn-block mt-3",attrs:{"variant":"primary"},on:{"click":_vm.updateOrder}},[_vm._v(" Opslaan ")]):_vm._e()],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }