<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <back-button />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <b-card header="Nieuwe leverancier">
          <b-form @submit.prevent="update">
            <Input
              :label="input.label"
              :placeholder="input.placeholder"
              :type="input.type"
              v-model="supplier[key]"
              :id="`supplier-${index}`"
              v-for="(input, key, index) in inputs"
              :key="key"
            />

            <b-button type="submit">Opslaan</b-button>
            <b-button @click="destroy" variant="primary" class="float-right">
              Verwijderen
            </b-button>
          </b-form>
        </b-card>
      </b-col>

      <b-col>
        <b-card header="Inkooporders">
          <b-row class="mb-3">
            <b-col>
              <b-form-group>
                <b-input
                  type="search"
                  v-model="filters.terms"
                  @change="filter"
                  placeholder="Zoek op factuurnummer"
                />
              </b-form-group>
            </b-col>
            <b-col cols="auto">
              <b-button
                variant="primary"
                :to="{ name: 'purchase-order.create' }"
              >
                <i class="far fa-plus mr-2" />Nieuwe inkooporder
              </b-button>
            </b-col>
          </b-row>

          <b-table
            hover
            show-empty
            small
            @row-contextmenu="rowContextMenuHandler"
            @row-clicked="rowClickHandler"
            :busy="loading"
            :items="purchaseOrders"
            :fields="fields"
            :tbody-tr-class="trClass"
          >
            <template #table-busy>
              <div class="text-center">
                <b-spinner
                  variant="secondary"
                  class="align-middle mr-3"
                ></b-spinner>
                <strong class="align-middle">
                  Inkooporders worden geladen
                </strong>
              </div>
            </template>

            <template #empty="">
              <span class="text-center d-block">
                Er zijn geen inkooporders gevonden
              </span>
            </template>

            <template #cell(created_at)="row">
              {{ new Date(row.item.created_at) | date("dd/MM/yyyy") }}
            </template>

            <template #cell(updated_at)="row">
              {{ new Date(row.item.updated_at) | date("dd/MM/yyyy") }}
            </template>

            <template #cell(delivery_date)="row">
              <template v-if="row.item.expected_delivery_date">
                {{
                  new Date(row.item.expected_delivery_date) | date("dd/MM/yyyy")
                }}
              </template>
              <template v-else>Nog niet bekend</template>
            </template>

            <template #cell(status)="row">
              {{ OrderStatusString[row.item.status] }}
            </template>
          </b-table>

          <b-pagination-nav
            use-router
            first-number
            last-number
            v-model="purchaseOrdersMeta.current_page"
            :link-gen="linkGen"
            :number-of-pages="purchaseOrdersMeta.last_page"
            @change="getPurchaseOrders()"
          >
            <template #prev-text>
              <i class="far fa-angle-left"></i>
            </template>
            <template #next-text>
              <i class="far fa-angle-right"></i>
            </template>
            <template #ellipsis-text>
              <i class="fal fa-ellipsis-h"></i>
            </template>
          </b-pagination-nav>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  import { OrderStatusString } from "@/enums/PurchaseOrder/Status";
  import BackButton from "@/components/BackButton";

  export default {
    components: { BackButton },

    data() {
      return {
        supplier: {},

        purchaseOrders: [],
        purchaseOrdersMeta: {},

        filters: {
          terms: null,
        },

        fields: [
          { key: "created_at", label: "Aangemaakt op" },
          { key: "updated_at", label: "Laatste wijziging" },
          { key: "status", label: "Status" },
          {
            key: "invoice_number",
            label: "Factuurnummer",
            formatter: (value) => {
              return value || "-";
            },
          },
          { key: "delivery_date", label: "Verwachte leverdatum" },
        ],

        inputs: {
          name: {
            label: "Naam",
            type: "text",
          },
        },

        loading: true,

        OrderStatusString: OrderStatusString,
      };
    },

    methods: {
      filter() {
        this.$root.applyFilters(this.filters);
        this.getPurchaseOrders();
      },

      linkGen(pageNum) {
        return pageNum === 1 ? "?" : `?purchase_orders_page=${pageNum}`;
      },

      get() {
        this.$http.get(`suppliers/${this.$route.params.id}`).then(({ data }) => {
          this.supplier = data.data;

          this.getPurchaseOrders();
        });
      },

      getPurchaseOrders() {
        this.loading = true;

        let params = {
          supplier: this.supplier.id,
          terms: this.filters.terms,
          page: this.$route.query.purchase_orders_page
            ? this.$route.query.purchase_orders_page
            : null,
        };

        this.$http
          .get(`purchase-orders`, { params: params })
          .then(({ data }) => {
            this.purchaseOrders = data.data;
            this.purchaseOrdersMeta = data.meta;
          })
          .then(() => {
            this.loading = false;
          });
      },

      trClass(item, type) {
        if (!item || type !== "row") return;

        if (item.invoice_number) return "row-invoiced";
        if (item.status === 3) return "row-green";
        if (item.status === 4) return "row-red";
      },

      update() {
        this.$http
          .put(`suppliers/${this.$route.params.id}`, this.supplier)
          .then(({ data }) => {
            this.supplier = data.data;

            this.$root.notify("De locatie is succesvol bijgewerkt.");
          });
      },

      destroy() {
        this.$root.showConfirm(
          "Weet je zeker dat je deze locatie wil verwijderen?",
          () => {
            this.$http
              .destroy(`suppliers/${this.supplier.id}`)
              .then(() => {
                this.$router.push({ name: "supplier.index" });
                this.$root.notify("De locatie is succesvol verwijderd.");
              })
              .catch((errors) => {
                this.$root.notify(errors.data.errors, "danger");
              });
          }
        );
      },

      rowClickHandler(record) {
        this.$router.push({
          name: "purchase-order.show",
          params: {
            id: this.supplier.id,
            orderId: record.id,
          },
        });
      },

      rowContextMenuHandler(item, _, event) {
        this.$refs.menu.open(event, item);
        event.preventDefault();
      },
    },

    mounted() {
      this.filters = this.$root.setFilters(this.filters);
      this.get();
    },
  };
</script>