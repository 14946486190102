<template>
  <b-button
    class="agenda__item"
    :class="{
      'agenda__item--regular': appointment.type === AppointmentType.REGULAR,
      'agenda__item--staff': appointment.type === AppointmentType.STAFF,
      'agenda__item--showroom': appointment.type === AppointmentType.SHOWROOM,
      'agenda__item--approval-required':
        appointment.type === AppointmentType.APPROVAL_REQUIRED,
    }"
    :id="`popover-appointment-${appointment.id}`"
  >
    <span>
      {{ appointment.title }}
      <template v-if="appointment.note"> - {{ appointment.note }} </template>
    </span>

    <b-popover
      v-if="auth.role.id === $root.Role.ADMIN"
      :target="`popover-appointment-${appointment.id}`"
      custom-class="popover-menu"
      placement="bottom"
      triggers="blur click"
      boundary="window"
    >
      <b-button-group vertical>
        <b-button
          variant="primary"
          @click="$parent.$parent.$parent.$parent.openUpdateModal(appointment)"
        >
          <i class="far fa-pencil" />
          Afspraak bewerken
        </b-button>
        <b-button
          variant="primary"
          @click="
            $parent.$parent.$parent.$parent.deleteAppointment(appointment)
          "
        >
          <i class="far fa-trash-alt" />
          Afspraak verwijderen
        </b-button>
        <b-button
          v-if="appointment.group"
          variant="primary"
          @click="
            $parent.$parent.$parent.$parent.deleteAppointment(appointment, true)
          "
        >
          <i class="far fa-calendar-alt" />
          Afspraak en volgende verwijderen
        </b-button>
      </b-button-group>

      <div class="popover-content">
        <template v-if="appointment.group">
          <p>
            Wordt
            <strong>
              {{ AppointmentIntervalString[appointment.interval] }}
            </strong>
            herhaald t/m
            <strong>
              {{ new Date(appointment.end_date) | date("d MMMM Y") }}
            </strong>
          </p>
        </template>

        <p v-if="appointment.author">
          <strong>Door:</strong>
          {{ appointment.author.formatted_name }}
        </p>
      </div>
    </b-popover>
  </b-button>
</template>

<script>
  import {
    AppointmentTypeString,
    AppointmentType,
  } from "@/enums/Appointment/Type";
  import { AppointmentIntervalString } from "@/enums/Appointment/Interval";

  import { mapGetters } from "vuex";

  export default {
    props: {
      appointment: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },

    data() {
      return {
        AppointmentType: AppointmentType,
        AppointmentTypeString: AppointmentTypeString,
        AppointmentIntervalString: AppointmentIntervalString,
      };
    },

    computed: {
      ...mapGetters({
        auth: "auth/user",
      }),
    },

    methods: {},
  };
</script>