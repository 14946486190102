<template>
  <div>
    <b-card>
      <template #header>
        Producten

        <div class="d-flex align-content-center justify-content-end">
          <b-form-group class="mb-0 d-inline-block mr-2">
            <b-button
              variant="transparant"
              size="sm"
              @click="selectFields = !selectFields"
            >
              <i class="far fa-eye-slash" />Toon / verberg kolommen
            </b-button>

            <template v-if="selectFields">
              <div class="v-select search-select">
                <div class="v-dropdown-container pt-0">
                  <b-form-group class="mb-0">
                    <template v-for="field in fields">
                      <b-checkbox
                        v-if="field.label"
                        :key="field.key"
                        v-model="field.visible"
                        class="m-2"
                      >
                        {{ field.label }}
                      </b-checkbox>
                    </template>
                  </b-form-group>
                </div>
              </div>
            </template>
          </b-form-group>

          <b-button id="filter-button" variant="transparant" size="sm">
            <i class="far fa-filter" />Filters
          </b-button>
        </div>

        <b-popover target="filter-button" triggers="focus" placement="bottom">
          <b-form-checkbox
            v-model="filters.show_archived"
            name="filter-show-archived"
            @change="filter"
            switch
          >
            Gearchiveerde producten
          </b-form-checkbox>
          <hr class="my-1" />
          <b-form-checkbox
            v-model="filters.show_exluded_from_report"
            name="filter-show-exluded-from-report"
            @change="filter"
            switch
          >
            Uitgesloten voor rapportage
          </b-form-checkbox>
          <hr class="my-1" />
          <b-form-checkbox
            v-model="filters.show_stock_warning_ignored"
            name="filter-show-stock-warning-ignored"
            @change="filter"
            switch
          >
            Voorraad melding uitgeschakeld
          </b-form-checkbox>
        </b-popover>
      </template>

      <b-row>
        <b-col md="12">
          <b-form-group
            label="Zoeken"
            description="Zoek op SKU, naam of omschrijving"
          >
            <b-input
              type="search"
              v-model="filters.terms"
              @change="filter"
              placeholder="Zoek een product"
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group label="Categorie" description="Kies een categorie">
            <b-form-select
              v-model="filters.category"
              :options="categories"
              text-field="name"
              value-field="id"
              @change="filter"
            >
              <template v-slot:first>
                <b-form-select-option :value="null" selected>
                  Alles
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
            label="Kanaal"
            description="Kies een kanaal waar het product uit komt"
          >
            <b-form-select v-model="filters.channel" @change="filter">
              <b-form-select-option :value="null" selected>
                Alle kanalen
              </b-form-select-option>
              <b-form-select-option :value="OrderChannel.WOOCOMMERCE">
                WooCommerce
              </b-form-select-option>
              <b-form-select-option :value="OrderChannel.GADERO">
                Gadero
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
            label="Voorraad"
            description="Kies een bepaalde status van de voorraad"
          >
            <b-form-select v-model="filters.stock" @change="filter">
              <b-form-select-option :value="null" selected>
                Alle producten
              </b-form-select-option>
              <b-form-select-option value="low_stock">
                Producten met een lage voorraad
              </b-form-select-option>
              <b-form-select-option value="in_stock">
                Producten met voldoende voorraad
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-table
            striped
            bordered
            hover
            show-empty
            no-local-sorting
            :items="products"
            :fields="visibleFields"
            :busy="loading.products"
            @sort-changed="sort"
            @row-contextmenu="rowContextMenuHandler"
            @row-clicked="$root.rowClickRedirect($event, 'product.show')"
          >
            <template #cell(available_stock)="row">
              {{ row.item.available_stock }}
              <span class="ml-2" v-if="!row.item.is_sold_per_piece">
                ({{
                  (row.item.available_stock * row.item.m2_per_product)
                    | formatNumber(0)
                }}
                m<sup>2</sup>)
              </span>
            </template>

            <template #cell(incoming_stock)="row">
              {{ row.item.incoming_stock }}
              <span class="ml-2" v-if="!row.item.is_sold_per_piece">
                ({{
                  (row.item.incoming_stock * row.item.m2_per_product)
                    | formatNumber(0)
                }}
                m<sup>2</sup>)
              </span>
            </template>

            <template #cell(reserved_stock)="row">
              {{ row.item.reserved_stock }}
              <span class="ml-2" v-if="!row.item.is_sold_per_piece">
                ({{
                  (row.item.reserved_stock * row.item.m2_per_product)
                    | formatNumber(0)
                }}
                m<sup>2</sup>)
              </span>
            </template>

            <template #cell(physical_stock)="row">
              {{ row.item.physical_stock }}
              <span class="ml-2" v-if="!row.item.is_sold_per_piece">
                ({{
                  (row.item.physical_stock * row.item.m2_per_product)
                    | formatNumber(0)
                }}
                m<sup>2</sup>)
              </span>
              <i
                v-if="
                  !row.item.ignore_stock_warning &&
                  row.item.physical_stock + row.item.incoming_stock <
                    row.item.stock_warning_threshold
                "
                :id="`warning-${row.item.id}`"
                v-b-tooltip.hover.right="
                  `De voorraad zit onder de grens van
                ${row.item.stock_warning_threshold}`
                "
                class="fas fa-exclamation-triangle text-danger ml-2"
              />
            </template>

            <template #cell(amount_sold)="row">
              {{ row.item.amount_sold }}
              <span class="ml-2" v-if="!row.item.is_sold_per_piece">
                ({{
                  (row.item.amount_sold * row.item.m2_per_product)
                    | formatNumber(0)
                }}
                m<sup>2</sup>)
              </span>
            </template>

            <template #table-busy>
              <div class="text-center">
                <b-spinner
                  variant="secondary"
                  class="align-middle mr-3"
                ></b-spinner>
                <strong class="align-middle">Producten worden geladen</strong>
              </div>
            </template>
            <template #empty="">
              <span class="text-center d-block">
                Er zijn geen producten gevonden
              </span>
            </template>
          </b-table>

          <b-pagination-nav
            use-router
            first-number
            last-number
            v-model="productsMeta.current_page"
            :link-gen="linkGen"
            :number-of-pages="productsMeta.last_page"
            @change="getProducts"
          >
            <template #prev-text>
              <i class="far fa-angle-left"></i>
            </template>
            <template #next-text>
              <i class="far fa-angle-right"></i>
            </template>
            <template #ellipsis-text>
              <i class="fal fa-ellipsis-h"></i>
            </template>
          </b-pagination-nav>
        </b-col>
      </b-row>
    </b-card>

    <context-menu
      :id="$route.name"
      :key="$route.fullPath"
      :display="showContextMenu"
      component="product"
      ref="menu"
    />
  </div>
</template>

<script>
  import ContextMenu from "@/components/ContextMenu";
  import { OrderChannel } from "@/enums/Order/Channel";

  export default {
    components: {
      ContextMenu,
    },

    data() {
      return {
        OrderChannel: OrderChannel,
        filters: {
          stock: null,
          category: null,
          channel: OrderChannel.WOOCOMMERCE,
          terms: null,
          orderBy: "sku",
          orderDirection: "ASC",
          show_archived: false,
          show_exluded_from_report: false,
          show_stock_warning_ignored: false,
        },

        showContextMenu: false,
        fields: [
          {
            key: "sku",
            label: "SKU",
            sortable: true,
            visible: true,
          },
          {
            key: "name",
            label: "Naam",
            visible: true,
          },
          {
            key: "physical_stock",
            label: "Fysieke voorraad",
            sortable: true,
            visible: true,
          },
          {
            key: "reserved_stock",
            label: "Gereserveerde voorraad",
            sortable: true,
            visible: true,
          },
          {
            key: "available_stock",
            label: "Beschikbare voorraad",
            visible: true,
          },
          {
            key: "incoming_stock",
            label: "Verwachte leveringen",
            visible: true,
          },
          {
            key: "amount_sold",
            label: "Verkocht afgelopen jaar",
            visible: true,
          },
        ],

        categories: [],
        products: [],
        productsMeta: {},

        selectFields: false,

        loading: {
          products: false,
        },
      };
    },

    computed: {
      visibleFields: {
        get: function () {
          return this.fields.filter((field) => field.visible);
        },
        set: function (value) {
          this.fields = value;
        },
      },
    },

    watch: {
      visibleFields: {
        handler() {
          localStorage.setItem("productsFields", JSON.stringify(this.fields));
        },
      },
    },

    mounted() {
      this.filters = this.$root.setFilters(this.filters);
      this.getCategories();
      this.getProducts();
    },

    created() {
      if (localStorage.getItem("productsFields").length !== 0) {
        this.fields = JSON.parse(localStorage.getItem("productsFields"));
      }
    },

    methods: {
      sort(ctx) {
        this.filters.orderBy = ctx.sortBy;
        this.filters.orderDirection = ctx.sortDesc ? "desc" : "asc";
        this.filter();
      },

      linkGen(pageNum) {
        return pageNum === 1 ? "?" : `?page=${pageNum}`;
      },

      filter() {
        this.$root.applyFilters(this.filters);
        this.getProducts();
      },

      getProducts(page = null) {
        this.loading.products = true;

        let params = {
          ...this.filters,
          page: page
            ? this.$route.query.page
              ? this.$route.query.page
              : null
            : null,
        };

        this.$http
          .get("products", {
            params: params,
          })
          .then((response) => {
            this.products = response.data.data;
            this.productsMeta = response.data.meta;
          })
          .then(() => {
            this.loading.products = false;
          });
      },

      getCategories() {
        let params = {
          orderBy: "name",
          orderDirection: "asc",
        };

        this.$http
          .get("product-categories", { params: params })
          .then((response) => {
            this.categories = response.data.data;
          });
      },

      rowContextMenuHandler(item, _, event) {
        this.$refs.menu.open(event, item);
        event.preventDefault();
      },
    },
  };
</script>