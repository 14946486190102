<template>
  <div>
    <CreditNotesTable
      :data="credit_notes"
      :meta="credit_notes_meta"
      :loading="loading"
      :customer="customer"
      @updateCreditNotesTable="getCreditNotes()"
    />
  </div>
</template>

<script>
  import CreditNotesTable from "../_CreditNotesTable";

  export default {
    components: {
      CreditNotesTable,
    },
    props: {
      customer: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
    data() {
      return {
        loading: false,
        credit_notes: [],
        credit_notes_meta: {
          total: 0,
        },
      };
    },
    methods: {
      getCreditNotes() {
        this.loading = true;

        let params = {
          customer: this.$route.params.id,
          page: this.$route.query.credit_notes_page
            ? this.$route.query.credit_notes_page
            : null,
          status: 2,
        };

        this.$http
          .get("credit-notes", { params: params })
          .then((response) => {
            this.credit_notes = response.data.data;
            this.credit_notes_meta = response.data.meta;
          })
          .then(() => {
            this.loading = false;
          });
      },
    },
    mounted() {
      this.getCreditNotes();
    },
  };
</script>