export const PaymentMethod = {
  FOURTEEN_DAYS : 0,
  DIRECT_DEBIT: 1,
  THIRTY_DAYS: 2,
};

export const PaymentMethodString = {
  0: 'FOURTEEN_DAYS',
  1: 'DIRECT_DEBIT',
  2: 'THIRTY_DAYS',
};