<template>
  <div>
    <div class="floer-windmill">
      <div class="windmill-wrapper">
        <div class="windmill">
          <div class="tower">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                 x="0px" y="0px" viewBox="0 0 225.972 379.952"
                 style="enable-background:new 0 0 225.972 379.952;"
                 xml:space="preserve">

                                  <g id="XMLID_1_">
                                      <path id="XMLID_39_" class="part-1" d="M214.973,379.952c-67.991,0-135.983,0-203.974,0c-9.64-3.619-10.583-5.522-9.706-15.954
                                        c1.199-14.262,1.989-28.558,3.013-42.836c1.868-26.056,3.843-52.104,5.65-78.164c1.52-21.911,2.798-43.839,4.304-65.751
                                        c2.191-31.866,4.448-63.727,6.85-95.577c0.153-2.03,1.315-4.503,2.831-5.832c26.79-23.495,53.895-46.634,80.483-70.354
                                        c7.955-7.096,12.154-4.764,17.86,0.346c26.55,23.778,53.221,47.421,79.738,71.235c1.512,1.358,2.758,3.75,2.92,5.758
                                        c1.241,15.418,2.111,30.865,3.232,46.294c1.892,26.039,3.917,52.068,5.823,78.105c1.785,24.393,3.515,48.79,5.221,73.188
                                        c1.96,28.036,3.962,56.069,5.747,84.116C225.63,375.001,224.487,376.381,214.973,379.952z M204.491,358.733
                                        c-6.398-88.675-12.776-177.068-19.159-265.536c-48.474,0-96.372,0-144.695,0c-6.393,88.639-12.767,177.02-19.139,265.369
                                        c17.275,0,33.815,0,50.515,0c0-16.73-0.072-33.051,0.019-49.372c0.102-18.269,10.715-33.892,26.689-39.559
                                        c27.365-9.707,54.746,9.46,55.197,38.807c0.23,14.989,0.043,29.984,0.044,44.976c0,1.759,0,3.517,0,5.315
                                        C171.083,358.733,187.508,358.733,204.491,358.733z M133.01,358.767c0-18.53,0.403-36.673-0.311-54.773
                                        c-0.159-4.031-3.529-8.658-6.687-11.691c-5.894-5.66-13.475-6.331-20.947-3.273c-7.552,3.091-12.042,9.011-12.216,17.279
                                        c-0.327,15.489-0.225,30.986-0.298,46.48c-0.009,1.928-0.001,3.855-0.001,5.978C106.362,358.767,119.472,358.767,133.01,358.767z
                                         M167.277,71.909c-18.713-16.396-36.441-31.93-54.318-47.594C94.814,40.246,77.157,55.749,58.751,71.909
                                        C95.33,71.909,130.691,71.909,167.277,71.909z"></path>

                                    <path id="XMLID_35_" class="part-2" d="M204.491,358.733c-16.983,0-33.408,0-50.53,0c0-1.798,0-3.556,0-5.315
                                        c-0.001-14.992,0.187-29.987-0.044-44.976c-0.451-29.347-27.832-48.514-55.197-38.807c-15.974,5.666-26.587,21.29-26.689,39.559
                                        c-0.091,16.32-0.019,32.642-0.019,49.372c-16.7,0-33.24,0-50.515,0c6.372-88.349,12.746-176.73,19.139-265.369
                                        c48.323,0,96.222,0,144.695,0C191.715,181.665,198.093,270.058,204.491,358.733z M122.767,174.571c0-10.235,0-20.219,0-30.278
                                        c-6.716,0-13.097,0-19.457,0c0,10.283,0,20.267,0,30.278C109.993,174.571,116.234,174.571,122.767,174.571z"></path>
                                    <path id="XMLID_34_" class="part-3" d="M133.01,358.767c-13.538,0-26.648,0-40.461,0c0-2.123-0.008-4.051,0.001-5.978
                                        c0.073-15.494-0.029-30.992,0.298-46.48c0.175-8.267,4.664-14.188,12.216-17.279c7.471-3.058,15.053-2.387,20.947,3.273
                                        c3.158,3.033,6.528,7.66,6.687,11.691C133.413,322.094,133.01,340.237,133.01,358.767z"></path>
                                    <path id="XMLID_33_" class="part-3" d="M167.277,71.909c-36.586,0-71.946,0-108.526,0c18.406-16.16,36.063-31.663,54.207-47.594
                                        C130.835,39.979,148.564,55.513,167.277,71.909z"></path>
                                    <path id="XMLID_32_" class="part-1" d="M122.767,174.571c-6.532,0-12.774,0-19.457,0c0-10.011,0-19.995,0-30.278c6.36,0,12.74,0,19.457,0
                                        C122.767,154.352,122.767,164.337,122.767,174.571z"></path>
                                  </g>
                                  </svg>


          </div>
          <div class="blades">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="35px"
                 viewBox="0 0 72 72" enable-background="new 0 0 72 72"
                 xml:space="preserve">
                                  <g>
                                      <g>
                                          <g>
                                              <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="63.361"
                                                    y1="8.281"
                                                    x2="35.328" y2="36.314"></line>

                                            <rect x="45.723" y="3.454"
                                                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 28.7982 -31.7865)"
                                                  fill="none" stroke="#FFFFFF" stroke-miterlimit="10" width="14.097"
                                                  height="30.835"></rect>
                                            <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="55.885"
                                                  y1="6.412"
                                                  x2="65.23" y2="15.757"></line>
                                            <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="52.77"
                                                  y1="9.526"
                                                  x2="62.115" y2="18.871"></line>
                                            <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="49.656"
                                                  y1="12.642"
                                                  x2="59" y2="21.986"></line>
                                            <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="46.541"
                                                  y1="15.757"
                                                  x2="55.885" y2="25.1"></line>
                                            <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="43.426"
                                                  y1="18.871"
                                                  x2="52.77" y2="28.215"></line>
                                            <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="40.311"
                                                  y1="21.986"
                                                  x2="49.656" y2="31.33"></line>
                                          </g>
                                        <g>
                                              <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="7.294"
                                                    y1="64.341"
                                                    x2="35.328" y2="36.314"></line>
                                          <polygon fill="none" stroke="#FFFFFF" stroke-miterlimit="10" points="23.803,37.871 33.771,47.833 11.967,69.637 2,59.669
                                        "></polygon>
                                          <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="14.771"
                                                y1="66.211"
                                                x2="5.426" y2="56.865"></line>
                                          <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="17.885"
                                                y1="63.096"
                                                x2="8.541" y2="53.751"></line>
                                          <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="21" y1="59.98"
                                                x2="11.656" y2="50.637"></line>
                                          <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="24.114"
                                                y1="56.865"
                                                x2="14.771" y2="47.521"></line>
                                          <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="27.229"
                                                y1="53.751"
                                                x2="17.885" y2="44.407"></line>
                                          <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="30.345"
                                                y1="50.637"
                                                x2="21" y2="41.297"></line>
                                          </g>
                                      </g>
                                    <g>
                                          <g>
                                              <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="64.607"
                                                    y1="63.718"
                                                    x2="36.574" y2="35.691"></line>
                                            <polygon fill="none" stroke="#FFFFFF" stroke-miterlimit="10" points="38.131,47.21 48.1,37.248 69.902,59.047 59.934,69.014
                                        "></polygon>
                                            <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="66.477"
                                                  y1="56.242"
                                                  x2="57.131" y2="65.588"></line>
                                            <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="63.361"
                                                  y1="53.128"
                                                  x2="54.016" y2="62.473"></line>
                                            <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="60.246"
                                                  y1="50.014"
                                                  x2="50.902" y2="59.358"></line>
                                            <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="57.131"
                                                  y1="46.898"
                                                  x2="47.787" y2="56.242"></line>
                                            <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="54.016"
                                                  y1="43.784"
                                                  x2="44.672" y2="53.128"></line>
                                            <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="50.902"
                                                  y1="40.674"
                                                  x2="41.559" y2="50.014"></line>
                                          </g>
                                      <g>
                                              <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="8.541"
                                                    y1="7.658"
                                                    x2="36.574" y2="35.691"></line>

                                        <rect x="12.083" y="2.831"
                                              transform="matrix(0.7071 -0.7071 0.7071 0.7071 -7.2999 18.8725)"
                                              fill="none"
                                              stroke="#FFFFFF" stroke-miterlimit="10" width="14.097"
                                              height="30.834"></rect>
                                        <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="6.672" y1="15.135"
                                              x2="16.017" y2="5.789"></line>
                                        <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="9.787" y1="18.248"
                                              x2="19.131" y2="8.903"></line>
                                        <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="12.901"
                                              y1="21.363"
                                              x2="22.246" y2="12.02"></line>
                                        <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="16.017"
                                              y1="24.478"
                                              x2="25.361" y2="15.135"></line>
                                        <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="19.131"
                                              y1="27.593"
                                              x2="28.475" y2="18.248"></line>
                                        <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="22.246"
                                              y1="30.707"
                                              x2="31.59" y2="21.363"></line>
                                          </g>
                                      </g>
                                  </g>
                                </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {

}
</script>