<template>
  <b-button
    class="agenda__item agenda__item--birthday"
    :id="`popover-staffmember-${staffmember.id}`"
  >
    🎂 {{ staffmember.name }}

    <b-popover
      :target="`popover-staffmember-${staffmember.id}`"
      custom-class="popover-menu"
      placement="bottom"
      triggers="blur click"
      boundary="window"
    >
      <b-button-group vertical>
        <b-button
          variant="primary"
          :to="{
            name: 'customer.show',
            params: {
              id: staffmember.customer.id,
              tab: 'personeel',
            },
          }"
          target="_blank"
        >
          <i class="far fa-external-link" />
          Personeel {{ staffmember.customer.billing_formatted_name }} bekijken
        </b-button>
      </b-button-group>
    </b-popover>
  </b-button>
</template>

<script>
  export default {
    props: {
      staffmember: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
  };
</script>