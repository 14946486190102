<template>
  <b-card>
    <b-row>
      <b-col>
        <h2>Whitelist</h2>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <strong>IP adres toevoegen</strong>
        <b-row class="mt-2">
          <b-col>
            <Input
              placeholder="Beschrijving"
              v-model="whitelistitem.description"
            />
          </b-col>
          <b-col>
            <Input placeholder="IP adres" v-model="whitelistitem.value" />
          </b-col>
        </b-row>
        <b-button variant="primary" @click="storeWhitelist">Opslaan</b-button>
        <a
          href="https://www.whatismyip.com/"
          target="_blank"
          class="btn btn-transparant ml-2"
          >Wat is mijn IP adres?</a
        >
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col>
        <b-table
          striped
          hover
          small
          bordered
          :fields="fields"
          :items="whitelist"
        >
          <template #cell(actions)="row">
            <b-button
              variant="primary"
              v-b-tooltip.hover.left="`Deze whitelist verwijderen?`"
              @click="deleteWhitelist(row.item)"
            >
              <i class="far fa-trash-alt mr-0"></i>
            </b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
  export default {
    data() {
      return {
        laoding: false,

        fields: [
          {
            key: "description",
            label: "Beschrijving",
            sortable: true,
          },
          {
            key: "value",
            label: "IP adres",
            sortable: true,
          },
          {
            key: "actions",
            label: "",
            tdClass: "column-fit-content",
          },
        ],

        whitelist: [],
        whitelistitem: {},
      };
    },

    methods: {
      getWhitelist() {
        this.loading = false;

        this.$http
          .get(`whitelist`)
          .then((response) => {
            this.whitelist = response.data.data;
          })
          .then(() => {
            this.loading = false;
          });
      },

      storeWhitelist() {
        let data = { ...this.whitelistitem };

        this.$http.post(`whitelist`, data).then((response) => {
          this.whitelistitem = {};
          this.whitelist.push(response.data.data);

          this.$root.notify("De whitelist is succesvol toegevoegd.");
        });
      },

      deleteWhitelist(item) {
        this.$root.showConfirm(
          "Weet je zeker dat je deze whitelist wil verwijderen?",
          () => {
            this.$http.delete(`whitelist/${item.id}`).then(() => {
              this.whitelist = this.whitelist.filter((existingItem) => {
                return existingItem.id !== item.id;
              });

              this.$root.notify("De whitelist is succesvol verwijderd.");
            });
          }
        );
      },
    },

    mounted() {
      this.getWhitelist();
    },
  };
</script>