export const OrderStatus = {
    PENDING: 1,
    INCOMING: 2,
    RECEIVED: 3,
    CANCELLED: 4,
};

export const OrderStatusString = {
    1: 'In afwachting',
    2: 'Inkomend',
    3: 'Ontvangen',
    4: 'Geannuleerd',
};