<template>
  <div>
    <div>
      <b-sidebar
        id="navigation"
        class="navigation-bar"
        no-close-on-route-change
        no-header
        visible
        v-model="$parent.expanded"
        width="300px"
      >
        <template #footer="{ hide }">
          <b-button variant="primary" @click="hide">
            <i class="far fa-toggle-off" />Menu inklappen
          </b-button>
        </template>

        <div class="logo-wrapper">
          <router-link :to="{ name: 'dashboard' }">
            <img class="login__logo" :src="`/${logo}`" alt="Floer" />
          </router-link>
        </div>
        <div class="navigation-items-wrapper">
          <template v-for="(item, index) in menuItems">
            <div :key="index" class="text-info">
              <router-link
                v-if="
                  auth.role.id !== $root.Role.ADMIN
                    ? item.admin
                      ? false
                      : true
                    : true
                "
                :key="index"
                :to="item.link"
                :exact="item.exact"
              >
                <i :class="`fal fa-${item.icon}`"></i>
                {{ item.title }}
                <template v-if="item.title === 'Orders'">
                  <div class="navigation-item-counter" :id="`item-${index}`">
                    {{ orderCount }}
                  </div>
                  <b-tooltip
                    :target="`item-${index}`"
                    placement="right"
                    boundary="window"
                    triggers="hover"
                  >
                    <template v-if="orderCount === 1">
                      Er is {{ orderCount }} order waarvan de leverdatum vandaag
                      is of is verstreken
                    </template>
                    <template v-else>
                      Er zijn {{ orderCount }} orders waarvan de leverdatum
                      vandaag is of is verstreken
                    </template>
                  </b-tooltip>
                </template>
                <template v-if="item.title === 'Producten'">
                  <div class="navigation-item-counter" :id="`item-${index}`">
                    {{ productCount }}
                  </div>
                  <b-tooltip
                    :target="`item-${index}`"
                    placement="right"
                    boundary="window"
                    triggers="hover"
                  >
                    <template v-if="productCount === 1">
                      Er is {{ productCount }} product met een te lage voorraad
                    </template>
                    <template v-else>
                      Er zijn {{ productCount }} producten met een te lage
                      voorraad
                    </template>
                  </b-tooltip>
                </template>
                <template v-if="item.title === 'Facturatie'">
                  <div class="navigation-item-counter" :id="`item-${index}`">
                    {{ invoiceCount }}
                  </div>
                  <b-tooltip
                    :target="`item-${index}`"
                    placement="right"
                    boundary="window"
                    triggers="hover"
                  >
                    <template v-if="invoiceCount === 1">
                      Er is {{ invoiceCount }} openstaande factuur
                    </template>
                    <template v-else>
                      Er zijn {{ invoiceCount }} openstaande facturen
                    </template>
                  </b-tooltip>
                </template>
                <template v-if="item.title === 'Order wachtrij'">
                  <div class="navigation-item-counter" :id="`item-${index}`">
                    {{ orderWaitinglistCount }}
                  </div>
                  <b-tooltip
                    :target="`item-${index}`"
                    placement="right"
                    boundary="window"
                    triggers="hover"
                  >
                    <template v-if="orderWaitinglistCount === 1">
                      Er is {{ orderWaitinglistCount }} inkomende order
                    </template>
                    <template v-else>
                      Er zijn {{ orderWaitinglistCount }} inkomende orders
                    </template>
                  </b-tooltip>
                </template>
              </router-link>
            </div>
          </template>
        </div>
        <hr />
        <div class="navigation-items-wrapper">
          <router-link :to="{ name: 'user.show', params: { id: auth.id } }">
            <i class="fal fa-user-edit"></i> {{ auth.formatted_name }}
          </router-link>
          <b-link @click="signOut">
            <i class="fal fa-sign-out"></i> Uitloggen
          </b-link>
        </div>
      </b-sidebar>
    </div>

    <div>
      <div class="sidebar-small">
        <div class="logo-wrapper">
          <router-link :to="{ name: 'dashboard' }">
            <img class="login__logo" :src="`/${logoSmall}`" alt="Floer" />
          </router-link>
        </div>

        <div class="navigation-items-wrapper">
          <div v-for="(item, index) in menuItems" :key="index">
            <router-link
              class="sidebar-small__navigation-item"
              v-b-tooltip.hover.right
              :title="item.title"
              v-if="
                auth.role.id !== $root.Role.ADMIN
                  ? item.admin
                    ? false
                    : true
                  : true
              "
              :to="item.link"
              :exact="item.exact"
            >
              <i :class="`fal fa-${item.icon}`" />

              <b-badge
                pill
                v-if="item.title === 'Producten'"
                variant="danger"
                class="navigation-item-counter"
                :id="`item-${index}`"
              >
                {{ productCount }}
              </b-badge>
              <b-badge
                pill
                v-if="item.title === 'Facturatie'"
                variant="danger"
                class="navigation-item-counter"
                :id="`item-${index}`"
              >
                {{ invoiceCount }}
              </b-badge>
              <b-badge
                pill
                v-if="item.title === 'Order wachtrij'"
                variant="danger"
                class="navigation-item-counter"
                :id="`item-${index}`"
              >
                {{ orderWaitinglistCount }}
              </b-badge>
              <b-badge
                pill
                v-if="item.title === 'Orders'"
                variant="danger"
                class="navigation-item-counter"
                :id="`item-${index}`"
              >
                {{ orderCount }}
              </b-badge>
            </router-link>
          </div>
          <router-link
            :to="{ name: 'user.show', params: { id: auth.id } }"
            class="sidebar-small__navigation-item"
            v-b-tooltip.hover.right
            title="Profiel bewerken"
          >
            <i class="fal fa-user-edit" />
          </router-link>
          <b-link
            class="sidebar-small__navigation-item"
            @click="signOut"
            v-b-tooltip.hover.right
            title="Uitloggen"
          >
            <i class="fal fa-sign-out" />
          </b-link>
        </div>
        <b-link
          class="navigation-toggle"
          aria-controls="navigation"
          :aria-expanded="$parent.expanded"
          @click="$parent.expanded = !$parent.expanded"
        >
          <i class="far fa-toggle-on" />
        </b-link>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import { OrderChannel } from "@/enums/Order/Channel";
  import { OrderStatus } from "@/enums/Order/Status";

  export default {
    data() {
      return {
        OrderChannel: OrderChannel,
        OrderStatus: OrderStatus,

        productCount: null,
        invoiceCount: null,
        orderCount: null,
        orderWaitinglistCount: null,

        logo: "logo.svg",
        logoSmall: "logo_small.svg",

        menuItems: [
          {
            title: "Producten",
            icon: "tag",
            link: {
              name: "product.index",
            },
            exact: false,
            admin: false,
          },
          {
            title: "Locaties",
            icon: "map-marker-smile",
            link: {
              name: "location.index",
            },
            exact: false,
            admin: false,
          },
          {
            title: "Orders",
            icon: "clipboard",
            link: {
              name: "order.index",
            },
            exact: false,
            admin: false,
          },
          {
            title: "Order wachtrij",
            icon: "clock",
            link: {
              name: "queued-order.index",
            },
            exact: true,
            admin: false,
          },
          {
            title: "Proefmonster orders",
            icon: "clipboard-list",
            link: {
              name: "sample-order.index",
            },
            exact: true,
            admin: false,
          },
          {
            title: "Agenda",
            icon: "calendar",
            link: {
              name: "agenda",
            },
            exact: true,
            admin: false,
          },
          {
            title: "Facturatie",
            icon: "file-invoice-dollar",
            link: {
              name: "invoice.index",
            },
            exact: false,
            admin: true,
          },
          {
            title: "Klanten",
            icon: "users",
            link: {
              name: "customer.index",
            },
            exact: false,
            admin: false,
          },
          {
            title: "Leveranciers",
            icon: "shuttle-van",
            link: {
              name: "supplier.index",
            },
            exact: false,
            admin: false,
          },
          {
            title: "Gebruikers",
            icon: "users-cog",
            link: {
              name: "user.index",
            },
            exact: false,
            admin: true,
          },
          {
            title: "Management",
            icon: "cog",
            link: {
              name: "management.index",
            },
            exact: false,
            admin: true,
          },
        ],
      };
    },

    computed: {
      ...mapGetters({
        auth: "auth/user",
      }),
    },

    watch: {
      "$route.path"() {
        this.getOrders();
        this.getProducts();
        this.getInvoices();
      },
    },

    methods: {
      ...mapActions({
        signOutAction: "auth/signOut",
      }),

      getProducts() {
        let params = {
          stock: "low_stock",
          channel: OrderChannel.WOOCOMMERCE,
        };

        this.$http
          .get("products/count", {
            params: params,
          })
          .then(({ data }) => {
            this.productCount = data;
          });
      },

      getOrders() {
        let params = {
          status: OrderStatus.PROCESSING,
          deliveryDatePassed: true,
        };

        this.$http
          .get("orders/count", {
            params: params,
          })
          .then(({ data }) => {
            this.orderCount = data;
          });
      },

      getWaitinglistOrders() {
        let params = {
          status: OrderStatus.ON_HOLD,
        };

        this.$http
          .get("orders/count", {
            params: params,
          })
          .then(({ data }) => {
            this.orderWaitinglistCount = data;
          });
      },

      getInvoices() {
        let params = {
          status: 1,
        };

        this.$http
          .get("invoices/count", {
            params: params,
          })
          .then(({ data }) => {
            this.invoiceCount = data;
          });
      },

      getCounts() {
        this.getOrders();
        this.getWaitinglistOrders();
        this.getProducts();
        this.getInvoices();
      },

      signOut() {
        this.signOutAction().then(() => {
          this.$router.replace({
            name: "login",
          });
        });
      },

      setLogo() {
        let now = new Date();
        let day = this.$options.filters.date(now, "d");
        let month = this.$options.filters.date(now, "M");

        if (month == 12) {
          if (day >= 6) {
            this.logo = "logo_christmas.svg";
            this.logoSmall = "logo_small_christmas.svg";
          }
        }
      },
    },

    beforeMount() {
      this.setLogo();
    },

    mounted() {
      this.getCounts();

      setInterval(() => {
        this.getCounts();
      }, 60000);
    },
  };
</script>