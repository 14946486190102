var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',[_c('b-col',[_c('b-table-simple',{staticClass:"table-order-info"},[_c('b-tbody',[_c('b-tr',[_c('b-th',[_vm._v(" "+_vm._s(_vm.OrderChannelString[_vm.order.channel])+" ordernummer ")]),_c('b-td',{attrs:{"colspan":"2"}},[_c('span',[_vm._v(" "+_vm._s(_vm.order.order_number)+" ")])])],1),_c('b-tr',{staticClass:"table-spacing-top"},[_c('b-th',[_vm._v("Klantnaam")]),_c('b-td',{staticClass:"column-input",attrs:{"colspan":"2"}},[_c('Input',{model:{value:(_vm.order.delivery_name),callback:function ($$v) {_vm.$set(_vm.order, "delivery_name", $$v)},expression:"order.delivery_name"}})],1)],1),_c('b-tr',[_c('b-th',[_vm._v("Bezorgadres")]),_c('b-td',{staticClass:"column-input",attrs:{"colspan":"2"}},[_c('Input',{model:{value:(_vm.order.delivery_address),callback:function ($$v) {_vm.$set(_vm.order, "delivery_address", $$v)},expression:"order.delivery_address"}})],1)],1),_c('b-tr',[_c('b-th',[_vm._v("Land van bezorging")]),_c('b-td',{staticClass:"column-input",attrs:{"colspan":"2"}},[_c('b-form-select',{model:{value:(_vm.order.delivery_country),callback:function ($$v) {_vm.$set(_vm.order, "delivery_country", $$v)},expression:"order.delivery_country"}},[_c('b-form-select-option',{attrs:{"value":"NL"}},[_vm._v(" 🇳🇱 Nederland ")]),_c('b-form-select-option',{attrs:{"value":"BE"}},[_vm._v(" 🇧🇪 België ")])],1)],1)],1),_c('b-tr',[_c('b-th',[_vm._v("Levering")]),_c('b-td',{staticClass:"column-input",attrs:{"colspan":"2","width":"1"}},[_c('b-select',{model:{value:(_vm.order.delivery_type),callback:function ($$v) {_vm.$set(_vm.order, "delivery_type", $$v)},expression:"order.delivery_type"}},[_c('b-select-option',{attrs:{"value":1}},[_vm._v("Op datum")]),_c('b-select-option',{attrs:{"value":2}},[_vm._v("Op afroep")]),_c('b-select-option',{attrs:{"value":3}},[_vm._v("Laten afhalen")]),_c('b-select-option',{attrs:{"value":4}},[_vm._v("Eigen bus")])],1),(
                  _vm.order.delivery_type === 1 ||
                  _vm.order.delivery_type === 3 ||
                  _vm.order.delivery_type === 4
                )?_c('vc-date-picker',{attrs:{"is-expanded":"","title-position":"left","show-iso-weeknumbers":"","attributes":_vm.calAttrs,"mode":"date","available-dates":[
                  { start: new Date(), end: null },
                  _vm.order.delivery_date,
                ],"disabled-dates":[
                  { weekdays: [1, 7] },
                  { start: null, end: new Date() },
                ],"locale":"nl","trim-weeks":""},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, inputEvents }){return [_c('b-input-group',{staticClass:"mt-2",scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',[_c('i',{staticClass:"fad fa-calendar"})])]},proxy:true}],null,true)},[_c('b-input',_vm._g({attrs:{"value":inputValue}},inputEvents))],1)]}}],null,false,3758765037),model:{value:(_vm.order.delivery_date),callback:function ($$v) {_vm.$set(_vm.order, "delivery_date", $$v)},expression:"order.delivery_date"}}):_vm._e(),_c('b-alert',{staticClass:"mt-2",attrs:{"variant":_vm.withinRange ? 'warning' : 'primary',"show":(_vm.order.distance_as_the_crow_flies &&
                    _vm.order.status === _vm.OrderStatus.ON_HOLD) ||
                  _vm.order.status === _vm.OrderStatus.PROCESSING}},[_c('p',{staticClass:"mb-2"},[(_vm.withinRange)?_c('span',[_c('i',{staticClass:"fas fa-circle-exclamation text-warning mr-1"})]):_vm._e(),_c('i',[_vm._v(" Wanneer de afstand minder is dan 30 kilometer kan de levering met eigen bus worden gedaan. ")])]),_c('p',[_c('b',[_vm._v("Afstand hemelsbreed")]),_vm._v(" "+_vm._s(_vm._f("formatNumber")((_vm.order.distance_as_the_crow_flies / 1000),2))+" kilometer ")])])],1)],1),_c('b-tr',{staticClass:"table-spacing-top"},[_c('b-th',[_vm._v("Status")]),_c('b-td',{class:{ 'column-input': _vm.user.role.id === _vm.$root.Role.ADMIN },attrs:{"colspan":"2"}},[(_vm.user.role.id === _vm.$root.Role.ADMIN)?[_c('b-form-select',{model:{value:(_vm.order.status),callback:function ($$v) {_vm.$set(_vm.order, "status", $$v)},expression:"order.status"}},_vm._l((_vm.OrderStatus),function(value,index){return _c('b-form-select-option',{key:index,attrs:{"value":value}},[_vm._v(_vm._s(_vm.OrderStatusString[value])+" ")])}),1)]:[_vm._v(" "+_vm._s(_vm.OrderStatusString[_vm.order.status])+" ")]],2)],1),_c('b-tr',[_c('b-th',[_vm._v("Aangemaakt op")]),_c('b-td',{attrs:{"colspan":"2"}},[_c('span',[_vm._v(" "+_vm._s(_vm._f("date")(new Date(_vm.order.created_at)))+" ")])])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }