export const CustomerTypeString = {
  'dealer': 'Dealer',
  'dealer_project': 'Dealer Project',
  'dealer_inactive': 'Dealer inactief',
  'dealer_star': 'Dealer *',
  'dealer_0': 'Dealer 0',
  'dealer_1': 'Dealer 1',
  'dealer_2': 'Dealer 2',
  'customer': 'Klant',
  'gadero': 'Gadero',
};