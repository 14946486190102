var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-button',{staticClass:"agenda__item",class:{
    'agenda__item--regular': _vm.appointment.type === _vm.AppointmentType.REGULAR,
    'agenda__item--staff': _vm.appointment.type === _vm.AppointmentType.STAFF,
    'agenda__item--showroom': _vm.appointment.type === _vm.AppointmentType.SHOWROOM,
    'agenda__item--approval-required':
      _vm.appointment.type === _vm.AppointmentType.APPROVAL_REQUIRED,
  },attrs:{"id":`popover-appointment-${_vm.appointment.id}`}},[_c('span',[_vm._v(" "+_vm._s(_vm.appointment.title)+" "),(_vm.appointment.note)?[_vm._v(" - "+_vm._s(_vm.appointment.note)+" ")]:_vm._e()],2),(_vm.auth.role.id === _vm.$root.Role.ADMIN)?_c('b-popover',{attrs:{"target":`popover-appointment-${_vm.appointment.id}`,"custom-class":"popover-menu","placement":"bottom","triggers":"blur click","boundary":"window"}},[_c('b-button-group',{attrs:{"vertical":""}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$parent.$parent.$parent.$parent.openUpdateModal(_vm.appointment)}}},[_c('i',{staticClass:"far fa-pencil"}),_vm._v(" Afspraak bewerken ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$parent.$parent.$parent.$parent.deleteAppointment(_vm.appointment)}}},[_c('i',{staticClass:"far fa-trash-alt"}),_vm._v(" Afspraak verwijderen ")]),(_vm.appointment.group)?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$parent.$parent.$parent.$parent.deleteAppointment(_vm.appointment, true)}}},[_c('i',{staticClass:"far fa-calendar-alt"}),_vm._v(" Afspraak en volgende verwijderen ")]):_vm._e()],1),_c('div',{staticClass:"popover-content"},[(_vm.appointment.group)?[_c('p',[_vm._v(" Wordt "),_c('strong',[_vm._v(" "+_vm._s(_vm.AppointmentIntervalString[_vm.appointment.interval])+" ")]),_vm._v(" herhaald t/m "),_c('strong',[_vm._v(" "+_vm._s(_vm._f("date")(new Date(_vm.appointment.end_date),"d MMMM Y"))+" ")])])]:_vm._e(),(_vm.appointment.author)?_c('p',[_c('strong',[_vm._v("Door:")]),_vm._v(" "+_vm._s(_vm.appointment.author.formatted_name)+" ")]):_vm._e()],2)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }