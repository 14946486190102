<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <back-button />
      </b-col>
      <b-col>
        <search-select
          searchable
          @input="searchProduct"
          searchType="products"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="32">
        <b-card>
          <h2 v-b-tooltip.hover.bottom="product.name">{{ product.name }}</h2>
          <b-alert
            :show="
              product.physical_stock + product.incoming_stock <
                product.stock_warning_threshold && !product.ignore_stock_warning
            "
            variant="danger"
          >
            <i class="fas fa-exclamation-triangle text-danger mr-2" />De vooraad
            is voorbij de minimale gewenste hoeveelheid!
          </b-alert>

          <b-form @submit.prevent="updateProduct()">
            <ProductInfoTable :product.sync="product" :suppliers="suppliers" />
            <b-row>
              <b-col class="text-right">
                <button-spinner
                  class="mt-4"
                  variant="primary"
                  type="submit"
                  :loading="loading.updateProduct"
                >
                  Product opslaan
                </button-spinner>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

        <ExpectedDeliveriesTable
          :id="`purchase-orders`"
          :loading="loading.product"
        />

        <OrderTable
          :id="`open-orders`"
          :status="$root.OrderStatus.PROCESSING"
          :loading="loading.product"
        />

        <OrderTable
          :id="`finished-orders`"
          :status="$root.OrderStatus.SENT"
          :loading="loading.product"
        />

        <Stats />
      </b-col>

      <b-col md="16">
        <b-card no-body class="card-scroll-collapse">
          <b-card-body>
            <h4 class="card-title">Balans product</h4>
            <b-form-group>
              <b-input-group>
                <b-input-group-prepend>
                  <b-button
                    variant="outline"
                    @click="physical_stock > 0 ? physical_stock-- : null"
                    class="align-middle"
                  >
                    <i class="far fa-minus mr-0"></i>
                  </b-button>
                </b-input-group-prepend>
                <b-form-input
                  v-model="physical_stock"
                  type="number"
                  :min="0"
                  :max="9999999999"
                  class="text-center"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline"
                    @click="physical_stock++"
                    class="align-middle"
                  >
                    <i class="far fa-plus mr-0"></i>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <button-spinner
              block
              @click="updateProduct('physical_stock', physical_stock)"
              :loading="loading.updateProduct"
            >
              Voorraad bijwerken
            </button-spinner>
          </b-card-body>
        </b-card>

        <b-card no-body class="mt-4 card-scroll-collapse">
          <b-card-body>
            <h4 class="card-title">
              Voorraadwijzigingen
              <b-button
                size="sm"
                variant="primary"
                v-b-toggle="'stockChanges'"
                class="float-right"
              >
                <span class="when-open"><i class="far fa-minus mr-0" /></span>
                <span class="when-closed"><i class="far fa-plus mr-0" /></span>
              </b-button>
            </h4>
          </b-card-body>

          <b-collapse :id="'stockChanges'" :visible="true">
            <div class="pb-2 px-3 d-flex align-items-center">
              <strong class="text-nowrap mr-2">Aantal wijzigingen: </strong>
              <b-select
                size="sm"
                v-model="productStockLogsLimit"
                @change="getStockLogs()"
              >
                <b-select-option :value="50">50</b-select-option>
                <b-select-option :value="100">100</b-select-option>
                <b-select-option :value="150">150</b-select-option>
              </b-select>
            </div>

            <b-table
              striped
              hover
              small
              responsive
              :busy="loading.product || loading.stockLog"
              :items="productStockLogs"
              :fields="fields.physical_stock_logs"
              class="mb-0"
            >
              <template #table-busy>
                <div class="text-center text-secondary my-2">
                  <b-spinner variant="secondary" class="align-middle mr-3" />
                  <strong class="align-middle">
                    Voorraadwijzigingen worden geladen
                  </strong>
                </div>
              </template>

              <template #cell(stock_diff)="row">
                <div
                  :class="{
                    'text-success': row.item.old_value < row.item.new_value,
                    'text-danger': row.item.old_value > row.item.new_value,
                    'text-primary': row.item.old_value === row.item.new_value,
                  }"
                >
                  <i
                    class="mx-2"
                    :class="{
                      'fas fa-caret-up text-success':
                        row.item.old_value < row.item.new_value,
                      'fas fa-caret-down text-danger':
                        row.item.old_value > row.item.new_value,
                      'fas fa-minus text-primary':
                        row.item.old_value === row.item.new_value,
                    }"
                  />
                  {{ Math.abs(row.item.new_value - row.item.old_value) }}
                </div>
              </template>

              <template #cell(created_at)="row">
                <span
                  v-b-tooltip.hover.left="
                    $options.filters.date(new Date(row.item.created_at))
                  "
                >
                  {{ new Date(row.item.created_at) | date("dd LLL") }}
                </span>
              </template>

              <template #cell(object_type)="row">
                <span v-if="row.item.type === 'product' && row.item.changed_by">
                  {{ row.item.changed_by.first_name }}
                </span>

                <span v-else-if="row.item.type === 'purchase-orderline'">
                  Levering
                </span>

                <router-link
                  v-else-if="row.item.type === 'creditline'"
                  :to="{
                    name: 'credit.show',
                    params: { id: row.item.creditline.credit_note.id },
                  }"
                >
                  {{ row.item.creditline.credit_note.number }}
                </router-link>

                <router-link
                  v-else-if="
                    row.item.type === 'orderline' &&
                    row.item.orderline &&
                    row.item.orderline.order
                  "
                  :to="{
                    name: 'order.show',
                    params: { id: row.item.orderline.order.id },
                  }"
                >
                  <span
                    class="text-monospace"
                    v-if="row.item.orderline && row.item.orderline.order"
                  >
                    {{ row.item.orderline.order.order_number }}
                  </span>
                </router-link>

                <span v-else>n.b.</span>
              </template>
            </b-table>
          </b-collapse>
        </b-card>

        <b-card no-body class="mt-4 card-scroll-collapse">
          <b-card-body>
            <h4 class="card-title">
              Voorraad waarschuwing
              <b-button
                size="sm"
                variant="primary"
                v-b-toggle="'warnings'"
                class="float-right"
              >
                <span class="when-open"><i class="far fa-minus mr-0" /></span>
                <span class="when-closed"><i class="far fa-plus mr-0" /></span>
              </b-button>
            </h4>
          </b-card-body>

          <b-collapse :id="'warnings'" :visible="false">
            <b-card-body class="pt-0">
              <b-form-group
                description="Wanneer de fysieke voorraad lager is dan dit getal zal dit product uitgelicht worden in het overzicht."
              >
                <b-input
                  type="number"
                  v-model="product.stock_warning_threshold"
                  @blur="updateProduct('stock_warning_threshold')"
                  placeholder="0"
                  min="0"
                  step="1"
                />
              </b-form-group>

              <b-form-checkbox
                switch
                v-model="product.ignore_stock_warning"
                @change="updateProduct('ignore_stock_warning')"
              >
                Voorraad alarm negeren
              </b-form-checkbox>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mt-4 card-scroll-collapse">
          <b-card-body>
            <h4 class="card-title">
              Voorraad locaties
              <b-button
                size="sm"
                variant="primary"
                v-b-toggle="'locations'"
                class="float-right"
              >
                <span class="when-open"><i class="far fa-minus mr-0" /></span>
                <span class="when-closed"><i class="far fa-plus mr-0" /></span>
              </b-button>
            </h4>
          </b-card-body>

          <b-collapse :id="'locations'" :visible="false">
            <b-table
              hover
              show-empty
              :items="locations"
              :fields="fields.locations"
              :busy="loading.product || loading.locations"
              class="mb-0 table-font-small table-semi-narrow"
              thead-class="d-none"
            >
              <template #table-busy>
                <div class="text-center">
                  <b-spinner
                    variant="secondary"
                    small
                    class="align-middle mr-1"
                  />
                  <strong class="align-middle">
                    Locaties worden geladen
                  </strong>
                </div>
              </template>

              <template #empty="">
                <span class="text-center d-block">
                  Geen locaties gevonden
                </span>
              </template>

              <template #cell(on_location)="row">
                <b-form-checkbox
                  v-model="row.item.checked"
                  @change="updateLocation(row.item)"
                />
              </template>
            </b-table>
          </b-collapse>
        </b-card>

        <b-card no-body class="mt-4 card-scroll-collapse">
          <b-card-body>
            <h4 class="card-title">
              Categorieën
              <b-button
                size="sm"
                variant="primary"
                v-b-toggle="'categories'"
                class="float-right"
              >
                <span class="when-open"><i class="far fa-minus mr-0" /></span>
                <span class="when-closed"><i class="far fa-plus mr-0" /></span>
              </b-button>
            </h4>
          </b-card-body>

          <b-collapse :id="'categories'" :visible="false">
            <b-table
              hover
              show-empty
              :items="categories"
              :fields="fields.categories"
              :busy="loading.product || loading.categories"
              class="mb-0 table-font-small table-semi-narrow"
              thead-class="d-none"
            >
              <template #table-busy>
                <div class="text-center">
                  <b-spinner
                    variant="secondary"
                    small
                    class="align-middle mr-1"
                  />
                  <strong class="align-middle">
                    Categorieën worden geladen
                  </strong>
                </div>
              </template>

              <template #empty="">
                <span class="text-center d-block">
                  Geen categorieën gevonden
                </span>
              </template>

              <template #cell(actions)="row">
                <b-form-checkbox
                  v-model="row.item.checked"
                  @change="updateCategory(row.item)"
                />
              </template>
            </b-table>
          </b-collapse>
        </b-card>

        <b-card no-body class="mt-4">
          <b-card-body>
            <h4 class="card-title">
              Tijdlijn
              <b-button
                size="sm"
                variant="primary"
                v-b-toggle="'productUpdates'"
                class="float-right"
              >
                <span class="when-open"><i class="far fa-minus mr-0" /></span>
                <span class="when-closed"><i class="far fa-plus mr-0" /></span>
              </b-button>
            </h4>
          </b-card-body>

          <b-collapse :id="'productUpdates'" :visible="true">
            <b-card-body class="pt-0">
              <template v-if="productUpdates.length">
                <div class="timeline timeline--has-pagination mt-3">
                  <div
                    :class="`timeline__item timeline__item--update`"
                    v-for="(line, key) in productUpdates"
                    v-bind:key="key"
                  >
                    <div>
                      <div class="timeline__date-wrapper">
                        <span class="timeline__date">
                          <b>Update</b>
                          {{ new Date(line.created_at) | date }}
                        </span>
                      </div>

                      <div class="timeline__content">
                        <div class="timeline__message">
                          {{ line.comment }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="d-flex align-items-center mt-3"
                    :class="{
                      'justify-content-between':
                        productUpdatesMeta.current_page !==
                        productUpdatesMeta.last_page,
                      'justify-content-end':
                        productUpdatesMeta.current_page ===
                        productUpdatesMeta.last_page,
                    }"
                  >
                    <button-spinner
                      :loading="loading.productUpdates"
                      variant="light"
                      @click="paginate"
                      v-if="
                        productUpdatesMeta.current_page !==
                        productUpdatesMeta.last_page
                      "
                    >
                      <i class="far fa-rotate mr-2" />Toon meer
                    </button-spinner>

                    <span class="timeline__meta">
                      toont: {{ productUpdates.length }} /
                      {{ productUpdatesMeta.total }}
                    </span>
                  </div>
                </div>
              </template>
              <template v-else> Geen updates gevonden </template>
            </b-card-body>
          </b-collapse>
        </b-card>
      </b-col>
    </b-row>

    <context-menu
      :id="$route.name"
      :key="$route.fullPath"
      :display="showContextMenu"
      :component="contextMenuComponent"
      ref="menu"
    />
  </div>
</template>

<script>
  import BackButton from "@/components/BackButton";
  import ContextMenu from "@/components/ContextMenu";
  import SearchSelect from "@/components/SearchSelect";

  import Stats from "./Partials/_Stats";
  import OrderTable from "./Partials/_OrderTable";
  import ProductInfoTable from "./Partials/_ProductInfoTable";
  import ExpectedDeliveriesTable from "./Partials/_ExpectedDeliveriesTable";

  export default {
    components: {
      BackButton,
      OrderTable,
      ContextMenu,
      ProductInfoTable,
      ExpectedDeliveriesTable,
      SearchSelect,
      Stats,
    },

    data() {
      return {
        loading: {
          product: false,
          stockLog: false,
          updateProduct: false,
          categories: false,
          locations: false,
          productUpdates: false,
        },

        physical_stock: 0,
        showContextMenu: false,
        contextMenuComponent: "order",

        product: {},
        locations: [],
        categories: [],
        suppliers: [],

        productStockLogs: [],
        productStockLogsMeta: {},
        productStockLogsLimit: 50,

        productUpdates: [],
        productUpdatesMeta: {},

        page: {
          productUpdates: 1,
        },

        errors: {},

        fields: {
          locations: [
            {
              key: "name",
            },
            {
              key: "on_location",
              class: "column-fit-content",
            },
          ],

          categories: [
            {
              key: "name",
            },
            {
              key: "actions",
              class: "column-fit-content",
            },
          ],

          physical_stock_logs: [
            { key: "stock_diff", label: "Verschil" },
            { key: "old_value", label: "Oud" },
            { key: "new_value", label: "Nieuw" },
            { key: "created_at", label: "Datum" },
            { key: "object_type", label: "Door" },
          ],
        },
      };
    },

    methods: {
      getProduct() {
        this.loading.product = true;

        this.$http
          .get(`products/${this.$route.params.id}`)
          .then((response) => {
            this.product = response.data.data;
          })
          .then(() => {
            this.physical_stock = this.product.physical_stock;

            this.getLocations();
            this.getCategories();
            this.getSuppliers();
            this.getStockLogs();

            this.loading.product = false;
          });
      },

      getStockLogs() {
        this.loading.stockLog = true;
        this.productStockLogs = [];

        let params = {
          limit: this.productStockLogsLimit,
          product_id: this.product.id,
          type: 3,
        };

        this.$http
          .get(`product-stock-logs`, { params: params })
          .then((response) => {
            this.productStockLogs = response.data.data;
            this.productStockLogsMeta = response.data.meta;
          })
          .then(() => {
            this.loading.stockLog = false;
          });
      },

      getLocations() {
        this.loading.locations = true;

        this.$http
          .get(`locations`, {
            params: {
              product_id: this.product.id,
            },
          })
          .then((response) => {
            this.locations = response.data.data;

            for (let location of this.locations) {
              for (let product of location.products) {
                location.checked = product.id === this.product.id ? true : false;
              }
            }
          })
          .then(() => {
            this.loading.locations = false;
          });
      },

      getCategories() {
        this.loading.categories = true;

        this.$http
          .get("product-categories", {
            params: {
              orderBy: "name",
              orderDirection: "ASC",
              product_id: this.product.id,
            },
          })
          .then((response) => {
            this.categories = response.data.data;

            for (let category of this.categories) {
              for (let product of category.products) {
                category.checked = product.id === this.product.id ? true : false;
              }
            }
          })
          .then(() => {
            this.loading.categories = false;
          });
      },

      updateLocation(location) {
        this.$http
          .put(`locations/${location.id}/sync-product`, this.product)
          .then(() => {
            this.$root.notify("De locatie is succesvol bijgewerkt.");
          });
      },

      updateCategory(category) {
        this.$http
          .put(`product-categories/${category.id}/sync-product`, this.product)
          .then(() => {
            this.$root.notify("De categorie is succesvol bijgewerkt.");
          });
      },

      getSuppliers() {
        this.$http.get(`suppliers`).then((response) => {
          this.suppliers = response.data.data;
        });
      },

      updateProduct(field = null, value = null) {
        this.loading.updateProduct = true;

        let data = field
          ? {
              [field]: value !== null ? value : this.product[field],
            }
          : this.product;

        this.$http
          .put(`products/${this.product.id}`, data)
          .then(() => {
            this.$root.notify("Het product is succesvol aangepast.");

            this.product.physical_stock =
              field === "physical_stock"
                ? this.physical_stock
                : this.product.physical_stock;

            this.loading.updateProduct = false;
          })
          .then(() => {
            this.getProduct();
          })
          .catch((errors) => {
            this.errors = this.$options.filters.setErrors(errors.data.errors);
          });
      },

      rowContextMenuHandler(item, _, event, component) {
        console.log(item, _, event, component);

        this.contextMenuComponent = component;

        this.$refs.menu.open(event, item);
        event.preventDefault();
      },

      hideInStockBalance(checked) {
        if (checked) {
          this.$root.showConfirm(
            "Weet je zeker dat je dit product wilt verbergen in de voorraadbalans?",
            () => {
              /* No callback */
            },
            () => {
              this.product.hide_in_balance_sheet = false;
            },
            "Nee",
            "Ja"
          );
        }
      },

      archiveProduct(checked) {
        if (checked) {
          this.$http.delete(`/products/${this.product.id}`).then(() => {
            this.$root.notify("Het product is succesvol gearchiveerd.");

            this.archived = true;
          });
        } else {
          this.$http.put(`/products/${this.product.id}/restore`).then(() => {
            this.$root.notify("Het product is succesvol hersteld.");

            this.archived = false;
          });
        }
      },

      searchProduct(record) {
        this.$router.push({
          name: "product.show",
          params: { id: record.id },
        });
      },

      paginate() {
        this.page.productUpdates++;
        this.getProductUpdates();
      },

      getProductUpdates() {
        this.loading.productUpdates = true;

        let params = {
          page: this.page.productUpdates,
          product: this.$route.params.id,
        };

        this.$http
          .get("product-updates", { params: params })
          .then(({ data }) => {
            this.productUpdates = this.productUpdates.concat(data.data);
            this.productUpdatesMeta = data.meta;
          })
          .then(() => {
            this.loading.productUpdates = false;
          });
      },

      initComponent() {
        this.getProduct();
        this.getProductUpdates();
      },
    },

    beforeRouteUpdate(to, from, next) {
      next();
      this.initComponent();
    },

    mounted() {
      this.initComponent();
    },
  };
</script>