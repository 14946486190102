<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <h2>Facturen downloaden</h2>
          <p class="font-italic">
            Gebruik deze knop om alle verzonden facturen binnen het
            geselecteerde datumbereik te downloaden.
          </p>

          <b-form-input
            type="date"
            :disabled="loading.invoice_export"
            v-model="invoice_export.from_date"
            class="mt-3"
          />
          <b-form-input
            type="date"
            :disabled="loading.invoice_export"
            v-model="invoice_export.to_date"
            class="mt-2"
          />

          <b-button
            class="mt-3"
            :disabled="
              loading.invoice_export ||
              !invoice_export.to_date ||
              !invoice_export.from_date
            "
            block
            @click="downloadInvoices"
          >
            Exporteren
          </b-button>

          <div class="mt-3" v-if="loading.invoice_export">
            <span>Voortgang</span>
            <b-progress :max="invoice_export.total" height="2rem">
              <b-progress-bar
                :value="invoice_export.loaded"
                variant="secondary"
              >
                <strong>
                  {{
                    ((invoice_export.loaded / invoice_export.total) * 100)
                      | formatNumber(2)
                  }}/'-' / 100%
                </strong>
              </b-progress-bar>
            </b-progress>
          </div>
        </b-card>
      </b-col>

      <b-col>
        <b-card>
          <h2>Creditfacturen downloaden</h2>
          <p class="font-italic">
            Gebruik deze knop om alle verzonden creditfacturen binnen het
            geselecteerde datumbereik te downloaden.
          </p>

          <b-form-input
            type="date"
            :disabled="loading.credit_export"
            v-model="credit_export.from_date"
            class="mt-3"
          />
          <b-form-input
            type="date"
            :disabled="loading.credit_export"
            v-model="credit_export.to_date"
            class="mt-2"
          />

          <b-button
            class="mt-3"
            :disabled="
              loading.credit_export ||
              !credit_export.to_date ||
              !credit_export.from_date
            "
            block
            @click="downloadCredits"
          >
            Exporteren
          </b-button>

          <div class="mt-3" v-if="loading.credit_export">
            <span>Voortgang</span>
            <b-progress :max="credit_export.total" height="2rem">
              <b-progress-bar :value="credit_export.loaded" variant="secondary">
                <strong>
                  {{
                    ((credit_export.loaded / credit_export.total) * 100)
                      | formatNumber(2)
                  }}/'-' / 100%
                </strong>
              </b-progress-bar>
            </b-progress>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col md="24">
        <b-card>
          <h2>Voorraad balans</h2>
          <p class="font-italic">
            Elke maand zal automatisch een export worden gedraaid en via de mail
            worden verstuurd. Gebruik deze knop om tussentijds handmatig een
            export te draaien.
          </p>

          <b-button class="mt-3" block @click="sendStockReport">
            Exporteren
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import Axios from "axios";

  export default {
    data() {
      return {
        loading: {
          invoice_export: false,
          credit_export: false,
          stock_report: false,
        },

        stock_report: {
          loaded: 0,
          total: 0,
        },

        invoice_export: {
          from_date: null,
          to_date: null,
          loaded: 0,
          total: 0,
        },

        credit_export: {
          from_date: null,
          to_date: null,
          loaded: 0,
          total: 0,
        },
      };
    },

    methods: {
      downloadInvoices() {
        this.loading.invoice_export = true;

        let vm = this;

        this.invoice_export.total = 0;
        this.invoice_export.loaded = 0;

        let params = {
          from_date: this.invoice_export.from_date,
          to_date: this.invoice_export.to_date,
        };

        Axios({
          url: "export-invoices",
          params: params,
          method: "GET",
          responseType: "blob",
          onDownloadProgress: function (progressEvent) {
            vm.invoice_export.total = progressEvent.total;
            vm.invoice_export.loaded = progressEvent.loaded;
          },
        })
          .then((response) => {
            var fileURL = window.URL.createObjectURL(
              new Blob([response.data], { type: "application/zip" })
            );
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              "facturen_export_" +
                this.invoice_export.from_date +
                "_en_" +
                this.invoice_export.to_date +
                ".zip"
            );

            document.body.appendChild(fileLink);

            fileLink.click();
          })
          .then(() => {
            this.loading.invoice_export = false;
          });
      },

      downloadCredits() {
        this.loading.credit_export = true;

        let vm = this;

        this.credit_export.total = 0;
        this.credit_export.loaded = 0;

        let params = {
          from_date: this.credit_export.from_date,
          to_date: this.credit_export.to_date,
        };

        Axios({
          url: "export-credit-notes",
          params: params,
          method: "GET",
          responseType: "blob",
          onDownloadProgress: function (progressEvent) {
            vm.credit_export.total = progressEvent.total;
            vm.credit_export.loaded = progressEvent.loaded;
          },
        })
          .then((response) => {
            var fileURL = window.URL.createObjectURL(
              new Blob([response.data], { type: "application/zip" })
            );
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              "credit_export_" +
                this.credit_export.from_date +
                "_en_" +
                this.credit_export.to_date +
                ".zip"
            );

            document.body.appendChild(fileLink);

            fileLink.click();
          })
          .then(() => {
            this.loading.credit_export = false;
          });
      },

      sendStockReport() {
        this.loading.stock_report = true;

        this.$http
          .get("send-stock-report")
          .then(() => {
            this.$root.notify("Voorraad balans is succesvol verstuurd");
          })
          .then(() => {
            this.loading.stock_report = false;
          });
      },
    },
  };
</script>