<template>
  <div
    class="agenda__category"
    v-if="
      selectedCategories.includes(categoryId) &&
      appointments &&
      appointments[day]
    "
  >
    <span>
      {{ AppointmentTypeString[appointmentType] }}
    </span>
    <div v-if="appointmentType === AppointmentType.REMINDER">
      <agenda-item-reminder
        v-for="(appointment, index) of appointments[day]"
        :key="index"
        :appointment="appointment"
      />
    </div>
    <div v-else>
      <agenda-item-appointment
        v-for="(appointment, index) of appointments[day]"
        :key="index"
        :appointment="appointment"
      />
    </div>
  </div>
</template>

<script>
  import {
    AppointmentTypeString,
    AppointmentType,
  } from "@/enums/Appointment/Type";

  import AgendaItemReminder from "../_AgendaItems/_Reminder";
  import AgendaItemAppointment from "../_AgendaItems/_Appointment";

  export default {
    components: {
      AgendaItemReminder,
      AgendaItemAppointment,
    },

    props: {
      selectedCategories: {
        type: Array,
        default: () => {
          return [];
        },
      },

      appointments: {
        type: [Object, Array],
        default: () => {
          return {};
        },
      },

      day: {
        type: String,
      },

      appointmentType: {
        type: Number,
      },

      categoryId: {
        type: Number,
      },
    },

    data() {
      return {
        AppointmentType: AppointmentType,
        AppointmentTypeString: AppointmentTypeString,
      };
    },

    methods: {
      getAppointments() {
        this.$parent.getAppointments();
      },
    },
  };
</script>