/**
 * Imports
 */
require('./filters/index')

import Vue from 'vue'

import '@/plugins/bootstrap-vue'
import VCalendar from 'v-calendar';
import VueProgressBar from 'vue-progressbar'

import App from '@/App'
import router from '@/router'

import store from '@/store'

import Axios from 'axios';

import Input from '@/components/Input'
import ButtonSpinner from '@/components/ButtonSpinner'

import { Role } from '@/enums/Role/Role'
import { PaymentMethod } from '@/enums/Customer/PaymentMethod'
import { OrderStatus } from "@/enums/Order/Status";
import { CreditStatus, CreditStatusString } from "@/enums/CreditNote/Status";
import { CustomerTypeString } from "@/enums/Customer/CustomerType";

/**
 * Basic settings
 */
Vue.config.productionTip = false
Vue.prototype.$http = Axios;

/**
 * Axios settings
 */
Axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Axios.defaults.withCredentials = true;

/**
 * Custom Middleware
 */
router.beforeEach((to, from, next) => {
  /**
   * Redirection for unauthenticated Clients
   */
  if (
    to.name !== 'login'
    && !store.getters['auth/authenticated']
  ) {
    next({ name: 'login', query: { referer: to.fullPath } });
  } else if (
    (to.name === 'login')
    && store.getters['auth/authenticated']
  ) {
    next({ name: 'dashboard' });
  } else {
    next();
  }

  /**
   * Redirection for non admin Clients
   */
  if (to.meta.admin && store.getters['auth/user'].role.id !== Role.ADMIN) {
    /**
     * Exception for own profile page
     */
    if (
      to.name === 'user.show'
      && parseInt(to.params.id) === store.getters['auth/user'].id
    ) {
      next();
    } else {
      next({ name: 'dashboard' });
    }
  }

  /**
   * Set page title
   */
  const title = 'Floer Beheer';
  document.title = to.meta.title ? `${title} - ${to.meta.title}` : title

  var classList = document.body.classList;
  while (classList.length > 0) {
    classList.remove(classList.item(0));
  }

  classList.add(to.name)

});

Vue.use(VueProgressBar, {
  color: '#b194a4',
  failedColor: '#e76f51',
  thickness: '3px',
});

Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});

/**
 * Global Accessible Components
 */
Vue.component('Input', Input);
Vue.component('ButtonSpinner', ButtonSpinner);

/**
 * Initialize Vue
 */
store.dispatch('auth/attempt', localStorage.getItem('token')).then(() => {
  new Vue({
    router,
    store,

    data() {
      return {
        Role,
        PaymentMethod,
        OrderStatus,
        CreditStatus,
        CreditStatusString,
        CustomerTypeString,
      }
    },

    methods: {
      showConfirm(message, callback, fallback, cancelText = 'Cancel', okText = 'OK') {
        this.$bvModal
          .msgBoxConfirm(message, {
            title: 'Bevestiging',
            size: 'm',
            buttonSize: 'sm',
            okTitle: okText,
            cancelTitle: cancelText,
            centered: true
          })
          .then((value) => {
            if (value === true) {
              callback();
            }
            else {
              if (fallback) {
                fallback();
              }
            }
          });
      },

      notify(message, variant = 'success') {
        let icon = 'fa-circle-check';

        if (variant === 'danger') {
          icon = 'fa-circle-exclamation'
        } else if (variant === 'warning') {
          icon = 'fa-circle-info'
        }

        const h = this.$createElement;

        if (message instanceof Array) {
          let temp = [];

          message.forEach((error) => {
            temp.push(
              h('span', [error])
            );
          })

          message = temp;
        }

        const vNodesMsg = h(
          'p',
          { class: ['d-flex align-items-center'] },
          [
            h('i', {
              class: ['fal', icon, `text-${variant}`]
            }),
            message,
          ]
        );

        this.$bvToast.toast([vNodesMsg], {
          solid: true,
          autoHideDelay: 5000,
          noCloseButton: true,
        });
      },

      copyData(order = null) {
        let text = "";

        let name = order.delivery_name ?? order.customer.billing_formatted_name;

        let deliveryAddress = order.delivery_address.split(", ");
        text += `${name}\r\n${deliveryAddress[0]}\r\n${deliveryAddress[1]}`;

        text += `\r\n${order.customer.billing_phone}`;
        text += `\r\n${order.customer.email}`;

        let samples = [];

        order.orderlines.forEach((orderline) => {
          orderline.samples.forEach((sample) => {
            samples.push(sample.sku);
          });
        });

        text += `\r\n${samples.join(', ')}`;

        var dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand("copy", true);
        document.body.removeChild(dummy);

        this.$root.notify(
          "De verzenddata is succesvol gekopieerd."
        );
      },

      applyFilters(filters) {
        this.$router.push({
          query: filters,
        });
      },

      setFilters(filters) {
        if (this.$route.query.tab) {
          this.$route.query.tab = parseInt(this.$route.query.tab)
        }

        return { ...filters, ...this.$route.query };
      },

      rowClickRedirect(record, routeName) {
        this.$router.push({
          name: routeName,
          params: { id: record.id },
        });
      }
    },

    render: h => h(App)
  }).$mount('#app')
});
