export const OrderDeliveryType = {
  DATE: 1,
  ON_CALL: 2,
  PICK_UP: 3,
  VAN_DELIVERY: 4,
};

export const OrderDeliveryTypeString = {
  1: 'Levering',
  2: 'Op afroep',
  3: 'Afhalen',
  4: 'Eigen bus'
};