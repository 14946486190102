<template>
  <div
    class="agenda__category"
    v-if="
      selectedCategories.includes(categoryId) &&
      purchaseOrders &&
      purchaseOrders[day]
    "
  >
    <span>Inkooporders</span>
    <div>
      <agenda-item-purchase-order
        v-for="(purchaseOrder, index) of purchaseOrders[day]"
        :key="index"
        :purchase-order="purchaseOrder"
      />
    </div>
  </div>
</template>

<script>
  import AgendaItemPurchaseOrder from "../_AgendaItems/_PurchaseOrder";

  export default {
    components: {
      AgendaItemPurchaseOrder,
    },

    props: {
      selectedCategories: {
        type: Array,
        default: () => {
          return [];
        },
      },

      purchaseOrders: {
        type: [Object, Array],
        default: () => {
          return {};
        },
      },

      day: {
        type: String,
      },

      categoryId: {
        type: Number,
      },
    },
  };
</script>