<template>
  <b-card no-body class="mt-4">
    <template #header>
      <span>
        <template v-if="id === 'finished-orders'">Afgeronde orders</template>
        <template v-if="id === 'open-orders'">Open orders</template>
        <b-badge class="ml-2">
          {{ orders_meta.total }}
        </b-badge>
      </span>
      <b-button size="sm" variant="primary" v-b-toggle="id" class="float-right">
        <span class="when-open">Inklappen</span>
        <span class="when-closed">Uitklappen</span>
      </b-button>
    </template>
    <b-collapse :id="id" :visible="expanded">
      <b-card-body>
        <b-table
          striped
          bordered
          hover
          show-empty
          no-local-sorting
          :items="orders"
          :fields="fields"
          :busy="loading"
          @sort-changed="sort"
          @row-contextmenu="rowContextMenuHandler"
          @row-clicked="$root.rowClickRedirect($event, 'order.show')"
        >
          <template #table-busy>
            <div class="text-center">
              <b-spinner variant="secondary" class="align-middle mr-3" />
              <strong class="align-middle">Orders worden geladen</strong>
            </div>
          </template>

          <template #empty="">
            <span class="text-center d-block">
              Er zijn geen orders gevonden
            </span>
          </template>

          <template #cell(customer)="data">
            <span v-b-tooltip.hover :title="data.value">{{ data.value }}</span>
          </template>
        </b-table>

        <b-pagination-nav
          use-router
          first-number
          last-number
          v-model="orders_meta.current_page"
          :link-gen="linkGen"
          :number-of-pages="orders_meta.last_page"
          @change="getOrders(status)"
        >
          <template #prev-text>
            <i class="far fa-angle-left"></i>
          </template>
          <template #next-text>
            <i class="far fa-angle-right"></i>
          </template>
          <template #ellipsis-text>
            <i class="fal fa-ellipsis-h"></i>
          </template>
        </b-pagination-nav>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
  import { OrderStatus } from "@/enums/Order/Status";

  export default {
    props: {
      /**
       * The unique ID used for the collapse
       */
      id: {
        type: String,
        default: null,
      },

      status: {
        type: Number,
        default: null,
      },

      loading: {
        type: Boolean,
        default: false,
      },

      expanded: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        OrderStatus: OrderStatus,

        filters: {
          orderBy: "created_at",
          orderDirection: "DESC",
        },

        orders: [],
        orders_meta: {},

        fields: [
          {
            key: "order_number",
            label: "Ordernummer",
            sortable: true,
            thStyle: {
              width: "155px",
              maxWidth: "155px",
            },
            tdClass: "td-155-px",
          },
          {
            key: "customer",
            label: "Bedrijfsnaam",
            formatter: (value) => {
              return value.billing_company || "-";
            },
            thStyle: {
              width: "155px",
              maxWidth: "155px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            },
            tdClass: "td-155-px",
          },
          {
            key: "orderlines",
            label: "Aantal",
            formatter: (value) => {
              let quantity = 0;

              value.forEach((orderline) => {
                quantity += orderline.quantity;
              });

              return quantity;
            },
          },
          {
            key: "delivery_date",
            label: "Leverdatum",
            sortable: true,
            formatter: (value) => {
              if (value) {
                value = this.$options.filters.date(new Date(value), "dd/MM/yyyy");
              } else {
                value = "n.b.";
              }
              return value;
            },
          },
          {
            key: "created_at",
            label: "Aangemaakt op",
            formatter: (value) => {
              return this.$options.filters.date(
                new Date(value),
                "dd/MM/yyyy HH:mm:ss"
              );
            },
          },
        ],
      };
    },

    watch: {
      filters: {
        deep: true,
        handler() {
          this.getOrders();
        },
      },
    },

    mounted() {
      this.getOrders();
    },

    methods: {
      sort(ctx) {
        this.filters.orderBy = ctx.sortBy;
        this.filters.orderDirection = ctx.sortDesc ? "desc" : "asc";
      },

      linkGen(pageNum) {
        let link = "finished";

        if (this.status === this.OrderStatus.PROCESSING) {
          link = "open";
        }

        return pageNum === 1 ? "?" : `?${link}_orders_page=${pageNum}`;
      },

      rowContextMenuHandler(item, _, event) {
        this.$parent.rowContextMenuHandler(item, _, event, "order");
      },

      getOrders() {
        let page = null;

        if (this.$route.query.open_orders_page) {
          page = this.$route.query.open_orders_page;
        } else if (this.$route.query.finished_orders_page) {
          page = this.$route.query.finished_orders_page;
        }

        let params = {
          ...this.filters,
          status: this.status,
          product: this.$route.params.id,
          page: page,
        };

        this.$http
          .get(`orders`, {
            params: params,
          })
          .then((response) => {
            this.orders = response.data.data;
            this.orders_meta = response.data.meta;
          });
      },
    },
  };
</script>
